import {
  Calendar as AntdCalendar,
  Button,
  Popover,
  Select,
  Typography,
} from "antd";
import Link from "antd/es/typography/Link";
import Paragraph from "antd/es/typography/Paragraph";
import Title from "antd/es/typography/Title";
import TextWithPopover from "components/textWithPopover";
import { COLOR } from "constants/color";
import moment from "moment";
import { useState } from "react";
import { FaLink } from "react-icons/fa6";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoMdHome } from "react-icons/io";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { TbWorld } from "react-icons/tb";

function generateMockData() {
  const mockData = {};
  for (let month = 1; month <= 12; month++) {
    mockData[month] = {};
    const daysInMonth = new Date(2024, month, 0).getDate(); // Get the number of days in the month
    for (let date = 1; date <= daysInMonth; date++) {
      mockData[month][date] = [];
      // Generate random number of events for each day, ranging from 1 to 4 slots
      const numEvents = Math.floor(Math.random() * 4) + 1;
      let usedHours = [];
      for (let i = 0; i < numEvents; i++) {
        // Generate start time at a full hour
        let startTimeHour = Math.floor(Math.random() * 24);
        while (usedHours.includes(startTimeHour)) {
          startTimeHour = Math.floor(Math.random() * 24);
        }
        usedHours.push(startTimeHour);
        usedHours.push((startTimeHour + 1) % 24);
        const startTimeMinute = 0;
        const finishTimeHour = (startTimeHour + 1) % 24;
        const finishTimeMinute = 0;
        const startTime = `${startTimeHour
          .toString()
          .padStart(2, "0")}:${startTimeMinute.toString().padStart(2, "0")}`;
        const finishTime = `${finishTimeHour
          .toString()
          .padStart(2, "0")}:${finishTimeMinute.toString().padStart(2, "0")}`;
        const courseName = `คอร์ส ${String.fromCharCode(
          65 + Math.floor(Math.random() * 3)
        )}`;
        const studentName = `นาย ${String.fromCharCode(
          65 + Math.floor(Math.random() * 5)
        )} ${String.fromCharCode(3585 + Math.floor(Math.random() * 58))}`;
        const event = {
          startTime,
          finishTime,
          courseName,
          studentName,
        };
        // Randomly assign type as online or home
        if (Math.random() < 0.5) {
          event.type = "online";
          event.url = "https://zoom.us/";
        } else {
          event.type = "home";
          event.position = {
            lat: 18.3170581,
            lon: 99.3986862,
          };
          event.locationName = `สถานที่ที่บ้าน ${String.fromCharCode(
            65 + Math.floor(Math.random() * 3)
          )}`;
        }
        mockData[month][date].push(event);
      }
      // Sort events by start time
      mockData[month][date].sort((a, b) => {
        return a.startTime.localeCompare(b.startTime);
      });
    }
  }
  return mockData;
}

const mockData = generateMockData();

const popoverContent = (item) => {
  const startTimeFormatted = moment(item.startTime, "HH:mm")
    .locale("en")
    .format("dddd, MMMM D ⋅ h:mm A");
  const finishTimeFormatted = moment(item.finishTime, "HH:mm")
    .locale("en")
    .format("h:mm A");
  return (
    <div>
      <Typography>
        <Title className="!tw-font-kanit" level={4}>
          {item.courseName}
        </Title>
        <Paragraph className="!tw-font-kanit">
          {startTimeFormatted} – {finishTimeFormatted}
        </Paragraph>
        <Paragraph className="!tw-font-kanit">
          ผู้เรียน: {item.studentName}
        </Paragraph>
        {item.url ? (
          <Paragraph className="!tw-font-kanit">
            <Link href={item.url}>
              <FaLink color={COLOR.primary} className="tw-mr-2" />
              {item.url}
            </Link>
          </Paragraph>
        ) : null}
        {item.position ? (
          <Paragraph className="!tw-font-kanit">
            <a
              href={`https://www.google.com/maps?q=${item.position.lat},${item.position.lon}`}
              target="_blank"
              className="tw-flex tw-items-center"
            >
              <HiOutlineLocationMarker color={COLOR.primary} className="me-2" />
              <p className="text-primary !tw-mb-0	">
                {item.locationName || "Location Name"}
              </p>
            </a>
          </Paragraph>
        ) : null}
      </Typography>
    </div>
  );
};

export default function Calendar({
  showMock = false,
  compact = true,
  listData = [],
  onClickDate = () => {},
  onMonthChange = () => {},
  eventTitleContent,
  innerPopover,
  readOnly
}) {
  const [prevMonth, setPrevMonth] = useState(null);
  const monthCellRender = (value) => {
    const data = showMock
      ? Object.values(mockData[value.month()] || {}).flatMap((item) => item)
      : listData.toSorted((a, b) => a.startTime - b.startTime);
    return (
      <ul className="tw-flex tw-flex-col gap-2">
        {data.map((item, index) => (
          <Popover
            key={`calendar-${index}`}
            placement="left"
            content={innerPopover ? innerPopover(item) : popoverContent(item)}
            arrow={false}
            trigger="click"
          >
            <li
              key={item.content}
              className="tw-flex tw-items-center tw-p-0 tw-text-left tw-pl-2 tw-border-primary hover:tw-bg-light_primary"
            >
              {item.position ? (
                <IoMdHome color={COLOR.primary} />
              ) : (
                <TbWorld color={COLOR.primary} />
              )}
              <TextWithPopover
                className="tw-ml-2"
                text={`${moment(item.startTime, "HH:mm")
                  .locale("th")
                  .format("HH:mm")} ${item.title || ""}`}
                maxLength={40}
              />
            </li>
          </Popover>
        ))}
      </ul>
    );
  };
  const dateCellRender = (value) => {
    const data =
      (showMock
        ? mockData[value.month()]?.[value.date()]
        : listData?.[moment(new Date(value)).format("YYYY-MM-DD")]?.toSorted(
            (a, b) => a.startTime - b.startTime
          )) || [];

    return (
      <div className="tw-flex tw-flex-col gap-2">
        {data.map((item, index) => (
          <Popover
            key={`calendar-${index}`}
            placement="left"
            content={innerPopover ? innerPopover(item) : popoverContent(item)}
            arrow={false}
            trigger="click"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              key={item.content}
              className="tw-whitespace-nowrap tw-text-xs md:tw-text-sm tw-flex tw-items-center tw-p-0 tw-text-left tw-pl-2 tw-bg-gray-100 hover:tw-bg-light_primary"
            >
              {eventTitleContent ? (
                eventTitleContent(item)
              ) : (
                <>
                  {item.position && item.position.lat && item.position.lon ? (
                    <IoMdHome color={COLOR.primary} />
                  ) : (
                    <TbWorld color={COLOR.primary} />
                  )}
                  <TextWithPopover
                    className="tw-ml-0 md:tw-ml-2"
                    text={`${moment(item.startTime, "HH:mm")
                      .locale("th")
                      .format("HH:mm")} ${item.title || ""}`}
                    maxLength={40}
                  />
                </>
              )}
            </div>
          </Popover>
        ))}
      </div>
    );
  };
  const cellRender = (current, info) => {
    if (info.type === "date")
      return (
        <div
          className={`ant-picker-cell-inner ant-picker-calendar-date ${
            moment(new Date(current)).format("YYYY-MM-DD") ===
            moment(new Date()).format("YYYY-MM-DD")
              ? "!tw-bg-primary !tw-bg-opacity-20"
              : ""
          }`}
          onClick={() => !readOnly && onClickDate(new Date(current))}
        >
          <div className="ant-picker-calendar-date-value">
            {new Date(current).getDate()}
          </div>
          <div
            className={`ant-picker-calendar-date-content ${
              compact ? "!tw-h-[50px]" : "!tw-h-[10vh]"
            } tw-overflow-y-auto tw-overflow-x-hidden tw-scrollbar-none`}
          >
            {dateCellRender(current)}
          </div>
        </div>
      );
    if (info.type === "month")
      return (
        <div className="ant-picker-cell-inner ant-picker-calendar-date">
          <div className="ant-picker-calendar-date-value">
            {new Date(current).toLocaleString("default", { month: "long" })}
          </div>
          <div
            className={`ant-picker-calendar-date-content ${
              compact ? "!tw-h-[50px]" : "!tw-h-[10vh]"
            } tw-overflow-y-auto tw-overflow-x-hidden tw-scrollbar-none`}
          >
            {monthCellRender(current)}
          </div>
        </div>
      );
    return info.originNode;
  };

  return (
    <AntdCalendar
      onChange={(e) => {
        const month = moment(new Date(e)).format("YYYY-MM");
        if (month != prevMonth) {
          setPrevMonth(month);
          onMonthChange(month);
        }
      }}
      fullCellRender={cellRender}
      headerRender={({ value, onChange }) => {
        const start = 0;
        const end = 12;
        const monthOptions = [];

        let current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
          current = current.month(i);
          months.push(localeData.monthsShort(current));
        }

        for (let i = start; i < end; i++) {
          monthOptions.push(
            <Select.Option key={i} value={i} className="month-item">
              {months[i]}
            </Select.Option>
          );
        }

        const year = value.year();
        const month = value.month();
        const options = [];
        for (let i = year - 10; i < year + 10; i += 1) {
          options.push(
            <Select.Option key={i} value={i} className="year-item">
              {i}
            </Select.Option>
          );
        }

        return (
          <div className="tw-p-2 tw-flex tw-justify-end tw-items-center tw-gap-2">
            <Button
              type="text"
              onClick={() => onChange(value.clone().subtract(1, "month"))}
            >
              <MdKeyboardArrowLeft />
            </Button>
            <Button
              type="text"
              onClick={() => onChange(value.clone().add(1, "month"))}
            >
              <MdKeyboardArrowRight />
            </Button>
            <Select
              size="small"
              popupMatchSelectWidth={false}
              className="my-year-select !tw-w-20"
              value={year}
              onChange={(newYear) => {
                const now = value.clone().year(newYear);
                onChange(now);
              }}
            >
              {options}
            </Select>
            <Select
              size="small"
              popupMatchSelectWidth={false}
              className="!tw-w-20"
              value={month}
              onChange={(newMonth) => {
                const now = value.clone().month(newMonth);
                onChange(now);
              }}
            >
              {monthOptions}
            </Select>
          </div>
        );
      }}
    />
  );
}
