import { Button, Card, Modal, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getRoleUser } from "utils";

const PreviewPackageModal = ({ data, open, onCancel }) => {
  const navigate = useNavigate();
  const roleUser = getRoleUser();
  const [dataSource, setDataSource] = useState([]);
  const coursePath = `/${roleUser}/course/${data?.courseId}`;

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  return (
    <Modal title={data?.unitName} open={open} onCancel={onCancel} footer={null}>
      <div>
        <video controls style={{ width: "100%", borderRadius: "4px" }}>
          <source src={data?.videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <Row>
          <Card className="w-100">
            <div>
              <b>รายละเอียด :</b> <span>{data?.description}</span>
            </div>
          </Card>
        </Row>
      </div>
      <a href={coursePath} target="_blank">
        <Button
          type="primary"
          block
          className="mt-2"
        >
          ไปยังคอร์ส
        </Button>
      </a>
    </Modal>
  );
};

export default PreviewPackageModal;
