import { jarallax } from "jarallax";
import $ from "jquery";
import { useEffect } from "react";
import "./App.css";
// import "moment/locale/th"; //

import { BrowserRouter, Route, Routes } from "react-router-dom";

import CourseDetails from "pages/course-details";
import CoursePage from "pages/courses";
import TeacherDetails from "pages/teacher-details";
import TeachersPage from "pages/teachers";
import Home from "./pages/home";
import LayoutPage from "./pages/layout/LayoutPage";
import Login from "./pages/login";

import CartPage from "pages/cart";
import PaymentFinish from "pages/paymentFinish";
import RegisterPage from "pages/register/RegisterPage";
import ErrorPage from "./pages/ErrorPage";
import About from "./pages/about";
import TeacherQuiz from "./pages/back-office/teacher/course/quiz";
import TeacherQuizDetail from "./pages/back-office/teacher/course/quiz/quiz-detail";
import ContactUsPage from "./pages/contact-us";

import AdminManageCourse from "pages/back-office/admin/manage-course";
import AdminManagePackages from "pages/back-office/admin/manage-packages";
import AdminManagePromotion from "pages/back-office/admin/manage-promotion";
import AdminManageTeacher from "pages/back-office/admin/manage-teacher";
import AdminManageUser from "pages/back-office/admin/manage-user";
import AdminManageWorkshop from "pages/back-office/admin/manage-workshop";
import AdminProfile from "pages/back-office/admin/profile";
import RedeemCash from "pages/back-office/admin/redeem-cash";
import AdminReport from "pages/back-office/admin/report";
import AdminManageOrder from "pages/back-office/admin/manage-order";
import AdminProfileLayout from "pages/back-office/components/AdminProfileLayout";
import LineCallbackPage from "components/lineCallback";
import TeacherProfileLayout from "pages/back-office/components/TeacherProfileLayout";
import UserAccount from "pages/back-office/student/account";
import UserCourse from "pages/back-office/student/course";
import { UserCourseDetail } from "pages/back-office/student/course/course-detail";
import UserPoint from "pages/back-office/student/point";
import UserProfile from "pages/back-office/student/profile";
import UserPurchase from "pages/back-office/student/purchase/index";
import UserWorkshop from "pages/back-office/student/workshop";
import { UserWorkshopDetail } from "pages/back-office/student/workshop/workshop-detail";
import TeacherAccount from "pages/back-office/teacher/account";
import TeacherAvailableTime from "pages/back-office/teacher/available-time";
import TeacherAward from "pages/back-office/teacher/award";
import TeacherCourse from "pages/back-office/teacher/course";
import TeacherCourseDetail from "pages/back-office/teacher/course/TeacherCourseDetail";
import TeacherCourseContent from "pages/back-office/teacher/course/course-content";
import TeacherHomework from "pages/back-office/teacher/homework";
import TeacherHomeworkDetail from "pages/back-office/teacher/homework/TeacherHomeworkDetail";
import TeacherPackages from "pages/back-office/teacher/packages";
import TeacherProfile from "pages/back-office/teacher/profile";
import QuizPage from "pages/back-office/teacher/quiz/index";
import TeacherReport from "pages/back-office/teacher/report";
import TeacherCalendar from "pages/back-office/teacher/teacherCalendar";
import TeacherWorkshop from "pages/back-office/teacher/workshop";
import TeacherSessionGroup from "pages/back-office/teacher/workshop/session-group";
import TeacherWorkshopContent from "pages/back-office/teacher/workshop/workshop-content";
import ChangePassword from "pages/change-password/index";
import CODPaymentFinish from "pages/codPaymentFinish";
import ForgetPassword from "pages/forget-password/index";
import SubscriptionPayment from "pages/teacher-details/SubscriptionPayment";
import VerifyEmail from "pages/verify-email";
import UserProfileLayout from "pages/back-office/components/UserProfileLayout";

function mobileDropdown() {
  if ($(".navbar-nav").length) {
    $(".navbar-nav .sa-dropdown").append(function () {
      return '<i class="fas fa-chevron-down icon"></i>';
    });
    $(".sa-dropdown .icon").on("click", function () {
      $(this).parent("li").children("ul").slideToggle();
    });
  }
}

function App() {
  useEffect(() => {
    $("#preloader").fadeOut("slow", function () {
      $(this).remove();
    });

    // mobileDropdown();

    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<LayoutPage mobileDropdown={() => mobileDropdown()} />}
        >
          <Route index element={<Home />} />
          {/* ---------------- BACK OFFICE ------------- */}
          <Route path="/cart" element={<CartPage />} />
          <Route
            path="/subscription/:teacherId"
            element={<SubscriptionPayment />}
          />
          {/* <Route path="/payment" element={<PaymentPage />} /> */}
          <Route path="/purchased/:orderNo" element={<PaymentFinish />} />
          <Route path="/codPaymentFinish" element={<CODPaymentFinish />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/teachers" element={<TeachersPage />} />
          <Route path="/teachers/:id" element={<TeacherDetails />} />

          <Route path="/courses" element={<CoursePage type="course" />} />
          <Route
            path="/courses/all/:type"
            element={<CoursePage type="course" isKruNut={false} />}
          />
          <Route
            path="/courses/kru-nut/:type"
            element={<CoursePage type="course" isKruNut={true} />}
          />
          <Route
            path="/courses/:id"
            element={<CourseDetails type="course" />}
          />

          <Route path="/packages" element={<CoursePage type="package" />} />
          <Route
            path="/packages/all"
            element={<CoursePage type="package" isKruNut={false} />}
          />
          <Route
            path="/packages/kru-nut"
            element={<CoursePage type="package" isKruNut={true} />}
          />
          <Route
            path="/packages/:id"
            element={<CourseDetails type="package" />}
          />

          <Route path="/workshops" element={<CoursePage type="workshop" />} />
          <Route
            path="/workshops/all"
            element={<CoursePage type="workshop" isKruNut={false} />}
          />
          <Route
            path="/workshops/kru-nut"
            element={<CoursePage type="workshop" isKruNut={true} />}
          />
          <Route
            path="/workshops/:id"
            element={<CourseDetails type="workshop" />}
          />

          {/* ----------------USER BACK OFFICE ------------- */}
          <Route path="/student" element={<UserProfileLayout />}>
            <Route path="profile" element={<UserProfile />} />
            <Route path="account" element={<UserAccount />} />
            <Route path="point" element={<UserPoint />} />
            <Route path="course" element={<UserCourse />} />
            <Route path="workshop" element={<UserWorkshop />} />
            <Route path="purchase" element={<UserPurchase />} />
          </Route>
          <Route
            path="/student/course/:courseId"
            element={<UserCourseDetail />}
          />
          <Route
            path="/student/workshop/:workshopId"
            element={<UserWorkshopDetail />}
          />
          {/* ----------------TEACHER BACK OFFICE ------------- */}
          <Route path="/teacher" element={<TeacherProfileLayout />}>
            <Route path="profile" element={<TeacherProfile />} />
            <Route path="account" element={<TeacherAccount />} />
            <Route path="award" element={<TeacherAward />} />
            <Route path="course" element={<TeacherCourse />}>
              <Route path="" element={<TeacherCourseContent />} />
              <Route path=":courseId" element={<TeacherCourseDetail />} />
              <Route path=":courseId/quiz/:unitId" element={<TeacherQuiz />} />
              <Route
                path=":courseId/quiz/:unitId/:quizId"
                element={<TeacherQuizDetail />}
              />
            </Route>
            <Route path="homework" element={<TeacherHomework />} />
            <Route
              path="homework/:courseId"
              element={<TeacherHomeworkDetail />}
            />
            <Route path="workshop" element={<TeacherWorkshop />}>
              <Route path="" element={<TeacherWorkshopContent />} />
              <Route path=":workshopId" element={<TeacherSessionGroup />} />
            </Route>
            <Route path="report" element={<TeacherReport />} />
            <Route path="availableTime" element={<TeacherAvailableTime />} />
            <Route path="packages" element={<TeacherPackages />} />
            <Route path="quiz" element={<QuizPage />} />
          </Route>

          <Route path="/teacher/schedule" element={<TeacherCalendar />} />

          {/* ----------------ADMIN ------------- */}
          <Route path="/admin" element={<AdminProfileLayout />}>
            <Route path="profile" element={<AdminProfile />} />
            <Route path="manageUser" element={<AdminManageUser />} />
            <Route
              path="manage-teacher"
              element={<AdminManageTeacher />}
            />
            <Route path="manageCourse" element={<AdminManageCourse />} />
            <Route
              path="manageWorkshop"
              element={<AdminManageWorkshop />}
            />
            <Route
              path="managePromotion"
              element={<AdminManagePromotion />}
            />
            <Route
              path="managePackages"
              element={<AdminManagePackages />}
            />
            <Route path="report" element={<AdminReport />} />
            <Route path="order" element={<AdminManageOrder />} />
            <Route path="redeemCash" element={<RedeemCash />} />
          </Route>
        </Route>

        <Route path="/login" element={<Login />} />
        <Route path="/forgetPassword" element={<ForgetPassword />} />
        <Route path="/changePassword/:id" element={<ChangePassword />} />
        <Route path="/verifyEmail/:id" element={<VerifyEmail />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/lineCallback" element={<LineCallbackPage />} />
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
