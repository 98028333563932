import { Button, Divider } from "antd";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CODPaymentFinish = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);


  return (
    <>
      <Divider />
      <div style={{ margin: "60px 0px" }} className="congrats-section">
        <div className="container">
          <div className="congrats-content">
            <div className="congrats-info">
              <div className="icon">
                <i className="fa fa-check-circle" aria-hidden="true"></i>
              </div>
              <h1>การสั่งซื้อสำเร็จ</h1>
              <p>
                คำสั่งซื้อของคุณได้รับการดำเนินการเรียบร้อยแล้วบนเว็บไซต์
                <br /> กรุณารอรับจดหมายทางไปรษณีย์ เพื่อเปิดใช้งานคอร์สเรียนของคุณ
              </p>
              <Button
                className="mt-5"
                type="primary"
                size="large"
                onClick={() => {
                  navigate("/");
                }}
              >
                กลับสู่หน้าหลัก
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CODPaymentFinish;
