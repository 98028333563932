import { COLOR } from "./constants/color";

export const antdTheme = {
  token: {
    colorPrimary: COLOR.primary,
    colorInfo: COLOR.primary,
    borderRadius: 4,
  },

  // components: {
  //   Button: {},
  // },
};
