import { Button, Card, Col, Input, Modal, Row, Upload } from "antd";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import { dummyRequest } from "hooks/dummyRequest";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { onUploadFile } from "hooks/onUploadFile";
import useUploadFileHook from "hooks/useUploadFileHook";
import _ from "lodash";
import { useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import { getUserId } from "utils";

const { TextArea } = Input;

const SubmitHomeworkModal = ({ data, open, onCancel, refetch }) => {
  const { courseId } = useParams();
  const userId = getUserId();
  const course = useSelector((state) => state.course);
  const currentUnit = course?.currentUnitData;
  const isUpdateHomework = !!currentUnit?.submitHomework?.id;

  const [answerText, setAnswerText] = useState(null);
  const [previewVideo, setPreviewVideo] = useState(null);

  const {
    fileList,
    setFileList,
    previewImage,
    handlePreview: _handlePreviewUpload,
    handleChange: _handleChangeUpload,
    handleCancel: _handleCancelUpload,
  } = useUploadFileHook();

  const onResetValues = () => {
    setAnswerText(null);
    setPreviewVideo(null);
    setFileList([]);
    refetch(); // refetchUnitDetail
  };

  const { mutate: createHomework } =
    courseBackOfficeServices.useMutationCreateHomeworkByUnitId(
      (res) => {
        AlertSuccess({});
        onResetValues();
        onCancel();
      },
      (err) => {
        onResetValues();
        AlertError({ text: errorToMessage(err) });
      }
    );

  const { mutate: updateHomework } =
    courseBackOfficeServices.useMutationUpdateHomeworkByUnitId(
      (res) => {
        AlertSuccess({});
        onResetValues();
        onCancel();
      },
      (err) => {
        onResetValues();
        AlertError({ text: errorToMessage(err) });
      }
    );

  const onFinish = async () => {
    const homeworkId = data?.submitHomework?.id;
    const prefixPath = `course/${courseId}/${homeworkId}/homework/video/${userId}`;
    const textFile = data?.homeworkAnswerType === "message";
    const answer = textFile ? answerText : null;
    const video =
      _.size(fileList) > 0
        ? await onUploadFile({
            prefix: prefixPath,
            fileList,
            showProgress: true,
          })
        : data?.video;

    let _values = {
      id: homeworkId,
      unitId: data?.id,
      answer,
      prefixPath,
      video,
    };
    const _newValues = answer
      ? _.omit(_values, ["prefixPath", "video"])
      : _values;
    if (isUpdateHomework) {
      updateHomework({ values: _newValues });
    } else {
      createHomework({ values: _newValues });
    }
  };

  return (
    <Modal
      title={isUpdateHomework ? "อัปเดตคำตอบ" : "ส่งการบ้าน"}
      width={600}
      open={open}
      onOk={() => {
        AlertConfirm({
          onOk: () => {
            onFinish();
          },
        });
      }}
      onCancel={onCancel}
      okText={isUpdateHomework ? "บันทึก" : "ส่งการบ้าน"}
      cancelText="ยกเลิก"
    >
      <Row>
        <Col span={24}>
          <p className="tw-font-semibold">คำถาม :</p>
          <p className="tw-ml-2">{data?.homework}</p>
          {data?.homeworkAnswerType === "message" ? (
            <>
              <p className="tw-font-semibold tw-mt-4">คำตอบ :</p>

              <TextArea
                value={answerText}
                style={{ height: "120px" }}
                className="my-2"
                size="large"
                placeholder="เขียนคำตอบ..."
                onChange={(e) => {
                  let text = e.target.value;
                  setAnswerText(text);
                }}
              />
            </>
          ) : (
            <>
              <p className="tw-font-semibold">คำตอบ :</p>
              {previewVideo ? (
                <div>
                  {previewVideo && (
                    <div>
                      <video
                        controls
                        style={{ width: "100%", borderRadius: "4px" }}
                      >
                        <source src={previewVideo.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                      <Row>
                        <Card className="w-100">
                          <div className="d-flex justify-content-between align-items-center">
                            <p
                              style={{
                                width: "max-content",
                                maxWidth: "320px",
                              }}
                            >
                              ชื่อไฟล์วิดีโอ : {previewVideo.file.name}
                            </p>
                            <Button
                              danger
                              onClick={() => {
                                setPreviewVideo(null);
                                setFileList([]);
                              }}
                            >
                              ลบวิดีโอ
                            </Button>
                          </div>
                        </Card>
                      </Row>
                    </div>
                  )}
                </div>
              ) : (
                <Upload
                  fileList={fileList}
                  customRequest={dummyRequest}
                  accept="video/*"
                  listType="picture-card"
                  maxCount={1}
                  beforeUpload={(file) => {
                    let videoUrl = URL.createObjectURL(file);
                    setPreviewVideo({ file, url: videoUrl });

                    return new Promise((resolve) => {
                      const video = document.createElement("video");
                      video.preload = "metadata";
                      video.onloadedmetadata = () => {
                        window.URL.revokeObjectURL(video.src);
                        resolve(file);
                      };
                      video.src = URL.createObjectURL(file);
                    });
                  }}
                  onPreview={_handlePreviewUpload}
                  onChange={_handleChangeUpload}
                >
                  <div>
                    <MdOutlineFileUpload size={20} className="mb-2" />
                    <p> อัปโหลดไฟล์</p>
                  </div>
                </Upload>
              )}
            </>
          )}
        </Col>
      </Row>
    </Modal>
  );
};

export default SubmitHomeworkModal;
