import {
  Card,
  Col,
  Empty,
  Input,
  Modal,
  Pagination,
  Row,
  Spin,
  Tabs,
  message,
} from "antd";
import { NO_IMAGE } from "assets/images";
import SelectCourseType from "components/elements/SelectCourseType";
import { COLOR } from "constants/color";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";
import { courseTypeOption } from "constants/options/courseTypeOption";
import { ROLE_USER } from "constants/roleUserConstant";
import useModalHook from "hooks/useModalHook";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { IoBookOutline, IoDesktopSharp } from "react-icons/io5";
import { MdOutlineAccessTime } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import courseService from "services/course.services";
import copy from "clipboard-copy";
import { AlertError } from "components/alert/Alert";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { Divider } from "../../../../../node_modules/antd/es/index";

const UserCourse = () => {
  const navigate = useNavigate();
  const limitPage = 5;
  const [page, setPage] = useState(1);
  const [tab, setTab] = useState(1);
  const [filterType, setFilterType] = useState("ทั้งหมด");
  const [sharingHostUrl, setSharingHostUrl] = useState();
  const [sharingUrl, setSharingUrl] = useState();
  const [referenceCode, setReferenceCode] = useState();
  const [messageApi, contextHolder] = message.useMessage();

  const hostname = window.location.protocol + "//" + window.location.host;

  const {
    data: getCourseUser,
    isFetching: isLoadingCourse,
    refetch: refetchCourse,
  } = courseBackOfficeServices.useQueryGetAllCourseByStudentId({
    queryObj: {
      paging: {
        limit: limitPage,
        start: page * limitPage - limitPage,
      },
      isFinish: tab === 1 ? false : true,
      type: filterType,
    },
  });

  const {
    open: shareCourseOpen,
    handleOpen: _handleshareCourseOpen,
    handleClose: _handleshareCourseClose,
  } = useModalHook();

  const { mutate: getCodeReference } =
    courseService.useMutationGetCodeReference(
      (res) => {
        const { data } = res;
        setReferenceCode(data);
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const success = (text) => {
    messageApi.open({
      type: "success",
      content: `${text}`,
    });
  };

  const handleCopyUrl = () => {
    copy(sharingUrl).then(() => {
      _handleshareCourseClose();
      success("คัดลอกแล้ว !");
    });
  };

  const generateShareCourse = async (courseId) => {
    setSharingHostUrl(`${hostname}/courses/${courseId}`);
    await getCodeReference({ courseId });
    _handleshareCourseOpen();
  };

  useEffect(() => {
    setSharingUrl(`${sharingHostUrl}?code=${referenceCode}`);
  }, [referenceCode]);

  useEffect(() => {
    refetchCourse();
  }, [page]);

  const renderContent = () => {
    return (
      <>
        {contextHolder}
        {isLoadingCourse ? (
          <Spin className="p-4" />
        ) : (
          <>
            <Row>
              {getCourseUser?.data ? (
                <Col span={24}>
                  {[...(getCourseUser?.data || [])].map((n, i) => {
                    return (
                      <Card
                        key={i}
                        title={n?.title}
                        extra={
                          <div
                            className="text-primary tw-cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              if (tab === 1) {
                                generateShareCourse(n?.id);
                              } else {
                                navigate("review");
                              }
                            }}
                          >
                            {tab === 1 ? "Share" : "Review"}
                          </div>
                        }
                        className="mb-3 course-list"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Row
                          className="tw-grid tw-grid-cols-1 tw-gap-2 lg:tw-grid-cols-[160px_1fr] lg:tw-gap-4"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/${ROLE_USER.student}/course/${n?.id}`);
                          }}
                        >
                          <div className="tw-w-full tw-h-full tw-max-h-[240px] lg:tw-w-[160px] lg:tw-h-[120px] tw-rounded-[8px] tw-overflow-hidden">
                            <img
                              className="tw-object-center tw-object-cover tw-w-full tw-h-full"
                              src={n?.coverImgUrl || NO_IMAGE}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = NO_IMAGE;
                              }}
                              alt="course-cover"
                            />
                          </div>
                          <div>
                            <p className="limited-text mb-1">
                              รายละเอียดคอร์ส : {n?.description}
                            </p>

                            <div className="tw-flex tw-flex-col lg:tw-flex-row ">
                              <div className="d-flex align-items-center">
                                <IoBookOutline
                                  color={COLOR.primary}
                                  className="me-2"
                                />
                                <p className="text-primary me-4">{`หมวดหมู่ : ${n?.category}`}</p>
                              </div>
                              <div className="d-flex align-items-center">
                                <IoDesktopSharp className="me-2" />{" "}
                                <p>
                                  {
                                    _.find(
                                      courseTypeOption,
                                      (x) => x.value === n?.type
                                    )?.label
                                  }
                                </p>
                              </div>
                            </div>
                            <div className="d-flex align-items-center mb-2 mt-1">
                              <MdOutlineAccessTime className="me-2" />
                              <p>{`วันที่ซื้อคอร์ส : ${moment(n?.date).format(
                                "DD/MM/YYYY"
                              )}`}</p>
                            </div>
                            <Divider className="m-2 lg:tw-hidden" />
                            <div className="d-flex align-items-center gap-2">
                              {/* <LuUser2 className="me-2" /> */}
                              <div
                                style={{ width: "24px", height: "24px" }}
                                className="d-flex justify-content-center align-items-center overflow-hidden rounded-circle"
                              >
                                <img
                                  className="w-100 h-100 object-fit-cover"
                                  src={n?.teacherImg || NO_IMAGE}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = NO_IMAGE;
                                  }}
                                  alt="i"
                                />
                              </div>
                              <p className="my-1">{`ครูผู้สอน : ${n?.teacherName}`}</p>
                            </div>
                          </div>
                        </Row>
                      </Card>
                    );
                  })}
                </Col>
              ) : (
                <div className="w-100 center p-4">
                  <Empty description={EMPTY_DESCRIPTION} />
                </div>
              )}
            </Row>
            <Row>
              {getCourseUser?.total > 0 && (
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  pageSize={limitPage}
                  total={getCourseUser?.total || 1}
                  onChange={(page) => {
                    setPage(page);
                  }}
                />
              )}
            </Row>
          </>
        )}
      </>
    );
  };

  const tabCourse = [
    {
      key: 1,
      label: `คอร์สที่ยังเรียนไม่จบ`,
      children: renderContent(),
    },
    {
      key: 2,
      label: `คอร์สที่เรียนจบแล้ว`,
      children: renderContent(),
    },
  ];

  useEffect(() => {
    refetchCourse();
    window.scrollTo(0, 440);
  }, [tab, filterType]);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <p className="mb-2">ประเภทคอร์สเรียน</p>
          <SelectCourseType
            withAll
            value={filterType}
            style={{ width: "200px" }}
            onChange={(value) => {
              setFilterType(value);
            }}
          />
        </Col>
      </Row>
      <Tabs
        size="large"
        defaultActiveKey={1}
        items={tabCourse}
        onChange={(key) => {
          setTab(key);
        }}
      />
      <Modal
        title="แชร์คอร์สเรียนนี้"
        open={shareCourseOpen}
        onOk={_handleshareCourseClose}
        onCancel={_handleshareCourseClose}
        footer={null}
      >
        <Row className="mb-4 mt-3">
          <Input style={{ flex: "1" }} readOnly value={sharingUrl} />
          <div
            style={{ width: "80px", cursor: "pointer" }}
            className="center text-primary"
            onClick={handleCopyUrl}
          >
            <p>คัดลอก</p>
          </div>
        </Row>
      </Modal>
    </>
  );
};

export default UserCourse;
