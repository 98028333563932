import { useMutation, useQuery } from "react-query";
import { PUBLIC_API, getToken, getUserId } from "utils";
import createRequest from "../utils/request";

const masterService = {
  // :: MASTER :: --------------------------------------------------
  useQueryGetUserProfile({ onSuccess, onError }) {
    return useQuery(
      ["getUserProfile", getUserId()],
      async () => {
        const path = `/user/getUserProfile`;
        const res = await createRequest.get(path);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetUniversity({ onSuccess, onError }) {
    return useQuery(
      ["getUniversity"],
      async () => {
        const path = `${PUBLIC_API}/master/getMasterUniversity`;
        const res = await createRequest.get(path);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryCheckPaymentOrder({ orderNo, onSuccess, onError }) {
    return useQuery(
      ["checkPaymentStatusAfterPay", orderNo],
      async () => {
        const path = `/payment/checkPaymentStatusAfterPay`;
        const query = { params: { orderNo } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetAllProvince({ onSuccess, onError }) {
    return useQuery(
      ["getAllProvince"],
      async () => {
        const path = `/master/getAllProvince`;
        const res = await createRequest.get(path);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetMyAvaliablePromotion({ onSuccess, onError }) {
    return useQuery(
      ["getMyAvaliablePromotion", getUserId()],
      async () => {
        const path = `/promotion/getMyAvaliablePromotion`;
        const res = await createRequest.get(path);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!getToken(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetUserDataFromToken({ token, onSuccess, onError }) {
    return useQuery(
      ["getUserDataFromToken", token],
      async () => {
        const path = `${PUBLIC_API}/getUserDataFromToken`;
        const query = { params: { token } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!token,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: 0,
      }
    );
  },

  //   ---------------------- :: USE_MUTATION :: --------------------
  useMutationLogin(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const { email, password } = values;
        const path = `/login`;
        const query = { email, password };
        const res = await createRequest.post(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationLoginLine(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/user/loginLine`;
        const query = { values };
        const res = await createRequest.post(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  useMutationRegister(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `${PUBLIC_API}/register`;
        const query = { ...values, active: true };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdatePassword(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/user/updateUserPassword`;
        const query = { ...values, userId: getUserId() };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  // useMutationUploadFile(onSuccess, onError) {
  //   return useMutation(
  //     async ({ values }) => {
  //       const { prefix, file } = values;
  //       const path = `/file/uploadFile`;
  //       const query = { prefix, file };
  //       try {
  //         const res = await createRequest.post(path, query);
  //         return res.data.data;
  //       } catch (error) {
  //         throw error;
  //       }
  //     },
  //     {
  //       onSuccess,
  //       onError,
  //     }
  //   );
  // },

  useMutationCreatePayment(onSuccess, onError) {
    return useMutation(
      async ({ cartList = [], ...other }) => {
        const path = `/payment/createPayment`;
        const query = {
          payment: { ...other },
          paymentDetail: cartList?.map(({ price, ...other }) => ({
            amount: price,
            ...other,
          })),
        };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  useQueryGetAllMyPurchase({ onSuccess, onError }) {
    return useQuery(
      ["getAllMyPurchase"],
      async () => {
        const path = `/payment/getAllMyPurchase`;
        const res = await createRequest.get(path);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!getToken(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useMutationCreateChargePayment(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/payment/createCharge`;
        const query = { ...values };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationCheckEventPayment(onSuccess, onError) {
    return useMutation(
      async ({ chargeId }) => {
        const path = `/payment/checkEventPaymentAfterPay`;
        const query = { params: { chargeId: chargeId } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationGetPromotionByCode(onSuccess, onError) {
    return useMutation(
      async ({ code }) => {
        const path = `/master/getCodePromotionByCode`;
        const query = { params: { code: code, userId: getUserId() } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  useMutationSendOTP(onSuccess, onError) {
    return useMutation(
      async ({ token }) => {
        const path = `${PUBLIC_API}/sendOTP`;
        const query = { params: { token } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationConfirmOTP(onSuccess, onError) {
    return useMutation(
      async ({ token, otp }) => {
        const path = `${PUBLIC_API}/confirmOTP`;
        const query = { token, otp };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationForgetPassword(onSuccess, onError) {
    return useMutation(
      async ({ email }) => {
        const path = `${PUBLIC_API}/forgotPassword`;
        const query = { email };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationChangePassword(onSuccess, onError) {
    return useMutation(
      async ({ id, password }) => {
        const path = `${PUBLIC_API}/resetPassword`;
        const query = { id, password };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationSendContact(onSuccess, onError) {
    return useMutation(
      async ({ contactName, topic, description, email }) => {
        const path = `${PUBLIC_API}/sendContact`;
        const query = { contactName, topic, description, email };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};
export default masterService;
