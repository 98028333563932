import { Button } from "antd";
import { useEffect, useState } from "react";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import moment from "../../../../../../node_modules/moment/moment";

const GroupPanel = ({ courseId, setActiveGroupData }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { data: getZoomGroup } =
    courseBackOfficeServices.useQueryGetAllZoomGroupByCourseId({
      id: courseId,
    });

  useEffect(() => {
    const groupData = getZoomGroup?.data;
    if (!!groupData) {
      setActiveGroupData(groupData[activeIndex]);
    }
  }, [activeIndex, getZoomGroup, setActiveGroupData]);

  return (
    !!getZoomGroup?.data && (
      <div className="widget widget_categories">
        <h3 className="widget_title">กลุ่มเรียน</h3>
        <ul className="w-100">
          {getZoomGroup?.data?.map((n, i) => {
            return (
              <Button
                size="large"
                block
                className="!tw-rounded-none"
                onClick={() => setActiveIndex(i)}
              >
                <div className="tw-flex tw-justify-start tw-items-center">
                  รอบที่ {i + 1} : {moment(n?.startDate).format("L")} -{" "}
                  {moment(n?.finishDate).format("L")}
                </div>
              </Button>
            );
          })}
        </ul>
      </div>
    )
  );
};

export default GroupPanel;
