import PackageCard from "components/PackageCard";
import FadingSection from "components/fadingSection";
import Section from "components/section";
import Slick from "components/slick";
import _ from "lodash";
import { COURSE_SLIDE_CONFIG } from "pages/back-office/constants/course";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import packageService from "services/package.services";
import PaginationSlideCard from "./components/PaginationSlideCard";
import SkeletonCardLoader from "./components/SkeletonCardLoader";
import { homeConstants } from "./constants/HomeConstants";

export default function PackageListSection() {
  const limitPage = homeConstants.limitPage;
  const [page, setPage] = useState(1);

  const {
    data: dataPackages,
    isFetching: isLoadingPackages,
    refetch: refetchPackages,
  } = packageService.useQueryGetAllPackageActive({
    queryObj: {
      paging: {
        limit: limitPage,
        start: page * limitPage - limitPage,
      },
    },
  });

  useEffect(() => {
    refetchPackages()
  }, [page])

  return (
    <FadingSection>
      <Section className="padding-top-0">
        <div className="section-header justify-content-between">
          <div className="title align-self-center">
            <h1>แพ็กเกจ</h1>
            <span>Packages</span>
          </div>
          {dataPackages?.data?.length ? (
            <div className="view-all align-self-center">
              <Link to="/packages">
                <button className="btn btn-primary">ดูทั้งหมด</button>
              </Link>
            </div>
          ) : null}
        </div>

        <div className="sa-course-content">
          {isLoadingPackages ? (
            <SkeletonCardLoader />
          ) : dataPackages?.data ? (
            <Slick
              className="sa-course-slider"
              config={COURSE_SLIDE_CONFIG(dataPackages.data.length || 0)}
            >
              {dataPackages.data.map((data, index) => {
                return (
                  <PackageCard key={`package-card-${index}`} data={data} />
                );
              })}
            </Slick>
          ) : (
            <p className="tw-text-center tw-text-gray-300 !tw-py-10 tw-font-extrabold tw-text-2xl">ยังไม่มีแพ็กเกจที่เปิดสอนในขณะนี้</p>
          )}
        </div>
        <PaginationSlideCard
          page={page}
          total={dataPackages?.total}
          limitPage={limitPage}
          setPage={setPage}
        />
      </Section>
    </FadingSection>
  );
}
