import { Button, Row, Table } from "antd";
import { localeCustomTH } from "constants/thaiLocale/localeCustomTH";
import { IoIosArrowBack } from "react-icons/io";
import teacherBackOfficeService from "services/backoffice/teacherBackoffice.services";
import { useNavigate, useParams } from "react-router-dom";
import { LiaEditSolid, LiaInfoSolid } from "react-icons/lia";
import courseService from "services/course.services";

export default function TeacherQuiz() {
  const { courseId, unitId } = useParams();
  const navigate = useNavigate();
  const {
    data: dataCourse
  } = courseService.useQueryGetCourseFlagById({ id: courseId });

  const { data: quiz, isLoading } =
    teacherBackOfficeService.useQueryGetQuizByUnitId(unitId);

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "seq",
      key: "seq",
      width: 100,
      fixed: "left",
    },
    {
      title: "คำถาม",
      dataIndex: "question",
      key: "question",
      width: 200,
      fixed: "left",
    },
    {
      title: "ประเภทแบบทดสอบ",
      dataIndex: "quizType",
      key: "quizType",
      width: 150,
      render: (text, record) => text === "choosing" ? "ปรนัย" : "อัตนัย"
    },
    {
      title: "ตัวเลือก A",
      dataIndex: "choiceA",
      key: "choiceA",
      width: 150,
    },
    {
      title: "ตัวเลือก B",
      dataIndex: "choiceB",
      key: "choiceB",
      width: 150,
    },
    {
      title: "ตัวเลือก C",
      dataIndex: "choiceC",
      key: "choiceC",
      width: 150,
    },
    {
      title: "ตัวเลือก D",
      dataIndex: "choiceD",
      key: "choiceD",
      width: 150,
    },
    {
      title: "คำตอบ",
      dataIndex: "answer",
      key: "answer",
      width: 150,
    },
    dataCourse?.isReject && {
      title: "แก้ไข",
      key: "edit",
      width: 120,
      fixed: "right",
      render: (text, record) => (
        <Button type="primary" onClick={() => {
          navigate(`/teacher/course/${courseId}/quiz/${unitId}/${record?.id}`);
        }}>
          <><LiaEditSolid size={18} className="me-1" />แก้ไข</>
        </Button>
      ),
    },
  ].filter(Boolean);

  const pagination = {
    pageSize: 5,
  };

  return (
    <>
      <Row className="mb-4">
        <Button type="link" className="center" onClick={() => {
          navigate(`/teacher/course/${courseId}`);
        }}>
          <IoIosArrowBack size={22} className="me-2" />
          <p>ย้อนกลับ</p>
        </Button>
      </Row>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4>แบบทดสอบ ({quiz?.length || 0})</h4>
      </div>
      <Table
        loading={isLoading}
        bordered
        locale={localeCustomTH.Table}
        dataSource={quiz || []}
        columns={columns}
        pagination={pagination}
        scroll={{
          x: 1500,
        }}
      />
    </>
  );
}
