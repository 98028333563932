import { Popover } from 'antd';
import React from 'react'

export default function TextWithPopover({ className, text, maxLength }) {
    if (text.length <= maxLength) {
        return <span className={className}>{text}</span>;
    } else {
        const truncatedText = text.slice(0, maxLength);
        const popoverContent = <span>{text}</span>;
        return (
            <Popover content={popoverContent}>
                <span className={className} >{truncatedText}...</span>
            </Popover>
        );
    }
}