import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Empty,
  Image,
  Pagination,
  Row,
  Spin,
  Switch,
  Tabs,
  Tag,
} from "antd";
import { NO_IMAGE } from "assets/images";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import SelectCourseType from "components/elements/SelectCourseType";
import { COLOR } from "constants/color";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";
import { courseTypeOption } from "constants/options/courseTypeOption";
import { errorToMessage } from "hooks/functions/errorToMessage";
import useModalHook from "hooks/useModalHook";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { IoBookOutline, IoDesktopSharp } from "react-icons/io5";
import { LiaEditSolid } from "react-icons/lia";
import { MdAdd, MdOutlineAccessTime } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SET_TAB, TABS_VALUE } from "redux/reducers/courseSlice";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import { getIsKruNut } from "utils";
import CourseFormModal from "../CourseFormModal";
import { Divider } from "../../../../../../node_modules/antd/es/index";

export default function CourseContent() {
  const tab = useSelector((state) => state.course.tab);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const limitPage = 5;
  const [page, setPage] = useState(1);
  const [editCourse, setEditCourse] = useState(null);
  const [filterType, setFilterType] = useState("ทั้งหมด");
  const isKruNut = getIsKruNut();

  const {
    open: openCreateCourse,
    handleOpen: _handleOpenCreateCourse,
    handleClose: _handleCloseCreateCourse,
  } = useModalHook();

  const {
    data: getCourseTeacher,
    isFetching: isLoadingCourse,
    refetch: refetchCourse,
  } = courseBackOfficeServices.useQueryGetAllCourseByTeacherId({
    queryObj: {
      paging: {
        limit: limitPage,
        start: page * limitPage - limitPage,
      },
      type: filterType,
      isApprove:
        tab === TABS_VALUE.ONLINE
          ? true
          : tab === TABS_VALUE.DRAFT
            ? false
            : false,
      isReject:
        tab === TABS_VALUE.ONLINE
          ? false
          : tab === TABS_VALUE.DRAFT
            ? true
            : false,
    },
  });

  const { mutate: sendToApproveCourse } =
    courseBackOfficeServices.useMutationSendToApproveCourse(
      () => {
        AlertSuccess({
          text: `
						กรุณารอตรวจสอบเนื้อหาและรอการอนุมัติ
						หากคอร์สเรียนของท่านได้รับการอนุมัติแล้ว ท่านจะได้รับอีเมลแจ้งเตือน
					`,
        });
        refetchCourse();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const { mutate: sendToPublishCourse } =
    courseBackOfficeServices.useMutationPublishCourse(
      () => {
        AlertSuccess({});
        refetchCourse();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const sendToApprove = (e, n) => {
    e.stopPropagation();
    AlertConfirm({
      text: "กรุณาตรวจสอบเนื้อหาความถูกต้องของคอร์สเรียนและบทเรียน หากคุณกดส่งเพื่ออนุมัติแล้ว คุณจะไม่สามารถแก้ไขเนื้อหาใด ๆ ได้",
      onOk: () => {
        sendToApproveCourse({ id: n.id });
      },
    });
  };

  const publishCourse = (v, e, n) => {
    e.stopPropagation();
    AlertConfirm({
      text: v
        ? "ต้องการเผยแพร่คอร์สเรียนหรือไม่ ?"
        : "ต้องการยกเลิกการเผยแพร่คอร์สเรียนหรือไม่ ?",
      onOk: () => {
        sendToPublishCourse({ id: n.id, publishStatus: v });
      },
    });
  };

  useEffect(() => {
    refetchCourse();
  }, [page]);

  useEffect(() => {
    refetchCourse();
    setPage(1);
    window.scrollTo(0, 440);
  }, [tab, filterType]);

  const Courses = () => (
    <>
      <Row className="my-4 justify-content-between">
        <p className="mb-2" style={{ fontSize: "18px", opacity: "0.8" }}>
          คอร์สเรียนทั้งหมด {getCourseTeacher?.total || 0} คอร์ส
        </p>
        <div className="tw-w-full tw-grid tw-grid-cols-1 tw-gap-2 sm:tw-grid-cols-[4fr_1fr]">
          <SelectCourseType
            withAll
            value={filterType}
            className="tw-max-w-full md:tw-max-w-[250px]"
            onChange={(value) => {
              setFilterType(value);
            }}
          />
          {tab === TABS_VALUE.ONLINE && (
            <Button
              type="primary"
              className="center"
              onClick={() => {
                setEditCourse(null);
                _handleOpenCreateCourse();
              }}
            >
              <MdAdd className="me-2" />
              สร้างคอร์สเรียน
            </Button>
          )}
        </div>
      </Row>
      {isLoadingCourse ? (
        <Spin className="p-4" />
      ) : (
        <>
          <Row>
            {getCourseTeacher?.data ? (
              <Col span={24}>
                {[...(getCourseTeacher?.data || [])].map((n, i) => {
                  return (
                    <Card
                      key={`teacher-course-${n?.id}`}
                      title={n.title}
                      extra={
                        <div
                          style={{ cursor: "pointer" }}
                          className="text-primary"
                          onClick={() => {
                            setEditCourse(n);
                            _handleOpenCreateCourse();
                          }}
                        >
                          {(!isKruNut &&
                            (tab === TABS_VALUE.ONLINE ||
                              tab === TABS_VALUE.WAITING)) ||
                            (isKruNut && n?.isPublish) ? (
                            <div className="d-flex align-items-center gap-1">
                              <IoMdInformationCircleOutline size={18} />
                              ดูรายละเอียด
                            </div>
                          ) : (
                            <div className="d-flex align-items-center gap-1">
                              <LiaEditSolid size={18} />
                              แก้ไข
                            </div>
                          )}
                        </div>
                      }
                      className="mb-3 course-list"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Row
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/teacher/course/${n?.id}`);
                        }}
                      >
                        <div className="tw-w-full tw-grid tw-grid-cols-1 lg:tw-grid-cols-[150px_1fr_140px] tw-gap-4">
                          <div className="tw-rounded-[8px] tw-overflow-hidden">
                            <img
                              className="tw-object-center tw-object-cover tw-w-full tw-h-full"
                              src={n?.coverImgUrl || NO_IMAGE}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = NO_IMAGE;
                              }}
                              alt="course-cover"
                            />
                          </div>
                          <div>
                            <p className="limited-text">
                              รายละเอียดคอร์ส : {n.description}
                            </p>

                            <div className="d-flex align-items-center mt-1">
                              <IoBookOutline
                                color={COLOR.primary}
                                className="me-2"
                              />
                              <p className="text-primary me-4">{`หมวดหมู่ : ${n.category}`}</p>
                            </div>
                            <div className="d-flex align-items-center my-1">
                              <IoDesktopSharp className="me-2" />{" "}
                              <p>
                                {
                                  _.find(
                                    courseTypeOption,
                                    (x) => x.value === n.type
                                  )?.label
                                }
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              <MdOutlineAccessTime className="me-2" />
                              <p>{`วันที่สร้างคอร์ส : ${moment(n.date).format(
                                "DD/MM/YYYY"
                              )}`}</p>
                            </div>
                            <div className="d-flex align-items-center mt-1">
                              {n?.isApprove ? (
                                <Tag
                                  color="success"
                                  icon={<CheckCircleOutlined />}
                                  className="mt-1"
                                >
                                  สถานะ : อนุมัติแล้ว
                                </Tag>
                              ) : (
                                <Tag
                                  color="warning"
                                  icon={<ExclamationCircleOutlined />}
                                  className="mt-1"
                                >
                                  สถานะ : รออนุมัติ
                                </Tag>
                              )}
                            </div>
                          </div>
                          <Divider className="my-1 lg:tw-hidden" />
                          <div className="tw-flex tw-justify-between tw-items-center tw-flex-row-reverse lg:tw-flex-col lg:tw-items-end">
                            {tab === TABS_VALUE.DRAFT ? (
                              <Button
                                type="primary"
                                onClick={(e) => sendToApprove(e, n)}
                              >
                                ส่งอนุมัติ
                              </Button>
                            ) : tab === TABS_VALUE.ONLINE ? (
                              <div>
                                เผยแพร่ <br></br>{" "}
                                <Switch
                                  checked={n?.isPublish}
                                  onClick={(v, e) => publishCourse(v, e, n)}
                                />
                              </div>
                            ) : (
                              <div></div>
                            )}
                            <b>
                              <p
                                className="text-primary"
                                style={{ fontSize: "16px" }}
                              >{`ราคา ${numeral(n.price).format(
                                "0,0"
                              )} บาท`}</p>
                            </b>
                          </div>
                        </div>
                      </Row>
                    </Card>
                  );
                })}
              </Col>
            ) : (
              <div className="w-100 center p-4">
                <Empty description={EMPTY_DESCRIPTION} />
              </div>
            )}
          </Row>
          <Row>
            {getCourseTeacher?.total > 0 && (
              <Pagination
                defaultCurrent={1}
                current={page}
                total={getCourseTeacher?.total || 1}
                pageSize={limitPage}
                onChange={(page) => {
                  setPage(page);
                }}
              />
            )}
          </Row>
        </>
      )}
    </>
  );

  const tabCourse = isKruNut
    ? [
      {
        key: TABS_VALUE.ONLINE,
        label: `คอร์สเรียนของฉัน`,
        children: <Courses />,
      },
    ]
    : [
      {
        key: TABS_VALUE.ONLINE,
        label: `คอร์สเรียนของฉัน`,
        children: <Courses />,
      },
      {
        key: TABS_VALUE.DRAFT,
        label: `ฉบับร่าง `,
        children: <Courses />,
      },
      {
        key: TABS_VALUE.WAITING,
        label: `รออนุมัติ `,
        children: <Courses />,
      },
    ];

  return (
    <>
      <Tabs
        size="large"
        activeKey={tab}
        items={tabCourse}
        onChange={(key) => dispatch(SET_TAB(key))}
      />
      <CourseFormModal
        isEditable={
          !editCourse ||
          editCourse.isReject ||
          (isKruNut && !editCourse?.isPublish)
        }
        data={editCourse}
        open={openCreateCourse}
        onOk={_handleCloseCreateCourse}
        onCancel={_handleCloseCreateCourse}
        refetch={refetchCourse}
      />
    </>
  );
}
