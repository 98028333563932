import { Modal, Row, Spin } from "antd";
import { logo_mini_tonemusic } from "assets";
import { NO_IMAGE } from "assets/images";
import { AlertError, AlertLoading } from "components/alert/Alert";
import useModalHook from "hooks/useModalHook";

import moment from "moment";
import numeral from "numeral";
import { useEffect, useState } from "react";
import Script from "react-load-script";
import { Link, useNavigate, useParams } from "react-router-dom";
import masterService from "services/master.services";
import teacherServices from "services/teacher.services";
import Swal from "sweetalert2";
import { OMISE_PUBLIC_KEY } from "utils";
import { PAYMENT_TYPE } from "pages/back-office/constants/payment";

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds
  ).padStart(2, "0")}`;
};

const SubscriptionPayment = () => {
  const { teacherId } = useParams();
  const navigate = useNavigate();
  const baseUrl = window.location.origin;
  const QR_TIME_OUT = 15 * 60; // 15 minutes in seconds

  const [intervalId, setIntervalId] = useState(null);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [countdown, setCountdown] = useState(QR_TIME_OUT);
  const [qrCodePayment, setQrCodePayment] = useState(null);
  const [qrExpire, setQrExpire] = useState(
    moment().add(QR_TIME_OUT, "minutes")
  );

  const { data: dataTeacher, isLoading: isLoadingTeacher } =
    teacherServices.useQueryGetTeacherDetailById({
      id: teacherId,
      onSuccess: (res) => {
        const isTeacher = res?.id;
        if (!isTeacher) {
          navigate("/teachers");
        }
      },
    });

  const {
    open: openQrCode,
    handleOpen: _handleOpenQrCode,
    handleClose: _handleCloseQrCode,
  } = useModalHook();

  const { mutate: qrPaymentChecking } =
    masterService.useMutationCheckEventPayment((res) => {
      // console.log("[6]-QR Payment Status:", res);
      let status_code = res?.status?.code;
      if (status_code === "200") {
        clearInterval(intervalId);
        setPaymentCompleted(true);
        setQrCodePayment(null);
        setCountdown(QR_TIME_OUT);
        _handleCloseQrCode();
      } else {
        setPaymentCompleted(false);
      }
    });

  const handleQrPayment = async (_chargeId) => {
    try {
      // console.log("[5]-Check QR Code Payment Completed?");

      // Set interval to call fetchAPI every 30 seconds
      const id = setInterval(() => {
        // console.log("PAYMENT CHECKING...");
        qrPaymentChecking({ chargeId: _chargeId });
      }, 20000); // 20 sec

      // Set timeout to stop calling fetchAPI after 15 minutes
      setTimeout(() => {
        clearInterval(id);
        setQrCodePayment(null);
        setCountdown(QR_TIME_OUT);
        setPaymentCompleted(false);
        _handleCloseQrCode();
        if (paymentCompleted === false) {
          AlertError({
            text: "QR Code หมดอายุแล้ว กรุณาทำการชำระเงินอีกครั้ง!",
          });
        }
        // console.log("[END]-TIMEOUT QR_CODE");
      }, QR_TIME_OUT * 1000); // 15 minutes in milliseconds

      setIntervalId(id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const { mutate: createPayment } = masterService.useMutationCreatePayment(
    (res) => {
      const { orderNo, paymentId } = res?.data;
      handlePayment(paymentId, orderNo);
      // console.log("[1]-Create Payment (res) :", res);
    },
    (err) => {
      return AlertError({ text: `CREATE PAYMENT : ${err?.message}` });
    }
  );

  const {
    mutate: createChargePayment,
    isLoading: isLoadingCreateChargePayment,
  } = masterService.useMutationCreateChargePayment(
    (res) => {
      // console.log("[3]-Create Charge Payment (res):", res);
      Swal.close();
      const {
        id,
        source,
        created_at,
        expires_at,
        amount,
        paid,
        status,
        authorize_uri,
      } = res?.data;

      setQrExpire(expires_at);

      let _chargeId = id;
      let payment_type = source?.type; // "promptpay"
      if (payment_type) {
        // payment_type : promptpay
        // console.log("[4.2]-PAY WITH QR Code");
        let qr_code = source?.scannable_code?.image?.download_uri;
        setQrCodePayment(qr_code);
        _handleOpenQrCode();
        handleQrPayment(_chargeId);
      } else {
        // payment_type : credit_card
        // console.log("[4.1]-PAY WITH Credit Card");
        window.location.replace(authorize_uri); // OTP Checking
      }
    },
    (err) => {
      AlertError({ text: `CREATE Charge : ${err?.message}` });
    }
  );

  if (isLoadingCreateChargePayment) {
    AlertLoading({});
  }

  // --------------------- OMISE CONFIGURE ---------------------------------
  const omiseLoadScript = () => {
    const OmiseCard = window.OmiseCard;
    OmiseCard.configure({
      publicKey: OMISE_PUBLIC_KEY,
      image: logo_mini_tonemusic,
      currency: "THB",
      frameLabel: "TONES MUSIC",
      submitLabel: "PAY NOW",
    });
  };

  const omisePaymentConfigure = () => {
    const OmiseCard = window.OmiseCard;
    OmiseCard.configure({
      defaultPaymentMethod: "credit_card",
      otherPaymentMethods: ["promptpay"],
    });
    OmiseCard.configureButton("#credit-card");
    OmiseCard.attach();
  };

  const omiseSubmit = (_paymentId, _orderNo) => {
    const OmiseCard = window.OmiseCard;

    OmiseCard.open({
      amount: (dataTeacher?.memberPrice || 0) * 100, // for omise (0.00)
      onCreateTokenSuccess: (token) => {
        let values = {
          amount: dataTeacher?.memberPrice,
          currency: "THB",
          paymentId: _paymentId,
          id: token,
          returnUri: `${baseUrl}/purchased/${_orderNo}`,
        };
        // console.log("[2]-OMISE_VALUES:", values);
        createChargePayment({ values }); // create Omise Charge Back-end
      },
      onFormClosed: () => {},
    });
  };

  const handlePayment = (_paymentId, _orderNo) => {
    omisePaymentConfigure();
    omiseSubmit(_paymentId, _orderNo);
  };
  // --------------------- END OMISE CONFIGURE ---------------------------------

  useEffect(() => {
    // Cleanup the interval when the component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  useEffect(() => {
    if (qrCodePayment !== null) {
      const timerInterval = setInterval(() => {
        const now = moment();
        const expirationTime = moment(qrExpire);
        const duration = moment.duration(expirationTime.diff(now));

        // Format the remaining time
        const toSeconds = duration.minutes() * 60 + duration.seconds();
        setCountdown(toSeconds);

        // Check if the expiration time has passed
        if (now.isAfter(expirationTime)) {
          clearInterval(timerInterval);
        }
      }, 1000);
    }
  }, [qrCodePayment]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [teacherId]);

  return (
    <>
      <Script url="https://cdn.omise.co/omise.js" onLoad={omiseLoadScript} />
      <section className="breadcrumb-section">
        <div className="container">
          <h1>การสมัครสมาชิกรายปี</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">หน้าแรก</Link>
            </li>
            <li className="breadcrumb-item">การสมัครสมาชิกรายปี</li>
          </ol>
        </div>
      </section>

      <div
        style={{ minHeight: "600px", paddingTop: "40px" }}
        className="shopping-cart section-padding"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <form className="woocommerce-cart-form" action="#">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">ครูผู้สอน </th>
                      <th scope="col">จำนวน</th>
                      <th scope="col">ราคา / ปี</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>
                        <div className="product">
                          <Link to={`/teachers/${teacherId}`}>
                            <span className="product-thumb">
                              <div style={{ height: "70px", width: "85px" }}>
                                <img
                                  style={{
                                    objectFit: "cover",
                                    height: "100%",
                                    width: "100%",
                                  }}
                                  src={dataTeacher?.thumbUrl || NO_IMAGE}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = NO_IMAGE;
                                  }}
                                  alt="cover"
                                  className="img-fluid"
                                />
                              </div>
                            </span>
                            <span>{`${dataTeacher?.firstName} ${dataTeacher?.lastName}`}</span>
                          </Link>
                        </div>
                      </th>
                      <td>
                        <div className="product-quantity">
                          <div className="quantity" data-trigger="spinner">
                            <input
                              type="text"
                              name="quantity"
                              value="1"
                              title="quantity"
                              className="input-text"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className=" d-flex justify-content-around align-items-center">
                          <span className="subtotal">{`${numeral(
                            dataTeacher?.memberPrice
                          ).format("0,0")} บาท`}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </form>
            </div>

            <div className="col-lg-4">
              {qrCodePayment && (
                <div className="order-summary mb-3">
                  <ul className="global-list">
                    <li className="d-flex justify-content-between align-items-center">
                      รอการชำระ {formatTime(countdown)} น.
                      <a
                        style={{ width: "150px" }}
                        className="py-2 btn btn-primary"
                        onClick={_handleOpenQrCode}
                      >
                        ชำระเงิน
                      </a>
                    </li>
                  </ul>
                </div>
              )}

              <div className="order-summary">
                <h2>สรุปการสั่งซื้อ</h2>
                {isLoadingTeacher ? (
                  <div className="center  p-4">
                    <Spin />
                  </div>
                ) : (
                  <>
                    <ul className="global-list">
                      <li>
                        ราคา{" "}
                        <span>
                          {numeral(dataTeacher?.memberPrice).format("0,0")}
                        </span>
                      </li>
                    </ul>
                    <div className="order-total">
                      <p>
                        ยอดรวมทั้งหมด :{" "}
                        <span>
                          {numeral(dataTeacher?.memberPrice).format("0,0")}
                        </span>
                      </p>
                    </div>
                  </>
                )}

                {qrCodePayment === null && (
                  <form>
                    <div
                      id="credit-card"
                      type="button"
                      className="btn btn-primary"
                      onClick={() => {
                        createPayment({
                          amount: dataTeacher?.memberPrice,
                          cartList: [],
                          method: PAYMENT_TYPE.online,
                        });
                      }}
                    >
                      ชำระเงิน
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title=""
        open={openQrCode}
        footer={null}
        onCancel={_handleCloseQrCode}
      >
        <Row className="center my-4">
          <h4>กรุณาชำระเงินภายใน {formatTime(countdown)} นาที</h4>
        </Row>
        <Row className="center mb-4">
          <img
            width={300}
            height={426}
            src={qrCodePayment || NO_IMAGE}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = NO_IMAGE;
            }}
            alt="QR CODE"
          />
        </Row>
      </Modal>
    </>
  );
};

export default SubscriptionPayment;
