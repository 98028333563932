import { AlertError } from "components/alert/Alert";
import { AlertSuccess } from "components/alert/Alert";
import _ from "lodash";
import { useMutation, useQuery } from "react-query";
import { getUserId } from "utils";
import createRequest from "utils/request";

const teacherBackOfficeService = {
  useQueryGetTeacherAwardByTeacherId({ onSuccess, onError }) {
    const teacherId = getUserId();
    return useQuery(
      ["getTeacherAwardByTeacherId", teacherId],
      async () => {
        const path = `/teacher/getTeacherAwardByTeacherId`;
        const query = { params: { teacherId: teacherId } };
        const res = await createRequest.get(path, query);
        return res?.data?.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!teacherId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetTeacherAvailableTime({ onSuccess, onError }) {
    const teacherId = getUserId();
    return useQuery(
      ["getTeacherAvailableTime", teacherId],
      async () => {
        const path = `/teacher/getTeacherAvailableTime`;
        const query = { params: { teacherId: teacherId } };
        const res = await createRequest.get(path, query);
        return res?.data?.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!teacherId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetMySchedule({ month, onSuccess, onError }) {
    return useQuery(
      ["getMySchedule", month],
      async () => {
        const path = `/teacher/getMySchedule`;
        const query = { params: { month } };
        const res = await createRequest.get(path, query);
        return res?.data?.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!month,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  // :: USE MUTATION --------------------------------------
  useMutationCreateTeacherAward(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/teacher/createTeacherAward`;
        const query = { ...values, teacherId: getUserId() };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  useMutationUpdateTeacherAward(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/teacher/updateTeacherAward`;
        const query = { ...values, teacherId: getUserId() };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDeleteTeacherAward(onSuccess, onError) {
    return useMutation(
      async ({ awardId }) => {
        const path = `/teacher/deleteTeacherAward`;
        const query = { params: { teacherAwardId: awardId } };
        const res = await createRequest.delete(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  useMutationCreateUpdateTeacherAvailableTime(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/teacher/createUpdateTeacherAvailableTime`;
        const query = values;
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  // Quiz
  useQueryGetAllQuizByCourseId(courseId) {
    return useQuery(
      "getAllQuizByCourseId",
      async () => {
        const path = `/course/getAllQuizByCourseId`;
        const query = { params: { courseId } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetQuizByUnitId(unitId) {
    return useQuery(
      "getQuizByUnitId",
      async () => {
        if (unitId) {
          const path = `/course/learn/getQuizByUnitId`;
          const query = { params: { unitId } };
          const res = await createRequest.get(path, query);
          return res.data.data;
        }
      },
      {
        enabled: !!unitId,
      }
    );
  },

  useQueryGetQuizDetailByQuizId(quizId) {
    return useQuery(
      "getQuizDetailByQuizId",
      async () => {
        if (quizId) {
          const path = `/course/learn/getQuizDetailByQuizId`;
          const query = { params: { quizId } };
          const res = await createRequest.get(path, query);
          return res.data.data;
        }
      },
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  async getAllUnitByCourseId(courseId) {
    const path = "/course/getAllUnitByCourseId";
    const query = { params: { courseId } };
    try {
      return await createRequest.get(path, query);
    } catch (error) {
      console.log(error);
    }
  },

  async updateQuiz(postData) {
    const path = "/course/updateQuiz";
    try {
      return await createRequest.post(path, postData);
    } catch (error) {
      console.log(error);
    }
  },

  async uploadQuiz(postData) {
    const path = "/course/uploadQuiz";
    try {
      const result = await createRequest.post(path, postData);
      if (result.status === 200) {
        AlertSuccess({});
      } else {
        AlertError({ text: result.data?.status?.description });
      }
    } catch (error) {
      AlertError({ text: error?.response?.data?.status?.description });
    }
  },

  // POST
  useCreateCourse({ onSuccess, onError }) {
    return useMutation(
      async (postData) => {
        const path = "/course/createCourse"; // Replace with your actual API endpoint
        const res = await createRequest.post(path, postData); // Assuming you want to POST some data
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};

export default teacherBackOfficeService;
