import { useMutation, useQuery } from "react-query";
import { getUserId } from "utils";
import createRequest from "utils/request";

const packageBackofficeServices = {
  // USE QUERY PACKAGES
  useQueryGetAllMyPackage({ queryObj, onSuccess, onError }) {
    const teacherId = getUserId();
    return useQuery(
      ["getAllMyPackage", teacherId],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `/package/getAllMyPackage`;
        const query = { params: { ...queryObj, paging: pagingStr } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!teacherId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  // ADMIN
  useQueryGetAllPackageForApprove({ queryObj, onSuccess, onError }) {
    const teacherId = getUserId();
    return useQuery(
      ["getAllPackageForApprove", teacherId],
      async () => {
        const path = `/package/getAllPackageForApprove`;
        const query = { params: { ...queryObj } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!teacherId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  // USE MUTATION PACKAGES
  useMutationCreatePackage(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/package/createPackage`;
        const query = { ...values, teacherId: getUserId() };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdatePackage(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/package/updatePackage`;
        const query = { ...values, teacherId: getUserId() };

        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDeletePackage(onSuccess, onError) {
    return useMutation(
      async ({ packageId }) => {
        const path = `/package/deletePackage`;
        const query = { params: { packageId: packageId } };
        const res = await createRequest.delete(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationSendToApprovePackage(onSuccess, onError) {
    return useMutation(
      async ({ id }) => {
        const path = `/package/sendToApprovePackage`;
        const query = { packageId: id };

        const res = await createRequest.post(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationPublishPackage(onSuccess, onError) {
    return useMutation(
      async ({ id, publishStatus }) => {
        const path = `/package/publishPackage`;
        const query = { packageId: id, publishStatus };
        const res = await createRequest.post(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  // ADMIN
  useMutationUpdatePackageApprove(onSuccess, onError) {
    return useMutation(
      async ({ id, approveStatus }) => {
        const path = `/package/updatePackageApprove`;
        const query = { approveStatus: approveStatus, packageId: id };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};

export default packageBackofficeServices;
