import { Divider, Modal } from "antd";
import { NO_IMAGE } from "assets/images/index";
import _ from "lodash";
import numeral from "numeral";
import { useNavigate } from "../../../node_modules/react-router-dom/dist/index";

const SubscriptionModal = ({ open, data, course, onCancel }) => {
  const navigate = useNavigate();
  return (
    <Modal
      title="การเป็นสมาชิกรายปี"
      open={open}
      okText="สมัครตอนนี้"
      cancelText="ยกเลิก"
      onOk={() => {
        onCancel();
        navigate(`/subscription/${data?.id}`);
      }}
      onCancel={onCancel}
    >
      <div className="mt-4 fs-6">
        <div className="d-flex justify-content-between ">
          <div className="d-flex align-items-center">
            <div
              className="d-flex justify-content-center align-items-center me-4"
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <img 
                src={data?.thumbUrl || NO_IMAGE} 
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = NO_IMAGE;
                }}
                alt="images-cover" />
            </div>
            <div>
              <h5>{`${data?.firstName} ${data?.lastName}`}</h5>
              <p>{data?.email}</p>
            </div>
          </div>
          <div>
            <b className="text-primary">{`${numeral(data?.memberPrice).format(
              "0,0"
            )} บาท / ปี`}</b>
          </div>
        </div>
        <Divider />

        <p>{` การเป็นสมาชิกของ : ${data?.firstName} ${data?.lastName} `}</p>
        <p>
          {`ซึ่งมีคอร์สวีดีโอออนไลน์ทั้งหมด ${numeral(_.size(course)).format(
            "0,0"
          )} คอร์ส`}
        </p>
        <p
          className="mt-3"
          style={{ opacity: "0.6", fontWeight: "400", fontSize: "14px" }}
        >
          {`** สามารถเข้าถึงคอร์สการเรียนแบบเหมาได้ทั้งหมดระยะเวลา 1 ปี
      นับจากวันที่เป็นสมาชิก`}
        </p>
        <Divider />
      </div>
    </Modal>
  );
};

export default SubscriptionModal;
