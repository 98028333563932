import React from "react";

const Breadcrumb = ({
  items = [
    {
      name: "Home",
      link: "#",
    },
  ],
}) => {
  return (
    <section className="breadcrumb-section">
      <div className="container">
        <h1>{items[items?.length - 1]?.name}</h1>
        <ol className="breadcrumb">
          {items.map((menu, index) => {
            return (
              <li key={index} className="breadcrumb-item">
                {menu?.link ? <a href={menu.link}>{menu?.name}</a> : menu?.name}
              </li>
            );
          })}
        </ol>
      </div>
    </section>
  );
};

export default Breadcrumb;
