import { Col, Row, Select } from "antd";
import CourseCard from "components/CourseCard";
import TutorCard from "components/TutorCard";
import Breadcrumb from "components/elements/Breadcrumb";
import Pagination from "components/elements/Pagination";

import { courseTypeOption } from "constants/options/courseTypeOption";
import { paging } from "constants/paginationSize";
import useModalHook from "hooks/useModalHook";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/dist";
import courseService from "services/course.services";
import teacherServices from "services/teacher.services";
import ActivityList from "./ActivityList";
import SubscriptionModal from "./SubscriptionModal";

const TeacherDetails = () => {
  const { id } = useParams();
  const [teacherName, setTeacherName] = useState({});

  const {
    open: openSubscription,
    handleOpen: _handleSubscriptionOpen,
    handleClose: _handleSubscriptionClose,
  } = useModalHook();

  const { data: teacherDetail } = teacherServices.useQueryGetTeacherDetailById({
    id,
    onSuccess: (res) => {
      setTeacherName(res?.displayName);
    },
  });

  const { data: teacherCourse } =
    courseService.useQueryGetAllCourseByTeacherIdPublic({
      id,
    });

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  let breadcrumb_item = [
    {
      name: "หน้าแรก",
      link: "/",
    },
    {
      name: "ครูผู้สอน",
    },
    {
      name: `${teacherName?.displayName}`,
    },
  ];

  return (
    <>
      <Breadcrumb items={breadcrumb_item} />
      <div className="sg-page-content">
        <div className="sg-section">
          <div className="section-content tutor-details section-padding">
            <div className="container">
              <div className="sg-author-content">
                <div style={{ minWidth: "300px" }} className="me-5">
                  <TutorCard
                    thumbUrl={teacherDetail?.thumbUrl}
                    name={teacherDetail?.displayName}
                    university={
                      teacherDetail?.educations[
                        _.size(teacherDetail.educations) - 1
                      ]?.universityName
                    }
                    course={teacherDetail?.course}
                  />
                  <button
                    className="w-100 mb-3 btn btn-primary"
                    onClick={_handleSubscriptionOpen}
                  >
                    สมัครสมาชิกรายปี
                  </button>

                  <p style={{ opacity: "0.5" }}>
                    {numeral(teacherDetail?.totalSubscribers).format("0,0")}{" "}
                    subscribers for VIP Content
                  </p>
                </div>

                <div className="author-info">
                  <h3>ประวัติครูผู้สอน</h3>
                  <p>{teacherDetail?.personalRecord}</p>
                  <h3>ประวัติการศึกษา</h3>
                  <div className="mb-3">
                    {teacherDetail?.edutations?.map((n, i) => (
                      <li key={`teacher-detail-${i}`}>{`${n?.degrees} - ${n?.universityName} ${
                        n?.universityNameOther ? n?.universityNameOther : ""
                      } ( ${n?.major} )`}</li>
                    ))}
                  </div>
                  <h3>ผลงาน / รางวัล</h3>

                  <div className="mb-3">
                    {teacherDetail?.awards?.map((n, i) => {
                      return (
                        <li key={i}>{`${n?.detail} - (${moment(n?.date).format(
                          "DD MMM YYYY"
                        )})`}</li>
                      );
                    })}
                  </div>

                  <ul className="global-list">
                    <li>
                      <strong>Total Students</strong>
                      <span>
                        {" "}
                        {numeral(teacherDetail?.totalStudent).format("0,0")}
                      </span>
                    </li>
                    <li>
                      <strong>Courses</strong>
                      <span>
                        {" "}
                        {numeral(teacherDetail?.totalCourse).format("0,0")}
                      </span>
                    </li>
                    <li>
                      <strong>Reviews</strong>
                      <span>
                        {numeral(teacherDetail?.totalReview).format("0,0")}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="author-course">
                <ActivityList />
              </div>

              <div className="author-course mt-2">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "start",
                  }}
                >
                  <div className="section-header mt-2">
                    <div className="title">
                      <h1>คอร์สเรียนของครูผู้สอน</h1>
                      <span>Courses</span>
                    </div>
                  </div>
                  <Row gutter={[8, 8]}>
                    <Col>
                      <Select
                        placeholder="ประเภทการเรียน"
                        size="large"
                        style={{
                          width: 240,
                        }}
                        options={courseTypeOption}
                      />
                    </Col>
                  </Row>
                </div>

                <div className="sg-course-content">
                  <div className="row">
                    {teacherCourse?.map((data, index) => {
                      return (
                        <div key={index} className="col-md-6 col-lg-4">
                          <CourseCard
                            key={`course-card-${index}`}
                            data={data}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <Pagination
                  page={paging.page}
                  pageSize={paging.pageSize}
                  total={paging.total}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubscriptionModal
        data={teacherDetail}
        course={teacherCourse}
        open={openSubscription}
        onCancel={_handleSubscriptionClose}
      />
    </>
  );
};

export default TeacherDetails;
