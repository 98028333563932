import TutorCard from "components/TutorCard";
import FadingSection from "components/fadingSection";
import Section from "components/section";
import Slick from "components/slick";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import teacherService from "services/teacher.services";
import { COURSE_SLIDE_CONFIG } from "./../back-office/constants/course";
import { homeConstants } from "./constants/HomeConstants";
import PaginationSlideCard from "./components/PaginationSlideCard";
import SkeletonCardLoader from "./components/SkeletonCardLoader";

const TutorSection = () => {
  const limitPage = homeConstants.limitPage;
  const [page, setPage] = useState(1);

  const {
    data: dataTeacher,
    isFetching: isLoadingTeacher,
    refetch: refetchTeacher,
  } = teacherService.useQueryGetAllTeacher({
    queryObj: {
      paging: {
        limit: limitPage,
        start: page * limitPage - limitPage,
      },
    },
  });

  useEffect(() => {
    refetchTeacher()
  }, [page])

  return (
    <FadingSection>
      <Section className="padding-top-0">
        <div className="section-header justify-content-between">
          <div className="title align-self-center">
            <h1>ครูผู้สอน</h1>
            <span>Tutors</span>
          </div>
          {dataTeacher?.data?.length ? (
            <div className="view-all align-self-center">
              <Link to="/teachers">
                <button className="btn btn-primary">ดูทั้งหมด</button>
              </Link>
            </div>
          ) : null}
        </div>

        <div className="sa-course-content">
          {isLoadingTeacher ? (
            <SkeletonCardLoader />
          ) : dataTeacher?.data ? (
            <Slick
              className="sa-course-slider"
              config={COURSE_SLIDE_CONFIG(dataTeacher.data.length || 0)}
            >
              {dataTeacher.data.map((data, index) => {
                return (
                  <TutorCard
                    key={index}
                    id={data.id}
                    thumbUrl={data.thumbUrl}
                    name={data.name}
                    course={data.course}
                    university={data.university}
                  />
                );
              })}
            </Slick>
          ) : (
            <p className="tw-text-center tw-text-gray-300 !tw-py-10 tw-font-extrabold tw-text-2xl">ยังไม่มีคุณครูที่เปิดสอนในขณะนี้</p>
          )}
        </div>
        <PaginationSlideCard
          page={page}
          total={dataTeacher?.total}
          limitPage={limitPage}
          setPage={setPage}
        />
      </Section>
    </FadingSection>
  );
};

export default TutorSection;
