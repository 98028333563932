export const thaiLocale = {
  lang: {
    locale: "th",
    today: "วันนี้",
    now: "ตอนนี้",
    backToToday: "กลับไปที่วันนี้",
    ok: "ตกลง",
    clear: "ลบทั้งหมด",
    month: "เดือน",
    year: "ปี",
    timeSelect: "เลือกเวลา",
    dateSelect: "เลือกวันที่",
    monthSelect: "เลือกเดือน",
    yearSelect: "เลือกปี",
    decadeSelect: "เลือกทศวรรษ",
    yearFormat: "YYYY",
    dateFormat: "D/M/YYYY",
    dayFormat: "D",
    dateTimeFormat: "D/M/YYYY HH:mm:ss",
    monthBeforeYear: true,
    previousMonth: "เดือนก่อนหน้า (PageUp)",
    nextMonth: "เดือนถัดไป (PageDown)",
    previousYear: "ปีก่อนหน้า (Control + left)",
    nextYear: "ปีถัดไป (Control + right)",
    previousDecade: "ทศวรรษก่อนหน้า",
    nextDecade: "ทศวรรษถัดไป",
    previousCentury: "ศตวรรษก่อนหน้า",
    nextCentury: "ศตวรรษถัดไป",
    shortWeekDays: ["อา", "จ", "อ", "พ", "พฤ", "ศ", "ส"],
    shortMonths: [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ],
  },
  timePickerLocale: {
    placeholder: "เลือกเวลา",
  },
  dateFormat: "YYYY-MM-DD",
  dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
  weekFormat: "YYYY-wo",
  monthFormat: "YYYY-MM",
};
