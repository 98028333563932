import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Pagination,
  Row,
  Spin,
  Switch,
  Tabs,
  Tag,
} from "antd";
import { NO_IMAGE } from "assets/images";
import { courseTypeOption } from "constants/options/courseTypeOption";
import useModalHook from "hooks/useModalHook";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { IoDesktopSharp } from "react-icons/io5";
import { LiaEditSolid } from "react-icons/lia";
import { MdAdd, MdOutlineAccessTime } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IoMdInformationCircleOutline } from "react-icons/io";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";
import numeral from "numeral";
import packageBackofficeServices from "services/backoffice/packageBackoffice.services";
import PackageFormModal from "./PackageFormModal";
import { useDispatch, useSelector } from "react-redux";
import { SET_TAB, TABS_VALUE } from "redux/reducers/packageSlice";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import { errorToMessage } from "hooks/functions/errorToMessage";

const TeacherPackages = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const limitPage = 5;
  const [page, setPage] = useState(1);
  const tab = useSelector((state) => state.package.tab);
  const [editPackage, setEditPackage] = useState(null);
  const {
    open: openCreatePackage,
    handleOpen: _handleOpenCreatePackage,
    handleClose: _handleCloseCreatePackage,
  } = useModalHook();

  const {
    data: getTeacherPackages,
    isFetching: isLoadingTeacherPackages,
    refetch: refetchPackage,
  } = packageBackofficeServices.useQueryGetAllMyPackage({
    queryObj: {
      paging: {
        limit: limitPage,
        start: page * limitPage - limitPage,
      },
      isApprove:
        tab === TABS_VALUE.ONLINE,
      isReject:
        tab === TABS_VALUE.ONLINE
          ? false
          : tab === TABS_VALUE.DRAFT,
    },
  });

  const { mutate: sendToApprovePackage } =
    packageBackofficeServices.useMutationSendToApprovePackage(
      () => {
        AlertSuccess({
          text: `
						กรุณารอตรวจสอบเนื้อหาและรอการอนุมัติ
						หากแพ็กเกจของท่านได้รับการอนุมัติแล้ว ท่านจะได้รับอีเมลแจ้งเตือน
					`,
        });
        refetchPackage();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const { mutate: sendToPublishPackage } =
    packageBackofficeServices.useMutationPublishPackage(
      () => {
        AlertSuccess({});
        refetchPackage();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  useEffect(() => {
    refetchPackage();
  }, [page]);

  const sendToApprove = (e, n) => {
    e.stopPropagation();
    AlertConfirm({
      text: "กรุณาตรวจสอบเนื้อหาความถูกต้องของแพ็กเกจ หากคุณกดส่งเพื่ออนุมัติแล้ว คุณจะไม่สามารถแก้ไขเนื้อหาใด ๆ ได้",
      onOk: () => {
        sendToApprovePackage({ id: n.id });
      },
    });
  };

  const publishPackage = (v, e, n) => {
    e.stopPropagation();
    AlertConfirm({
      text: v
        ? "ต้องการเผยแพร่แพ็กเกจหรือไม่ ?"
        : "ต้องการยกเลิกการเผยแพร่แพ็กเกจหรือไม่ ?",
      onOk: () => {
        sendToPublishPackage({ id: n.id, publishStatus: v });
      },
    });
  };

  useEffect(() => {
    refetchPackage();
    window.scrollTo(0, 440);
  }, [tab]);

  const renderContent = () => {
    return (
      <>
        <Row className="my-4 justify-content-between">
          <p style={{ fontSize: "18px", opacity: "0.8" }}>
            แพ็กเกจทั้งหมด {getTeacherPackages?.total || 0} แพ็กเกจ
          </p>
          <div className="center">
            {tab === TABS_VALUE.ONLINE && (
              <Button
                type="primary"
                className="ms-2 center"
                onClick={() => {
                  setEditPackage(null);
                  _handleOpenCreatePackage();
                }}
              >
                <MdAdd className="me-2" />
                สร้างแพ็กเกจ
              </Button>
            )}
          </div>
        </Row>
        {isLoadingTeacherPackages ? (
          <Spin className="p-4" />
        ) : (
          <>
            <Row>
              {getTeacherPackages?.data ? (
                <Col span={24}>
                  {[...(getTeacherPackages?.data || [])]?.map((n, i) => {
                    return (
                      <Card
                        key={i}
                        title={n.title || n.name}
                        extra={
                          <a
                            className="text-primary center"
                            onClick={() => {
                              setEditPackage(n);
                              _handleOpenCreatePackage();
                            }}
                          >
                            {tab === TABS_VALUE.ONLINE ||
                              tab === TABS_VALUE.WAITING ? (
                              <div className="d-flex align-items-center gap-1">
                                <IoMdInformationCircleOutline size={18} />
                                ดูรายละเอียด
                              </div>
                            ) : (
                              <div className="d-flex align-items-center gap-1">
                                <LiaEditSolid size={18} />
                                แก้ไข
                              </div>
                            )}
                          </a>
                        }
                        className="mb-3 course-list"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Row
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/teacher/package/${n?.id}`);
                          }}
                        >
                          <div className="tw-w-full tw-grid tw-grid-cols-1 lg:tw-grid-cols-[150px_1fr_140px] tw-gap-4">
                            <div className="tw-rounded-[8px] tw-overflow-hidden">
                              <img
                                className="tw-object-center tw-object-cover tw-w-full tw-h-full"
                                src={n?.coverImgUrl || NO_IMAGE}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = NO_IMAGE;
                                }}
                                alt="package-cover"
                              />
                            </div>
                            <div>
                              <p className="limited-text">
                                รายละเอียดคอร์ส : {n.description}
                              </p>
                              <div className="d-flex align-items-center my-1">
                                <IoDesktopSharp className="me-2" />{" "}
                                <p>
                                  {
                                    _.find(
                                      courseTypeOption,
                                      (x) => x.value === "online"
                                    )?.label
                                  }
                                </p>
                              </div>
                              <div className="d-flex align-items-center">
                                <MdOutlineAccessTime className="me-2" />
                                <p>{`วันที่สร้าง : ${moment(n.date).format(
                                  "DD/MM/YYYY"
                                )}`}</p>
                              </div>
                              <div className="d-flex align-items-center mt-1">
                                {n?.isApprove ? (
                                  <Tag
                                    color="success"
                                    icon={<CheckCircleOutlined />}
                                    className="mt-1"
                                  >
                                    สถานะ : อนุมัติแล้ว
                                  </Tag>
                                ) : (
                                  <Tag
                                    color="warning"
                                    icon={<ExclamationCircleOutlined />}
                                    className="mt-1"
                                  >
                                    สถานะ : รออนุมัติ
                                  </Tag>
                                )}
                              </div>
                            </div>
                            <Divider className="my-1 lg:tw-hidden" />
                            <div className="tw-flex tw-justify-between tw-items-center tw-flex-row-reverse lg:tw-flex-col lg:tw-items-end">
                              {tab === TABS_VALUE.DRAFT ? (
                                <Button
                                  type="primary"
                                  onClick={(e) => {
                                    sendToApprove(e, n);
                                  }}
                                >
                                  ส่งอนุมัติ
                                </Button>
                              ) : tab === TABS_VALUE.ONLINE ? (
                                <div>
                                  เผยแพร่ <br></br>{" "}
                                  <Switch
                                    checked={n?.isPublish}
                                    onClick={(v, e) => {
                                      publishPackage(v, e, n);
                                    }}
                                  />
                                </div>
                              ) : (
                                <div></div>
                              )}
                              <b>
                                <p
                                  className="text-primary"
                                  style={{ fontSize: "16px" }}
                                >{`ราคา ${numeral(n.price).format(
                                  "0,0"
                                )} บาท`}</p>
                              </b>
                            </div>
                          </div>
                        </Row>
                      </Card>
                    );
                  })}
                </Col>
              ) : (
                <div className="w-100 center p-4">
                  <Empty description={EMPTY_DESCRIPTION} />
                </div>
              )}
            </Row>
            <Row>
              {getTeacherPackages?.total > 0 && (
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  pageSize={limitPage}
                  total={getTeacherPackages?.total || 1}
                  onChange={(page) => {
                    setPage(page);
                  }}
                />
              )}
            </Row>
          </>
        )}
      </>
    );
  };

  const tabPackages = [
    {
      key: TABS_VALUE.ONLINE,
      label: `แพ็กเกจของฉัน`,
      children: renderContent(),
    },
    {
      key: TABS_VALUE.DRAFT,
      label: `ฉบับร่าง`,
      children: renderContent(),
    },
    {
      key: TABS_VALUE.WAITING,
      label: `รออนุมัติ`,
      children: renderContent(),
    },
  ];

  return (
    <>
      <Tabs
        size="large"
        activeKey={tab}
        items={tabPackages}
        onChange={(key) => dispatch(SET_TAB(key))}
      />
      <PackageFormModal
        isEditable={!editPackage || editPackage.isReject}
        data={editPackage}
        open={openCreatePackage}
        onCancel={_handleCloseCreatePackage}
        refetch={refetchPackage}
      />
    </>
  );
};

export default TeacherPackages;
