import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import adminMasterService from "services/adminMaster.services";
import { Button, Spin, Table, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { AlertConfirm, AlertError } from "components/alert/Alert";
import { localeCustomTH } from "constants/thaiLocale/localeCustomTH";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
export default function RedeemCash() {
  // const quizData = useSelector((state) => state.quiz.quiz);
  const dispatch = useDispatch();
  const [tab, setTab] = useState("1");
  const [dataSource, setDataSource] = useState([]);

  const { data, isLoading, refetch } =
    adminMasterService.useQueryGetAllRedeemCash(
      tab === "1" ? "waiting" : tab === "2" ? "approved" : "paid"
    );

  const columns =
    tab === "1" || tab === "2"
      ? [
          {
            title: "วันที่",
            dataIndex: "redeemDate",
            key: "redeemDate",
            width: 100,
            fixed: "left",
          },
          {
            title: "ชื่อผู้ทำรายการ",
            dataIndex: "username",
            key: "username",
            width: 150,
            fixed: "left",
          },
          {
            title: "คะแนนที่ใช้",
            dataIndex: "pointSpend",
            key: "pointSpend",
            width: 150,
          },
          {
            title: "จำนวนเงินที่แลก",
            dataIndex: "cash",
            key: "cash",
            width: 150,
          },
          {
            title: "",
            key: "edit",
            width: 80,
            fixed: "right",
            render: (text, record) =>
              tab === "1" ? (
                <div className="d-flex gap-2">
                  <Button type="primary" onClick={() => approve(record)}>
                    <CheckOutlined />
                  </Button>
                  <Button onClick={() => denied(record)}>
                    <CloseOutlined />
                  </Button>
                </div>
              ) : (
                <Button type="primary" onClick={() => paid(record)}>
                  Paid
                </Button>
              ),
          },
        ]
      : [
          {
            title: "วันที่",
            dataIndex: "redeemDate",
            key: "redeemDate",
            width: 100,
            fixed: "left",
          },
          {
            title: "ชื่อผู้ทำรายการ",
            dataIndex: "username",
            key: "username",
            width: 150,
            fixed: "left",
          },
          {
            title: "คะแนนที่ใช้",
            dataIndex: "pointSpend",
            key: "pointSpend",
            width: 150,
          },
          {
            title: "จำนวนเงินที่แลก",
            dataIndex: "cash",
            key: "cash",
            width: 150,
          },
        ];

  const approve = (record) => {
    AlertConfirm({
      text: "ต้องการอนุมัติรายการหรือไม่ ?",
      onOk: async () => {
        const result = await adminMasterService.approveRedeemCash(record.id);
        if (result?.status === 200) {
          refetch();
        } else {
          AlertError({ text: `ทำรายการไม่สำเร็จ` });
        }
      },
    });
  };

  const denied = (record) => {
    AlertConfirm({
      text: "ต้องการปฏิเสธรายการหรือไม่ ?",
      onOk: async () => {
        const result = await adminMasterService.deniedRedeemCash(record.id);
        if (result?.status === 200) {
          refetch();
        } else {
          AlertError({ text: `ทำรายการไม่สำเร็จ` });
        }
      },
    });
  };

  const paid = (record) => {
    AlertConfirm({
      text: "ต้องการตัดจ่ายรายการหรือไม่ ?",
      onOk: async () => {
        const result = await adminMasterService.paidRedeemCash(record.id);
        if (result?.status === 200) {
          refetch();
        } else {
          AlertError({ text: `ทำรายการไม่สำเร็จ` });
        }
      },
    });
  };

  const pagination = {
    pageSize: 5,
  };

  const handleTabChange = (key) => {
    setDataSource([]);
    setTab(key);
  };

  useEffect(() => {
    if (data) {
      setDataSource(data.data);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [tab]);

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h4>รายการแลกคะแนน ({dataSource?.length || 0})</h4>
        </div>
        <Tabs activeKey={tab} onChange={handleTabChange} type="card">
          <TabPane tab={"รายการรออนุมัติ"} key={"1"}>
            <Table
              locale={localeCustomTH.Table}
              dataSource={dataSource || []}
              columns={columns}
              pagination={pagination}
            />
          </TabPane>
          <TabPane tab={"รายการอนุมัติแล้ว"} key={"2"}>
            <Table
              locale={localeCustomTH.Table}
              dataSource={dataSource || []}
              columns={columns}
              pagination={pagination}
            />
          </TabPane>
          <TabPane tab={"รายการจ่ายแล้ว"} key={"3"}>
            <Table
              locale={localeCustomTH.Table}
              dataSource={dataSource || []}
              columns={columns}
              pagination={pagination}
            />
          </TabPane>
        </Tabs>
      </Spin>
    </>
  );
}
