import { Card, Col, Empty, Image, Row, Spin, Tabs } from "antd";
import { NO_IMAGE } from "assets/images";
import { COLOR } from "constants/color";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";
import { ROLE_USER } from "constants/roleUserConstant";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { IoBookOutline } from "react-icons/io5";
import { LuUser2 } from "react-icons/lu";
import { MdOutlineAccessTime } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import workshopBackofficeServices from "services/backoffice/workshopBackoffice.services";
import { Divider } from "../../../../../node_modules/antd/es/index";
const UserWorkshop = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);

  const { data: getWorkshop, refetch, isFetching: isLoadingWorkshop } =
    workshopBackofficeServices.useQueryGetAllMyStudentWorkshop({
      queryObj: {
        isFinish: tab === 1 ? false : true,
      },
    });

  useEffect(() => {
    refetch()
  }, [tab])

  const renderContent = () => {
    return (
      <>
        {isLoadingWorkshop ? (
          <Spin className="p-4" />
        ) : (
          <Row>
            {getWorkshop.data ? (
              <Col span={24}>
                {(getWorkshop?.data || []).map((n, i) => {
                  return (
                    <Card
                      key={`workshop-${i}`}
                      title={n?.title}
                      className="mb-3 course-list"
                      style={{
                        width: "100%",
                      }}
                    >
                      <Row
                        className="tw-grid tw-grid-cols-1 tw-gap-2 lg:tw-grid-cols-[160px_1fr] lg:tw-gap-4 tw-cursor-default"
                      >
                        <div className="tw-w-full tw-h-full tw-max-h-[240px] lg:tw-w-[160px] lg:tw-h-[120px] tw-rounded-[8px] tw-overflow-hidden">
                          <img
                            className="tw-object-center tw-object-cover tw-w-full tw-h-full"
                            src={n?.coverImgUrl || NO_IMAGE}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = NO_IMAGE;
                            }}
                            alt="course-cover"
                          />
                        </div>
                        <div>
                          <p
                            className="limited-text tw-cursor-pointer"
                            onClick={() => {
                              navigate(`/${ROLE_USER.student}/workshop/${n?.workshopId}`);
                            }}>
                            รายละเอียดเวิร์กชอป: {n?.description || "-"}
                          </p>

                          <div className="tw-flex tw-flex-col tw-gap-1 lg:tw-gap-4 lg:tw-flex-row">
                            <div className="d-flex align-items-center">
                              <IoBookOutline
                                color={COLOR.primary}
                                className="me-2"
                              />
                              <p className="text-primary">{`หมวดหมู่ : ${n?.category || "-"
                                }`}</p>
                            </div>
                            <div className="d-flex align-items-center">
                              <a href={`https://www.google.com/maps?q=${n?.position.lat},${n?.position.lon}`} target="_blank" className="tw-flex tw-items-center">
                                <HiOutlineLocationMarker
                                  color={COLOR.primary}
                                  className="me-2"
                                />
                                <p className="text-primary !tw-mb-0	">
                                  {n?.locationName || "Location Name"}
                                </p>
                              </a>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <LuUser2 className="me-2" />
                            <p className="my-1">{`จำนวนที่เปิดรับ ${numeral(
                              n?.limitPerGroup
                            ).format("0,0")} คน`}</p>
                          </div>
                          <div className="d-flex align-items-center">
                            <MdOutlineAccessTime className="me-2" />
                            <p>{`วันที่จัดงาน : ${moment(n?.startTime).format(
                              "DD/MM/YYYY"
                            )} - ${moment(n?.finishTime).format(
                              "DD/MM/YYYY"
                            )}`}</p>
                          </div>
                          <Divider className="m-2 lg:tw-hidden" />
                          <div className="d-flex align-items-center gap-2">
                            <b>
                              <p
                                className="text-primary"
                                style={{ fontSize: "16px" }}
                              >{`ราคา ${numeral(n?.price).format(
                                "0,0"
                              )} บาท`}</p>
                            </b>
                          </div>
                        </div>
                      </Row>
                    </Card>
                  );
                })}
              </Col>
            ) : (
              <div className="w-100 center p-4">
                <Empty description={EMPTY_DESCRIPTION} />
              </div>
            )}
          </Row>
        )}
      </>
    );
  };

  const tabWorkshop = [
    {
      key: 1,
      label: `ที่กำลังจะมาถึง`,
      children: renderContent(),
    },
    {
      key: 2,
      label: `สิ้นสุดแล้ว`,
      children: renderContent(),
    },
  ];
  return (
    <>
      <Tabs
        size="large"
        defaultActiveKey={1}
        items={tabWorkshop}
        onChange={(key) => {
          setTab(key);
        }}
      />
    </>
  );
};

export default UserWorkshop;
