import { Select } from "antd";
import { courseCategoryOption } from "constants/options/categoryOption";
import _ from "lodash";

const SelectCourseCategory = ({
  withAll = false,
  withOther = false,
  ...props
}) => {
  let _all = withAll
    ? courseCategoryOption
    : _.filter(courseCategoryOption, (n) => n.value !== "ทั้งหมด");

  let _option = withOther ? _all : _.filter(_all, (n) => n.value !== "other");

  return <Select placeholder="เลือกหมวดหมู่" options={_option} {...props} />;
};

export default SelectCourseCategory;
