import { Button, Divider, Form, Input } from "antd";
import {
  AlertConfirm,
  AlertError,
  AlertSuccess,
  AlertWarning,
} from "components/alert/Alert";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { useEffect } from "react";
import userBackOfficeServices from "services/backoffice/userBackoffice.services";
import masterService from "services/master.services";

const UserAccount = () => {
  const [form] = Form.useForm();

  const { data: userProfile } = userBackOfficeServices.useQueryGetUserProfile({
    onSuccess: (res) => {
      form.setFieldsValue(res);
    },
  });

  const { mutate: updatePassword } = masterService.useMutationUpdatePassword(
    (res) => {
      const { code, description } = res?.status;
      if (code === "200") {
        AlertSuccess({});
      } else {
        AlertError({ text: description });
      }
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );

  const onFinish = (values) => {
    const { oldPassword, newPassword, confirmPassword } = values;
    if (newPassword !== confirmPassword) {
      AlertWarning({ text: "กรุณาระบุรหัสผ่านใหม่ให้ตรงกัน!" });
    } else {
      let values = {
        oldPassword,
        newPassword,
        isPassword: userProfile?.getPassword,
      };

      AlertConfirm({
        onOk: () => {
          updatePassword({ values });
        },
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 440);
  }, []);
  return (
    <>
      <Form
        form={form}
        name="user-account"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <h4 className="mb-4">ข้อมูลส่วนตัว</h4>

        <Form.Item label="E-mail" name="email">
          <Input disabled size="large" placeholder="E-mail" />
        </Form.Item>
        {userProfile?.getPassword === "YES" && (
          <Form.Item
            label="รหัสผ่านเดิม"
            name="oldPassword"
            rules={[
              {
                required: true,
                message: "กรุณาระบุรหัสผ่านเดิม!",
              },
            ]}
          >
            <Input.Password size="large" placeholder="รหัสผ่านเดิม" />
          </Form.Item>
        )}
        <Form.Item
          label="รหัสผ่านใหม่"
          name="newPassword"
          rules={[
            {
              required: true,
              message: "กรุณาระบุรหัสผ่านใหม่!",
            },
            {
              min: 8,
              message: "รหัสผ่านควรมีอย่างน้อย 8 ตัวขึ้นไป!",
            },
            {
              pattern: /^(?=.*[a-zA-Z])(?=.*\d).+$/,
              message: "รหัสผ่านควรมีตัวอักษรและตัวเลขอย่างน้อย 1 ตัว!",
            },
          ]}
        >
          <Input.Password size="large" placeholder="รหัสผ่านใหม่" />
        </Form.Item>
        <Form.Item
          label="ยืนยันรหัสผ่านใหม่"
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "กรุณายืนยันรหัสผ่านใหม่!",
            },
            {
              min: 8,
              message: "รหัสผ่านควรมีอย่างน้อย 8 ตัวขึ้นไป!",
            },
            {
              pattern: /^(?=.*[a-zA-Z])(?=.*\d).+$/,
              message: "รหัสผ่านควรมีตัวอักษรและตัวเลขอย่างน้อย 1 ตัว!",
            },
          ]}
        >
          <Input.Password size="large" placeholder="ยืนยันรหัสผ่านใหม่" />
        </Form.Item>

        <Divider className="my-4" />

        <Form.Item>
          <Button
            style={{ height: "48px", width: "160px" }}
            size="large"
            type="primary"
            htmlType="submit"
          >
            บันทึก
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default UserAccount;
