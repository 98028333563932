import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Divider, Modal, Table, Tabs, Tooltip, Upload } from "antd";
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";

import { localeCustomTH } from "constants/thaiLocale/localeCustomTH";
import fileService from "services/file.services";
import { AlertError } from "components/alert/Alert";

const { TabPane } = Tabs;

const QuizModal = ({ courseId, file, isOpen = false, onOk, onCancel }) => {
  const [fileName, setFileName] = useState("");
  const [excelData, setExcelData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [activeSheetKey, setActiveSheetKey] = useState(null);

  const props = {
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "binary" });

        // const sheetNames = workbook.SheetNames;
        const allData = [];
        const sheetValues = Object.values(workbook.Sheets);

        for (const [index, sheetData] of sheetValues.entries()) {
          const sheet = sheetData;

          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          if (jsonData[0][0] !== "ชื่อบทเรียน" || !jsonData[0][1]) {
            AlertError({ text: "กรุณาอัปโหลดตามเทมเพลทที่ระบุ" })
            setFileName("")
            break
          } else {
            setFileName(file.name);
          }
          const unitName = jsonData[0][1];
          jsonData.shift();

          const headers = jsonData[0];
          jsonData.shift();

          const formattedData = jsonData.map((row) => {
            const obj = {};
            headers.forEach((header, index) => {
              obj[header] = row[index];
            });
            return obj;
          });

          allData.push({ sheetName: unitName, data: formattedData });

          if (index === 0) {
            setActiveSheetKey(unitName);
          }
        };

        setExcelData(allData);
        setUploading(false);
      };
      reader.readAsBinaryString(file);
      setUploading(true);
      return false;
    },
    showUploadList: false,
    onChange: (info) => {
      if (info.fileList.length > 0) {
        setFileName(info.fileList[0].name);
      }
    },
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleOk = () => {
    onOk(excelData);
  };

  const { mutate: downloadTemplate } =
    fileService.useMutationDownloadTemplateQuiz(courseId);

  useEffect(() => {
    if (!isOpen) {
      setExcelData(null);
      setFileName("");
      setUploading(false);
      setActiveSheetKey(null);
    }
  }, [isOpen]);

  const columns = excelData
    ? Object.keys(excelData[0]?.data[0] || {})?.map((header) => ({
      title: header,
      dataIndex: header,
    }))
    : [];

  return (
    <Modal
      title="สร้างแบบทดสอบ"
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText="บันทึก"
      cancelText="ยกเลิก"
      width={600}
    >
      <div className="my-4">
        <p className="fs-6 mb-2">อัปโหลดแบบทดสอบ (.xlsx)</p>
        <div className={`d-flex gap-2`}>
          <Button
            size="large"
            icon={<DownloadOutlined />}
            onClick={downloadTemplate}
          >
            ดาวน์โหลดฟอร์ม
          </Button>

          <Upload accept=".xls , .xlsx , .csv , .numbers" {...props}>
            <Tooltip title={fileName}>
              <Button
                size="large"
                type="dashed"
                icon={<UploadOutlined />}
                disabled={uploading}
                className="d-flex gap-2 align-items-center"
              >
                <span style={{ maxWidth: "200px" }} className="text-truncate">
                  {uploading ? "Uploading..." : ` + อัปโหลดไฟล์: ${fileName}`}
                </span>
              </Button>
            </Tooltip>
          </Upload>
        </div>

        {excelData && (
          <div className="mt-2">
            <Tabs
              activeKey={activeSheetKey}
              onChange={(key) => setActiveSheetKey(key)}
              type="card"
            >
              {excelData.map(({ sheetName, data }, i) => (
                <React.Fragment key={`quiz-${i}`}>
                  <TabPane
                    tab={sheetName}
                    key={sheetName}
                    style={{ overflow: "auto", maxWidth: "800px" }}
                  >
                    <Table
                      bordered
                      locale={localeCustomTH.Table}
                      dataSource={data}
                      columns={columns}
                      pagination={false}
                    />
                  </TabPane>
                </React.Fragment>
              ))}
            </Tabs>
          </div>
        )}
      </div>
      <Divider />
    </Modal>
  );
};

export default QuizModal;
