import Section from "components/section";
import React from "react";

// image size : 860 x 970

export default function AboutUsSection() {
  return (
    <Section className="sa-about">
      <div className="row">
        <div className="col-lg-6">
          <div className="about-thumb">
            <img
              src="assets/images/others/about.jpg"
              alt="about"
              className="tw-w-full tw-h-full tw-object-cover"
            />
          </div>
        </div>
        <div className="col-lg-6 align-self-center">
          <div className="section-header">
            <div className="title">
              <h1>ABOUT US</h1>
              <span>About</span>
            </div>
          </div>
          <div className="about-info">
            <h2>เกี่ยวกับ โทนส์ มิวสิค</h2>
            <p>
              โทนส์มิวสิค ก่อตั้ง โดย ครูนัท ณวภัสร์ หรือ นัท ไมค์ทองคำ2 ศิลปิน
              นักร้องลูกทุ่ง ที่เติบโตจากเส้นการประกวด
              จนมีผลงานเพลงแรกเป็นของตนเอง คือ “นางฟ้าตาน้ำผึ้ง”
              มีประสบการณ์ในการร้องเพลงและผ่านเวทีการประกวด มากกว่า 17ปี
              เรียนจบปริญญาตรี เอกขับร้องลูกทุ่ง สาขาดนตรีและการแสดง
              จากมหาวิทยาลัยบูรพา , หลักสูตรประกาศนียบัตรวิชาชีพครู มหาวิทยาลัย
              มหาจุฬาลงกรณราชวิทยาลัย และ ปัจจุบัน (2566) กำลังศึกษาปริญญาโท
              ดนตรีศึกษา สาขาวิชาศิลปศึกษา มหาวิทยาลัยศรีนครินทรวิโรฒ
              ซึ่งเป็นหลักสูตรที่เกี่ยวข้องกับการจัดการเรียนการสอนดนตรีโดยตรง
            </p>
            <ul className="global-list">
              <li> เน้นการพัฒนาศักยภาพผู้เรียนขั้นสุด</li>
              <li> ช่วยให้ทุกท่านประหยัดเวลา และค่าใช้จ่ายมากยิ่งขึ้น</li>
              <li> แพลตฟอร์ม ที่เรียนได้ทุกวัย คอร์สเรียนที่หลากหลาย</li>
            </ul>
            <p className="btn btn-primary">อ่านเพิ่มเติม</p>
          </div>
        </div>
      </div>
    </Section>
  );
}
