import { useMutation, useQuery } from "react-query";
import { PUBLIC_API, getUserId } from "utils";
import createRequest from "../utils/request";
import { getToken } from "utils";

const courseService = {
  // :: COURSE :: --------------------------------------------------
  useQueryGetAllCourse({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllCourse"],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `${PUBLIC_API}/course/getAllCourseActive`;
        const query = { params: { ...queryObj, paging: pagingStr } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetAllCourseByTeacherIdPublic({ id, onSuccess, onError }) {
    return useQuery(
      ["getAllCourseByTeacherId", id],
      async () => {
        const path = `${PUBLIC_API}/course/getAllCourseByTeacherId`;
        const query = { params: { teacherId: id } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetPopularCourse({ onSuccess, onError }) {
    return useQuery(
      ["getPopularCourse"],
      async () => {
        const path = `${PUBLIC_API}/course/getPopularCourse`;
        const res = await createRequest.get(path);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetCourseById({ id, onSuccess, onError }) {
    return useQuery(
      ["getCourseById", id],
      async () => {
        const path = `${PUBLIC_API}/course/getCourseById`;
        const query = { params: { id } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetAllZoomGroupByCourseId({ courseId, onSuccess, onError }) {
    return useQuery(
      ["getAllZoomGroupByCourseId", courseId],
      async () => {
        const path = `${PUBLIC_API}/course/getAllZoomGroupByCourseId`;
        const query = { params: { courseId } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!courseId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetCourseFlagById({ id, onSuccess, onError }) {
    return useQuery(
      ["getCourseFlagById", id],
      async () => {
        const path = `/course/getCourseFlagById`;
        const query = { params: { id } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetUnitByCourseId({ id, onSuccess, onError }) {
    return useQuery(
      ["getUnitByCourseId", id],
      async () => {
        const path = `${PUBLIC_API}/course/getAllUnitByCourseId`;
        const query = { params: { id } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetReviewByCourseId({ id, onSuccess, onError }) {
    return useQuery(
      ["getReviewByCourseId", id],
      async () => {
        const path = `${PUBLIC_API}/course/getReviewByCourseId`;
        const query = { params: { courseId: id } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetSlotAvailableTimeByTeacherAndDay({
    queryObj,
    onSuccess,
    onError,
  }) {
    return useQuery(
      ["getSlotAvailableTimeByTeacherAndDay", queryObj],
      async () => {
        const { date, dayOfWeek, hour, hourTravel, teacherId } = queryObj;
        const path = `/course/getSlotAvailableTimeByTeacherAndDay`;
        const query = {
          date,
          dayOfWeek,
          hour,
          hourTravel,
          teacherId,
        };
        const res = await createRequest.post(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!queryObj?.teacherId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetItemInCart({ onSuccess, onError }) {
    return useQuery(
      ["getItemInCart", getUserId()],
      async () => {
        const path = `/course/getItemInCart`;
        if (!!getToken()) {
          const res = await createRequest.get(path);
          return res.data.data || [];
        } else {
          return [];
        }
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetAppointment({ courseId, onSuccess, onError }) {
    return useQuery(
      ["getAppointmentByUserIdAndCourseId", courseId],
      async () => {
        const path = `/course/getAppointmentByUserIdAndCourseId`;
        const query = { params: { courseId: courseId, userId: getUserId() } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!courseId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetCourseSchedule({ courseId, month, onSuccess, onError }) {
    return useQuery(
      ["getCourseSchedule", courseId, month],
      async () => {
        const path = `${PUBLIC_API}/course/getCourseSchedule`;
        const query = { params: { courseId, month } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!courseId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  //   ---------------------- :: USE_MUTATION :: --------------------
  useMutationGetCodeReference(onSuccess, onError) {
    return useMutation(
      async ({ courseId }) => {
        const path = `/course/getCodeReference`;
        const query = { params: { courseId } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  useMutationDeleteCourse(onSuccess, onError) {
    return useMutation(
      async ({ courseId }) => {
        const path = `/course/deleteCourse`;
        const query = { params: { courseId } };
        const res = await createRequest.delete(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationAddItemToCart(onSuccess, onError) {
    return useMutation(
      async ({
        courseId,
        sessionGroupId,
        packageId,
        subscriptionId,
        referenceCode,
      }) => {
        const path = `/course/addItemToCart`;
        const query = {
          courseId,
          sessionGroupId,
          packageId,
          subscriptionId,
          referenceCode,
        };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationClearCourseInCart(onSuccess, onError) {
    return useMutation(
      async ({ cartId }) => {
        const path = `/course/clearCourseInCart`;
        const query = { params: { cartId } };
        const res = await createRequest.delete(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationClearAllCourseInCart(onSuccess, onError) {
    return useMutation(
      async () => {
        const path = `/course/clearAllCourseInCart`;
        const query = { params: { userId: getUserId() } };
        try {
          const res = await createRequest.delete(path, query);
          return res.data;
        } catch (error) {
          throw error;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  useMutationCreateAppointment(onSuccess, onError) {
    return useMutation(
      async ({ booking }) => {
        const path = `/course/createAppointment`;
        const query = booking;
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateAppointment(onSuccess, onError) {
    return useMutation(
      async ({ booking }) => {
        const path = `/course/updateAppointment`;
        const query = booking;
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationGetSlotAvailableTimeByTeacherAndDay(onSuccess, onError) {
    return useMutation(
      async ({ queryObj }) => {
        const path = `/course/getSlotAvailableTimeByTeacherAndDay`;
        const query = queryObj;
        const res = await createRequest.post(path, query);
        return res.data?.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationCheckPaid(onSuccess, onError) {
    return useMutation(
      async ({ id }) => {
        const path = `/course/checkPaid`;
        const query = { params: { id } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationCreateReviewInCourse(onSuccess, onError) {
    return useMutation(
      async ({ courseId, unitId, text, rating }) => {
        const path = `/course/createReviewInCourse`;
        const query = { courseId, unitId, text, rating };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateReviewInCourse(onSuccess, onError) {
    return useMutation(
      async ({ courseId, unitId, text, rating }) => {
        const path = `/course/updateReviewInCourse`;
        const query = { courseId, unitId, text, rating };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};
export default courseService;
