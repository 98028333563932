import { Button, Col, Row, Spin, Tabs } from "antd";

import { NO_IMAGE } from "assets/images";
import Breadcrumb from "components/elements/Breadcrumb";
import { courseTypeOption } from "constants/options/courseTypeOption";
import _ from "lodash";
import Calendar from "pages/back-office/components/Calendar/index";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  COURSE_DETAIL_SECTION,
  INIT,
  SET_COURSE_DETAIL,
  SET_COURSE_PAGE,
  SET_UNIT_DETAIL,
} from "redux/reducers/courseSlice";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import courseService from "services/course.services";
import CertificationPanel from "./CertificationPanel";
import CourseInfo from "./CourseInfo";
import GroupPanel from "./GroupPanel";
import Homework from "./Homework";
import Quiz from "./Quiz";
import Review from "./Review";
import UnitPanel from "./UnitPanel";
import Video from "./Video";
import CourseSchedule from "./CourseScedule";

export const UserCourseDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courseId } = useParams();
  const course = useSelector((state) => state.course);
  const currentUnit = course.currentUnitData;
  const courseDetail = course?.courseDetail;
  const [paid, setPaid] = useState(null);
  const [courseType, setCourseType] = useState("");
  const [quizType, setQuizType] = useState(COURSE_DETAIL_SECTION.PRE_QUIZ);
  const [isLoadingPostQuiz, setIsLoadingPostQuiz] = useState(false);

  const page = course.page;
  const [tab, setTab] = useState("desc");
  const [activeGroupData, setActiveGroupData] = useState({});

  let breadcrumb_item = [
    {
      name: "หน้าแรก",
      link: "/",
    },
    {
      name: `คอร์ส`,
    },
    {
      name: courseType,
    },
  ];

  // user ที่เข้ามาดูคอร์ส ได้มีการซื้อคอร์สไปหรือเปล่า ?
  const { mutate: checkPaid, isLoading: isLoadingPaidCourse } =
    courseService.useMutationCheckPaid((res) => {
      setPaid(res?.data);
    });

  const { data: unitDetail, refetch: refetchUnitDetail } =
    courseBackOfficeServices.useQueryGetAllUnitByCourseId({ courseId });

  const { data: dataPoint, refetch: refetchPoint } =
    courseBackOfficeServices.useQueryGetPointByUnitId({
      unitId: currentUnit?.id,
    });

  const {
    data: couseDetail,
    refetch,
    isFetching: isLoadingCourse,
  } = courseBackOfficeServices.useQueryGetCourseById({ id: courseId });

  const {
    data: dataQuiz,
    refetch: refetchQuiz,
    isFetching: isLoadingQuiz,
  } = courseBackOfficeServices.useQueryGetAllQuizByUnitId({
    unitId: currentUnit?.id,
    type: quizType,
  });

  const renderNotFound = () => {
    return (
      <div className="sg-section">
        <div className="section-content course-details bg-white section-padding">
          <div style={{ minHeight: "500px" }} className="container center">
            <p>ไม่พบข้อมูล</p>
          </div>
        </div>
      </div>
    );
  };

  const renderNotPaid = () => {
    return (
      <div className="sg-section">
        <div className="section-content course-details bg-white section-padding">
          <div style={{ minHeight: "500px" }} className="container center">
            <Row>
              <Col span={24}>
                <p>ไม่สามารถเข้าถึงคอร์สเรียนนี้ได้ กรุณาซื้อคอร์สเรียน</p>
                <Row className="center mt-4">
                  <Button
                    type="primary"
                    onClick={() => {
                      const { id } = couseDetail;
                      navigate(`/courses/${id}`);
                    }}
                  >
                    เข้าชมคอร์สเรียนนี้
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  const CourseScore = () => (
    <div className="tw-flex tw-justify-center tw-items-center tw-min-h-64 tw-text-center">
      {
        dataPoint?.data ? <>
          <div className="tw-flex tw-justify-between tw-items-center tw-py-10 tw-w-full">
            <div className="tw-mx-10">
              <div className="tw-w-40 tw-h-40 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-bg-light_primary tw-border-solid tw-border-8 tw-border-light_primary">
                <h5 className="tw-m-0 tw-text-white">
                  {dataPoint.data?.totalPoint}
                </h5>
              </div>
              <p className="tw-font-light">คะแนนเต็ม</p>
            </div>
            <div className="tw-mx-10">
              <div className="tw-w-40 tw-h-40 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-bg-light_primary tw-border-solid tw-border-8 tw-border-primary">
                <h5 className="tw-m-0 tw-text-white">
                  {dataPoint.data?.prePoint}
                </h5>
              </div>
              <p className="tw-font-light">คะแนนก่อนเรียนที่ทำได้</p>
            </div>
            <div className="tw-mx-10">
              <div className="tw-w-40 tw-h-40 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-bg-light_primary tw-border-solid tw-border-8 tw-border-dark_primary">
                <h5 className="tw-m-0 tw-text-white">
                  {dataPoint.data?.postPoint}
                </h5>
              </div>
              <p className="tw-font-light">คะแนนหลังเรียนที่ทำได้</p>
            </div>
          </div>
        </> :
          <p className="">ไม่พบข้อมูล</p>
      }
    </div>
  );

  const tabCourse = [
    {
      key: "desc",
      label: `รายละเอียดคอร์สเรียน`,
      children: (
        <CourseInfo
          dataCourse={couseDetail}
          currentUnit={currentUnit}
          courseType={courseType}
          activeGroupData={activeGroupData}
        />
      ),
    },
    {
      key: "course-info",
      label:
        couseDetail?.type === "online"
          ? "คะแนนของฉัน"
          : couseDetail?.type === "zoom" && couseDetail?.isGroup
            ? "ตารางเรียน"
            : "เวลาเรียน",
      children:
        couseDetail?.type === "online" ? (
          <CourseScore />
        ) : (
          <>
            {couseDetail?.type === "zoom" && couseDetail?.isGroup ? (
              <Calendar
                placeholder="ตารางเรียน"
                listData={activeGroupData?.schedule}
                //   eventTitleContent={eventTitleContent}
                //   innerPopover={calendarPopover}
                readOnly={true}
              />
            ) : (
              <CourseSchedule
                type={couseDetail?.type}
                courseId={couseDetail?.id}
                dataCourse={couseDetail}
              />
            )}
          </>
        ),
    },
    // {
    //   key: "schedule",
    //   label: `จองเวลาเรียน`,
    //   children: (
    //     <CourseSchedule
    //       type={couseDetail?.type}
    //       courseId={couseDetail?.id}
    //       dataCourse={couseDetail}
    //     />
    //   ),
    // },
    {
      key: "homework",
      label: `การบ้าน`,
      children: <Homework />,
      disabled: !currentUnit?.homework,
    },
    {
      key: "review",
      label: `รีวิวของฉัน`,
      children: <Review currentUnit={currentUnit} />,
      disabled: true
    },
  ];

  useEffect(() => {
    dispatch(INIT());
  }, []);

  useEffect(() => {
    dispatch(SET_COURSE_DETAIL(couseDetail));
    dispatch(SET_UNIT_DETAIL(unitDetail));
    let courseTypeLabel = _.find(
      courseTypeOption,
      (n) => n.value === couseDetail?.type
    )?.label;
    setCourseType(courseTypeLabel);
  }, [couseDetail, unitDetail, couseDetail?.type]);

  useEffect(() => {
    if (currentUnit?.id && page != COURSE_DETAIL_SECTION.VIDEO) {
      refetchQuiz();
      if (isLoadingPostQuiz) {
        setIsLoadingPostQuiz(false);
      }
    }
  }, [currentUnit]);

  useEffect(() => {
    if (
      couseDetail?.type === "online" &&
      !dataQuiz?.isDone &&
      dataQuiz?.quizzes?.length &&
      page === COURSE_DETAIL_SECTION.VIDEO
    ) {
      dispatch(
        SET_COURSE_PAGE(
          isLoadingPostQuiz
            ? COURSE_DETAIL_SECTION.POST_QUIZ
            : COURSE_DETAIL_SECTION.PRE_QUIZ
        )
      );
    } else if (!dataQuiz?.quizzes?.length) {
      dispatch(SET_COURSE_PAGE(COURSE_DETAIL_SECTION.VIDEO));
    }
  }, [dataQuiz]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    checkPaid({ id: courseId });
  }, [courseId]);

  useEffect(() => {
    if (page !== COURSE_DETAIL_SECTION.VIDEO) {
      setQuizType(page);
    }
  }, [page]);

  useEffect(() => {
    if (tab === "course-info" && couseDetail?.type === "online") {
      refetchPoint();
    }
  }, [currentUnit, tab]);

  return (
    <>
      {isLoadingCourse ? (
        <Row style={{ minHeight: "500px" }} className="center">
          <Spin />
        </Row>
      ) : couseDetail ? (
        isLoadingPaidCourse ? (
          <Row style={{ minHeight: "500px" }} className="center">
            <Spin />
          </Row>
        ) : true ? (
          <>
            <Breadcrumb items={breadcrumb_item} />
            <div className="sg-section">
              <div className="section-content course-details bg-white section-padding">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="sa-course">
                        {page === COURSE_DETAIL_SECTION.VIDEO ? (
                          <div
                            style={{
                              maxWidth: "900px",
                              maxHeight: "500px",
                              overflow: "hidden",
                              backgroundColor: "#eeeeee",
                            }}
                            className="course-thumb center"
                          >
                            <Video
                              coverImgUrl={couseDetail?.coverImgUrl}
                              url={currentUnit?.videoUrl}
                              handleVideoEnd={() => {
                                setIsLoadingPostQuiz(true);
                                dispatch(
                                  SET_COURSE_PAGE(
                                    COURSE_DETAIL_SECTION.POST_QUIZ
                                  )
                                );
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              maxWidth: "900px",
                              overflow: "hidden",
                              backgroundColor: "#eeeeee",
                            }}
                            className="course-thumb center"
                          >
                            {page === COURSE_DETAIL_SECTION.PRE_QUIZ ? (
                              <Quiz
                                data={dataQuiz}
                                type={COURSE_DETAIL_SECTION.PRE_QUIZ}
                                isLoadingQuiz={isLoadingQuiz}
                              />
                            ) : (
                              <Quiz
                                data={dataQuiz}
                                type={COURSE_DETAIL_SECTION.POST_QUIZ}
                                refetch={refetch}
                                isLoadingQuiz={isLoadingQuiz}
                              />
                            )}
                          </div>
                        )}
                        <div className="course-info mt-4">
                          <h2 className="title" style={{ marginBottom: "5px" }}>
                            {couseDetail?.title}
                          </h2>
                          <div className="d-flex align-items-center gap-2">
                            <div
                              style={{ width: "30px", height: "30px" }}
                              className="d-flex justify-content-center align-items-center overflow-hidden rounded-circle"
                            >
                              <img
                                className="w-100 h-100 object-fit-cover"
                                src={couseDetail?.teacherImg || NO_IMAGE}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = NO_IMAGE;
                                }}
                                alt="i"
                              />
                            </div>
                            <p className="my-1">{`ครูผู้สอน : ${couseDetail?.teacherName || "-"
                              }`}</p>
                          </div>
                          <Tabs
                            size="large"
                            defaultActiveKey={tab}
                            items={tabCourse}
                            onChange={(key) => setTab(key)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="b-shadow sidebar course-sidebar">
                        <div className="widget-area">
                          <GroupPanel
                            courseId={couseDetail?.id}
                            setActiveGroupData={setActiveGroupData}
                          />
                          <UnitPanel />
                          <CertificationPanel
                            data={courseDetail}
                            disabled={!courseDetail?.isFinish}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          renderNotPaid() // ยังไม่ได้ซื้อคอร์สนี้
        )
      ) : (
        renderNotFound() // ไม่มีคอร์สนี้
      )}
    </>
  );
};
