import { Divider, Input, Modal, Radio, Row, Select, Space, Spin } from "antd";
import { logo_mini_tonemusic } from "assets";
import { NOT_FOUND_IMG, NO_IMAGE } from "assets/images";
import {
  AlertConfirm,
  AlertError,
  AlertLoading,
  AlertWarning,
} from "components/alert/Alert";
import useModalHook from "hooks/useModalHook";
import useProvinceHook from "hooks/useProvinceHook";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { PAYMENT_TYPE } from "pages/back-office/constants/payment";
import { useEffect, useState } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import Script from "react-load-script";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LOAD_ITEM_TO_CART, TOGGLE_TO_LOAD } from "redux/reducers/cartSlice";
import { CLOSE_LOADING, OPEN_LOADING, SET_LOADING } from "redux/reducers/loadingSlice";
import {
  REMOVE_PROMOTION,
  SELECT_PROMOTION,
} from "redux/reducers/promotionSlice";
import courseService from "services/course.services";
import masterService from "services/master.services";
import Swal from "sweetalert2";
import { OMISE_PUBLIC_KEY } from "utils";
import PromotionList from "./PromotionList";
import VoucherModal from "./VoucherModal";

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(
    remainingSeconds
  ).padStart(2, "0")}`;
};

const CartItem = ({
  id,
  courseId,
  coverImgUrl,
  title = "title",
  price = 0,
  refetch,
}) => {
  const dispatch = useDispatch();

  const { mutate: deleteCourseInCart } =
    courseService.useMutationClearCourseInCart(() => {
      dispatch(TOGGLE_TO_LOAD());
      refetch();
    });

  return (
    <tr>
      <th>
        <div className="product">
          <Link to={`/courses/${courseId}`}>
            <span className="product-thumb">
              <div style={{ height: "70px", width: "85px" }}>
                <img
                  style={{
                    objectFit: "cover",
                    height: "100%",
                    width: "100%",
                  }}
                  src={coverImgUrl || NOT_FOUND_IMG}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = NOT_FOUND_IMG;
                  }}
                  alt="course"
                  className="img-fluid"
                />
              </div>
            </span>
            <span>{title || "title name"}</span>
          </Link>
        </div>
      </th>
      <td>
        <div className="product-quantity">
          <div className="quantity tw-text-center" data-trigger="spinner">
            1
          </div>
        </div>
      </td>
      <td>
        <div className=" d-flex justify-content-around align-items-center">
          <span className="subtotal">
            {`${numeral(price).format("0,0")} บาท`}
          </span>
        </div>
      </td>
      <td>
        <a
          style={{ cursor: "pointer" }}
          onClick={() => {
            deleteCourseInCart({ cartId: id });
          }}
        >
          <i className="far fa-trash-alt"></i>
        </a>
      </td>
    </tr>
  );
};

const CartPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseUrl = window.location.origin;
  const QR_TIME_OUT = 15 * 60; // 15 minutes in seconds

  const myCart = useSelector((state) => state.cart);
  const myPromotion = useSelector((state) => state.promotion);
  const [promotionInput, setPromotionInput] = useState("");
  const [paymentTypeValue, setPaymentTypeValue] = useState(PAYMENT_TYPE.online);
  const [deliveryAddress, setDeliveryAddress] = useState(null);

  const [intervalId, setIntervalId] = useState(null);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [countdown, setCountdown] = useState(QR_TIME_OUT);
  const [qrCodePayment, setQrCodePayment] = useState(null);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [qrExpire, setQrExpire] = useState(
    moment().add(QR_TIME_OUT, "minutes")
  );

  const [paymentInfo, setPaymentInfo] = useState({
    cartList: [],
    amount: 0,
    net: 0,
    deduction: 0,
    promotionMappingId: null,
    total: 0,
    vat: 0,
  });

  const { refetch } = courseService.useQueryGetItemInCart({
    onSuccess: (data) => {
      if (!_.isEqual(myCart.items, data)) {
        dispatch(LOAD_ITEM_TO_CART(data));
      }
    },
    onError: (err) => {
      let msg = err?.response?.data?.status?.description;
      if (msg === "Invalid Token") {
        AlertError({
          text: msg,
          onOk: () => {
            navigate("/login");
          },
        });
      }
    },
  });

  const { isLoadingProvince, province, provinceOption, setProvince } =
    useProvinceHook();

  const {
    open: openQrCode,
    handleOpen: _handleOpenQrCode,
    handleClose: _handleCloseQrCode,
  } = useModalHook();

  const {
    open: openVoucher,
    handleOpen: _handleOpenVoucher,
    handleClose: _handleCloseVoucher,
  } = useModalHook();

  const { mutate: searchPromotion } =
    masterService.useMutationGetPromotionByCode((res) => {
      let data = res?.data;
      if (data) {
        dispatch(SELECT_PROMOTION(data));
      } else {
        AlertWarning({
          title: "ไม่พบโค้ดโปรโมชั่นนี้ !",
          text: "กรุณาลองกรอกโค้ดอีกครั้ง",
        });
      }
    });

  const { mutate: clearCourseInCart } =
    courseService.useMutationClearAllCourseInCart(() => {
      dispatch(TOGGLE_TO_LOAD());
    });

  const { mutate: qrPaymentChecking } =
    masterService.useMutationCheckEventPayment((res) => {
      // console.log("[6]-QR Payment Status:", res);
      let status_code = res?.status?.code;
      if (status_code === "200") {
        clearInterval(intervalId);
        setPaymentCompleted(true);
        setQrCodePayment(null);
        setCountdown(QR_TIME_OUT);
        _handleCloseQrCode();
        clearCourseInCart();
      } else {
        setPaymentCompleted(false);
      }
    });

  const handleQrPayment = async (_chargeId) => {
    try {
      // console.log("[5]-Check QR Code Payment Completed?");

      // Set interval to call fetchAPI every 30 seconds
      const id = setInterval(() => {
        // console.log("PAYMENT CHECKING...");
        qrPaymentChecking({ chargeId: _chargeId });
      }, 20000); // 20 sec

      // Set timeout to stop calling fetchAPI after 15 minutes
      setTimeout(() => {
        clearInterval(id);
        setQrCodePayment(null);
        setCountdown(QR_TIME_OUT);
        setPaymentCompleted(false);
        _handleCloseQrCode();
        if (paymentCompleted === false) {
          AlertError({
            text: "QR Code หมดอายุแล้ว กรุณาทำการชำระเงินอีกครั้ง!",
          });
        }
        // console.log("[END]-TIMEOUT QR_CODE");
      }, QR_TIME_OUT * 1000); // 15 minutes in milliseconds

      setIntervalId(id);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const { mutateAsync: createPayment, isLoading: isLoadingCreatePayment } =
    masterService.useMutationCreatePayment(
      () => { },
      (err) => {
        return AlertError({ text: `CREATE PAYMENT : ${err?.message}` });
      }
    );

  const {
    mutate: createChargePayment,
    isLoading: isLoadingCreateChargePayment,
  } = masterService.useMutationCreateChargePayment(
    (res) => {
      // console.log("[3]-Create Charge Payment (res):", res);
      Swal.close();
      const { id, source, expires_at, authorize_uri } = res?.data || {};

      setQrExpire(expires_at);

      let _chargeId = id;
      let payment_type = source?.type; // "promptpay"
      if (payment_type) {
        // payment_type : promptpay
        // console.log("[4.2]-PAY WITH QR Code");
        let qr_code = source?.scannable_code?.image?.download_uri;
        setQrCodePayment(qr_code);
        _handleOpenQrCode();
        handleQrPayment(_chargeId);
      } else {
        // payment_type : credit_card
        clearCourseInCart();
        // console.log("[4.1]-PAY WITH Credit Card");
        window.location.replace(authorize_uri); // OTP Checking
      }
    },
    (err) => {
      AlertError({ text: `CREATE Charge : ${err?.message}` });
    }
  );

  if (isLoadingCreateChargePayment) {
    AlertLoading({});
  }

  // --------------------- OMISE CONFIGURE ---------------------------------
  const omiseLoadScript = () => {
    const OmiseCard = window.OmiseCard;
    OmiseCard.configure({
      publicKey: OMISE_PUBLIC_KEY,
      image: logo_mini_tonemusic,
      currency: "THB",
      frameLabel: "TONES MUSIC",
      submitLabel: "PAY NOW",
    });
  };

  const openOmisePopup = () => {
    const OmiseCard = window.OmiseCard;
    OmiseCard.configure({
      defaultPaymentMethod: "credit_card",
      otherPaymentMethods: ["promptpay"],
    });
    OmiseCard.configureButton("#credit-card");
    OmiseCard.attach();
    OmiseCard.open({
      amount: (paymentInfo?.total || 0) * 100,
      onCreateTokenSuccess: async (token) => {
        const response = await createPayment({
          ...paymentInfo,
          method: PAYMENT_TYPE.online,
        });
        const { orderNo, paymentId } = response?.data;
        let values = {
          amount: paymentInfo?.total,
          currency: "THB",
          paymentId,
          id: token,
          returnUri: `${baseUrl}/purchased/${orderNo}`,
        };
        createChargePayment({ values });
      },
      onFormClosed: () => {
        setDisabledBtn(false);
      },
    });
  };

  // const omiseSubmit = (_paymentId, _orderNo) => {
  //   const OmiseCard = window.OmiseCard;

  // };
  // --------------------- END OMISE CONFIGURE ---------------------------------

  const processPayment = () => {
    setDisabledBtn(true);
    if (paymentTypeValue === PAYMENT_TYPE.online) {
      openOmisePopup();
    } else {
      if (deliveryAddress?.address && deliveryAddress?.province && deliveryAddress?.zipCode) {
        AlertConfirm({
          title: "ยืนยันคำสั่งซื้อ",
          text: "ต้องการยืนยันรายการคำสั่งซื้อหรือไม่ ?",
          onOk: async () => {
            await createPayment({
              ...paymentInfo,
              method: PAYMENT_TYPE.cod,
              address: `${deliveryAddress.address} ${deliveryAddress.province} ${deliveryAddress.zipCode}`,
            });
            dispatch(CLOSE_LOADING());
            clearCourseInCart();
            navigate(`/codPaymentFinish`);
          },
        });
      } else {
        AlertError({
          text: "กรุณากรอกที่อยู่การจัดส่งให้ครบ"
        })
      }
    }
  };

  useEffect(() => {
    dispatch(SET_LOADING(isLoadingCreatePayment || isLoadingCreateChargePayment));
  }, [isLoadingCreatePayment, isLoadingCreateChargePayment]);

  useEffect(() => {
    // Cleanup the interval when the component unmounts
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  useEffect(() => {
    if (qrCodePayment !== null) {
      const timerInterval = setInterval(() => {
        const now = moment();
        const expirationTime = moment(qrExpire);
        const duration = moment.duration(expirationTime.diff(now));

        // Format the remaining time
        const toSeconds = duration.minutes() * 60 + duration.seconds();
        setCountdown(toSeconds);

        // Check if the expiration time has passed
        if (now.isAfter(expirationTime)) {
          clearInterval(timerInterval);
        }
      }, 1000);
    }
  }, [qrCodePayment]);

  const calcDiscount = () => {
    let total_price = _.sumBy(myCart?.items, (n) => n.price);
    let percent_discount =
      myPromotion?.select_promotion?.deductionPercentage || 0;
    let max_discount = myPromotion?.select_promotion?.limitAmount || 0;

    let total_discount = (total_price * percent_discount) / 100;

    if (percent_discount && max_discount) {
      return total_discount > max_discount ? max_discount : total_discount;
    } else if (percent_discount) {
      return total_discount;
    } else {
      return max_discount;
    }
  };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  useEffect(() => {
    const amount = _.sumBy(myCart?.items, (n) => n.price);
    const deduction = calcDiscount();
    const net = amount - deduction;
    const vat = net * 0;
    const total = net + vat;
    setPaymentInfo({
      amount,
      cartList: myCart?.items || [],
      net,
      deduction,
      promotionMappingId: myPromotion?.select_promotion?.id,
      total,
      vat,
    });
  }, [myCart, paymentTypeValue, myPromotion]);

  return (
    <>
      <Script url="https://cdn.omise.co/omise.js" onLoad={omiseLoadScript} />
      <section className="breadcrumb-section">
        <div className="container">
          <h1>ตะกร้าสินค้าของฉัน</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">หน้าแรก</Link>
            </li>
            <li className="breadcrumb-item">ตะกร้าสินค้า</li>
          </ol>
        </div>
      </section>

      <PromotionList />

      <div
        style={{ minHeight: "600px", paddingTop: "40px" }}
        className="shopping-cart section-padding"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <form className="woocommerce-cart-form" action="#">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">คอร์สเรียน </th>
                      <th scope="col" className="tw-text-center">
                        จำนวน
                      </th>
                      <th scope="col" className="tw-text-center">
                        ราคา
                      </th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {myCart?.loading ? (
                      <div className="w-100 p-4 center">
                        <Spin />
                      </div>
                    ) : (
                      myCart?.items.map((item, i) => (
                        <CartItem
                          key={`cart-item-${i}`}
                          id={item?.id}
                          courseId={item?.courseId}
                          type={item?.type}
                          coverImgUrl={item?.coverImgUrl}
                          title={item?.title}
                          price={item?.price}
                          refetch={refetch}
                        />
                      ))
                    )}
                  </tbody>
                </table>
                {_.size(myCart?.items) < 1 && (
                  <div
                    style={{ height: "120px" }}
                    className="bg-body w-100 center"
                  >
                    <p style={{ opacity: "0.4" }}>- ยังไม่มีรายการ - </p>
                  </div>
                )}
                <div className="buttons d-flex justify-content-end">
                  <Link to="/courses/all/online" className="btn btn-primary">
                    เลือกคอร์สเรียนเพิ่ม
                  </Link>
                </div>
              </form>
            </div>

            <div className="col-lg-4">
              {qrCodePayment && (
                <div className="order-summary mb-3">
                  <ul className="global-list">
                    <li className="d-flex justify-content-between align-items-center">
                      รอการชำระ {formatTime(countdown)} น.
                      <a
                        style={{ width: "150px" }}
                        className="py-2 btn btn-primary"
                        onClick={_handleOpenQrCode}
                      >
                        ชำระเงิน
                      </a>
                    </li>
                  </ul>
                </div>
              )}
              <div className="order-summary mb-3">
                <div
                  style={{
                    borderBottom: "1px solid #e1e1e1",
                    borderTop: "none",
                    marginTop: "0px",
                    paddingTop: "0px",
                    marginBottom: "25px",
                    paddingBottom: "20px",
                  }}
                  className="order-total"
                >
                  <p style={{ color: "#5a5a5a", fontSize: "24px" }}>
                    ส่วนลด :{" "}
                    <span>
                      <u
                        style={{
                          fontSize: "12pt",
                          fontWeight: "lighter",
                          cursor: "pointer",
                        }}
                        onClick={_handleOpenVoucher}
                      >
                        ส่วนลดของฉัน
                      </u>
                    </span>
                  </p>
                </div>
                <ul className="global-list">
                  <li className="mb-4">
                    <Input
                      placeholder="กรอกส่วนลด"
                      onChange={(e) => {
                        let text = e.target.value;
                        setPromotionInput(text.trim());
                      }}
                    />
                    <span>
                      <button
                        style={{ height: "40px", padding: "0px 32px" }}
                        type="button"
                        className="btn btn-primary center"
                        onClick={() => {
                          searchPromotion({ code: promotionInput });
                        }}
                      >
                        ค้นหา
                      </button>
                    </span>
                  </li>
                  {myPromotion?.select_promotion?.id && (
                    <div className="d-flex justify-content-between gap-2">
                      <li className="d-flex align-items-center">
                        <RiDeleteBinLine
                          className="me-2 "
                          color="#dc3545"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            dispatch(
                              REMOVE_PROMOTION(myPromotion?.select_promotion)
                            );
                          }}
                        />
                        <p>{myPromotion?.select_promotion?.code}</p>
                      </li>
                      <div className="text-end">
                        {myPromotion?.select_promotion?.deductionPercentage &&
                          myPromotion?.select_promotion?.limitAmount ? (
                          <>
                            <p>{`- ${myPromotion?.select_promotion?.deductionPercentage} % `}</p>
                            <p>{`สูงสุด ${myPromotion?.select_promotion?.limitAmount}฿`}</p>
                          </>
                        ) : myPromotion?.select_promotion
                          ?.deductionPercentage ? (
                          <p>{`- ${myPromotion?.select_promotion?.deductionPercentage} % `}</p>
                        ) : (
                          <p>{`- ${myPromotion?.select_promotion?.limitAmount}฿`}</p>
                        )}
                      </div>
                    </div>
                  )}
                </ul>
              </div>
              <div className="order-summary mb-3">
                <div
                  style={{
                    borderBottom: "1px solid #e1e1e1",
                    borderTop: "none",
                    marginTop: "0px",
                    paddingTop: "0px",
                    marginBottom: "25px",
                    paddingBottom: "20px",
                  }}
                  className="order-total"
                >
                  <p style={{ color: "#5a5a5a", fontSize: "24px" }}>
                    ช่องทางการชำระเงิน
                  </p>
                </div>
                <ul className="global-list">
                  <Radio.Group
                    value={paymentTypeValue}
                    onChange={(e) => {
                      const value = e.target.value;
                      setPaymentTypeValue(value);
                      if (value === PAYMENT_TYPE.cod) {
                        setDeliveryAddress({
                          ...deliveryAddress,
                          paymentType: PAYMENT_TYPE.cod,
                        });
                      } else {
                        setDeliveryAddress(null);
                      }
                    }}
                  >
                    <Space direction="vertical">
                      <Radio value={PAYMENT_TYPE.online}>ชำระเงินออนไลน์</Radio>
                      <Radio value={PAYMENT_TYPE.cod}>ชำระเงินปลายทาง</Radio>
                    </Space>
                  </Radio.Group>
                </ul>
                {paymentTypeValue === PAYMENT_TYPE.cod && (
                  <div>
                    <Divider />
                    <p className="mb-2">ที่อยู่จัดส่ง</p>
                    <Input
                      className="mb-2"
                      size="large"
                      placeholder="ระบุที่อยู่..."
                      onChange={(e) => {
                        const value = e.target.value;
                        setDeliveryAddress({
                          ...deliveryAddress,
                          address: value,
                        });
                      }}
                    />
                    <Select
                      className="w-100 mb-2"
                      size="large"
                      placeholder="จังหวัด"
                      disabled={isLoadingProvince}
                      value={province}
                      options={provinceOption}
                      onChange={(e) => {
                        setProvince(e);
                        setDeliveryAddress({
                          ...deliveryAddress,
                          province: e,
                        });
                      }}
                    />
                    <Input
                      className="mb-2"
                      size="large"
                      maxLength={5}
                      value={deliveryAddress?.zipCode}
                      placeholder="รหัสไปรษณีย์"
                      onChange={(e) => {
                        const value = e.target.value;
                        const clearText = value.replace(/\D/g, "");
                        setDeliveryAddress({
                          ...deliveryAddress,
                          zipCode: clearText,
                        });
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="order-summary">
                <h2>สรุปการสั่งซื้อ</h2>
                {myCart?.loading ? (
                  <div className="center  p-4">
                    <Spin />
                  </div>
                ) : (
                  <>
                    <ul className="global-list">
                      <li>
                        ราคา{" "}
                        <span>{numeral(paymentInfo.amount).format("0,0")}</span>
                      </li>
                      <li>
                        ส่วนลดทั้งหมด{" "}
                        <span>
                          {numeral(paymentInfo.deduction).format("-0,0")}
                        </span>
                      </li>
                      <li>
                        ราคาหลังหักส่วนลด{" "}
                        <span>{numeral(paymentInfo.net).format("0,0")}</span>
                      </li>
                      {/* <li>
                        ภาษี (7%)
                        <span>{numeral(
                          paymentInfo.vat
                        ).format("-0,0")}</span>
                      </li> */}
                    </ul>
                    <div className="order-total">
                      <p>
                        ยอดรวมทั้งหมด :{" "}
                        <span>{numeral(paymentInfo.total).format("0,0")}</span>
                      </p>
                    </div>
                  </>
                )}

                {paymentTypeValue === PAYMENT_TYPE.online &&
                  qrCodePayment === null ? (
                  <form>
                    <button
                      id="credit-card"
                      className="btn btn-primary"
                      onClick={processPayment}
                      disabled={disabledBtn}
                    >
                      {paymentTypeValue === PAYMENT_TYPE.online
                        ? "ชำระเงิน"
                        : "สั่งซื้อสินค้า"}
                    </button>
                  </form>
                ) : paymentTypeValue === PAYMENT_TYPE.cod ? (
                  <button className="btn btn-primary" onClick={processPayment}>
                    สั่งซื้อสินค้า
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title=""
        open={openQrCode}
        footer={null}
        onCancel={_handleCloseQrCode}
      >
        <Row className="center my-4">
          <h4>กรุณาชำระเงินภายใน {formatTime(countdown)} นาที</h4>
        </Row>
        <Row className="center mb-4">
          <img
            width={300}
            height={426}
            src={qrCodePayment || NO_IMAGE}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = NO_IMAGE;
            }}
            alt="QR CODE"
          />
        </Row>
      </Modal>
      <VoucherModal open={openVoucher} onCancel={_handleCloseVoucher} />
    </>
  );
};

export default CartPage;
