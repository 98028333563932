import { Checkbox, Form, Input, InputNumber, Modal, Select } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import teacherBackOfficeService from "services/backoffice/teacherBackoffice.services";
import { v4 as uuidv4 } from 'uuid';

const CalendarModal = ({ currentDate, open, onCancel, onOk, onChange }) => {
  const [form] = Form.useForm();
  const [fieldsChange, setFieldsChange] = useState({});
  const [availableDate, setAvailableDate] = useState([])
  const [startTime, setStartTime] = useState([])
  const [finishTime, setFinishTime] = useState([])
  const [currentDateLabel, setCurrentDateLabel] = useState([])

  teacherBackOfficeService.useQueryGetTeacherAvailableTime({
    onSuccess: (data) => {
      setAvailableDate(data)
    },
  });

  const onFinish = async (values) => {
    if (values?.repeat) {
      const data = {}
      for (let i = 0; i < values.repeatCount; i++) {
        const nextDate = moment(currentDate).add(i * (values.repeatGap || 1), 'weeks');
        const formattedDate = nextDate.format("YYYY-MM-DD");
        data[formattedDate] = [{
          refId: uuidv4(),
          startTime: values.startTime,
          finishTime: values.finishTime,
          note: values.note,
        }];
      }
      onChange(data)
    } else {
      const data = {
        [moment(currentDate).format("YYYY-MM-DD")]: [{
          refId: uuidv4(),
          startTime: values.startTime,
          finishTime: values.finishTime,
          note: values.note,
        }]
      }
      onChange(data)
    }
    onOk()
  };

  useEffect(() => {
    if (form.__INTERNAL__.name) {
      form.resetFields();
    }
    setFieldsChange(null);
  }, [open, form]);

  useEffect(() => {
    if (open && currentDate) {
      const currentDay = currentDate?.toLocaleString('en-us', { weekday: 'long' });
      setCurrentDateLabel(currentDate?.toLocaleString('th', { weekday: 'long' }))

      const currentData = availableDate?.filter(item => item.day === currentDay?.toLowerCase());

      let startTimeArray = [];
      let finishTimeArray = [];

      currentData.forEach(item => {
        let startTime = new Date(`2000-01-01T${item.startTime}`);
        let finishTime = new Date(`2000-01-01T${item.endTime}`);

        while (startTime < finishTime) {
          if (startTime < finishTime) {
            startTimeArray.push({ label: startTime.toTimeString().substring(0, 5), value: startTime.toTimeString().substring(0, 5) });
          }
          let nextTime = new Date(startTime.getTime() + 30 * 60000);
          if (nextTime > finishTime) {
            finishTimeArray.push({ label: finishTime.toTimeString().substring(0, 5), value: finishTime.toTimeString().substring(0, 5) });
          } else {
            finishTimeArray.push({ label: nextTime.toTimeString().substring(0, 5), value: nextTime.toTimeString().substring(0, 5) });
          }
          startTime = nextTime;
        }
      });
      
      setStartTime(startTimeArray)
      setFinishTime(finishTimeArray)
    }
  }, [open, currentDate]);

  return (
    <Modal
      title={"รายละเอียดตารางเรียน" + currentDateLabel}
      open={open}
      onOk={() => {
        form.submit();
      }}
      onCancel={onCancel}
      okText="เพิ่ม"
      cancelText="ยกเลิก"
    >
      <Form
        form={form}
        name="calendar-form"
        className="mt-4"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="เวลาเริ่มต้น"
          name="startTime"
          rules={[
            {
              required: true,
              message: "กรุณาระบุเวลาเริ่มต้น!",
            },
          ]}
        >
          <Select
            size="large"
            placeholder="เวลาเริ่มต้น"
            options={startTime}
            onChange={(value) => {
              setFieldsChange({
                ...fieldsChange,
                startTime: value,
                finishTime: null
              });
              form.setFieldsValue({
                finishTime: null,
              });
            }}
          />
        </Form.Item>

        <Form.Item
          label="เวลาสิ้นสุด"
          name="finishTime"
          rules={[
            {
              required: true,
              message: "กรุณาระบุเวลาสิ้นสุด!",
            },
          ]}
        >
          <Select
            size="large"
            placeholder="เวลาสิ้นสุด"
            options={finishTime?.filter(e => new Date(`2000-01-01T${e.value}:00`) > new Date(`2000-01-01T${fieldsChange?.startTime}:00`)) || []}
          />
        </Form.Item>

        <Form.Item
          shouldUpdate
          valuePropName="checked"
          name="repeat"
          onChange={(e) => {
            setFieldsChange({
              ...fieldsChange,
              repeat: e.target.checked,
            });
            if (e.target.checked) {
              form.setFieldsValue({ homeworkAnswerType: "message" });
            }
          }}
        >
          <Checkbox size="large">
            ทำซ้ำ
          </Checkbox>
        </Form.Item>

        {fieldsChange?.repeat && (
          <>
            <Form.Item
              label="จำนวน (ครั้ง)"
              name="repeatCount"
              rules={[
                {
                  required: fieldsChange?.repeat,
                  message: "กรุณาระบุจำนวน!",
                },
              ]}
            >
              <InputNumber
                min={2}
                max={10}
                size="large"
                className="w-100"
                placeholder="จำนวน (ครั้ง)"
              />
            </Form.Item>
            <Form.Item
              label="ทุกๆ (สัปดาห์)"
              name="repeatGap"
            >
              <InputNumber
                size="large"
                className="w-100"
                placeholder="ทุกๆ (สัปดาห์)"
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          label="หมายเหตุ"
          name="note"
        >
          <Input
            size="large"
            placeholder="หมายเหตุ"
          />
        </Form.Item>

      </Form>
    </Modal>
  );
};

export default CalendarModal;
