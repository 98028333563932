import { Spin } from "antd";
import CourseCard from "components/CourseCard";
import FadingSection from "components/fadingSection";
import Section from "components/section";
import Slick from "components/slick";
import { COURSE_SLIDE_CONFIG } from "pages/back-office/constants/course";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom/dist";
import courseService from "services/course.services";
import NotFound from "./NotFound";
import { homeConstants } from "./constants/HomeConstants";
import PaginationSlideCard from "./components/PaginationSlideCard";
import SkeletonCardLoader from "./components/SkeletonCardLoader";

export default function CourseListSection() {
  const limitPage = homeConstants.limitPage;
  const [page, setPage] = useState(1);

  const {
    data: dataCourse,
    isFetching: isLoadingCourse,
    refetch: refetchCourse,
  } = courseService.useQueryGetAllCourse({
    queryObj: {
      paging: {
        limit: limitPage,
        start: page * limitPage - limitPage,
      },
    },
  });

  useEffect(() => {
    refetchCourse()
  }, [page])

  return (
    <FadingSection>
      <Section className="tw-pt-[100px]">
        <div className="section-header justify-content-between">
          <div className="title align-self-center">
            <h1>คอร์สเรียน</h1>
            <span>Courses</span>
          </div>
          {dataCourse?.data?.length ? (
            <div className="view-all align-self-center">
              <Link to="/courses">
                <button className="btn btn-primary">ดูทั้งหมด</button>
              </Link>
            </div>
          ) : null}
        </div>

        <div className="sa-course-content">
          {isLoadingCourse ? (
            <SkeletonCardLoader />
          ) : dataCourse?.data ? (
            <Slick
              className="sa-course-slider"
              config={COURSE_SLIDE_CONFIG(dataCourse.data.length || 0)}
            >
              {dataCourse.data.map((data, index) => {
                return <CourseCard key={`course-card-${index}`} data={data} />;
              })}
            </Slick>
          ) : (
            <p className="tw-text-center tw-text-gray-300 !tw-py-10 tw-font-extrabold tw-text-2xl">ยังไม่มีคอร์สเรียนที่เปิดสอนในขณะนี้</p>
          )}
        </div>
        <PaginationSlideCard
          page={page}
          total={dataCourse?.total}
          limitPage={limitPage}
          setPage={setPage}
        />
      </Section>
    </FadingSection>
  );
}
