import { Spin } from "antd";
import { NOT_FOUND_IMG } from "assets/images";
import ActivityCard from "components/ActivityCard";
import Slick from "components/slick";
import _ from "lodash";
import { COURSE_SLIDE_CONFIG } from "pages/back-office/constants/course";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import teacherServices from "services/teacher.services";

const ActivityList = () => {
  const { id } = useParams();
  const [awardImages, setAwardImages] = useState([]);

  const { isLoading, refetch } =
    teacherServices.useQueryGetTeacherAwardByTeacherId({
      id,
      onSuccess: (res) => {
        const files = _.chain(res)
          .flatMap("teacherAwardFiles")
          .map("fileUrl")
          .map((fileUrl) => ({ fileUrl }))
          .value();
        setAwardImages(files);
      },
    });

  useEffect(() => {
    refetch();
  }, [id]);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <div className="section-header mt-2 mb-4">
          <div className="title">
            <h1>ภาพกิจกรรม / ใบรับรอง {`( ${_.size(awardImages)} )`}</h1>
            <span>Activities</span>
          </div>
        </div>
      </div>
      <div className="sa-course-content">
        <Slick
          className="sa-course-slider"
          config={COURSE_SLIDE_CONFIG(awardImages?.length || 0)}
        >
          {isLoading ? (
            <Spin />
          ) : (
            awardImages?.map((data, index) => {
              return <ActivityCard key={index} thumbUrl={data?.fileUrl} />;
            })
          )}
        </Slick>
      </div>
    </>
  );
};

export default ActivityList;
