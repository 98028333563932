import { Button, Modal, Row, Table } from "antd";
import { localeCustomTH } from "constants/thaiLocale/localeCustomTH";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REMOVE_PROMOTION, SELECT_PROMOTION } from "redux/reducers/promotionSlice";
import masterService from "services/master.services";

const VoucherModal = ({ open, onCancel }) => {
  const dispatch = useDispatch();

  const myPromotion = useSelector((state) => state.promotion.select_promotion);

  const { data: getPromotion, isFetching: isLoadingPromotion, refetch: refetchMyPromotion } =
    masterService.useQueryGetMyAvaliablePromotion({});

  useEffect(() => {
    if (open) {
      refetchMyPromotion()
    }
  }, [open])
  const headerVoucher = [
    {
      title: "โค้ดส่วนลด",
      dataIndex: "code",
    },
    {
      title: "เปอร์เซ็นต์ส่วนลด (%)",
      dataIndex: "deductionPercentage",
    },
    {
      title: "ส่วนลดสูงสุด (บาท)",
      dataIndex: "limitAmount",
    },
    {
      title: "วันหมดอายุ",
      dataIndex: "expiryDate",
      render: (text) => <>{moment(text).format("DD/MM/YYYY")}</>,
    },
    {
      title: "เลือกใช้",
      dataIndex: "id",
      render: (id, record) => {
        let used = myPromotion?.id;
        return (
          <>
            {id == used ? (
              <Button
                danger
                onClick={() => {
                  dispatch(REMOVE_PROMOTION(record));
                }}
              >
                นำออก
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  dispatch(SELECT_PROMOTION(record));
                }}
              >
                เลือกใช้
              </Button>
            )}
          </>
        );
      },
    },
  ];
  return (
    <Modal
      title={"ส่วนลดของฉัน"}
      width={800}
      open={open}
      onCancel={onCancel}
      okText="บันทึก"
      cancelText="ยกเลิก"
      footer={null}
    >
      <Row className="my-4">
        <Table
          locale={localeCustomTH.Table}
          className="w-100 text-nowrap"
          bordered
          scroll={{ x: true }}
          isLoading={isLoadingPromotion}
          dataSource={getPromotion?.data || []}
          columns={headerVoucher}
        />
      </Row>
    </Modal>
  );
};

export default VoucherModal;
