import adminMasterService from "services/adminMaster.services";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Switch,
} from "antd";
import { AlertLoading } from "components/alert/Alert";
import { AlertError } from "components/alert/Alert";
import { AlertConfirm } from "components/alert/Alert";
import { AlertSuccess } from "components/alert/Alert";
import { thaiLocale } from "constants/thaiLocale/thaiLocale";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { getUserId } from "utils";

const PromotionFormModal = ({ data, open, onCancel, refetch }) => {
  const [form] = Form.useForm();
  const [generatedCode, setGeneratedCode] = useState(null);

  const generateCode = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";

    for (let i = 0; i < 10; i++) {
      const randomChar = characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
      code += randomChar;
    }
    return code;
  };

  const handleGenerateCode = () => {
    const code = generateCode();
    setGeneratedCode(code);
    form.setFieldsValue({ code });
  };

  const { mutate: createPromotion, isLoading: isLoadingCreate } =
    adminMasterService.useMutationCreatePromotion(
      (res) => {
        Swal.close();
        onCancel();
        AlertSuccess({});
        refetch();
      },
      (err) => {
        Swal.close();
        onCancel();
        AlertError({ text: `Create Promotion : ${err?.message}` });
      }
    );

  const { mutate: updatePromotion, isLoading: isLoadingUpdate } =
    adminMasterService.useMutationUpdatePromotion(
      (res) => {
        Swal.close();
        onCancel();
        AlertSuccess({});
        refetch();
      },
      (err) => {
        Swal.close();
        onCancel();
        AlertError({ text: `Update Promotion : ${err?.message}` });
      }
    );

  const { mutate: deletePromotion, isLoading: isLoadingDelete } =
    adminMasterService.useMutationDeletePromotion(
      (res) => {
        Swal.close();
        onCancel();
        AlertSuccess({});
        refetch();
      },
      (err) => {
        Swal.close();
        onCancel();
        AlertError({ text: `Delete Promotion : ${err?.message}` });
      }
    );

  const onFinish = async (values) => {
    let _promotion = { ...data, ...values };

    if (data?.id) {
      let _update = {
        ..._promotion,
        startDate: dayjs(_promotion.startDate).format("YYYY-MM-DD"),
        expiryDate: dayjs(_promotion.expiryDate).format("YYYY-MM-DD"),
      };
      updatePromotion({ values: _update });
    } else {
      let _create = {
        ..._promotion,
        userId: getUserId(),
        startDate: dayjs(_promotion.startDate).format("YYYY-MM-DD"),
        expiryDate: dayjs(_promotion.expiryDate).format("YYYY-MM-DD"),
      };
      createPromotion({ values: _create });
    }
  };

  if (isLoadingCreate || isLoadingUpdate || isLoadingDelete) {
    AlertLoading({ text: "กำลังบันทึก..." });
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    } else {
      if (form.__INTERNAL__.name) {
        form.resetFields();
      }
    }
    if (!open) {
      setGeneratedCode("");
    }
  }, [data, form, open]);

  return (
    <Modal
      title={data ? "แก้ไขโปรโมชั่น" : "เพิ่มโปรโมชั่น"}
      open={open}
      onOk={() => {
        form.submit();
      }}
      onCancel={onCancel}
      okText="บันทึก"
      cancelText="ยกเลิก"
    >
      <Form
        form={form}
        name="awards"
        className="mt-4"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="โค้ดโปรโมชั่น"
          name="code"
          rules={[
            {
              required: true,
              message: "กรุณาสร้างโค้ดโปรโมชั่น!",
            },
          ]}
        >
          <Input.Group compact>
            <Input
              size="large"
              placeholder="กดสร้างโค้ดโปรโมชั่น"
              style={{ width: "80%" }}
              value={data ? data.code : generatedCode}
              disabled={true}
            />
            <Button
              type="primary"
              size="large"
              style={{ width: "20%" }}
              onClick={handleGenerateCode}
              disabled={!!data}
            >
              สร้างโค้ด
            </Button>
          </Input.Group>
        </Form.Item>
        <Form.Item
          label="ส่วนลด %"
          name="deductionPercentage"
        >
          <InputNumber
            className="w-100"
            size="large"
            placeholder="กำหนดส่วนลด %"
          />
        </Form.Item>
        <Form.Item label="ส่วนลดสูงสุด (บาท)" name="limitAmount">
          <InputNumber
            className="w-100"
            size="large"
            placeholder="ส่วนลดสูงสุด (บาท)"
          />
        </Form.Item>
        <Form.Item label="คะแนนที่ใช้แลก" name="redeemPoint">
          <InputNumber
            className="w-100"
            size="large"
            placeholder="ส่วนลดสูงสุด (บาท)"
          />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="วันที่เริ่มใช้โค้ดโปรโมชั่น"
              name="startDate"
              rules={[
                {
                  required: true,
                  message: "กรุณาระบุวันที่เริ่มใช้โค้ดโปรโมชั่น",
                },
              ]}
            >
              <DatePicker
                locale={thaiLocale}
                placeholder="กรุณาระบุวันที่เริ่มใช้โค้ดโปรโมชั่น"
                size="large"
                className="w-100"
                disabledDate={(currentDate) => {
                  const expiryDateFieldValue = form.getFieldValue("expiryDate");
                  if (expiryDateFieldValue) {
                    return (
                      currentDate && currentDate.isAfter(expiryDateFieldValue)
                    );
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="วันหมดอายุโค้ดโปรโมชั่น"
              name="expiryDate"
              rules={[
                {
                  required: true,
                  message: "กรุณาระบุวันที่หมดอายุ",
                },
              ]}
            >
              <DatePicker
                locale={thaiLocale}
                placeholder="กรุณาระบุวันที่หมดอายุ"
                size="large"
                className="w-100"
                disabledDate={(currentDate) => {
                  const startDateFieldValue = form.getFieldValue("startDate");
                  return (
                    currentDate && currentDate.isBefore(startDateFieldValue)
                  );
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item label="สถานะการใช้งาน" name="active">
          <Switch defaultChecked />
        </Form.Item>
        <>
          {data ? (
            <Button
              danger
              block
              className="mb-4"
              onClick={() => {
                AlertConfirm({
                  text: "คุณต้องการลบรายการนี้หรือไม่ ?",
                  onOk: () => {
                    deletePromotion({ promotionId: data?.id });
                  },
                });
              }}
            >
              ลบ
            </Button>
          ) : null}
        </>
      </Form>
    </Modal>
  );
};

export default PromotionFormModal;
