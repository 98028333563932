import { Card, Col, Modal, Row, Tabs, Empty, Tag } from "antd";
import { NOT_FOUND_IMG } from "assets/images";
import { NO_IMAGE } from "assets/images/index";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";
import moment from "moment";
import { useEffect, useState } from "react";
import workshopBackofficeServices from "services/backoffice/workshopBackoffice.services";

const KEY_TABS = {
  workshop: {
    key: "workshop",
    label: "เวิร์กชอป",
  },
  sessionGroup: {
    key: "sessionGroup",
    label: "รอบกิจกรรม",
  },
};

const WorkshopDetailsModal = ({ id, open, onCancel }) => {
  const [tab, setTab] = useState("workshop");

  const { data } =
    workshopBackofficeServices.useQueryGetWorkshopById({
      id,
    });

  const { data: dataSessionGroup } =
    workshopBackofficeServices.useQueryGetAllSessionGroupByWorkshopId({
      workshopId: id,
    });

  useEffect(() => {
    setTab("workshop")
  }, [open])

  const renderDetail = () => <>
    <p>หมวดหมู่ : {data?.category}</p>
    <p>รายละเอียด : {data?.description}</p>

    {data?.courseObjective?.length > 0 && (
      <div className="requirements">
        <p className="tw-font-bold tw-my-2">วัตถุประสงค์</p>
        <ul className="global-list">
          {data?.courseObjective?.map((n, i) => (
            <li key={i}>{n?.description}</li>
          ))}
        </ul>
      </div>
    )}

    {data?.courseEarn?.length > 0 && (
      <div className="you-learn">
        <p className="tw-font-bold tw-my-2">สิ่งที่คุณจะได้รับในเวิร์กชอปนี้</p>
        <ul className="global-list">
          {data?.courseEarn?.map((n, i) => (
            <li key={i}>{n?.description}</li>
          ))}
        </ul>
      </div>
    )}

    <p>{data?.description}</p>
  </>

  const headerTabs = [
    {
      key: KEY_TABS.workshop.key,
      label: KEY_TABS.workshop.label,
      children: renderDetail(),
    },
    {
      key: KEY_TABS.sessionGroup.key,
      label: KEY_TABS.sessionGroup.label,
      children: (
        <Row>
          <Col span={24}>
            {!!dataSessionGroup?.data ? (
              [...(dataSessionGroup?.data || [])].map((entry, index) => {
                return (
                  <Card key={`sessionGroupCard-${index}`} className="mb-2">
                    <div>
                      {`รอบ ${moment(entry.startTime).format("DD/MM/yyyy HH:MM")} - ${moment(entry.finishTime).format("DD/MM/yyyy HH:MM")} ที่ ${entry.locationName}`}
                    </div>
                  </Card>
                );
              })
            ) : (
              <Empty className="my-4" description={EMPTY_DESCRIPTION} />
            )}
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <Modal
      title="รายละเอียด"
      width={800}
      open={open}
      onCancel={onCancel}
      footer={false}
    >
      <Row className="my-4">
        <div className="d-flex justify-content-between ">
          <div className="d-flex align-items-center">
            <div
              className="d-flex justify-content-center align-items-center me-4"
              style={{
                height: "80px",
                overflow: "hidden",
              }}
            >
              <img
                className="w-100 h-100 object-fit-cover"
                src={data?.coverImgUrl || NO_IMAGE}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = NO_IMAGE;
                }}
                alt="images-cover"
              />
            </div>
            <div>
              <h5>{`${data?.title}`}</h5>
              <div
                className="tw-flex tw-items-center tw-overflow-hidden tw-max-w-[163px] tw-mb-2"
              >
                <div
                  style={{
                    marginRight: "8px",
                    width: "32px",
                    height: "32px",
                    borderRadius: "100px",
                    backgroundColor: "grey",
                    overflow: "hidden",
                  }}
                  className="center"
                >
                  <img
                    src={data?.teacherImg || NOT_FOUND_IMG}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = NOT_FOUND_IMG;
                    }}
                    alt="profile"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
                {data?.teacherName || "Teacher name"}
              </div>
              <Tag color="gold">เวิร์กชอป</Tag>
            </div>
          </div>
        </div>
      </Row>
      <Tabs
        activeKey={tab}
        onChange={(key) => setTab(key)}
        items={headerTabs} />
    </Modal>
  );
};

export default WorkshopDetailsModal;
