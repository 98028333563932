import { Empty } from "antd";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";

export const localeCustomTH = {
  global: {
    placeholder: "กรุณาเลือก",
  },
  Table: {
    filterTitle: "ตัวกรอง",
    filterConfirm: "ยืนยัน",
    filterReset: "รีเซ็ต",
    filterEmptyText: "ไม่มีตัวกรอง",
    emptyText: <Empty description={EMPTY_DESCRIPTION} />,
    selectAll: "เลือกทั้งหมดในหน้านี้",
    selectInvert: "กลับสถานะการเลือกในหน้านี้",
    selectionAll: "เลือกข้อมูลทั้งหมด",
    sortTitle: "เรียง",
    expand: "แสดงแถวข้อมูล",
    collapse: "ย่อแถวข้อมูล",
    triggerDesc: "คลิกเรียงจากมากไปน้อย",
    triggerAsc: "คลิกเรียงจากน้อยไปมาก",
    cancelSort: "คลิกเพื่อยกเลิกการเรียง",
  },
  Modal: {
    okText: "ตกลง",
    cancelText: "ยกเลิก",
    justOkText: "ตกลง",
  },
  Popconfirm: {
    okText: "ตกลง",
    cancelText: "ยกเลิก",
  },
  Transfer: {
    titles: ["", ""],
    searchPlaceholder: "ค้นหา",
    itemUnit: "ชิ้น",
    itemsUnit: "ชิ้น",
    remove: "นำออก",
    selectCurrent: "เลือกทั้งหมดในหน้านี้",
    removeCurrent: "นำออกทั้งหมดในหน้านี้",
    selectAll: "เลือกข้อมูลทั้งหมด",
    removeAll: "นำข้อมูลออกทั้งหมด",
    selectInvert: "กลับสถานะการเลือกในหน้านี้",
  },
  Upload: {
    uploading: "กำลังอัปโหลด...",
    removeFile: "ลบไฟล์",
    uploadError: "เกิดข้อผิดพลาดในการอัปโหลด",
    previewFile: "ดูตัวอย่างไฟล์",
    downloadFile: "ดาวน์โหลดไฟล์",
  },
  Empty: {
    description: EMPTY_DESCRIPTION,
  },
};

// :: Document ::
// https://github.com/ant-design/ant-design/blob/6dae4a7e18ad1ba193aedd5ab6867e1d823e2aa4/components/locale/th_TH.tsx
