import { Button, Card, Col, Modal, Row, Spin } from "antd";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import { COLOR } from "constants/color";
import { useEffect } from "react";
import { FaRegCircleCheck } from "react-icons/fa6";
import { MdAccessTime } from "react-icons/md";
import userBackOfficeServices from "services/backoffice/userBackoffice.services";
const PromotionListFormModal = ({ open, onCancel }) => {

  const {
    data: dataPromotion,
    isFetching: isLoadingPromotion,
    refetch: refetchPromotion,
  } = userBackOfficeServices.useQueryGetAllPromotionActive({});

  const { Meta } = Card;

  const { mutate: redeemPoint } =
    userBackOfficeServices.useMutationRedeemPointByUser(
      (res) => {
        const { code, description } = res?.status || {};

        if (code === "200") {
          AlertSuccess({});
          refetchPromotion();
        } else {
          AlertError({ text: description });
        }
      },
      (err) => {
        AlertError({ text: `แลกคะแนนไม่สำเร็จ : ${err?.message}` });
      }
    );

  const onSave = (values) => {
    AlertConfirm({
      onOk: () => {
        redeemPoint({ values });
      },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 440);
  }, []);

  return (
    <Modal
      title="รายการโปรโมชชั่นโค้ด"
      open={open}
      onCancel={onCancel}
      width={500}
      footer={null}
    >
      {isLoadingPromotion ? (
        <Spin className="m-2" />
      ) : (
        <Row className="my-4 mx-2">
          <Col span={24}>
            {dataPromotion?.map((n, i) => (
              <Card key={i} hoverable className="mb-3">
                <Row gutter={[16, 16]}>
                  <Col span={16}>
                    <Meta
                      title={n?.code}
                      description={`ส่วนลด ${n?.deductionPercentage} % ลดสูงสุด ${n?.limitAmount} บาท`}
                    />
                    <Row className="mt-3 flex align-items-center">
                      <MdAccessTime className="me-2" />
                      <p>ใช้ได้ถึงวันที่ {n?.expiryDate}</p>
                    </Row>
                  </Col>
                  <Col span={8}>
                    {n?.codeCollected === 1 ? (
                      <Row
                        className="h-100 flex justify-content-end align-items-center"
                        style={{ fontSize: "18px" }}
                      >
                        <FaRegCircleCheck color={`${COLOR.green_primary}`} />
                        <span
                          className="ms-2"
                          style={{ color: `${COLOR.green_primary}` }}
                        >
                          เก็บแล้ว
                        </span>
                      </Row>
                    ) : (
                      <Row className="h-100">
                        <Col
                          span={24}
                          className="h-100"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "end",
                          }}
                        >
                          <p
                            className="text-primary"
                            style={{ fontSize: "16px" }}
                          >
                            ใช้ {n?.redeemPoint} คะแนน
                          </p>
                          <Button
                            className="px-4"
                            type="primary"
                            onClick={() => {
                              onSave(n);
                              // ตัวอย่างโค้ดที่คุณต้องการให้ทำเมื่อคลิกปุ่ม
                            }}
                          >
                            แลกโค้ด
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </Card>
            ))}
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default PromotionListFormModal;
