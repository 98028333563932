export const courseType = [
  {
    id: 1,
    type: "online",
    nameTh: "เรียนออนไลน์ผ่านระบบ",
  },
  {
    id: 2,
    type: "zoom",
    nameTh: "เรียนตัวต่อตัวผ่าน Zoom",
  },
  {
    id: 3,
    type: "studio",
    nameTh: "เรียนตัวต่อตัวที่ Studio",
  },
  {
    id: 4,
    type: "at_home",
    nameTh: "เรียนตัวต่อตัวที่บ้านนักเรียน",
  },
  {
    id: 5,
    type: "workshop",
    nameTh: "เวิร์กชอป",
  },
  {
    id: 6,
    type: "trial",
    nameTh: "ทดลองเรียน",
  },
];
