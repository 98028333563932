import { Avatar, Button, Col, Divider, List, Row } from "antd";
import { NO_IMAGE } from "assets/images";
import useModalHook from "hooks/useModalHook";
import moment from "moment";
import { useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import StudentHomeworkModal from "./StudentHomeworkModal";

const TeacherHomeworkDetail = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [student, setStudent] = useState(null);

  const {
    open: openHomeworkStudent,
    handleOpen: _handleOpenHomeworkStudent,
    handleClose: _handleCloseHomeworkStudent,
  } = useModalHook();

  const {
    data: dataHomeworkByCourse,
    isFetching: isLoadingHomework,
    refetch: refetchHomeWork,
  } = courseBackOfficeServices.useQueryGetAllStudentByCourseId({ courseId });

  return (
    <>
      <Row className="mb-4">
        <Button
          type="link"
          className="center"
          onClick={() => {
            navigate("/teacher/homework");
          }}
        >
          <IoIosArrowBack size={22} className="me-2" />
          <p>ย้อนกลับ</p>
        </Button>
      </Row>
      <Row>
        <Col>
          <h4>การบ้านนักเรียน</h4>
          <p>{`${
            dataHomeworkByCourse?.courseName || "ไม่พบชื่อคอร์สเรียน!"
          }`}</p>
        </Col>
      </Row>
      <Divider />
      <List
        className="demo-loadmore-list"
        loading={isLoadingHomework}
        itemLayout="horizontal"
        dataSource={dataHomeworkByCourse?.data || []}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                type="primary"
                onClick={() => {
                  const student = {
                    studentId: item?.id,
                    name: item?.name,
                    courseId: courseId,
                    email: item?.email,
                    thumbUrl: item?.thumbUrl,
                  };
                  setStudent(student);
                  _handleOpenHomeworkStudent();
                }}
              >
                ตรวจการบ้าน
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar src={item?.thumbUrl || NO_IMAGE} />}
              title={<p>{item?.name || "Username"}</p>}
              description={moment(item?.date).format("DD-MMMM-YYYY")}
            />
            {/* <div className="me-3 center">
              <IoMdBook size={20} className="me-2" />
              <b>
                <p>UNIT 2</p>
              </b>
            </div> */}
          </List.Item>
        )}
      />
      <StudentHomeworkModal
        data={student}
        open={openHomeworkStudent}
        onCancel={() => {
          _handleCloseHomeworkStudent();
          setStudent(null);
        }}
      />
    </>
  );
};

export default TeacherHomeworkDetail;
