import adminMasterService from "services/adminMaster.services";
import adminTeacherServices from "services/adminTeacher.services";
import { Button, Image, Row, Table, Tabs } from "antd";
import { NO_IMAGE } from "assets/images";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import { localeCustomTH } from "constants/thaiLocale/localeCustomTH";
import { errorToMessage } from "hooks/functions/errorToMessage";
import moment from "moment";
import { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineUndo } from "react-icons/ai";
import { RxLoop } from "react-icons/rx";

const AdminManageTeacher = () => {
  const [keyTab, setKeyTab] = useState(1);
  const {
    data: dataTeacher,
    isLoading,
    refetch,
  } = adminTeacherServices.useQueryGetRoleTeacher({
    approval: keyTab === 1 ? true : 0,
  });

  const { mutate: approveTeacherId } =
    adminTeacherServices.useMutationApproveTeacher(
      () => {
        AlertSuccess({ text: "อนุมัติสำเร็จ" });
        refetch();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const { mutate: deleteTeacher } = adminMasterService.useMutationDeleteUser(
    () => {
      AlertSuccess({ text: "ลบผู้ใช้ออกจากระบบแล้ว" });
      refetch();
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );

  const renderContent = () => {
    return (
      <Row>
        <Table
          locale={localeCustomTH.Table}
          loading={isLoading}
          scroll={{ x: true }}
          className="w-100 text-nowrap"
          bordered
          dataSource={dataTeacher?.data}
          columns={teacherHeaderTable}
        />
      </Row>
    );
  };

  const teacherHeaderTable = [
    {
      title: "รูปโปรไฟล์",
      dataIndex: "thumbUrl",
      key: "thumbUrl",
      render: (_, { thumbUrl }) => (
        <Image
          style={{ borderRadius: "4px" }}
          width={60}
          height={60}
          src={thumbUrl || NO_IMAGE}
          fallback={NO_IMAGE}
          alt="profile_img"
        />
      ),
    },
    {
      title: "ชื่อเล่น",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "tel",
      key: "tel",
      render: (text) => (
        <>
          <p style={{ width: "100px" }}>{text}</p>
        </>
      ),
    },
    {
      title: "มหาวิทยาลัย",
      dataIndex: "university",
      key: "university",
    },
    {
      title: "วันที่ลงทะเบียน",
      dataIndex: "date",
      key: "date",
      render: (_, { date }) => (
        <>
          <p style={{ width: "100px" }}>{moment(date).format("YYYY-MM-DD")}</p>
        </>
      ),
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      fixed: "right",
      render: (_, { id }) => (
        <>
          {keyTab === 1 ? (
            <div className="center gap-2">
              {/* <Button>
                <AiOutlineEdit />
              </Button> */}
              <Button
                className="center tw-gap-1"
                onClick={() => {
                  approveTeacherId({ id, approveStatus: false });
                }}
              >
                <RxLoop />
                <span>ให้รออนุมัติ</span>
              </Button>
              <Button
                className="center tw-gap-1"
                danger
                onClick={() => {
                  deleteTeacher({ id });
                }}
              >
                <AiOutlineDelete />
                <span className="!tw-hidden lg:!tw-block">ลบ</span>
              </Button>
            </div>
          ) : (
            <div className="center gap-2">
              <Button
                className="w-100"
                type="primary"
                onClick={() => {
                  AlertConfirm({
                    text: "ต้องการอนุมัติครูผู้สอนหรือไม่ ?",
                    onOk: () => {
                      approveTeacherId({ id, approveStatus: true });
                    },
                  });
                }}
              >
                อนุมัติ
              </Button>
              <Button
                className="w-100"
                danger
                onClick={() => {
                  AlertConfirm({
                    text: "ต้องการลบครูผู้สอนหรือไม่ ?",
                    onOk: () => {
                      deleteTeacher({ id });
                    },
                  });
                }}
              >
                ลบ
              </Button>
            </div>
          )}
        </>
      ),
    },
  ];

  const teacherTabs = [
    {
      key: 1,
      label: `ออนไลน์`,
      children: renderContent(),
    },
    {
      key: 2,
      label: `รออนุมัติ`,
      children: renderContent(),
    },
  ];

  useEffect(() => {
    refetch();
  }, [keyTab]);

  return (
    <>
      <Row>
        <Tabs
          className="w-100"
          type="card"
          defaultActiveKey="1"
          items={teacherTabs}
          onChange={(key) => setKeyTab(key)}
        />
      </Row>
    </>
  );
};

export default AdminManageTeacher;
