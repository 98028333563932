import { NO_IMAGE } from "assets/images";
import { ROLE_USER } from "constants/roleUserConstant";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, Outlet } from "react-router-dom";
import { PROFILE_LOADING, SET_PROFILE } from "redux/reducers/profileSlice";
import userBackOfficeServices from "services/backoffice/userBackoffice.services";
import { getRoleUser } from "utils";
import { userTabMenu } from "../constants/userTabMenu";

const UserProfileLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const _path = location.pathname.split("/");
  const currentPath = _path.length - 1;
  const profile = useSelector((state) => state.profile);
  const [menuName, setMenuName] = useState("");
  const [menuDesc, setMenuDesc] = useState("");
  const { data: userProfile, refetch: refetchUserProfile } =
    userBackOfficeServices.useQueryGetUserProfile({
      onSuccess: (res) => {
        if (res) {
          dispatch(SET_PROFILE(res));
          dispatch(PROFILE_LOADING(false));
        } else {
          navigate("/login");
        }
      },
    });

  useEffect(() => {
    const roleUser = getRoleUser(); // using role
    let subPath = _path.filter((_, index) => index > 1).join("/");
    let redirect = roleUser ? `/${roleUser}/${subPath}` : `/login`;
    // for student
    if (roleUser != ROLE_USER.student) {
      navigate(redirect);
    }
    refetchUserProfile();
  }, [currentPath, profile.loading]);

  useEffect(() => {
    let keysIncluded = userTabMenu.some((menu) => _path.includes(menu.key));
    if (!keysIncluded) {
      navigate(`/${ROLE_USER.admin}/${userTabMenu[0]?.key}`);
    } else {
      setMenuName(userTabMenu.find((e) => e.key === _path[2])?.name);
      setMenuDesc(userTabMenu.find((e) => e.key === _path[2])?.desc);
    }
  }, [_path]);

  return (
    <>
      <section className="breadcrumb-section">
        <div className="container">
          <h1>โปรไฟล์</h1>
          <p>ประวัติและข้อมูลส่วนตัว</p>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">หน้าหลัก</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/student/profile">นักเรียน</Link>
            </li>
            <li className="breadcrumb-item">{menuName}</li>
          </ol>
        </div>
      </section>

      <div className="sg-page-content">
        <div className="sg-section">
          <div
            style={{ backgroundColor: "white" }}
            className="section-content profile-content section-padding"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="profile-tabs">
                    <ul
                      // style={{ backgroundColor: "white" }}
                      className="nav nav-tabs"
                      role="tablist"
                    >
                      <li>
                        <div className="user-info">
                          <div className="profile-picture center">
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              src={userProfile?.thumbUrl || NO_IMAGE}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = NO_IMAGE;
                              }}
                              alt="Profile"
                            />
                          </div>
                          <span
                            style={{ fontSize: "20px" }}
                            className="user-name"
                          >
                            {userProfile?.displayName || "username"}
                          </span>
                        </div>
                      </li>

                      {userTabMenu?.map((menu, index) => (
                        <li className="nav-item" key={index}>
                          {menu.disabled ? (
                            <div
                              className={`nav-link tw-font-[600] !tw-text-[#5a5a5a] tw-opacity-50 tw-cursor-not-allowed`}
                            >
                              {" "}
                              {menu.name}
                            </div>
                          ) : (
                            <Link
                              to={menu.path}
                              className={`nav-link ${
                                _.includes(
                                  location.pathname,
                                  `/${ROLE_USER.student}/${menu.key}`
                                ) && "active"
                              }`}
                            >
                              {menu.name}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>

                    <div
                      // style={{ backgroundColor: "white" }}
                      className="tab-content"
                    >
                      <div className="tab-pane active">
                        <div className="profile-section">
                          <div className="tab-header">
                            <h2>{menuName}</h2>
                            <p>{menuDesc}</p>
                          </div>
                          <div
                            style={{
                              borderTop: "1px solid #b2b4b9",
                              minHeight: "600px",
                            }}
                            className="px-3 py-5"
                          >
                            <Outlet />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfileLayout;
