import { Divider, Rate } from "antd";
import moment from "moment";
import React from "react";
import { NO_IMAGE } from "../assets/images";

const ReviewCard = ({ data }) => {
  return (
    <div className="review">
      <div className="user-infos d-flex gap-3">
        <div
          className="user-picture d-flex justify-content-center align-items-center !tw-min-w-0 !tw-w-[50px] !tw-h-[50px] tw-rounded-full tw-overflow-hidden"
        >
          <img
            src={data?.thumbUrl || NO_IMAGE}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = NO_IMAGE;
            }}
            alt="User Profile"
            className="w-100 h-100 object-fit-cover"
          />
        </div>
        <div className="">
          <p className="tw-font-bold !tw-mb-0">{data?.name || "Username"}</p>
          <p className="tw-text-sm tw-mb-0">
            {data?.date
              ? moment(data?.data).format("DD MMM YYYY")
              : moment().format("LLL")}
          </p>
        </div>
      </div>
      <div className="review-content mt-3">
        <Rate disabled defaultValue={data?.rating || 0} />
        <p className="mt-2">{data?.text || "-"}</p>
        <Divider />
      </div>
    </div>
  );
};

export default ReviewCard;
