import adminMasterService from "services/adminMaster.services";
import adminUserServices from "services/adminUser.services";
import { Button, Image, Row, Table, Tabs } from "antd";
import { NO_IMAGE } from "assets/images";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import { localeCustomTH } from "constants/thaiLocale/localeCustomTH";
import { errorToMessage } from "hooks/functions/errorToMessage";
import moment from "moment";
import { useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";

const AdminManageUser = () => {
  const [keyTab, setKeyTab] = useState(1);
  const {
    data: dataStudent,
    isLoading,
    refetch,
  } = adminUserServices.useQueryGetRoleStudent({});
  const { mutate: deleteUserId } = adminMasterService.useMutationDeleteUser(
    () => {
      AlertSuccess({ text: "ลบบัญชีนักเรียนสำเร็จ!" });
      refetch();
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );

  const renderContent = () => {
    return (
      <Row>
        <Table
          locale={localeCustomTH.Table}
          loading={isLoading}
          scroll={{ x: true }}
          className="w-100 text-nowrap"
          bordered
          dataSource={dataStudent?.data}
          columns={teacherHeaderTable}
        />
      </Row>
    );
  };

  const teacherHeaderTable = [
    {
      title: "รูปโปรไฟล์",
      dataIndex: "thumbUrl",
      key: "thumbUrl",
      render: (_, { thumbUrl }) => (
        <Image
          style={{ borderRadius: "4px" }}
          width={60}
          height={60}
          src={thumbUrl || NO_IMAGE}
          fallback={NO_IMAGE}
          alt="profile_img"
        />
      ),
    },
    {
      title: "ชื่อเล่น",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "tel",
      key: "tel",
    },
    {
      title: "สถานะ",
      dataIndex: "active",
      key: "active",
    },
    {
      title: "วันที่ลงทะเบียน",
      dataIndex: "date",
      key: "date",
      render: (_, { date }) => (
        <>
          <p style={{ width: "100px" }}>{moment(date).format("YYYY-MM-DD")}</p>
        </>
      ),
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      fixed: "right",
      render: (_, { id }) => (
        <>
          <div className="center gap-2">
            {/* <Button>
              <AiOutlineEdit />
            </Button> */}
            <Button
              className="center tw-gap-1"
              danger
              onClick={() => {
                AlertConfirm({
                  text: "ต้องการลบบัญชีนักเรียนนี้หรือไม่ ?",
                  onOk: () => {
                    deleteUserId({ id });
                  },
                });
              }}
            >
              <AiOutlineDelete />
              <span className="!tw-hidden lg:!tw-block">ลบ</span>
            </Button>
          </div>
        </>
      ),
    },
  ];

  const teacherTabs = [
    {
      key: 1,
      label: `ออนไลน์`,
      children: renderContent(),
    },
  ];

  return (
    <>
      <Row>
        <Tabs
          className="w-100"
          type="card"
          defaultActiveKey="1"
          items={teacherTabs}
          onChange={(key) => setKeyTab(key)}
        />
      </Row>
    </>
  );
};

export default AdminManageUser;
