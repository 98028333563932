import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  is_authen: false,
  authen_user: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    AUTHEN_LOADING: (state, action) => {
      state.loading = action.payload;
    },
    SET_AUTHEN: (state, action) => {
      state.authen_user = action.payload;
      // state.is_authen = action.payload.status;
    },
    CLEAR_AUTHEN: (state) => {
      state.loading = false;
      state.is_authen = false;
      state.authen_user = [];
    },
  },
});

export const { AUTHEN_LOADING, SET_AUTHEN, CLEAR_AUTHEN } = authSlice.actions;
export default authSlice.reducer;
