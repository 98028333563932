import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Form, Row, Select } from "antd";
import {
  AlertConfirm,
  AlertError,
  AlertSuccess,
  AlertWarning,
} from "components/alert/Alert";
import { errorToMessage } from "hooks/functions/errorToMessage";
import _ from "lodash";
import React, { useState } from "react";
import teacherBackOfficeService from "services/backoffice/teacherBackoffice.services";
import { getUserId } from "utils";

// NumberOfDay start at [Sunday = 0]
const days = [
  { value: "1", key: "monday", label: "วันจันทร์" },
  { value: "2", key: "tuesday", label: "วันอังคาร" },
  { value: "3", key: "wednesday", label: "วันพุธ" },
  { value: "4", key: "thursday", label: "วันพฤหัสบดี" },
  { value: "5", key: "friday", label: "วันศุกร์" },
  { value: "6", key: "saturday", label: "วันเสาร์" },
  { value: "0", key: "sunday", label: "วันอาทิตย์" },
];

const times = [
  { value: "06:00", label: "06:00" },
  { value: "07:00", label: "07:00" },
  { value: "08:00", label: "08:00" },
  { value: "09:00", label: "09:00" },
  { value: "10:00", label: "10:00" },
  { value: "11:00", label: "11:00" },
  { value: "12:00", label: "12:00" },
  { value: "13:00", label: "13:00" },
  { value: "14:00", label: "14:00" },
  { value: "15:00", label: "15:00" },
  { value: "16:00", label: "16:00" },
  { value: "17:00", label: "17:00" },
  { value: "18:00", label: "18:00" },
  { value: "19:00", label: "19:00" },
  { value: "20:00", label: "20:00" },
  { value: "21:00", label: "21:00" },
  { value: "22:00", label: "22:00" },
  { value: "23:00", label: "23:00" },
];

const toTimeFormat = (time) => {
  // format : "08:00"
  let aa = time.split(":")[0]; // [AA:BB]
  let is_time = `${aa}:00`;
  return is_time;
};

const filterTimeOption = (selectedValue) => {
  const selectedIndex = times.findIndex((item) => item.value === selectedValue);
  if (selectedIndex !== -1) {
    const filteredTimes = times.slice(selectedIndex + 1);
    return filteredTimes;
  } else {
    return [];
  }
};

const TeacherAvailableTime = () => {
  const [form] = Form.useForm();
  const [selectedDays, setSelectedDays] = useState({});
  const [timeValues, setTimeValues] = useState({});

  const { data: getAvailableTime, refetch: refetchAvailableTime } =
    teacherBackOfficeService.useQueryGetTeacherAvailableTime({
      onSuccess: (res) => {
        form.resetFields();
        let data = res?.map((n) => ({
          ...n,
          day: _.find(days, (d) => d.key === n?.day)?.value, // convert day text to number
          startTime: toTimeFormat(n?.startTime), // convert time '08:30:00' to '08:00'
          endTime: toTimeFormat(n?.endTime),
        }));

        let key = _.uniq(data?.map((x) => x.day)); // make a uniq day
        let isDay = _.fromPairs(key.map((k) => [k, []])); // set days selected

        // set form format
        let values = {
          days: {
            ..._.fromPairs(key.map((k) => [k, true])), // days selected
            times: _.fromPairs(
              key.map((k) => [k, _.filter(data, (f) => f.day === k)]) // times selected
            ),
          },
        };

        // set default time option when get data from api
        let defaultValues = _.mapValues(values.days.times, (day) => {
          let item = {
            ..._.map(
              day,
              (time, key) =>
                ({
                  [key]: {
                    id: time?.id,
                    startTime: time?.startTime,
                    startTimeOption:
                      key === 0
                        ? filterTimeOption(time?.startTime)
                        : filterTimeOption(day[key - 1]?.endTime),
                    endTime: time?.endTime,
                    endTimeOption: _.intersectionBy(
                      key === 0
                        ? filterTimeOption(time?.startTime)
                        : filterTimeOption(day[key - 1]?.endTime),
                      filterTimeOption(time?.startTime),
                      "value"
                    ),
                  },
                }[key])
            ),
          };
          return item;
        });

        setTimeValues(defaultValues);
        setSelectedDays(isDay);
        form.setFieldsValue(values);
      },
    });

  const { mutate: createUpdateAvailableTime } =
    teacherBackOfficeService.useMutationCreateUpdateTeacherAvailableTime(
      () => {
        AlertSuccess({});
        refetchAvailableTime();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const onFinish = async (values) => {
    const tickDays = _.keys(
      _.pickBy(_.omit(values?.days, "times"), (day) => day) // Key เฉพาะวันที่เลือก ไม่เอา times
    );

    let userId = getUserId();
    let newData = [];
    _.forEach(values.days.times, (timesArray, day) => {
      // set time data เฉพาะวันที่เลือกเท่านั้น
      if (_.includes(tickDays, day)) {
        _.forEach(timesArray, (time) => {
          newData.push({
            id: time.id || null,
            day: day || null,
            startTime: time.startTime || null,
            endTime: time.endTime || null,
            flage: time?.id ? "update" : "insert",
            teacherId: userId,
          });
        });
      }
    });

    // startTime > endTime
    const validate = _.some(
      _.map(newData, (n) => {
        let date = new Date();
        let start = new Date(`${date.toDateString()} ${n.startTime}`);
        let end = new Date(`${date.toDateString()} ${n.endTime}`);
        return start >= end;
      }),
      (value) => value === true
    );

    if (validate) {
      return AlertWarning({
        title: "รูปแบบเวลาเลือกไม่ถูกต้อง!",
        text: "เวลาเริ่มต้นต้องน้อยกว่าเวลาสิ้นสุดเท่านั้น!",
      });
    }

    let is_create = _.filter(newData, (n) => n?.id === null);
    let is_update = _.filter(newData, (n) => n?.id !== null);
    let is_delete = _.map(
      _.differenceBy(getAvailableTime, newData, "id"), // (A,B,[key]) value A not in B
      (n) => ({
        ...n,
        day: _.find(days, (d) => d.key === n?.day)?.value,
        flage: "delete",
      })
    );

    let to_save = [...is_create, ...is_update, ...is_delete];
    let key = _.uniq(to_save?.map((x) => x.day)); // make a uniq day
    let isDay = _.fromPairs(key.map((k) => [k, []])); // set days selected

    AlertConfirm({
      onOk: () => {
        createUpdateAvailableTime({
          values: to_save,
        });
      },
    });
  };

  return (
    <>
      <Form
        form={form}
        name="available-time-form"
        className="mt-4"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.List name="days">
          {() => (
            <>
              {days?.map(({ value, label, ...restField }) => {
                return (
                  <React.Fragment key={value}>
                    <Form.Item
                      {...restField}
                      shouldUpdate
                      valuePropName="checked"
                      name={value}
                    >
                      <Checkbox
                        size="large"
                        onChange={(e) => {
                          let checked = e.target.checked;
                          setSelectedDays((prev) => ({
                            ...prev,
                            [value]: checked ? [] : undefined,
                          }));
                        }}
                      >
                        {label}
                      </Checkbox>
                    </Form.Item>
                    {selectedDays[value] && (
                      <Form.List name={["times", value]}>
                        {(fields, { add, remove }) => (
                          <>
                            {fields.map(({ key, name, ...restField }) => {
                              const currentTimeValue =
                                timeValues[value]?.[name] || null;

                              return (
                                <React.Fragment key={key}>
                                  <Row gutter={[16, 16]}>
                                    <Col xs={12} lg={10}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "startTime"]}
                                        label="เวลาเริ่มต้น"
                                        rules={[
                                          {
                                            required: true,
                                            message: "ระบุเวลาเริ่มต้น!",
                                          },
                                        ]}
                                      >
                                        <Select
                                          size="large"
                                          placeholder="เลือกเวลาเริ่มต้น"
                                          disabled={
                                            currentTimeValue?.startTime
                                              ? name < _.size(fields) - 1
                                              : false
                                          }
                                          options={
                                            name === 0
                                              ? times
                                              : filterTimeOption(
                                                  timeValues[value]?.[name - 1]
                                                    ?.endTime
                                                )
                                          }
                                          onChange={(time) =>
                                            setTimeValues((prevValues) => ({
                                              ...prevValues,
                                              [value]: {
                                                ...timeValues[value],
                                                [name]: {
                                                  ...currentTimeValue,
                                                  startTime: time,
                                                  endTimeOption:
                                                    filterTimeOption(time),
                                                },
                                              },
                                            }))
                                          }
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col xs={12} lg={10}>
                                      <Form.Item
                                        {...restField}
                                        name={[name, "endTime"]}
                                        label="เวลาสิ้นสุด"
                                        rules={[
                                          {
                                            required: true,
                                            message: "ระบุเวลาสิ้นสุด !",
                                          },
                                        ]}
                                      >
                                        <Select
                                          size="large"
                                          placeholder="เลือกเวลาสิ้นสุด"
                                          disabled={
                                            currentTimeValue?.endTime
                                              ? name < _.size(fields) - 1
                                              : false
                                          }
                                          options={
                                            currentTimeValue?.endTimeOption
                                          }
                                          onChange={(time) => {
                                            setTimeValues((prevValues) => ({
                                              ...prevValues,
                                              [value]: {
                                                ...timeValues[value],
                                                [name]: {
                                                  ...currentTimeValue,
                                                  endTime: time,
                                                },
                                                [name + 1]: {
                                                  startTimeOption:
                                                    filterTimeOption(time),
                                                },
                                              },
                                            }));
                                          }}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col flex={1}>
                                      <div className="w-100 d-flex align-items-end tw-h-[24px] tw-mb-[24px] lg:tw-h-[70px] lg:tw-mb-0">
                                        <Button
                                          size="large"
                                          className="w-100"
                                          danger
                                          onClick={() => {
                                            remove(name);
                                          }}
                                        >
                                          ลบ
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              );
                            })}
                            <Form.Item>
                              <Button
                                type="primary"
                                onClick={() => {
                                  add();
                                }}
                                block
                                icon={<PlusOutlined />}
                              >
                                เพิ่มช่วงเวลา
                              </Button>
                            </Form.Item>
                            <Divider />
                          </>
                        )}
                      </Form.List>
                    )}
                  </React.Fragment>
                );
              })}
            </>
          )}
        </Form.List>
      </Form>
      <Row>
        <Button
          style={{ height: "48px", width: "160px" }}
          size="large"
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          บันทึก
        </Button>
      </Row>
    </>
  );
};

export default TeacherAvailableTime;
