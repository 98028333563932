import TextWithPopover from "components/textWithPopover";
import { HiLocationMarker } from "react-icons/hi";

const CourseDescription = ({ dataCourse }) => {
  const objective = dataCourse?.courseObjective || [];
  const earn = dataCourse?.courseEarn || [];
  return (
    <>
      <h4>{`รายละเอียด${
        dataCourse?.type === "workshop" ? "เวิร์กชอป" : "คอร์สเรียน"
      }`}</h4>
      <p>{dataCourse?.description}</p>
      {dataCourse?.locationName ? (
        <>
          <h4>{`สถานที่จัดคอร์สเรียน`}</h4>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <a
              href={`https://www.google.com/maps?q=${dataCourse?.position?.lat},${dataCourse?.position?.lon}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="d-flex align-items-center">
                <HiLocationMarker className="me-2" />
                {
                  <TextWithPopover
                    text={dataCourse?.locationName || "Location name"}
                    maxLength={20}
                  />
                }
              </div>
            </a>
          </div>
        </>
      ) : null}

      {objective.length > 0 && (
        <div className="requirements">
          <h4>วัตถุประสงค์</h4>
          <ul className="global-list">
            {objective.length
              ? objective.map((n, i) => <li key={i}>{n?.description}</li>)
              : "-"}
          </ul>
        </div>
      )}

      {earn.length > 0 && (
        <div className="you-learn">
          <h4>{`สิ่งที่คุณจะได้รับใน${
            dataCourse?.type === "workshop" ? "เวิร์กชอป" : "คอร์สเรียน"
          }นี้`}</h4>
          <ul className="global-list">
            {earn.length
              ? earn.map((n, i) => <li key={i}>{n?.description}</li>)
              : "-"}
          </ul>
        </div>
      )}
    </>
  );
};

export default CourseDescription;
