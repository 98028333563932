import { useMutation, useQuery } from "react-query";
import { getUserId } from "utils";
import createRequest from "utils/request";

const courseBackOfficeServices = {
  // :: COURSE TEACHER ::----------------------------------------------
  useQueryGetAllCourseByTeacherId({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllMyTeacherCourse", queryObj],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `/course/getAllMyTeacherCourse`;
        const query = {
          params: { ...queryObj, paging: pagingStr },
        };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!getUserId(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetAllMyCourseHomework({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllMyCourseHomework", getUserId()],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `/course/getAllMyCourseHomework`;
        const query = {
          params: { ...queryObj, paging: pagingStr },
        };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!getUserId(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetAllStudentByCourseId({ courseId, onSuccess, onError }) {
    return useQuery(
      ["getAllStudentByCourseId", courseId],
      async () => {
        const path = `/course/getAllStudentByCourseId`;
        const query = { params: { courseId: courseId } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!courseId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetAllRecentHomework({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllRecentHomework", queryObj],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `/course/getAllRecentHomework`;
        const query = {
          params: { ...queryObj, paging: pagingStr },
        };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetPointByUnitId({ unitId, onSuccess, onError }) {
    return useQuery(
      ["getPointByUnitId", unitId],
      async () => {
        const path = `/course/getPointByUnitId`;
        const query = { params: { unitId } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!unitId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetAllUnitByCourseId({ courseId, onSuccess, onError }) {
    return useQuery(
      ["getAllUnitByCourseId", courseId],
      async () => {
        const path = `/course/getAllUnitByCourseId`;
        const query = { params: { courseId } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!courseId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetAllMyUnit({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllMyUnit", getUserId(), queryObj],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `/course/getAllMyUnit`;
        const query = {
          params: { ...queryObj, paging: pagingStr },
        };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetAllRecentQuiz({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllRecentQuiz", queryObj],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `/course/getAllRecentQuiz`;
        const query = {
          params: { ...queryObj, paging: pagingStr },
        };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetWritingQuizByCourseId({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getWritingQuizByCourseId", queryObj],
      async () => {
        const path = `/course/getWritingQuizByCourseId`;
        const query = {
          params: { ...queryObj },
        };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: !!queryObj.courseId && !!queryObj.studentId,
      }
    );
  },
  useQueryGetAllQuizByUnitId({ unitId, type, onSuccess, onError }) {
    return useQuery(
      ["getQuiz", unitId, type],
      async () => {
        const path = `/course/getQuiz`;
        const query = { params: { unitId, type } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!unitId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
      }
    );
  },
  useQueryGetAllZoomGroupByCourseId({ id, onSuccess, onError }) {
    return useQuery(
      ["getAllZoomGroupByCourseId", id],
      async () => {
        const path = `course/getAllZoomGroupByCourseId`;
        const query = { params: { id } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  // :: MUTATION - COURSE TEACHER ::----------------------------------------------
  useMutationSaveAnswer(onSuccess, onError) {
    return useMutation(
      async ({ answers, type }) => {
        const path = `/course/saveAnswer`;
        const query = { answers, type };
        const res = await createRequest.post(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationSaveAnswerForWritingQuiz(onSuccess, onError) {
    return useMutation(
      async ({ answers, studentId }) => {
        const path = `/course/saveAnswerForWritingQuiz`;
        const query = { answers, studentId };
        const res = await createRequest.post(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  useMutationCreateCourse(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/course/createCourse`;
        const query = { ...values };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateCourse(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/course/updateCourse`;
        const query = { ...values, teacherId: getUserId() };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDeleteCourse(onSuccess, onError) {
    return useMutation(
      async ({ courseId }) => {
        const path = `/course/deleteCourse`;
        const query = { params: { courseId } };
        const res = await createRequest.delete(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationCreateUnit(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/course/createUnit`;
        const query = { ...values };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateUnit(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/course/updateUnit`;
        const query = { ...values };
        try {
          const res = await createRequest.post(path, query);
          return res.data;
        } catch (error) {
          throw error;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationCreateZoomGroup(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/course/createZoomGroup`;
        const query = { ...values };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateZoomGroup(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/course/updateZoomGroup`;
        const query = { ...values };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationGetStudentHomeworkByCourseId(onSuccess, onError) {
    return useMutation(
      async ({ courseId, studentId }) => {
        const path = `/course/getStudentHomeworkByCourseId`;
        const query = { params: { courseId: courseId, studentId } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  // :: COURSE STUDENTS ::----------------------------------------------
  useQueryGetAllCourseByStudentId({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllMyStudentCourse", getUserId(), queryObj],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `/course/getAllMyStudentCourse`;
        const query = { params: { ...queryObj, paging: pagingStr } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetCourseById({ id, onSuccess, onError }) {
    return useQuery(
      ["getCourseById", id],
      async () => {
        const path = `/course/getCourseById`;
        const query = { params: { id } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  // :: MUTATION - COURSE STUDENTS ::----------------------------------------------
  useMutationCreateHomeworkByUnitId(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/course/learn/createHomeworkByUnitId`;
        const query = { ...values };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateHomeworkByUnitId(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/course/learn/updateHomeworkByUnitId`;
        const query = { ...values };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationSendToApproveCourse(onSuccess, onError) {
    return useMutation(
      async ({ id }) => {
        const path = `/course/sendToApproveCourse`;
        const query = { courseId: id };
        const res = await createRequest.post(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationPublishCourse(onSuccess, onError) {
    return useMutation(
      async ({ id, publishStatus }) => {
        const path = `/course/publishCourse`;
        const query = { courseId: id, publishStatus };
        const res = await createRequest.post(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationCreateCommentInHomework(onSuccess, onError) {
    return useMutation(
      async ({ homeworkId, text }) => {
        const path = `/course/createCommentInHomework`;
        const query = { homeworkId: homeworkId, text: text };
        const res = await createRequest.post(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateHomeworkStatus(onSuccess, onError) {
    return useMutation(
      async ({ homeworkId, isPass = true }) => {
        const path = `/course/learn/updateHomeworkStatus`;
        const query = { id: homeworkId, isPass: isPass };
        const res = await createRequest.post(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};

export default courseBackOfficeServices;
