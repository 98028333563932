import { Col, Input, Pagination, Row, Select, Spin, Button } from "antd";
import CourseCard from "components/CourseCard";
import LayoutContent from "components/LayoutContent";
import Breadcrumb from "components/elements/Breadcrumb";
import { courseType } from "constants/courseType";
import { instrumentOption } from "constants/options/instrumentOption";
import { paging } from "constants/paginationSize";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom/dist";
import courseService from "services/course.services";
import packageService from "services/package.services";
import workshopService from "services/workshop.services";
import { getUserId } from "utils/index";
const { Search } = Input;

const CoursePage = ({ type: componentType, isKruNut }) => {
  const { type } = useParams();
  const navigate = useNavigate();
  const userId = getUserId();

  const limitPage = 30;
  const [page, setPage] = useState(1);
  const [filterInstrument, setFilterInstrument] = useState("ทั้งหมด");
  const [filterSearch, setFilterSearch] = useState(null);

  const {
    data: dataCourse,
    isFetching: isLoadingCourse,
    refetch,
  } = componentType === "course"
    ? courseService.useQueryGetAllCourse({
        queryObj: {
          paging: {
            limit: limitPage,
            start: page * limitPage - limitPage,
          },
          type: type,
          search: filterSearch,
          category: filterInstrument,
          isKruNut,
        },
      })
    : componentType === "workshop"
    ? workshopService.useQueryGetAllWorkshop({
        queryObj: {
          paging: {
            limit: limitPage,
            start: page * limitPage - limitPage,
          },
          type: type,
          search: filterSearch,
          category: filterInstrument,
          isKruNut,
        },
      })
    : packageService.useQueryGetAllPackageActive({
        queryObj: {
          paging: {
            limit: limitPage,
            start: page * limitPage - limitPage,
          },
          type: type,
          search: filterSearch,
          category: filterInstrument,
          isKruNut,
        },
      });

  let breadcrumb_item = [
    {
      name: "หน้าแรก",
      link: "/",
    },
    {
      name: isKruNut
        ? "เรียนร้องเพลงกับครูนัท"
        : componentType === "course"
        ? "คอร์สเรียน"
        : componentType === "workshop"
        ? "เวิร์กชอป"
        : "แพ็กเกจ",
    },
    componentType === "course" && {
      name: `${type ? _.find(courseType, { type: type })?.nameTh : "ทั้งหมด"}`,
    },
  ].filter(Boolean);

  const onChange = (e) => {
    setFilterSearch(e.target.value);
  };
  const onSearch = () => {
    refetch();
  };
  const handleChange = (value) => {
    setFilterInstrument(value);
  };

  useEffect(() => {
    if (type && componentType === "course") {
      let isValid = _.find(courseType, { type: type })?.nameTh;
      if (!isValid) {
        navigate("/");
      }
    }
    setFilterInstrument("ทั้งหมด");
    setFilterSearch(null);
  }, [type]);

  useEffect(() => {
    refetch();
  }, [type, filterInstrument, page, isKruNut]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [type, componentType]);

  return (
    <>
      <Breadcrumb items={breadcrumb_item} />
      <LayoutContent>
        {type === "at_home" && !userId ? (
          <Row>
            <Col span={24} className="tw-py-4 tw-text-center">
              <h4>กรุณาเข้าสู่ระบบ</h4>
              <p>
                กรุณาเข้าสู่ระบบก่อนเลือกคอร์สเรียนประเภทเรียนตัวต่อตัวที่บ้านนักเรียน
              </p>
              <Button
                type="primary"
                className="tw-mt-4"
                onClick={() => {
                  navigate("/login");
                }}
              >
                เข้าสู่ระบบ
              </Button>
            </Col>
          </Row>
        ) : (
          <>
            <Row gutter={[8, 8]} className="mb-5">
              <Col>
                <p style={{ opacity: "0.6" }} className="mb-2">
                  ค้นหา
                </p>
                <Search
                  value={filterSearch}
                  style={{
                    width: 240,
                  }}
                  size="large"
                  placeholder="ค้นหาจากสิ่งที่สนใจ..."
                  onChange={onChange}
                  onSearch={onSearch}
                  allowClear
                />
              </Col>
              <Col>
                <p style={{ opacity: "0.6" }} className="mb-2">
                  หมวหมู่เครื่องดนตรี
                </p>
                <Select
                  value={filterInstrument}
                  placeholder="หมวหมู่เครื่องดนตรี"
                  size="large"
                  style={{
                    width: 240,
                  }}
                  onChange={handleChange}
                  options={instrumentOption}
                />
              </Col>
            </Row>
            {isLoadingCourse ? (
              <Spin className="m-4" />
            ) : (
              [...(dataCourse?.data || [])].map((data, index) => {
                return (
                  index < paging?.pageSize && (
                    <div key={index} className="col-md-6 col-lg-4">
                      <CourseCard key={`course-card-${index}`} data={data} />
                    </div>
                  )
                );
              })
            )}
            <Row className="center">
              <Pagination
                size="large"
                current={page}
                pageSize={limitPage}
                total={dataCourse?.total}
                onChange={(page) => {
                  setPage(page);
                }}
              />
            </Row>
          </>
        )}
      </LayoutContent>
    </>
  );
};

export default CoursePage;
