import { Tabs } from "antd";
import ActivateModal from "components/ActivateModal";
import useModalHook from "hooks/useModalHook";
import React, { useState } from "react";
import HistoryCard from "./components/HistoryCard";
import paymentService from "../../../../services/payment.services";
import { Spin } from "antd";

const UserPurchase = () => {
  const [tabs, setTabs] = useState(1);
  const {
    open: openActivateCourse,
    handleOpen: _handleOpenActivateCourse,
    handleClose: _handleCloseActivateCourse,
  } = useModalHook();

  const {
    data: myPayments,
    isLoading: isLoadingMyPayments,
    refetch: refetchMyPayments,
  } = paymentService.useQueryGetAllMyPayment({
    queryObj: {
      status: tabs === 1 ? "pending" : tabs === 2 ? "successful" : "failed",
    },
  });

  const tabWorkshop = [
    {
      key: 1,
      label: `กำลังจัดส่ง`,
      children: (
        <div>
          {isLoadingMyPayments ? (
            <Spin />
          ) : (
            (myPayments?.data || []).map((item, index) => (
              <HistoryCard
                key={index}
                data={item}
                tabs={tabs}
                onClickActivate={_handleOpenActivateCourse}
                refetchMyPayments={refetchMyPayments}
              />
            ))
          )}
        </div>
      ),
    },
    {
      key: 2,
      label: `ซื้อสำเร็จ`,
      children: (
        <div>
          {isLoadingMyPayments ? (
            <Spin />
          ) : (
            (myPayments?.data || []).map((item, index) => (
              <HistoryCard
                key={index}
                data={item}
                tabs={tabs}
                onClickActivate={_handleOpenActivateCourse}
                refetchMyPayments={refetchMyPayments}
              />
            ))
          )}
        </div>
      ),
    },
    {
      key: 3,
      label: `ซื้อไม่สำเร็จ`,
      children: (
        <div>
          {isLoadingMyPayments ? (
            <Spin />
          ) : (
            (myPayments?.data || []).map((item, index) => (
              <HistoryCard
                key={index}
                data={item}
                tabs={tabs}
                onClickActivate={_handleOpenActivateCourse}
                refetchMyPayments={refetchMyPayments}
              />
            ))
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Tabs
        size="large"
        defaultActiveKey={1}
        items={tabWorkshop}
        onChange={(key) => {
          setTabs(key);
        }}
      />
      <ActivateModal
        open={openActivateCourse}
        onCancel={_handleCloseActivateCourse}
      />
    </>
  );
};

export default UserPurchase;
