import { Button, Image, Row, Table, Tabs } from "antd";
import { NO_IMAGE } from "assets/images";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import { localeCustomTH } from "constants/thaiLocale/localeCustomTH";
import { errorToMessage } from "hooks/functions/errorToMessage";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import packageBackofficeServices from "services/backoffice/packageBackoffice.services";

const AdminManagePackages = () => {
  const [keyTab, setKeyTab] = useState(1);
  const {
    data: getTeacherPackages,
    isFetching: isLoadingTeacherPackages,
    refetch: refetchPackage,
  } = packageBackofficeServices.useQueryGetAllPackageForApprove({
    queryObj: {
      isApprove: keyTab === 1,
    },
  });

  const { mutate: approvePackage } =
    packageBackofficeServices.useMutationUpdatePackageApprove(
      () => {
        AlertSuccess({});
        refetchPackage();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );
  const { mutate: deletePackage } =
    packageBackofficeServices.useMutationDeletePackage(
      () => {
        AlertSuccess({});
        refetchPackage();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const renderContent = () => {
    return (
      <Row>
        <Table
          locale={localeCustomTH.Table}
          loading={isLoadingTeacherPackages}
          scroll={{ x: true }}
          className="w-100"
          bordered
          dataSource={getTeacherPackages?.data}
          columns={headerTable}
        />
      </Row>
    );
  };

  const headerTable = [
    {
      title: "ลำดับ",
      render: (_, {}, index) => index + 1,
    },
    {
      title: "ภาพหน้าปก",
      dataIndex: "coverImgUrl",
      key: "coverImgUrl",
      render: (_, { coverImgUrl }) => (
        <div style={{ minWidth: "80px" }} className="center">
          <Image
            style={{ borderRadius: "4px" }}
            width={60}
            height={60}
            src={coverImgUrl || NO_IMAGE}
            fallback={NO_IMAGE}
            alt="profile_img"
          />
        </div>
      ),
    },
    {
      title: "ประเภท",
      dataIndex: "type",
      render: () => <div style={{ minWidth: "80px" }}>แพ็คเกจ</div>,
    },
    {
      title: "ชื่อแพ็คเกจ",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <>
          <div style={{ minWidth: "200px" }}>{text}</div>
        </>
      ),
    },
    {
      title: "รายละเอียด",
      dataIndex: "description",
      key: "description",
      render: (text) => (
        <>
          <div style={{ minWidth: "200px" }}>{text}</div>
        </>
      ),
    },

    {
      title: "ราคาปกติ",
      dataIndex: "delPrice",
      render: (text) => (
        <div style={{ minWidth: "60px" }}>{numeral(text).format("0,0")}</div>
      ),
    },
    {
      title: "ราคาสุทธิ",
      dataIndex: "price",
      render: (text) => (
        <div style={{ minWidth: "60px" }}>{numeral(text).format("0,0")}</div>
      ),
    },
    {
      title: "รีวิว",
      dataIndex: "rating",
      render: (text) => (
        <div style={{ minWidth: "60px" }}>{numeral(text).format("0,0")}</div>
      ),
    },
    {
      title: "โปรไฟล์ผู้สอน",
      dataIndex: "teacherImg",
      key: "teacherImg",
      render: (_, { teacherImg }) => (
        <div style={{ minWidth: "100px" }} className="center">
          <Image
            style={{ borderRadius: "4px" }}
            width={60}
            height={60}
            src={teacherImg || NO_IMAGE}
            fallback={NO_IMAGE}
            alt="profile_img"
          />
        </div>
      ),
    },
    {
      title: "ชื่อครูผู้สอน",
      dataIndex: "teacherName",
      render: (text) => <div style={{ minWidth: "80px" }}>{text}</div>,
    },
    {
      title: "วันที่สร้างคอร์ส",
      dataIndex: "date",
      render: (text) => (
        <div style={{ minWidth: "100px" }}>
          {moment(text).format("DD-MM-YYYY")}
        </div>
      ),
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      fixed: "right",
      render: (_, { id }) => (
        <>
          {keyTab === 1 ? (
            <div className="center gap-2 tw-gap-1">
              <Button
                className="center"
                danger
                onClick={() => {
                  AlertConfirm({
                    onOk: () => {
                      deletePackage({ packageId: id });
                    },
                  });
                }}
              >
                <AiOutlineDelete />
                <span className="!tw-hidden lg:!tw-block">ลบ</span>
              </Button>
            </div>
          ) : (
            <div className="d-flex gap-2">
              <div className="center">
                <Button
                  className="w-100"
                  type="primary"
                  onClick={() => {
                    AlertConfirm({
                      onOk: () => {
                        approvePackage({ id, approveStatus: true });
                      },
                    });
                  }}
                >
                  อนุมัติ
                </Button>
              </div>
              <div className="center">
                <Button
                  className="w-100"
                  danger
                  onClick={() => {
                    AlertConfirm({
                      onOk: () => {
                        approvePackage({ id, approveStatus: false });
                      },
                    });
                  }}
                >
                  ไม่อนุมัติ
                </Button>
              </div>
            </div>
          )}
        </>
      ),
    },
  ];

  const packageTabs = [
    {
      key: 1,
      label: `ออนไลน์ `,
      children: renderContent(),
    },
    {
      key: 2,
      label: `รออนุมัติ `,
      children: renderContent(),
    },
  ];

  useEffect(() => {
    refetchPackage();
  }, [keyTab]);

  return (
    <>
      <Row>
        <Tabs
          className="w-100"
          type="card"
          defaultActiveKey="1"
          items={packageTabs}
          onChange={(key) => setKeyTab(key)}
        />
      </Row>
    </>
  );
};

export default AdminManagePackages;
