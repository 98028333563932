import { useMutation, useQuery } from "react-query";
import { getUserId } from "utils";
import createRequest from "utils/request";

const adminCourseService = {
  // :: ADMIN_COURSE :: --------------------------------------------------
  useQueryGetAllCourseForApprove({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllCourse", queryObj],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `/course/getAllCourseForApprove`;
        const query = { params: { ...queryObj, paging: pagingStr } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  // :: ADMIN_WORKSHOP :: --------------------------------------------------
  useQueryGetAllWorkshop({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllWorkshop", queryObj],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `/workshop/getAllWorkshop`;
        const query = { params: { ...queryObj, paging: pagingStr } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  //   ---------------------- :: USE_MUTATION :: --------------------
  useMutationApproveCourse(onSuccess, onError) {
    return useMutation(
      async ({ id, approveStatus }) => {
        const path = `/course/updateCourseApprove`;
        const query = { courseId: id, approveStatus };
        const res = await createRequest.post(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  //   ---------------------- :: USE_MUTATION  WORKSHOP:: --------------------
  useMutationApproveWorkshop(onSuccess, onError) {
    return useMutation(
      async ({ workshopId, approveStatus }) => {
        const path = `/workshop/updateWorkshopApprove`;
        const query = {
          workshopId,
          approveStatus: approveStatus, // true : approve , false : reject
        };
        const res = await createRequest.post(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDeleteWorkshop(onSuccess, onError) {
    return useMutation(
      async ({ workshopId }) => {
        const path = `/workshop/deleteWorkshop`;
        const query = {
          params: { workshopId: workshopId },
        };
        const res = await createRequest.delete(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};
export default adminCourseService;
