import { Card, Col, Divider, Empty, Pagination, Row, Spin, Tabs } from "antd";
import { NO_IMAGE } from "assets/images";
import { COLOR } from "constants/color";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";
import { courseTypeToText } from "hooks/functions/courseTypeToText";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { IoBookOutline, IoDesktopSharp } from "react-icons/io5";
import { MdOutlineAccessTime } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import RecentHomework from "./RecentHomework";

const TeacherHomework = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const limitPage = 5;
  const [page, setPage] = useState(1);

  const {
    data: dataHomework,
    isFetching: isLoadingHomework,
    refetch: refetchHomework,
  } = courseBackOfficeServices.useQueryGetAllMyCourseHomework({
    queryObj: {
      paging: {
        limit: limitPage,
        start: page * limitPage - limitPage,
      },
    },
  });

  useEffect(() => {
    refetchHomework();
  }, [page]);

  const renderContent = () => {
    return (
      <>
        {isLoadingHomework ? (
          <Spin className="p-4" />
        ) : (
          <>
            {dataHomework && (
              <Row className="my-4 justify-content-between">
                <p style={{ fontSize: "18px", opacity: "0.8" }}>
                  การบ้านจาก {dataHomework?.total} คอร์สเรียน
                </p>
              </Row>
            )}

            <Row>
              {dataHomework?.data ? (
                <Col span={24}>
                  {[...(dataHomework?.data || [])]?.map((n, i) => {
                    return (
                      <Card
                        key={i}
                        title={n.title}
                        className="mb-3 course-list"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Row
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/teacher/homework/${n?.id}`);
                          }}
                        >
                          <div className="tw-w-full tw-grid tw-grid-cols-1 lg:tw-grid-cols-[150px_1fr_140px] tw-gap-4">
                            <div className="tw-rounded-[8px] tw-max-h-[140px] tw-overflow-hidden">
                              <img
                                className="tw-object-center tw-object-cover tw-w-full tw-h-full"
                                src={n?.coverImgUrl || NO_IMAGE}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = NO_IMAGE;
                                }}
                                alt="course-cover"
                              />
                            </div>
                            <div>
                              <p className="limited-text">
                                รายละเอียดคอร์ส : {n.description}
                              </p>

                              <div className="d-flex align-items-center mt-1">
                                <div className="d-flex align-items-center">
                                  <IoBookOutline
                                    color={COLOR.primary}
                                    className="me-2"
                                  />
                                  <p className="text-primary me-4">{`หมวดหมู่ : ${n.category}`}</p>
                                </div>
                              </div>
                              <div className="d-flex align-items-center my-1">
                                <IoDesktopSharp className="me-2" />
                                <p>{courseTypeToText(n.type)}</p>
                              </div>
                              <div className="d-flex align-items-center">
                                <MdOutlineAccessTime className="me-2" />
                                <p>{`วันที่สร้างคอร์ส : ${moment(n.date).format(
                                  "DD/MM/YYYY"
                                )}`}</p>
                              </div>
                            </div>
                            <Divider className="my-1 lg:tw-hidden" />
                            <div className="tw-flex tw-justify-between tw-items-center tw-flex-row-reverse lg:tw-flex-col lg:tw-items-end">
                              <div
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "600",
                                }}
                                className="text-primary w-100 d-flex justify-content-around align-items-center"
                              >
                                <div className="text-center">
                                  <p className="mb-2">
                                    {numeral(n.totalStudent).format("0,0")}
                                  </p>
                                  <div>
                                    <p>นักเรียน</p>
                                  </div>
                                </div>
                                <div className="text-center">
                                  <p className="mb-2">
                                    {numeral(n.totalHomework).format("0,0")}
                                  </p>
                                  <div>
                                    <p>การบ้าน</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Row>
                      </Card>
                    );
                  })}
                </Col>
              ) : (
                <div className="w-100 center p-4">
                  <Empty description={EMPTY_DESCRIPTION} />
                </div>
              )}
            </Row>
            <Row>
              {dataHomework?.total > 0 && (
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  pageSize={limitPage}
                  total={dataHomework?.total || 1}
                  onChange={(page) => {
                    setPage(page);
                  }}
                />
              )}
            </Row>
          </>
        )}
      </>
    );
  };

  const tabCourse = [
    {
      key: 1,
      label: `คอร์สของฉัน`,
      children: renderContent(),
    },
    {
      key: 2,
      label: `ส่งงานล่าสุด`,
      children: <RecentHomework />,
    },
  ];

  const onChange = (key) => {
    setTab(key);
  };

  useEffect(() => {
    refetchHomework();
    window.scrollTo(0, 440);
  }, [tab]);

  return (
    <Tabs
      size="large"
      defaultActiveKey={1}
      items={tabCourse}
      onChange={onChange}
    />
  );
};

export default TeacherHomework;
