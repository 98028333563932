import { Button, Empty, Row, Spin } from "antd";
import { courseType } from "constants/courseType";
import _ from "lodash";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import CourseOnline from "./course-type/CourseOnline";
import CourseOther from "./course-type/CourseOther";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";

const TeacherCourseDetail = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();

  const {
    data: getUnit,
    isFetching: isLoadingUnit,
    refetch: refetchUnit,
  } = courseBackOfficeServices.useQueryGetAllUnitByCourseId({ courseId });

  return (
    <>
      <Row className="mb-4">
        <Button
          type="link"
          className="center"
          onClick={() => {
            navigate(`/teacher/course`);
          }}
        >
          <IoIosArrowBack size={22} className="me-2" />
          <p>ย้อนกลับ</p>
        </Button>
      </Row>

      {isLoadingUnit ? (
        <Spin />
      ) : (
        <>
          {getUnit?.type === "online" && (
            <CourseOnline data={getUnit} refetch={refetchUnit} />
          )}
          {getUnit?.type !== "online" && (
            <CourseOther data={getUnit} refetch={refetchUnit} />
          )}

          {!_.includes(
            courseType.map((n) => n?.type),
            getUnit?.type
          ) && (
            <Row className="center">
              <Empty className="my-4" description={EMPTY_DESCRIPTION} />
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default TeacherCourseDetail;
