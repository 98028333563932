import { Image } from "antd";
import { NOT_FOUND_IMG } from "assets/images";
import React from "react";

const ActivityCard = ({ thumbUrl = NOT_FOUND_IMG }) => {
  return (
    <div className="sa-tutor">
      <div className="tutor-thumb">
        <a href="#">
          <div style={{ height: "260px" }} className="center">
            <Image
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
              className="img-fluid"
              src={thumbUrl || NOT_FOUND_IMG}
              fallback={NOT_FOUND_IMG}
              alt="Image"
            />
          </div>
        </a>
      </div>
    </div>
  );
};

export default ActivityCard;
