import { NO_IMAGE } from "assets/images";
import { ROLE_USER } from "constants/roleUserConstant";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { PROFILE_LOADING, SET_PROFILE } from "redux/reducers/profileSlice";
import userBackOfficeServices from "services/backoffice/userBackoffice.services";
import { getIsKruNut, getRoleUser } from "utils";
import { teacherTabMenu } from "../constants/teacherTabMenu";

const TeacherProfileLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const _path = location.pathname.split("/");
  const currentPath = _path.length - 1;
  const profile = useSelector((state) => state.profile);
  const isKruNut = getIsKruNut();

  const tabKruNut = teacherTabMenu.filter((n) => n.isKruNut);
  const tabMenu = isKruNut ? tabKruNut : teacherTabMenu;

  const [menuName, setMenuName] = useState("");
  const [menuDesc, setMenuDesc] = useState("");
  const { data: userProfile, refetch: refetchUserProfile } =
    userBackOfficeServices.useQueryGetUserProfile({
      onSuccess: (res) => {
        if (res) {
          dispatch(SET_PROFILE(res));
          dispatch(PROFILE_LOADING(false));
        } else {
          navigate("/login");
        }
      },
    });

  useEffect(() => {
    const roleUser = getRoleUser(); // using role
    let subPath = _path.filter((_, index) => index > 1).join("/");
    let redirect = roleUser ? `/${roleUser}/${subPath}` : `/login`;
    // for teacher
    if (roleUser != ROLE_USER.teacher && !isKruNut) {
      navigate(redirect);
    }
    refetchUserProfile();
  }, [currentPath, profile.loading]);

  useEffect(() => {
    let keysIncluded = tabMenu.some((menu) => _path.includes(menu.key));
    if (!keysIncluded) {
      navigate(`/${ROLE_USER.teacher}/${tabMenu[0]?.key}`);
    } else {
      setMenuName(tabMenu.find((e) => e.key === _path[2])?.name);
      setMenuDesc(tabMenu.find((e) => e.key === _path[2])?.desc);
    }
  }, [tabMenu, _path, isKruNut]);

  return (
    <>
      <section className="breadcrumb-section">
        <div className="container">
          <h1>โปรไฟล์</h1>
          <p>ประวัติและข้อมูลส่วนตัว</p>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">หน้าหลัก</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/teacher/profile">คุณครู</Link>
            </li>
            <li className="breadcrumb-item">{menuName}</li>
          </ol>
        </div>
      </section>

      <div className="sg-page-content">
        <div className="sg-section">
          <div
            style={{ backgroundColor: "white" }}
            className="section-content profile-content section-padding"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-10 offset-md-1">
                  <div className="profile-tabs">
                    <ul
                      // style={{ backgroundColor: "white" }}
                      className="nav nav-tabs"
                      role="tablist"
                    >
                      <li>
                        <div className="user-info">
                          <div className="profile-picture center">
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              src={userProfile?.thumbUrl || NO_IMAGE}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = NO_IMAGE;
                              }}
                              alt="Profile"
                            />
                          </div>
                          <span className="user-name">
                            {userProfile?.displayName || "username"}
                          </span>
                        </div>
                      </li>

                      {tabMenu?.map((menu, index) => (
                        <li className="nav-item" key={index}>
                          {menu.disabled ? (
                            <div
                              className={`nav-link tw-font-[600] !tw-text-[#5a5a5a] tw-opacity-50 tw-cursor-not-allowed`}
                            >
                              {" "}
                              {menu.name}
                            </div>
                          ) : (
                            <Link
                              to={menu.path}
                              style={{
                                opacity: `${menu.disabled ? "0.2" : "1"}`,
                              }}
                              className={`nav-link ${_.includes(
                                location.pathname,
                                `/teacher/${menu.key}`
                              ) && "active"
                                } `}
                            >
                              {menu.name}
                            </Link>
                          )}
                        </li>
                      ))}
                    </ul>

                    <div
                      // style={{ backgroundColor: "white" }}
                      className="tab-content"
                    >
                      <div className="tab-pane active">
                        <div className="profile-section">
                          <div className="tab-header">
                            <h2>{menuName}</h2>
                            <p>{menuDesc}</p>
                          </div>
                          <div
                            style={{
                              borderTop: "1px solid #b2b4b9",
                              minHeight: "600px",
                            }}
                            className="px-3 py-5"
                          >
                            <Outlet />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherProfileLayout;
