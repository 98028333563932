import { Button, Typography } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import TextWithPopover from 'components/textWithPopover';
import { COLOR } from 'constants/color';
import moment from 'moment';
import Calendar from 'pages/back-office/components/Calendar'
import React, { useEffect, useState } from 'react'
import { FaLink } from 'react-icons/fa6';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import teacherBackOfficeService from 'services/backoffice/teacherBackoffice.services';

export default function TeacherCalendar() {

	const [currentMonth, setCurrentMonth] = useState(moment(new Date()).format('YYYY-MM'))
	const [listData, setListData] = useState([])
	const { data, refetch } =
		teacherBackOfficeService.useQueryGetMySchedule({
			month: currentMonth
		});
	const onMonthChange = (e) => {
		setCurrentMonth(e)
	}
	const calendarPopover = (item) => {
		const startTimeFormatted = moment(item.startDate + item.startTime, 'YYYY-MM-DDHH:mm').locale('th').format('dddd, MMMM D ⋅ HH:mm');
		const finishTimeFormatted = moment(item.finishTime, 'HH:mm').locale('th').format('HH:mm');
		return (
			<div>
				<Typography>
					<Title className='!tw-font-kanit' level={4}>{item.courseName}</Title>
					<Paragraph className='!tw-font-kanit'>
						{startTimeFormatted} – {finishTimeFormatted}
					</Paragraph>
					{
						item.studentName ? <Paragraph className='!tw-font-kanit'>
							ผู้เรียน: {item.studentName}
						</Paragraph>
							: null
					}

					{item.linkZoom ? <Paragraph className='!tw-font-kanit'>
						<a href={`https://www.${item.linkZoom}`} target='_blank'>
							<FaLink color={COLOR.primary} className='tw-mr-2' />{item.linkZoom}
						</a>
					</Paragraph> : null}
					{item.position && item.position.lat && item.position.lon ? <Paragraph className='!tw-font-kanit'>
						<a href={`https://www.google.com/maps?q=${item.position.lat},${item.position.lon}`} target="_blank" className="tw-flex tw-items-center">
							<HiOutlineLocationMarker
								color={COLOR.primary}
								className="me-2"
							/>
							<p className="text-primary !tw-mb-0	">
								{item.locationName || "สถานที่ทำการสอน"}
							</p>
						</a>
					</Paragraph> : null}
				</Typography>
			</div>
		)
	}

	useEffect(() => {
		const formatted = {}
		data?.forEach(({ id, startTime: _startTime, finishTime: _finishTime, ...rest }) => {
			const startDate = _startTime.split(" ")[0]
			const startTime = _startTime.split(" ")[1]
			const finishDate = _finishTime.split(" ")[0]
			const finishTime = _finishTime.split(" ")[1]
			if (!formatted[startDate]) {
				if (startDate === finishDate) {
					formatted[startDate] = [{ startDate, startTime, finishDate, finishTime, ...rest }]
				} else {
					let _startDate = new Date(startDate)
					let _finishDate = new Date(finishDate)
					while (_startDate <= _finishDate) {
						const dateStr = moment(_startDate).locale('en').format("YYYY-MM-DD")
						if (!formatted[dateStr]) {
							formatted[dateStr] = [{ startDate, startTime: dateStr != startDate ? "00:00" : startTime, finishDate, finishTime: dateStr != finishDate ? "00:00" : finishTime, ...rest }]
						} else {
							formatted[dateStr].push({ startDate, startTime: dateStr != startDate ? "00:00" : startTime, finishDate, finishTime: dateStr != finishDate ? "00:00" : finishTime, ...rest })
						}
						_startDate.setDate(_startDate.getDate() + 1)
					}
				}

			} else {
				formatted[startDate].push({ startDate, startTime, finishDate, finishTime, ...rest })
			}
		})
		setListData(formatted)
	}, [data])

	return (
		<div>
			<Calendar
				compact={false}
				placeholder="ตารางสอน"
				listData={listData}
				innerPopover={calendarPopover}
				onMonthChange={onMonthChange}
			/>
		</div>
	)
}
