import Section from "components/section";
import React from "react";

// image size : 1920 x 700

export default function WhyWeJoinSection() {
  return (
    <Section
      className="feature-content jarallax section-before"
      style={{ backgroundImage: `url(assets/images/bg/s3.jpg)` }}
    >
      <div className="section-header">
        <div className="title align-self-center">
          <h1>ทำไมต้องเรียนกับเรา</h1>
          <span>Join Learn</span>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="feature">
            <div className="icon">
              <i className="fas fa-user-graduate"></i>
            </div>
            <div className="text">
              <h2>ครูผู้เชี่ยวชาญ</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo
                at tempora quos illum voluptatum quisquam qui, architecto
                repellendus, a officiis adipisci.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="feature">
            <div className="icon">
              <i className="fa fa-compress"></i>
            </div>
            <div className="text">
              <h2>คืนเงินหากไม่พอใจ</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo
                at tempora quos illum voluptatum quisquam qui, architecto
                repellendus, a officiis adipisci.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="feature">
            <div className="icon">
              <i className="far fa-folder-open"></i>
            </div>
            <div className="text">
              <h2>มีคอร์สเรียนให้เลือกหลากหลาย</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo
                at tempora quos illum voluptatum quisquam qui, architecto
                repellendus, a officiis adipisci.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="feature">
            <div className="icon">
              <i className="fas fa-external-link-alt"></i>
            </div>
            <div className="text">
              <h2>มีแอดมินคอยให้บริการ</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo
                at tempora quos illum voluptatum quisquam qui, architecto
                repellendus, a officiis adipisci.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="feature">
            <div className="icon">
              <i className="far fa-edit"></i>
            </div>
            <div className="text">
              <h2>มีคอร์สสำหรับทดลองเรียนฟรี</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo
                at tempora quos illum voluptatum quisquam qui, architecto
                repellendus, a officiis adipisci.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="feature">
            <div className="icon">
              <i className="fas fa-hand-holding-usd"></i>
            </div>
            <div className="text">
              <h2>วิธีการชำระเงินที่ยืดหยุ่น</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illo
                at tempora quos illum voluptatum quisquam qui, architecto
                repellendus, a officiis adipisci.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}
