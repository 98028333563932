import { Button, Card, Col, Divider, Empty, Row, Spin, Tag } from "antd";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";
import { IoIosArrowForward } from "react-icons/io";
import QuizDetailSection from "./QuizDetailSection";
import ReactPlayer from "../../../../../node_modules/react-player/lib/index";

const UnitDetailSection = ({ getUnit, isLoading, unitItem, setUnitItem }) => {
  return isLoading ? (
    <Spin />
  ) : (
    <Row>
      {!!unitItem ? (
        <QuizDetailSection unitItem={unitItem} setUnitItem={setUnitItem} />
      ) : (
        <Col span={24}>
          {getUnit?.data ? (
            [...(getUnit?.data || [])].map((n, i) => {
              return (
                <Card className="mb-2" key={`course-detail-${i}`}>
                  <div className="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-mb-2">
                    <h4 className="tw-text-[16px] !tw-m-0">
                      บทที่ {i + 1} - {n.unitName}
                    </h4>
                    <Button
                      type="link"
                      onClick={() => {
                        setUnitItem(n);
                      }}
                    >
                      <div className="center tw-gap-2">
                        <span>ดูแบบทดสอบ</span> <IoIosArrowForward />
                      </div>
                    </Button>
                  </div>
                  <Divider className="tw-my-4" />
                  <div className="tw-w-full tw-flex tw-gap-4">
                    {n?.videoUrl && (
                      <div className="tw-w-[180px]">
                        <ReactPlayer
                          url={n?.videoUrl}
                          width={"180"}
                          height={"auto"}
                          controls
                        />
                      </div>
                    )}
                    <div>
                      {n?.isChallenge && <Tag color="green">มี Challenge</Tag>}
                      <p>รายละเอียด : {n?.description}</p>
                      <p>การบ้าน : {n?.homework}</p>
                      <p>
                        ประเภทการตอบ :{" "}
                        {n?.homeworkAnswerType === "message"
                          ? "ข้อความ"
                          : "ไฟล์วิดีโอ"}
                      </p>
                    </div>
                  </div>
                </Card>
              );
            })
          ) : (
            <Empty className="my-4" description={EMPTY_DESCRIPTION} />
          )}
        </Col>
      )}
    </Row>
  );
};

export default UnitDetailSection;
