import { NO_IMAGE } from "assets/images";
import { NOT_FOUND_IMG } from "assets/images";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom/dist";

const PopularCourseCard = ({
  id = 0,
  type,
  coverImgUrl = NOT_FOUND_IMG,
  date = moment(),
  title = "title name",
}) => {
  return (
    <li className="media">
      <div className="entry-thumbnail">
        <Link to={`/courses/${id}`}>
          <div
            style={{ width: "70px", height: "50px", overflow: "hidden" }}
            className="center"
          >
            <img
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
              src={coverImgUrl || NO_IMAGE}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = NO_IMAGE;
              }}
              alt="cover-course"
              className="img-fluid"
            />
          </div>
        </Link>
      </div>
      <div className="media-body">
        <span className="post-date">{moment(date).format("DD MMM YYYY")}</span>
        <Link to={`/courses/${id}`}>
          {title}
        </Link>
      </div>
    </li>
  );
};

export default PopularCourseCard;
