import JSEncrypt from "jsencrypt";
import { decodeToken } from "react-jwt";

const URL_API = process.env.REACT_APP_API_URL;
const STORAGE_TMS_TOKEN = process.env.REACT_APP_STORAGE_TMS_TOKEN;
const STORAGE_TMS_USER = process.env.REACT_APP_STORAGE_TMS_USER;
const OMISE_PUBLIC_KEY = process.env.REACT_APP_OMISE_PUBLIC_KEY;
const OMISE_SECRET_KEY = process.env.REACT_APP_OMISE_SECRET_KEY;
const PUBLIC_API = "/public-api";
const LINE_CHANNEL_ID = process.env.REACT_APP_LINE_LOGIN_CHANNEL_ID;
const LINE_CHANNEL_SECRET = process.env.REACT_APP_LINE_LOGIN_CHANNEL_SECRET;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;
const DECODE_KEY = process.env.REACT_APP_DECODE_KEY;

const decryptMessage = (encryptedMessage) => {
  const decrypt = new JSEncrypt();
  decrypt.setPrivateKey(DECODE_KEY);
  const decrypted = JSON.parse(decrypt.decrypt(encryptedMessage));
  return decrypted;
};

function setToken(value) {
  if (value == null) {
    localStorage.removeItem(STORAGE_TMS_TOKEN);
  } else {
    localStorage.setItem(STORAGE_TMS_TOKEN, value);

    // let jwtDecoded = decodeToken(value); // decode token
    // console.log(`:: USER_TOKEN : `, { token: `Bearer ${value}` });
    // console.log(":: JWT_DECODED:", jwtDecoded);
  }
}

function getToken() {
  return localStorage.getItem(STORAGE_TMS_TOKEN);
}

function setUser(value) {
  if (value == null) {
    localStorage.removeItem(STORAGE_TMS_USER);
  } else {
    // let decrypt = decryptMessage(value);
    // console.log(":: DECRYPT_MESSAGE :", decrypt);
    // console.log("--------------------");
    localStorage.setItem(STORAGE_TMS_USER, value);
  }
}

function getUser() {
  let value = localStorage.getItem(STORAGE_TMS_USER);
  if (value) {
    let decrypt = decryptMessage(value);
    return decrypt;
  }
}

function getUserId() {
  let value = localStorage.getItem(STORAGE_TMS_USER);
  if (value) {
    let decrypt = decryptMessage(value);
    let userId = decrypt?.id;
    return userId;
  }
}

function getRoleUser() {
  let value = localStorage.getItem(STORAGE_TMS_USER);
  if (value) {
    let decrypt = decryptMessage(value);
    let roleUser = decrypt?.roleName;
    return roleUser;
  }
}

function getIsKruNut() {
  let value = localStorage.getItem(STORAGE_TMS_USER);
  if (value) {
    let decrypt = decryptMessage(value);
    let isKruNut = decrypt?.isKruNut;
    return isKruNut || false;
  }
}

function groupQuizzesByUnit(data) {
  const groupedQuizzes = {};
  data.forEach((quiz) => {
    const { unitId, quizType, type, unitName } = quiz;
    const key = `${unitId}_${quizType}_${type}`;

    if (!groupedQuizzes[key]) {
      groupedQuizzes[key] = {
        unitId,
        unitName,
        quizType,
        type,
        quizzes: [],
      };
    }

    groupedQuizzes[key].quizzes.push(quiz);
  });

  return Object.values(groupedQuizzes);
}

export {
  LINE_CHANNEL_ID,
  LINE_CHANNEL_SECRET,
  OMISE_PUBLIC_KEY,
  OMISE_SECRET_KEY,
  PUBLIC_API,
  REDIRECT_URI,
  URL_API,
  getRoleUser,
  getToken,
  getUser,
  getUserId,
  groupQuizzesByUnit,
  setToken,
  setUser,
  getIsKruNut,
};
