import adminCourseService from "services/adminCourse.services";
import { Button, Col, Image, Row, Select, Table, Tabs } from "antd";
import { NO_IMAGE } from "assets/images";
import ActivateModal from "components/ActivateModal";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import { courseTypeOption } from "constants/options/courseTypeOption";
import { localeCustomTH } from "constants/thaiLocale/localeCustomTH";
import { errorToMessage } from "hooks/functions/errorToMessage";
import useModalHook from "hooks/useModalHook";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { IoIosInformationCircleOutline } from "react-icons/io";
import courseService from "services/course.services";
import CourseDetailsModal from "./CourseDetailsModal";

const AdminManageCourse = () => {
  const [detail, setDetail] = useState(null);
  const [keyTab, setKeyTab] = useState(1);
  const [courseType, setCourseType] = useState("");

  const {
    open: openCourseDetails,
    handleOpen: _handleOpenCourseDetails,
    handleClose: _handleCloseCourseDetails,
  } = useModalHook();

  const { mutate: approveCourse } = adminCourseService.useMutationApproveCourse(
    () => {
      AlertSuccess({});
      refetch();
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );

  const {
    data: dataCourse,
    isFetching: isLoadingCourse,
    refetch,
  } = adminCourseService.useQueryGetAllCourseForApprove({
    queryObj: {
      paging: {
        limit: 10000,
        start: 0,
      },
      isApprove: keyTab === 1,
      type: courseType,
    },
  });

  const { mutate: deleteCourseById } = courseService.useMutationDeleteCourse(
    () => {
      refetch();
      AlertSuccess({});
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );

  const renderContent = () => {
    return (
      <Row>
        <Table
          locale={localeCustomTH.Table}
          loading={isLoadingCourse}
          scroll={{ x: true }}
          className="w-100 text-nowrap"
          bordered
          dataSource={dataCourse?.data}
          columns={courseHeaderTable}
        />
      </Row>
    );
  };

  const courseHeaderTable = [
    {
      title: "ลำดับ",
      render: (_, { }, index) => index + 1,
    },
    {
      title: "ภาพหน้าปก",
      dataIndex: "coverImgUrl",
      key: "coverImgUrl",
      render: (_, { coverImgUrl }) => (
        <div style={{ minWidth: "80px" }} className="center">
          <Image
            style={{ borderRadius: "4px" }}
            width={60}
            height={60}
            src={coverImgUrl || NO_IMAGE}
            fallback={NO_IMAGE}
            alt="profile_img"
          />
        </div>
      ),
    },
    {
      title: "ประเภทคอร์ส",
      dataIndex: "type",
      render: (text) => <div style={{ minWidth: "100px" }}>{text}</div>,
    },
    {
      title: "ชื่อคอร์สเรียน",
      dataIndex: "title",
      key: "title",
      render: (text) => <div style={{ minWidth: "200px" }}>{text}</div>,
    },
    {
      title: "หมวดหมู่",
      dataIndex: "category",
      render: (text) => <div style={{ minWidth: "80px" }}>{text}</div>,
    },
    {
      title: "ราคาปกติ",
      dataIndex: "delPrice",
      render: (text) => (
        <div style={{ minWidth: "60px" }}>{numeral(text).format("0,0")}</div>
      ),
    },
    {
      title: "ราคาสุทธิ",
      dataIndex: "price",
      render: (text) => (
        <div style={{ minWidth: "60px" }}>{numeral(text).format("0,0")}</div>
      ),
    },
    {
      title: "รีวิว",
      dataIndex: "rating",
      render: (text) => (
        <div style={{ minWidth: "60px" }}>{numeral(text).format("0,0")}</div>
      ),
    },
    {
      title: "โปรไฟล์ผู้สอน",
      dataIndex: "teacherImg",
      key: "teacherImg",
      render: (_, { teacherImg }) => (
        <div style={{ minWidth: "100px" }} className="center">
          <Image
            style={{ borderRadius: "4px" }}
            width={60}
            height={60}
            src={teacherImg || NO_IMAGE}
            fallback={NO_IMAGE}
            alt="profile_img"
          />
        </div>
      ),
    },
    {
      title: "ชื่อครูผู้สอน",
      dataIndex: "teacherName",
      render: (text) => <div style={{ minWidth: "80px" }}>{text}</div>,
    },
    {
      title: "วันที่สร้างคอร์ส",
      dataIndex: "date",
      render: (text) => (
        <div style={{ minWidth: "100px" }}>
          {moment(text).format("DD-MM-YYYY")}
        </div>
      ),
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      fixed: "right",
      render: (_, record) => {
        const { id } = record;
        return (
          <>
            {keyTab === 1 ? (
              <div className="center gap-2">
                <Button
                  onClick={() => {
                    _handleOpenCourseDetails();
                    setDetail(record);
                  }}
                >
                  <IoIosInformationCircleOutline />
                </Button>
                <Button
                  className="center tw-gap-1"
                  danger
                  onClick={() => {
                    deleteCourseById({ courseId: id });
                  }}
                >
                  <AiOutlineDelete />
                  <span className="!tw-hidden lg:!tw-block">ลบ</span>
                </Button>
              </div>
            ) : (
              <div className="d-flex gap-2">
                <div className="center">
                  <Button
                    className="w-100"
                    type="primary"
                    onClick={() => {
                      AlertConfirm({
                        text: "ต้องการอนุมัติคอร์สเรียนหรือไม่ ?",
                        onOk: () => {
                          approveCourse({ id, approveStatus: true });
                        },
                      });
                    }}
                  >
                    อนุมัติ
                  </Button>
                </div>
                <div className="center">
                  <Button
                    className="w-100"
                    danger
                    onClick={() => {
                      AlertConfirm({
                        text: "ต้องการไม่อนุมัติคอร์สเรียนหรือไม่ ?",
                        onOk: () => {
                          approveCourse({ id, approveStatus: false });
                        },
                      });
                    }}
                  >
                    ไม่อนุมัติ
                  </Button>
                </div>
              </div>
            )}
          </>
        );
      },
    },
  ];

  const courseTabs = [
    {
      key: 1,
      label: `ออนไลน์ `,
      children: renderContent(),
    },
    {
      key: 2,
      label: `รออนุมัติ `,
      children: renderContent(),
    },
  ];

  useEffect(() => {
    refetch();
  }, [keyTab, courseType]);

  return (
    <>
      <ActivateModal />
      <Row className="mb-5">
        <Col>
          <p>ประเภทการเรียน</p>
          <Select
            style={{ width: "200px" }}
            value={courseType === "" ? "ทั้งหมด" : courseType}
            options={courseTypeOption}
            onChange={(e) => {
              setCourseType(e === "ทั้งหมด" ? "" : e);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Tabs
          className="w-100"
          type="card"
          defaultActiveKey="1"
          items={courseTabs}
          onChange={(key) => setKeyTab(key)}
        />
      </Row>
      <CourseDetailsModal
        data={detail}
        open={openCourseDetails}
        onCancel={() => {
          _handleCloseCourseDetails();
          setDetail(null);
        }}
      />
    </>
  );
};

export default AdminManageCourse;
