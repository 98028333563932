import { logo_tonemusic } from "../../assets";
import React from "react";

export default function Preloader() {
  return (
    <div id="preloader" className="tw-flex tw-justify-center tw-items-center">
      <img
        src={logo_tonemusic}
        alt="Tones Music"
        className="tr-preloader tw-h-16"
      />
    </div>
  );
}
