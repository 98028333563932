import {
  course_01,
  course_02,
  course_03,
  course_04,
} from "assets/images/course";

// image size : 500 x 430

export const courseList = [
  {
    id: 1,
    delPrice: 1900,
    price: 590,
    thumbUrl: course_01,
    rating: -1,
    date: "21 MAY 2021",
    title: "คอร์สเรียนร้องแบบลูกทุ่ง 10 ชั่วโมงเต็มพื้นฐานพร้อมเทคนิคต่างๆ",
    category: "ร้องเพลง",
    teacherImg:
      "https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2338&q=590",
    teacherName: "Leslie Alexander",
    comment: 400,
  },
  {
    id: 2,
    delPrice: 1900,
    price: 590,
    thumbUrl: course_02,
    rating: 0,
    date: "21 MAY 2021",
    title: "คอร์สเรียนร้องแบบลูกทุ่ง 10 ชั่วโมงเต็มพื้นฐานพร้อมเทคนิคต่างๆ",
    category: "ร้องเพลง",
    teacherImg:
      "https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2338&q=590",
    teacherName: "Leslie Alexander",
    comment: 400,
  },
  {
    id: 3,
    delPrice: 1900,
    price: 590,
    thumbUrl: course_03,
    rating: 1,
    date: "21 MAY 2021",
    title: "คอร์สเรียนร้องแบบลูกทุ่ง 10 ชั่วโมงเต็มพื้นฐานพร้อมเทคนิคต่างๆ",
    category: "ร้องเพลง",
    teacherImg:
      "https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2338&q=590",
    teacherName: "Leslie Alexander",
    comment: 400,
  },
  {
    id: 4,
    delPrice: 1900,
    price: 590,
    thumbUrl: course_04,
    rating: 3,
    date: "21 MAY 2021",
    title: "คอร์สเรียนร้องแบบลูกทุ่ง 10 ชั่วโมงเต็มพื้นฐานพร้อมเทคนิคต่างๆ",
    category: "ร้องเพลง",
    teacherImg:
      "https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2338&q=590",
    teacherName: "Leslie Alexander",
    comment: 400,
  },
  {
    id: 5,
    delPrice: 1900,
    price: 590,
    thumbUrl: course_01,
    rating: 5,
    date: "21 MAY 2021",
    title: "คอร์สเรียนร้องแบบลูกทุ่ง 10 ชั่วโมงเต็มพื้นฐานพร้อมเทคนิคต่างๆ",
    category: "ร้องเพลง",
    teacherImg:
      "https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2338&q=590",
    teacherName: "Leslie Alexander",
    comment: 400,
  },
  {
    id: 6,
    delPrice: 1900,
    price: 590,
    thumbUrl: course_02,
    rating: 6,
    date: "21 MAY 2021",
    title: "คอร์สเรียนร้องแบบลูกทุ่ง 10 ชั่วโมงเต็มพื้นฐานพร้อมเทคนิคต่างๆ",
    category: "ร้องเพลง",
    teacherImg:
      "https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2338&q=590",
    teacherName: "Leslie Alexander",
    comment: 400,
  },
  {
    id: 7,
    delPrice: 1900,
    price: 590,
    thumbUrl: course_03,
    rating: 4,
    date: "21 MAY 2021",
    title: "คอร์สเรียนร้องแบบลูกทุ่ง 10 ชั่วโมงเต็มพื้นฐานพร้อมเทคนิคต่างๆ",
    category: "ร้องเพลง",
    teacherImg:
      "https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2338&q=590",
    teacherName: "Leslie Alexander",
    comment: 400,
  },
  {
    id: 8,
    delPrice: 1900,
    price: 590,
    thumbUrl: course_04,
    rating: 2,
    date: "21 MAY 2021",
    title: "คอร์สเรียนร้องแบบลูกทุ่ง 10 ชั่วโมงเต็มพื้นฐานพร้อมเทคนิคต่างๆ",
    category: "ร้องเพลง",
    teacherImg:
      "https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2338&q=590",
    teacherName: "Leslie Alexander",
    comment: 400,
  },
  {
    id: 9,
    delPrice: 1900,
    price: 590,
    thumbUrl: course_01,
    rating: 2,
    date: "21 MAY 2021",
    title: "คอร์สเรียนร้องแบบลูกทุ่ง 10 ชั่วโมงเต็มพื้นฐานพร้อมเทคนิคต่างๆ",
    category: "ร้องเพลง",
    teacherImg:
      "https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2338&q=590",
    teacherName: "Leslie Alexander",
    comment: 400,
  },
  {
    id: 10,
    delPrice: 1900,
    price: 590,
    thumbUrl: course_02,
    rating: 2,
    date: "21 MAY 2021",
    title: "คอร์สเรียนร้องแบบลูกทุ่ง 10 ชั่วโมงเต็มพื้นฐานพร้อมเทคนิคต่างๆ",
    category: "ร้องเพลง",
    teacherImg:
      "https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2338&q=590",
    teacherName: "Leslie Alexander",
    comment: 400,
  },
  {
    id: 11,
    delPrice: 1900,
    price: 590,
    thumbUrl: course_03,
    rating: 2,
    date: "21 MAY 2021",
    title: "คอร์สเรียนร้องแบบลูกทุ่ง 10 ชั่วโมงเต็มพื้นฐานพร้อมเทคนิคต่างๆ",
    category: "ร้องเพลง",
    teacherImg:
      "https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2338&q=590",
    teacherName: "Leslie Alexander",
    comment: 400,
  },
  {
    id: 12,
    delPrice: 1900,
    price: 590,
    thumbUrl: course_04,
    rating: 2,
    date: "21 MAY 2021",
    title: "คอร์สเรียนร้องแบบลูกทุ่ง 10 ชั่วโมงเต็มพื้นฐานพร้อมเทคนิคต่างๆ",
    category: "ร้องเพลง",
    teacherImg:
      "https://images.unsplash.com/photo-1500048993953-d23a436266cf?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2338&q=590",
    teacherName: "Leslie Alexander",
    comment: 400,
  },
];
