import { Button, Card, Col, Divider, Empty, Row, Spin } from "antd";
import { COLOR } from "constants/color";
import { courseTypeToText } from "hooks/functions/courseTypeToText";
import useModalHook from "hooks/useModalHook";
import moment from "moment";
import numeral from "numeral";
import { useState } from "react";
import { LiaEditSolid } from "react-icons/lia";
import { useNavigate, useParams } from "react-router-dom";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";
import courseService from "services/course.services";
import SessionFormModal from "../workshop-modal/SessionFormModal";
import { IoIosArrowBack, IoMdInformationCircleOutline } from "react-icons/io";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { LuUser2 } from "react-icons/lu";
import dayjs from "dayjs";
import workshopBackofficeServices from "services/backoffice/workshopBackoffice.services";

const SessionGroup = () => {
	const navigate = useNavigate();
	const [editSession, setEditSession] = useState(null);
	const { workshopId } = useParams();

	const {
		data: dataCourse
	} = courseService.useQueryGetCourseFlagById({ id: workshopId });

	const {
		data: sessions,
		isFetching: isLoadingSessionGroup,
		refetch: refetchSessionGroup,
	} = workshopBackofficeServices.useQueryGetAllSessionGroupByWorkshopId({ workshopId });

	const {
		open: openSessionFormModal,
		handleOpen: _handleSessionFormModalOpen,
		handleClose: _handleSessionFormModalClose,
	} = useModalHook();

	return (
		<>
			<Row className="mb-4">
				<Button
					type="link"
					className="center"
					onClick={() => {
						navigate(`/teacher/workshop`);
					}}
				>
					<IoIosArrowBack size={22} className="me-2" />
					<p>ย้อนกลับ</p>
				</Button>
			</Row>
			{isLoadingSessionGroup ? (
				<Spin />
			) : (
				<>
					<Row className="d-flex justify-content-between">
						<div>
							<h4>
								{`${sessions?.courseName} ( ${numeral(sessions?.total).format("0,0")} )`}
							</h4>
							<p>{courseTypeToText("workshop")}</p>
						</div>
						<div className="d-flex gap-2">
							<Button
								type="primary"
								onClick={() => {
									setEditSession(null);
									_handleSessionFormModalOpen();
								}}
							>
								+ เพิ่มรอบกิจกรรม
							</Button>
						</div>
					</Row>
					<Divider />
					<Row>
						{sessions?.data ? (
							<Col span={24}>
								{sessions?.data?.map((n, i) => {
									const isNotAllow = dayjs(n.startTime).isBefore(dayjs(new Date()).add(5, 'day'))
									return (
										<Card
											key={i}
											title={`รอบวันที่ ${moment(n?.startTime).format(
												"DD/MM/YYYY"
											)} - ${moment(n?.finishTime).format(
												"DD/MM/YYYY"
											)}`}
											extra={
												<a
													className="text-primary center"
													onClick={() => {
														setEditSession({ ...n })
														_handleSessionFormModalOpen();
													}}
												>
													{isNotAllow ? (
														<div className="d-flex align-items-center gap-1">
															<IoMdInformationCircleOutline size={18} />
															ดูรายละเอียด
														</div>
													) : (
														<div className="d-flex align-items-center gap-1">
															<LiaEditSolid size={18} />
															แก้ไข
														</div>
													)}
												</a>
											}
											className="mb-3 course-list"
											style={{
												width: "100%",
											}}
										>
											<Row>
												<Col span={13}>
													<div className="d-flex align-items-center mt-1">
														<a href={`https://www.google.com/maps?q=${n?.position?.lat},${n?.position?.lon}`} target="_blank" className="d-flex align-items-center">
															<HiOutlineLocationMarker
																color={COLOR.primary}
																className="me-2"
															/>
															<p className="text-primary">
																{n?.locationName || "Location Name"}
															</p>
														</a>
													</div>
													<div className="d-flex align-items-center">
														<LuUser2 className="me-2" />
														<p className="my-1">{`จำนวนที่เปิดรับ ${numeral(
															n?.limitPerGroup
														).format("0,0")} คน`}</p>
													</div>
												</Col>
											</Row>
										</Card>
									);
								})}
							</Col>
						) : (
							<div className="w-100 center p-4">
								<Empty description={EMPTY_DESCRIPTION} />
							</div>
						)}
					</Row>
				</>
			)}
			<SessionFormModal
				isEditable={!editSession || (editSession && dayjs(editSession.startTime).isAfter(dayjs(new Date()).add(5, 'day')))}
				data={editSession}
				total={sessions?.total}
				open={openSessionFormModal}
				onOk={_handleSessionFormModalClose}
				onCancel={_handleSessionFormModalClose}
				refetch={refetchSessionGroup}
			/>
		</>
	);
};

export default SessionGroup;
