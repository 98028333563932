import { Col, Input, Pagination, Row, Spin } from "antd";
import Breadcrumb from "components/elements/Breadcrumb";
import SelectUniversity from "components/elements/SelectUniversity";
import LayoutContent from "components/LayoutContent";
import TutorCard from "components/TutorCard";
import { useEffect, useState } from "react";
import teacherServices from "services/teacher.services";
const { Search } = Input;

const TeachersPage = () => {
  const limitPage = 30;
  const [page, setPage] = useState(1);
  const [filterUniversity, setFilterUniversity] = useState("ทั้งหมด");
  const [filterSearch, setFilterSearch] = useState(null);
  const breadcrumb_item = [
    {
      name: "หน้าแรก",
      link: "/",
    },
    {
      name: "ครูผู้สอน",
    },
  ];

  const {
    data: dataTeacher,
    isFetching: isLoadingTeacher,
    refetch,
  } = teacherServices.useQueryGetAllTeacher({
    queryObj: {
      paging: {
        limit: limitPage,
        start: page * limitPage - limitPage,
      },
      search: filterSearch,
      university: filterUniversity,
    },
  });

  const onSearch = (value) => {
    setFilterSearch(value);
  };
  const handleChange = (value) => {
    setFilterUniversity(value);
  };

  useEffect(() => {
    refetch();
  }, [filterSearch, filterUniversity, page]);

  return (
    <>
      <Breadcrumb items={breadcrumb_item} />
      <LayoutContent>
        <Row className="mb-5">
          <div className="tw-w-full tw-flex tw-flex-col sm:tw-flex-row tw-gap-2">
            <Search
              className="tw-w-full sm:tw-w-1/2 md:tw-w-[240px]"
              size="large"
              allowClear
              placeholder="ค้นหาจากชื่อครูผู้สอน..."
              onSearch={onSearch}
            />
            <SelectUniversity
              className="tw-w-full sm:tw-w-1/2 md:tw-w-[240px]"
              withAll
              onChange={handleChange}
            />
          </div>
        </Row>

        {isLoadingTeacher ? (
          <Spin className="m-4" />
        ) : (
          [...(dataTeacher?.data || [])].map((data, index) => {
            return (
              <div key={index} className="col-md-4">
                <TutorCard
                  key={index}
                  id={data?.id}
                  thumbUrl={data?.thumbUrl}
                  name={data?.name}
                  course={data?.course}
                  university={data?.university}
                />
              </div>
            );
          })
        )}
        <Row className="center">
          <Pagination
            size="large"
            current={page}
            pageSize={limitPage}
            total={dataTeacher?.total}
            onChange={(page) => {
              setPage(page);
            }}
          />
        </Row>
      </LayoutContent>
    </>
  );
};

export default TeachersPage;
