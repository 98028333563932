import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  loading: false,
  select_promotion: {},
  value_promotion: 0,
  total_discount: 0,
};

const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    PROMOTION_LOADING: (state, action) => {
      state.loading = action.payload;
    },
    SELECT_PROMOTION: (state, action) => {
      state.select_promotion = action.payload || {};
    },
    REMOVE_PROMOTION: (state, action) => {
      let id = action.payload.id;
      state.select_promotion = {};
    },
    CLEAR_PROMOTION: (state) => {
      state = initialState;
    },
  },
});

export const {
  PROMOTION_LOADING,
  SELECT_PROMOTION,
  REMOVE_PROMOTION,
  CLEAR_PROMOTION,
} = promotionSlice.actions;
export default promotionSlice.reducer;
