import { Button, Col, Input, Rate, Row } from "antd";
import { useState } from "react";
import { AlertWarning } from "./alert/Alert";

const { TextArea } = Input;
const MIN_RATING = 1;
const WritingReview = ({ onFinish = () => {} }) => {
  const [dataReviews, setDataReviews] = useState([]);
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState(null);

  const handleSubmit = () => {
    if (!review) {
      return AlertWarning({ text: "กรุณาเขียนรีวิว" });
    }
    if (rating < MIN_RATING) {
      return AlertWarning({ text: `สามารถวิวได้ต่ำสุด ${MIN_RATING} ดาว` });
    }
    const data = {
      rating,
      review,
    };
    setDataReviews(data);
    setRating(0);
    setReview(null);
    onFinish(data);
  };
  return (
    <Row>
      <Col span={24}>
        <p className="mb-3">เขียนรีวิว</p>
        <Rate className="mb-3" value={rating} onChange={(e) => setRating(e)} />
        <TextArea
          value={review}
          className="w-100 mb-3"
          rows={4}
          placeholder="เขียนรีวิว"
          onChange={(e) => setReview(e.target.value)}
        />
        <Row className="flex justify-content-end">
          <Button
            type="primary"
            size="large"
            className="px-5"
            onClick={handleSubmit}
          >
            ส่ง
          </Button>
        </Row>
      </Col>
    </Row>
  );
};

export default WritingReview;
