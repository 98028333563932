const LayoutContent = ({ children }) => {
  return (
    <div className="sg-page-content">
      <div className="sg-section">
        <div className="section-content sg-filter-content grid-view-tab section-padding">
          <div className="container">
            <div className="row">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutContent;
