export const COLOR = {
  // primary: "#ab8d4a",
  primary: "#506e83",
  light_primary: "#abcae1",
  dark_primary: "#214c6a",
  secondary: "#AE8A37",
  light_secondary: "#CDB57F",
  dark_secondary: "#887240",
  gold: "#daa222",

  green_primary: "#52c41a",
  yellow_primary: "#faad14",
  red_primary: "#ff4d4f",
};
