import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Upload,
} from "antd";
import { AlertError, AlertSuccess } from "components/alert/Alert";
import { dummyRequest } from "hooks/dummyRequest";
import { courseTypeToText } from "hooks/functions/courseTypeToText";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { onUploadFile } from "hooks/onUploadFile";
import useUploadFileHook from "hooks/useUploadFileHook";
import _ from "lodash";
import { useEffect, useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { useParams } from "react-router-dom";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
const { TextArea } = Input;

const CourseOnlineModal = ({
  isEditable,
  data,
  total = 0,
  open,
  onCancel,
  refetch,
  index
}) => {
  const { courseId } = useParams();
  const [form] = Form.useForm();
  const [fieldsChange, setFieldsChange] = useState({});
  const [previewVideo, setPreviewVideo] = useState(null);
  const [videoDuration, setVideoDuration] = useState(0);

  const {
    fileList,
    setFileList,
    previewImage,
    previewOpen,
    handlePreview: _handlePreviewUpload,
    handleChange: _handleChangeUpload,
    handleCancel: _handleCancelUpload,
  } = useUploadFileHook();

  const { mutate: createUnit } = courseBackOfficeServices.useMutationCreateUnit(
    () => {
      refetch();
      onCancel();
      setPreviewVideo(null);
      setVideoDuration(0);
      AlertSuccess({
        title: "สร้างบทเรียนสำเร็จ!",
        text: "รอแอดมินตรวจสอบเพื่ออนุมัติคอร์สเรียนและบทเรียน หากมีการอนุมัติแล้วท่านจะได้รับอีเมลแจ้งเตือน",
      });
      form.resetFields();
      form.setFieldsValue({ homeworkAnswerType: "message" });
      setFileList([]);
    },
    (err) => {
      setPreviewVideo(null);
      setVideoDuration(0);
      onCancel();
      setFileList([]);
      form.resetFields();
      AlertError({ text: errorToMessage(err) });
    }
  );

  const { mutate: updateUnit } = courseBackOfficeServices.useMutationUpdateUnit(
    () => {
      refetch();
      onCancel();
      setPreviewVideo(null);
      setVideoDuration(0);
      AlertSuccess({ text: "อัปเดตบทเรียนสำเร็จ!" });
      setFileList([]);
    },
    (err) => {
      setPreviewVideo(null);
      setVideoDuration(0);
      onCancel();
      setFileList([]);
      form.resetFields();
      AlertError({ text: errorToMessage(err) });
    }
  );

  const onFinish = async (values) => {
    let prefix = `course/${courseId}/video`;
    let videoName =
      _.size(fileList) > 0
        ? await onUploadFile({ prefix, fileList, showProgress: true })
        : data?.videoUrl;
    if (data?.id) {
      //update unit
      let _update = {
        ...data,
        ...values,
        videoTime: 0,
        videoUrl: videoName,
        prefixPath: _.size(fileList) > 0 ? prefix : null,
      };
      updateUnit({ values: _update });
    } else {
      //create unit
      let _create = {
        courseId,
        videoUrl: videoName,
        prefixPath: prefix,
        videoTime: videoDuration,
        ...values,
      };
      if (videoName) {
        createUnit({ values: _create });
      }
    }
  };

  useEffect(() => {
    setFileList([]);
    setPreviewVideo(null);
    if (data) {
      let fields = {
        ...data,
        type: courseTypeToText(data?.type),
        isHomework: data?.homework ? true : false,
      };
      form.setFieldsValue(fields);
      setFieldsChange(fields);
      setPreviewVideo({
        file: { name: data?.unitName || "-" },
        url: data?.videoUrl,
      });
    } else {
      if (form.__INTERNAL__.name) {
        form.resetFields();
      }
      setFieldsChange(null);
    }
  }, [data, form]);

  return (
    <Modal
      title={
        isEditable
          ? data
            ? "แก้ไขบทเรียน"
            : "เพิ่มบทเรียนออนไลน์"
          : "รายละเอียดบทเรียน"
      }
      open={open}
      okButtonProps={{ disabled: !isEditable }}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        if (!data) {
          form.resetFields();
          setFileList([]);
          setPreviewVideo(null);
        }
        onCancel();
      }}
      okText="บันทึก"
      cancelText="ยกเลิก"
    >
      <Form
        form={form}
        name="unit-teacher-form"
        className="mt-4"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        {data?.id ? (
          <h5>{`บทที่ ${index}`}</h5>
        ) : (
          <h5>{`บทที่ ${total + 1}`}</h5>
        )}

        <Row>
          <Col span={24}>
            {isEditable ? <p className="mb-2">อัปโหลดวิดีโอการสอน</p> : null}
            <Form.Item
              name="videoUpload"
              rules={[
                {
                  required: previewVideo ? false : true,
                  message: "กรุณาอัปโหลดวิดีโอ",
                },
              ]}
            >
              <div>
                {previewVideo ? (
                  <div>
                    {previewVideo && (
                      <div>
                        <video
                          controls
                          style={{ width: "100%", borderRadius: "4px" }}
                        >
                          <source src={previewVideo.url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <Row>
                          <Card className="w-100">
                            <div className="d-flex justify-content-between align-items-center">
                              <p
                                style={{
                                  width: "max-content",
                                  maxWidth: "320px",
                                }}
                              >
                                ชื่อไฟล์วิดีโอ : {previewVideo.file.name}
                              </p>
                              {isEditable ? (
                                <Button
                                  danger
                                  onClick={() => {
                                    setPreviewVideo(null);
                                    setFileList([]);
                                  }}
                                >
                                  ลบวิดีโอ
                                </Button>
                              ) : null}
                            </div>
                          </Card>
                        </Row>
                      </div>
                    )}
                  </div>
                ) : (
                  <Upload
                    fileList={fileList}
                    customRequest={dummyRequest}
                    accept="video/*"
                    listType="picture-card"
                    maxCount={1}
                    beforeUpload={(file) => {
                      let videoUrl = URL.createObjectURL(file);
                      setPreviewVideo({ file, url: videoUrl });

                      return new Promise((resolve) => {
                        const video = document.createElement("video");
                        video.preload = "metadata";
                        video.onloadedmetadata = () => {
                          window.URL.revokeObjectURL(video.src);
                          setVideoDuration(video.duration);
                          resolve(file);
                        };
                        video.src = URL.createObjectURL(file);
                      });
                    }}
                    onPreview={_handlePreviewUpload}
                    onChange={_handleChangeUpload}
                    disabled={!isEditable}
                  >
                    {isEditable ? (
                      <div>
                        <MdOutlineFileUpload size={20} className="mb-2" />
                        <p> อัปโหลดวิดีโอ</p>
                      </div>
                    ) : null}
                  </Upload>
                )}
              </div>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="ชื่อบทเรียน"
          name="unitName"
          rules={[
            {
              required: true,
              message: "กรุณาระบุชื่อบทเรียน!",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="ชื่อบทเรียน"
            readOnly={!isEditable}
          />
        </Form.Item>
        <Form.Item
          label="รายละเอียดบทเรียน"
          name="description"
          rules={[
            {
              required: true,
              message: "กรุณาระบุรายละเอียดบทเรียน!",
            },
          ]}
        >
          <TextArea
            rows={4}
            size="large"
            placeholder="รายละเอียดบทเรียน"
            readOnly={!isEditable}
          />
        </Form.Item>
        <Form.Item valuePropName="checked" name="isChallenge">
          <Checkbox size="large" disabled={!isEditable}>
            เพิ่มเป็นบทเรียนชาเลนจ์
          </Checkbox>
        </Form.Item>
        <Form.Item
          shouldUpdate
          valuePropName="checked"
          name="isHomework"
          onChange={(e) => {
            setFieldsChange({
              ...fieldsChange,
              isHomework: e.target.checked,
            });
          }}
        >
          <Checkbox size="large" disabled={!isEditable}>
            การบ้าน
          </Checkbox>
        </Form.Item>
        {fieldsChange?.isHomework && (
          <>
            <Form.Item
              label="การบ้าน"
              name="homework"
              rules={[
                {
                  required: fieldsChange?.isHomework,
                  message: "กรุณาระบุการบ้าน!",
                },
              ]}
            >
              <TextArea
                rows={4}
                size="large"
                placeholder="การบ้าน"
                readOnly={!isEditable}
              />
            </Form.Item>

            <Form.Item
              initialValue="message"
              label="ประเภทการตอบการบ้าน"
              name="homeworkAnswerType"
            >
              <Radio.Group disabled={!isEditable}>
                <Radio value="message">ข้อความ</Radio>
                <Radio value="file">แนบไฟล์ (รูปภาพ / วิดีโอ)</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}
      </Form>
      {/* {data?.id && (
        <Button
          block
          danger
          className="mb-4"
          onClick={() => {
            AlertConfirm({
              text: "ต้องการลบบทเรียนนี้หรือไม่ ?",
              onOk: () => {},
            });
          }}
        >
          ลบบทเรียน
        </Button>
      )} */}
    </Modal>
  );
};

export default CourseOnlineModal;
