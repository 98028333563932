import { Button, Col, Empty, Row, Spin, Tabs } from "antd";

import { NO_IMAGE } from "assets/images";
import Breadcrumb from "components/elements/Breadcrumb";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Review from "./Review";
import courseService from "services/course.services";
import workshopBackofficeServices from "services/backoffice/workshopBackoffice.services";
import moment from "moment";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";
import CertificationPanel from "pages/back-office/student/course/course-detail/CertificationPanel";

export const UserWorkshopDetail = () => {
  const navigate = useNavigate();
  const { workshopId } = useParams();
  const course = useSelector((state) => state.course);
  const currentUnit = course.currentUnitData
  const courseDetail = course?.courseDetail
  const [paid, setPaid] = useState(null);

  const [tab, setTab] = useState("desc");

  let breadcrumb_item = [
    {
      name: "หน้าแรก",
      link: "/",
    },
    {
      name: `เวิร์กชอป`,
    }
  ];

  const { mutate: checkPaid, isLoading: isLoadingPaidCourse } =
    courseService.useMutationCheckPaid((res) => {
      setPaid(res?.data);
    });

  const { data: dataWorkshop, isFetching: isLoadingCourse } = workshopBackofficeServices.useQueryGetWorkshopById({ id: workshopId });

  const { data: dataSessionGroup, refetch: refetchSessionGroup, isFetching: isLoadingSessionGroup } = workshopBackofficeServices.useQueryGetAllMySessionGroupByWorkshopId({ workshopId, isExpired: tab === "expired" });

  const renderNotFound = () => {
    return (
      <div className="sg-section">
        <div className="section-content course-details bg-white section-padding">
          <div style={{ minHeight: "500px" }} className="container center">
            <p>ไม่พบข้อมูล</p>
          </div>
        </div>
      </div>
    );
  };

  const renderNotPaid = () => {
    return (
      <div className="sg-section">
        <div className="section-content course-details bg-white section-padding">
          <div style={{ minHeight: "500px" }} className="container center">
            <Row>
              <Col span={24}>
                <p>ไม่สามารถเข้าถึงเวิร์กชอปนี้ได้ กรุณาซื้อเวิร์กชอป</p>
                <Row className="center mt-4">
                  <Button
                    type="primary"
                    onClick={() => {
                      const { id } = dataWorkshop;
                      navigate(`/courses/${id}`);
                    }}
                  >
                    เข้าชมเวิร์กชอปนี้
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  };

  const workshopInfo = () => {
    return <>
      <h4>รายละเอียดเวิร์กชอป</h4>

      <p>หมวดหมู่ : {dataWorkshop?.category}</p>
      <p>รายละเอียด : {dataWorkshop?.description}</p>

      {dataWorkshop?.courseObjective?.length > 0 && (
        <div className="requirements">
          <h4>วัตถุประสงค์</h4>
          <ul className="global-list">
            {dataWorkshop?.courseObjective?.map((n, i) => (
              <li key={`course-objective-${i}`}>{n?.description}</li>
            ))}
          </ul>
        </div>
      )}

      {dataWorkshop?.courseEarn?.length > 0 && (
        <div className="you-learn">
          <h4>สิ่งที่คุณจะได้รับในเวิร์กชอปนี้</h4>
          <ul className="global-list">
            {dataWorkshop?.courseEarn?.map((n, i) => (
              <li key={`course-earn-${i}`}>{n?.description}</li>
            ))}
          </ul>
        </div>
      )}

      <p>{dataWorkshop?.description}</p>

    </>
  }

  const sessionGroupInfo = () => {
    return <>
      {
        isLoadingSessionGroup ? (
          <Row style={{ minHeight: "200px" }} className="center">
            <Spin />
          </Row>
        ) :
          <>
            {
              dataSessionGroup?.data?.map((entry, index) => (
                <a
                  key={`dataSessionGroup-${index}`}
                  href={`https://www.google.com/maps?q=${entry.position.lat},${entry.position.lon}`}
                  target="_blank">
                  <Button
                    type={`default`}
                    style={{
                      borderRadius: "0px",
                      height: "auto",
                      padding: "8px 12px",
                    }}
                    className="w-100 mb-2 center"
                  >
                    <div
                      className="w-100 d-flex align-items-center justify-content-between"
                      style={{
                        textAlign: "start",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "break-word",
                        paddingRight: "8px",
                      }}
                    >
                      {`รอบ ${moment(entry.startTime).format("DD/MM/yyyy HH:MM")} - ${moment(entry.finishTime).format("DD/MM/yyyy HH:MM")} ที่ ${entry.locationName}`}
                    </div>
                  </Button>
                </a>
              )
              ) ||
              <div className="w-100 center p-4">
                <Empty description={EMPTY_DESCRIPTION} />
              </div>
            }
          </>
      }

    </>
  }

  const tabWorkshop = [
    {
      key: "desc",
      label: `รายละเอียดเวิร์กชอป`,
      children: workshopInfo(),
    },
    {
      key: "upcoming",
      label: `กิจกรรมที่กำลังจะมาถึง`,
      children: sessionGroupInfo(),
    },
    {
      key: "expired",
      label: `กิจกรรมที่เสร็จสิ้นแล้ว`,
      children: sessionGroupInfo(),
    },
    {
      key: "review",
      label: `รีวิวของฉัน`,
      children: <Review currentUnit={currentUnit} />,
      disabled: true
    },
  ];

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    checkPaid({ id: workshopId });
  }, [workshopId]);

  useEffect(() => {
    refetchSessionGroup()
  }, [tab])

  return (
    <>
      {isLoadingCourse ? (
        <Row style={{ minHeight: "500px" }} className="center">
          <Spin />
        </Row>
      ) : dataWorkshop ? (
        isLoadingPaidCourse ? (
          <Row style={{ minHeight: "500px" }} className="center">
            <Spin />
          </Row>
        ) : paid ? (
          <>
            <Breadcrumb items={breadcrumb_item} />
            <div className="sg-section">
              <div className="section-content course-details bg-white section-padding">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-8">
                      <div className="sa-course">
                        <div
                          style={{
                            maxWidth: "900px",
                            maxHeight: "500px",
                            overflow: "hidden",
                            backgroundColor: "#eeeeee",
                          }}
                          className="course-thumb center"
                        >
                          <img
                            width={"100%"}
                            height={"500px"}
                            src={dataWorkshop?.coverImgUrl || NO_IMAGE}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = NO_IMAGE;
                            }}
                            alt="NOT FOUND"
                          />
                        </div>
                        <div className="course-info mt-4">
                          <h2 className="title" style={{ marginBottom: "5px" }}>
                            {dataWorkshop?.title}
                          </h2>
                          <div className="d-flex align-items-center gap-2">
                            <div
                              style={{ width: "30px", height: "30px" }}
                              className="d-flex justify-content-center align-items-center overflow-hidden rounded-circle"
                            >
                              <img
                                className="w-100 h-100 object-fit-cover"
                                src={dataWorkshop?.teacherImg || NO_IMAGE}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = NO_IMAGE;
                                }}
                                alt="i"
                              />
                            </div>
                            <p className="my-1">{`ครูผู้สอน : ${dataWorkshop?.teacherName || "-"
                              }`}</p>
                          </div>
                          <Tabs
                            size="large"
                            defaultActiveKey={tab}
                            items={tabWorkshop}
                            onChange={(key) => setTab(key)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="b-shadow sidebar course-sidebar">
                        <div className="widget-area">
                          <CertificationPanel
                            disabled={!courseDetail?.isFinish}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          renderNotPaid()
        )
      ) : (
        renderNotFound()
      )}
    </>
  );
};
