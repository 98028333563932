import { Button, Card, Col, Empty, Row, Spin } from "antd";
import { COLOR } from "constants/color";
import dayjs from "dayjs";
import useModalHook from "hooks/useModalHook";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { LiaEditSolid } from "react-icons/lia";
import { MdAdd, MdOutlineAccessTime } from "react-icons/md";
import { TbAward } from "react-icons/tb";
import teacherBackOfficeService from "services/backoffice/teacherBackoffice.services";
import AwardFormModal from "./AwardFormModal";
import AwardPreviewModal from "./AwardPreviewModal";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";
import { IoEyeOutline } from "react-icons/io5";

const TeacherAward = () => {
  const [editAward, setEditAward] = useState(null);
  const [teacherAwardFiles, setTeacherAwardFiles] = useState(null);

  const {
    open: openCreateAward,
    handleOpen: _handleOpenCreateAward,
    handleClose: _handleCloseCreateAward,
  } = useModalHook();

  const {
    open: openPreviewImage,
    handleOpen: _handleOpenPreviewImage,
    handleClose: _handleClosePreviewImage,
  } = useModalHook();

  const {
    data: dataAward,
    isFetching: isLoadingAward,
    refetch: refetchAward,
  } = teacherBackOfficeService.useQueryGetTeacherAwardByTeacherId({});

  useEffect(() => {
    window.scrollTo(0, 440);
  }, []);

  return (
    <>
      <Row className="my-4 justify-content-between align-items-center">
        <h4 className="m-0 p-0 tw-text-[16px] lg:tw-text-[24px]">{`รางวัล / ผลงานที่ได้รับ ( ${_.size(
          dataAward
        )} )`}</h4>
        <Button
          type="primary"
          className="center"
          onClick={() => {
            setEditAward(null);
            _handleOpenCreateAward();
          }}
        >
          <MdAdd className="me-2" />
          เพิ่มผลงาน
        </Button>
      </Row>

      {isLoadingAward ? (
        <Spin className="m-2" />
      ) : (
        <Row>
          <Col span={24}>
            {_.size(dataAward) > 0 ? (
              dataAward?.map((n, index) => {
                return (
                  <Card
                    key={`item-award-${index}`}
                    title={n?.detail}
                    extra={
                      <a
                        className="text-primary center"
                        onClick={() => {
                          setEditAward({
                            ...n,
                            startDate: n?.startDate
                              ? dayjs(n?.startDate)
                              : null,
                            endDate: n?.endDate ? dayjs(n?.endDate) : null,
                            tempFileList:
                              n?.teacherAwardFiles?.map((file) => ({
                                uid: file?.id,
                                status: "done",
                                url: file?.fileUrl,
                              })) || [],
                          });
                          _handleOpenCreateAward();
                        }}
                      >
                        <LiaEditSolid size={18} className="me-1" />
                        แก้ไข
                      </a>
                    }
                    className="mb-3 course-list"
                    style={{
                      width: "100%",
                    }}
                  >
                    <Row>
                      <Col xs={24} lg={16}>
                        <p className="limited-text">{n?.detail || "-"}</p>
                        <div className="d-flex align-items-center my-2">
                          <TbAward
                            color={COLOR.primary}
                            size={20}
                            className="me-2"
                          />
                          <p className="text-primary me-4">{`ประเภทรางวัล : ${n?.type}`}</p>
                        </div>

                        <div className="d-flex align-items-center">
                          <MdOutlineAccessTime className="me-2" />
                          <p>{`วันที่เพิ่มผลงาน : ${moment().format(
                            "DD/MM/YYYY"
                          )}`}</p>
                        </div>
                      </Col>
                      <Col
                        xs={24}
                        lg={8}
                        className="d-flex justify-content-end align-items-end"
                      >
                        <Button
                          block
                          className="mt-2"
                          onClick={() => {
                            setTeacherAwardFiles(n?.teacherAwardFiles);
                            _handleOpenPreviewImage();
                          }}
                        >
                          <IoEyeOutline className="me-1" />
                          <span>แสดงภาพประกอบ</span>
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                );
              })
            ) : (
              <Row className="center my-5">
                <Empty description={EMPTY_DESCRIPTION} />
              </Row>
            )}
          </Col>
        </Row>
      )}

      <AwardFormModal
        data={editAward}
        open={openCreateAward}
        onCancel={_handleCloseCreateAward}
        refetch={refetchAward}
      />

      <AwardPreviewModal
        data={teacherAwardFiles}
        open={openPreviewImage}
        onCancel={_handleClosePreviewImage}
      />
    </>
  );
};

export default TeacherAward;
