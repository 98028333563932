import { NO_IMAGE } from "assets/images";
import React, { useEffect } from "react";
import { Link } from "react-router-dom/dist";

const TutorCard = ({
  id = 0,
  thumbUrl = NO_IMAGE,
  name = "tutor name",
  course = 0,
  university = "University",
}) => {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="sa-tutor">
      <div className="tutor-thumb">
        <Link to={`/teachers/${id}`}>
          <div style={{ height: "260px" }} className="center">
            <img
              style={{ objectFit: "cover", height: "100%", width: "100%" }}
              src={thumbUrl || NO_IMAGE}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = NO_IMAGE;
              }}
              alt="teacher"
              className="img-fluid"
            />
          </div>
          {/* <img src={thumbUrl || ""} alt="Image" className="img-fluid" /> */}
        </Link>
      </div>
      <div className="tutor-info">
        <h2>
          <Link to={`/teachers/${id}`}>{name || "Teacher Name"}</Link>
        </h2>
        <span>คอร์สที่เปิดสอน {course || 0} คอร์ส</span>
        <span className="text-primary">{university || "University"}</span>
      </div>
      {/* <div className="tutor-social">
        <ul className="global-list">
          <li>
            <a href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fab fa-twitter"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="far fa-envelope"></i>
            </a>
          </li>
          <li>
            <a href="#">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default TutorCard;
