import PopularCourseCard from "components/PopularCourseCard";
import { useEffect } from "react";
import courseService from "services/course.services";

const PopularCourses = () => {
  const { data: dataPopular } = courseService.useQueryGetPopularCourse({});

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);

  return (
    <div className="widget widget_recent_entries">
      <h3 className="widget_title">คอร์สเรียน ยอดนิยม</h3>
      <ul>
        {dataPopular?.map(
          (data, index) =>
            index < 4 && (
              <PopularCourseCard
                key={`popular-course-${index}`}
                id={data?.id}
                type={data?.type}
                coverImgUrl={data?.coverImgUrl}
                date={data?.date}
                title={data?.title}
              />
            )
        )}
        {/* <li>แสดงทั้งหมด</li> */}
      </ul>
    </div>
  );
};

export default PopularCourses;
