import { Col, Modal, Row } from "antd";
import { toFormatDurationTime } from "hooks/functions/toFormatDurationTime";
import useModalHook from "hooks/useModalHook";
import _ from "lodash";
import { useState } from "react";
import ReactPlayer from "react-player";

const UnitCourse = ({ data = [], type }) => {
  const [isPreviewUnit, setIsPreviewUnit] = useState(null);
  const {
    open: previewOpen,
    handleOpen: _handlePreviewOpen,
    handleClose: _handlePreviewClose,
  } = useModalHook();

  return (
    <>
      <div className="widget widget_categories">
        <h3 className="widget_title">หลักสูตรการเรียนรู้</h3>
        <ul>
          {_.size(data) > 0 ? (
            data?.map((n, index) => (
              <li key={index}>
                {/* <Link to={n.path}> */}
                <div
                  className="w-100"
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      textAlign: "start",
                      whiteSpace: "pre-wrap",
                      overflowWrap: "break-word",
                      paddingRight: "8px",
                    }}
                  >
                    {`บทที่ ${index + 1} : ${n?.unitName}`}{" "}
                    {/* <span>
                      {n?.videoUrlPreview && (
                        <u
                          style={{ cursor: "pointer" }}
                          className="text-primary"
                          onClick={() => {
                            setIsPreviewUnit(n);
                            _handlePreviewOpen();
                          }}
                        >
                          ตัวอย่าง
                        </u>
                      )}
                    </span> */}
                  </div>
                  {type === "online" && (
                    <div style={{ width: "80px", textAlign: "end" }}>
                      {toFormatDurationTime(n?.videoTime)}
                    </div>
                  )}
                </div>
              </li>
            ))
          ) : (
            <Row className="center">
              <p className="my-3">ไม่พบข้อมูล</p>
            </Row>
          )}
        </ul>
      </div>
      <Modal
        title="ตัวอย่างคอร์สเรียน"
        open={previewOpen}
        onOk={_handlePreviewClose}
        onCancel={_handlePreviewClose}
        footer={null}
      >
        <Col span={24} className="mt-3">
          <p>{isPreviewUnit?.unitName || "ตัวอย่างหน่วยการเรียนรู้"}</p>
          <Row className="mt-3 mb-3">
            <ReactPlayer
              url={isPreviewUnit?.videoUrlPreview}
              width={"100%"}
              height={"320px"}
              controls
            />
          </Row>
          <h6 className="mb-1">รายละเอียด</h6>
          <p>{isPreviewUnit?.description || "ตัวอย่างหน่วยการเรียนรู้"}</p>
          {/* <h6 className="mb-3">ตัวอย่าง</h6>
          <Button
            type="primary"
            className="w-100 d-flex justify-content-start mb-2"
          >
            บทที่ 1 : พื้นฐานการเรียนร้องเพลง
          </Button>
          <Button className="w-100 d-flex justify-content-start mb-2">
            บทที่ 2 : พื้นฐานการเรียนร้องเพลง
          </Button>
          <Button disabled className="w-100 d-flex justify-content-start mb-2">
            บทที่ 3 : พื้นฐานการเรียนร้องเพลง
          </Button> */}
        </Col>
      </Modal>
    </>
  );
};

export default UnitCourse;
