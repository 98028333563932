import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authSlice";
import cartReducer from "./reducers/cartSlice";
import checkQuizSlice from "./reducers/checkQuizSlice";
import courseSlice from "./reducers/courseSlice";
import loadingSlice from "./reducers/loadingSlice";
import packageSlice from "./reducers/packageSlice";
import profileSlice from "./reducers/profileSlice";
import promotionSlice from "./reducers/promotionSlice";
import quizSlice from "./reducers/quizSlice";

const store = configureStore({
  reducer: {
    cart: cartReducer,
    auth: authReducer,
    profile: profileSlice,
    quiz: quizSlice,
    course: courseSlice,
    package: packageSlice,
    promotion: promotionSlice,
    loading: loadingSlice,
    checkQuiz: checkQuizSlice,
  },
});

export default store;
