import Section from "components/section";
import Breadcrumb from "./Breadcrumb";

export default function ImageCover() {
  return (
    <>
      <Section
        style={{
          backgroundImage: `url(assets/images/others/cover_header.png)`,
        }}
      ></Section>

      <div className="sa-section" style={{ marginTop: "20px" }}>
        <div className="container">
          <div className="row">
            <Breadcrumb />
          </div>
        </div>
      </div>
    </>
  );
}
