import { Col, Form, Input, Row, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import { AlertError } from "components/alert/Alert";
import { AlertSuccess } from "components/alert/Alert";
import { contact } from "constants/contact";
import { errorToMessage } from "hooks/functions/errorToMessage";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { CLOSE_LOADING } from "redux/reducers/loadingSlice";
import { OPEN_LOADING } from "redux/reducers/loadingSlice";
import masterService from "services/master.services";

const ContactUsPage = () => {

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { mutate: sendContact, isLoading } =
    masterService.useMutationSendContact(
      () => {
        form.resetFields()
        AlertSuccess({});
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const onFinish = async (values) => {
    sendContact(values);
  };

  useEffect(() => {
    dispatch(isLoading ? OPEN_LOADING() : CLOSE_LOADING())
  }, [isLoading])

  return (
    <>
      <section className="breadcrumb-section">
        <div className="container">
          <h1>ติดต่อเรา</h1>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">หน้าแรก</Link>
            </li>
            <li className="breadcrumb-item">ติดต่อเรา</li>
          </ol>
        </div>
      </section>
      <div className="contact-section section-padding">
        <div className="container">
          <div className="address-content">
            <div className="row">
              <div className="col-lg-4">
                <div className="address">
                  <div className="icon">
                    <i className="fas fa-map-signs"></i>
                  </div>
                  <div className="text">
                    <h3>สถานที่ติดต่อ</h3>
                    <p>{contact.company}</p>
                    <p>{contact.address}</p>
                    <p>{contact.province}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="address">
                  <div className="icon">
                    <i className="fas fa-phone-alt"></i>
                  </div>
                  <div className="text">
                    <h3>เบอร์โทรศัพท์</h3>
                    <p>{contact.phone_number}</p>
                    <p style={{ opacity: "0" }}>.</p>
                    <p style={{ opacity: "0" }}>.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="address !tw-text-wrap">
                  <div className="icon">
                    <i className="far fa-envelope"></i>
                  </div>
                  <div className="text">
                    <h3>Email address</h3>
                    <p>{contact.email}</p>
                    <p style={{ opacity: "0" }}>.</p>
                    <p style={{ opacity: "0" }}>.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Form
            form={form}
            name="contact-form"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={[16, 16]}>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="ชื่อผู้ส่ง"
                  name="contactName"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุชื่อผู้ส่ง !",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="ชื่อผู้ส่ง"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item
                  label="อีเมล"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุอีเมล !",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="อีเมล"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Form.Item
                  label="หัวข้อ"
                  name="topic"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุหัวข้อ !",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="หัวข้อ"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Form.Item
                  label="ติดต่อเรื่องใด"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุหัวข้อ !",
                    },
                  ]}
                >
                  <TextArea
                    size="large"
                    placeholder="กรุณาระบุความต้องการโดยละเอียด"
                    rows={4}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div className="form-group">
              <div className="flashinfo"></div>
              <button
                className="btn btn-primary btn-l"
              >
                ส่ง
              </button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ContactUsPage;
