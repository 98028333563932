import { Select } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import masterService from "services/master.services";

const SelectUniversity = ({ withAll = false, ...props }) => {
  const [universityOption, setUniversityOption] = useState([]);
  const { isLoading } = masterService.useQueryGetUniversity({
    onSuccess: (res) => {
      let _withAll = { value: "ทั้งหมด", label: "ทั้งหมด" };
      let other = { value: "other", label: "อื่น ๆ" };
      let options = _.map(res, (n) => {
        return { value: n.universityName, label: n.universityName };
      });
      options.push(other);
      setUniversityOption(withAll ? [_withAll, ...options] : options);
    },
  });

  return (
    <Select
      disabled={isLoading}
      placeholder="เลือกจากสถาบัน"
      size="large"
      options={universityOption}
      {...props}
    />
  );
};

export default SelectUniversity;
