import { Button, Divider, Result, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TOGGLE_TO_LOAD } from "redux/reducers/cartSlice";
import courseService from "services/course.services";
import masterService from "services/master.services";
import { getUserId } from "utils";
import { MdPendingActions } from "react-icons/md";
import { COLOR } from "constants/color";
const PaymentFinish = () => {
  const { orderNo } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("pending");
  const [timer, setTimer] = useState()
  const { mutate: clearCourseInCart } =
    courseService.useMutationClearAllCourseInCart(() => {
      dispatch(TOGGLE_TO_LOAD());
    });

  const { isLoading, refetch } = masterService.useQueryCheckPaymentOrder({
    orderNo,
    onSuccess: (res) => {
      const user_id = getUserId();
      const user_payment_id = res?.userId;
      if (user_id !== user_payment_id) {
        navigate("/");
      } else {
        setStatus(res.status)
        if (res.status === "pending") {
          const timer = setTimeout(() => {
            refetch()
          }, [5000])
          setTimer(timer)
        } else if (timer) {
          clearTimeout(timer)
        }
      }
    },
  });

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, []);

  useEffect(() => {
    if (status === "successful") {
      clearCourseInCart();
    }
  }, [status]);

  return (
    <>
      <Divider />
      <div style={{ margin: "60px 0px" }} className="congrats-section">
        <div className="container">
          <div className="congrats-content">
            <div className="congrats-info">
              {isLoading ? (
                <Spin />
              ) : status === "failed" ? (
                <PaymentFailed />
              ) : status === "reversed" ? (
                <PaymentRevesrse />
              ) : status === "successful" ? (
                <Paymentsuccessfully />
              ) : (
                <PaymentPending />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const PaymentRevesrse = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="error"
      title="การชำระเงินถูกยกเลิก"
      subTitle="ขออภัย คำสั่งซื้อของคุณถูกยกเลิก หากมีข้อสงสัยโปรดติดต่อผู้ให้บริการของคุณ"
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate("/cart");
          }}
        >
          กลับสู่รายการสั่งซื้อของคุณ
        </Button>
      }
    ></Result>
  );
};

const PaymentFailed = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="error"
      title="การชำระเงินล้มเหลว"
      subTitle="โปรดลองทำรายการการชำระเงินใหม่อีกครั้ง"
      extra={
        <Button
          type="primary"
          onClick={() => {
            navigate("/cart");
          }}
        >
          กลับสู่รายการสั่งซื้อของคุณ
        </Button>
      }
    ></Result>
  );
};

const PaymentPending = () => {
  return (
    <Result
      icon={<MdPendingActions size={100} color={COLOR.primary} />}
      title="กำลังตรวจสอบรายการ"
      subTitle="กรุณาอย่าปิดหน้านี้ โปรดรอให้ระบบทำรายการเสร็จสิ้น"
    ></Result>
  );
};

const Paymentsuccessfully = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="success"
      title="แจ้งชำระเงินสำเร็จ"
      subTitle={
        <>
          ทางเว็บไซต์ได้รับการแจ้งชำระเงินของคุณเรียบร้อยแล้ว<br /> กรุณารอการตรวจสอบจากทางเว็บไซต์
        </>
      }
      extra={
        <Button
          className="mt-5"
          type="primary"
          size="large"
          onClick={() => {
            navigate("/");
          }}
        >
          กลับสู่หน้าหลัก
        </Button>
      }
    />
  );
};
export default PaymentFinish;
