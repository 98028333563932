import { Button, Carousel, Col, Modal, Row } from "antd";
import { NO_IMAGE } from "assets/images";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";

const AwardPreviewModal = ({ data, open, onCancel }) => {
  const carouselRef = useRef(null);
  const [dataSource, setDataSource] = useState([]);

  const handlePrev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };
  const handleNext = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  useEffect(() => {
    setDataSource(data);
  }, [data]);

  return (
    <Modal
      title={`รูปภาพผลงาน ( ${_.size(dataSource)} )`}
      open={open}
      onCancel={onCancel}
      footer={null}
    >
      {_.size(dataSource) > 0 ? (
        <Carousel className="mt-4" ref={carouselRef}>
          {dataSource?.map((n, i) => {
            return (
              <Row key={i}>
                <Col span={24}>
                  <div
                    className="center"
                    style={{
                      width: "100%",
                      height: "auto",
                      maxHeight: "472px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      className="w-100 h-100"
                      style={{ objectFit: "cover" }}
                      src={n?.fileUrl || NO_IMAGE}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = NO_IMAGE;
                      }}
                      alt={`award-Image ${i + 1}`}
                    />
                  </div>
                </Col>
              </Row>
            );
          })}
        </Carousel>
      ) : (
        <Row className="py-5 center">
          <p>ยังไม่มีภาพผลงาน</p>
        </Row>
      )}

      {_.size(dataSource) > 1 && (
        <Row className="mt-4 center">
          <Button type="link" onClick={handlePrev}>
            ก่อนหน้า
          </Button>
          <Button type="link" onClick={handleNext}>
            ถัดไป
          </Button>
        </Row>
      )}
    </Modal>
  );
};

export default AwardPreviewModal;
