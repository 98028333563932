import { Spin } from "antd";
import { Footer, Header } from "components/layout";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

export default function LayoutPage({ mobileDropdown }) {
  const loading = useSelector((state) => state.loading.loading);
  return (
    <>
      {
        loading ?
          <div className="tw-flex tw-justify-center tw-items-center tw-w-screen tw-h-full tw-overflow-hidden tw-absolute tw-bg-black tw-bg-opacity-20 tw-z-50">
            <Spin size="large"/></div> :
          null
      }
      <Header mobileDropdown={() => mobileDropdown()} />
      <Outlet />
      <Footer />
    </>
  );
}
