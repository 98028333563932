import { IoHomeOutline } from "react-icons/io5";
import { LuExternalLink } from "react-icons/lu";
import { RiGroupLine } from "react-icons/ri";
import { Divider } from "../../../../../../node_modules/antd/es/index";

export default function CourseInfo({
  dataCourse,
  currentUnit,
  courseType,
  activeGroupData,
}) {
  return (
    <>
      <h4>รายละเอียดคอร์สเรียน</h4>
      <u>
        <p>ประเภทการเรียน : {courseType}</p>
      </u>

      <p>หมวดหมู่ : {dataCourse?.category}</p>
      <p>รายละเอียด : {dataCourse?.description}</p>
      {/* // TODO : เช็คเพิ่มกรณีที่มีการซื้อคอร์สแล้ว  */}
      {!!activeGroupData?.id && (
        <div>
          <Divider />
          <div className="tw-flex tw-items-center tw-gap-2">
            <RiGroupLine />
            <p className="m-0">
              จำนวนต่อกลุ่ม : {activeGroupData?.limitPerGroup} คน
            </p>
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <IoHomeOutline /> <span> Link กลุ่ม : </span>
            <a
              className="text-primary"
              href={activeGroupData?.linkGroup ?? "#"}
              target="_blank"
              rel="noreferrer"
            >
              {activeGroupData?.linkGroup}
            </a>
          </div>
          <div className="tw-flex tw-items-center tw-gap-2">
            <LuExternalLink /> <span> Link zoom : </span>
            <a
              className="text-primary"
              href={activeGroupData?.linkZoom ?? "#"}
              target="_blank"
              rel="noreferrer"
            >
              {activeGroupData?.linkZoom}
            </a>
          </div>
        </div>
      )}

      {dataCourse?.courseObjective.length > 0 && (
        <div className="requirements">
          <h4>วัตถุประสงค์</h4>
          <ul className="global-list">
            {dataCourse?.courseObjective?.map((n, i) => (
              <li key={i}>{n?.description}</li>
            ))}
          </ul>
        </div>
      )}

      {dataCourse?.courseEarn.length > 0 && (
        <div className="you-learn">
          <h4>สิ่งที่คุณจะได้รับในคอร์สนี้</h4>
          <ul className="global-list">
            {dataCourse?.courseEarn?.map((n, i) => (
              <li key={i}>{n?.description}</li>
            ))}
          </ul>
        </div>
      )}

      {currentUnit ? (
        <p>{currentUnit?.description}</p>
      ) : (
        <p>{dataCourse?.description}</p>
      )}
    </>
  );
}
