import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

export const TABS_VALUE = {
  ONLINE: "online",
  DRAFT: "draft",
  WAITING: "waiting",
  FINISH: "finish",
};

export const COURSE_DETAIL_SECTION = {
  VIDEO: "video",
  PRE_QUIZ: "pre",
  POST_QUIZ: "post",
};

const initialState = {
  page: COURSE_DETAIL_SECTION.PRE_QUIZ,
  currentUnit: 0,
  currentDuration: 0,
  currentUnitData: {},
  courseDetail: {},
  unitDetail: {},
  tab: TABS_VALUE.ONLINE,
};

const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    SET_COURSE_PAGE: (state, action) => {
      state.page = action.payload;
    },
    CLEAR_COURSE_DATA: (state) => {
      state.page = COURSE_DETAIL_SECTION.PRE_QUIZ;
    },
    SET_COURSE_DETAIL: (state, action) => {
      state.courseDetail = action.payload;
    },
    SET_UNIT_DETAIL: (state, action) => {
      state.unitDetail = action.payload || {};
      state.currentUnitData = action.payload?.data[state.currentUnit] || {};
    },
    SET_CURRENT_UNIT: (state, action) => {
      let all_unit = cloneDeep(state.unitDetail?.data);
      state.currentUnit = action.payload;
      state.currentUnitData = all_unit[state.currentUnit];
    },
    SET_DURATION_VIDEO: (state, action) => {
      state.currentDuration = action.payload;
    },
    SET_TAB: (state, action) => {
      state.tab = action.payload;
    },
    CLEAR_DURATION_VIDEO: (state, action) => {
      state.currentDuration = 0;
    },
    INIT: () => initialState
  },
});

export const {
  SET_COURSE_PAGE,
  CLEAR_COURSE_DATA,
  SET_COURSE_DETAIL,
  SET_UNIT_DETAIL,
  SET_CURRENT_UNIT,
  SET_DURATION_VIDEO,
  SET_TAB,
  CLEAR_DURATION_VIDEO,
  INIT
} = courseSlice.actions;
export default courseSlice.reducer;
