import { Button, Input, Modal, Row, message } from "antd";
import { NO_IMAGE } from "assets/images";
import copy from "clipboard-copy";
import { AlertError } from "components/alert/Alert";
import { ROLE_USER } from "constants/roleUserConstant";
import { errorToMessage } from "hooks/functions/errorToMessage";
import useModalHook from "hooks/useModalHook";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TOGGLE_TO_LOAD } from "redux/reducers/cartSlice";
import courseService from "services/course.services";
import workshopService from "services/workshop.services";
import { getRoleUser } from "utils";

const SidebarCourses = ({ dataSource = {}, setSchedule }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roleUser = getRoleUser();
  const isURL = window.location.href; // now path
  const searchParams = new URLSearchParams(window.location.search); // get params
  const referenceCode = searchParams.get("code"); // gat code value
  const splitCodeURL = isURL.slice(0, isURL.indexOf("?")); // cut codeReference

  const myCart = useSelector((state) => state.cart);
  const [messageApi, contextHolder] = message.useMessage();
  const [sharingUrl, setSharingUrl] = useState();
  const [sessionGroup, setSessionGroup] = useState();
  const [currentSessionGroup, setCurrentSessionGroup] = useState(null);
  const {
    open: shareCourseOpen,
    handleOpen: _handleshareCourseOpen,
    handleClose: _handleshareCourseClose,
  } = useModalHook();

  const { mutate: getCodeReference } =
    courseService.useMutationGetCodeReference(
      (res) => {
        const { data } = res;
        setSharingUrl(`${referenceCode ? splitCodeURL : isURL}?code=${data}`);
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const { mutate: addToCart } = courseService.useMutationAddItemToCart(
    () => {
      success("เพิ่มรายการลงตะกร้าเรียบร้อย");
      dispatch(TOGGLE_TO_LOAD());
    },
    (err) => {
      const statusCode = err?.response?.status;
      const isForbidden = statusCode == 403;
      if (isForbidden) {
        navigate("/login");
      } else if (
        err?.response?.data?.status?.description !== "ข้อมูลซ้ำกับในระบบ"
      ) {
        AlertError({ text: errorToMessage(err) });
      }
    }
  );

  if (dataSource?.type === "workshop") {
    workshopService.useQueryGetAllSessionGroupByWorkshopId({
      workshopId: dataSource.id,
      onSuccess: (response) => {
        const data = response.data;
        setSessionGroup(data);
      },
    });
  } else if (dataSource?.isGroup) {
    courseService.useQueryGetAllZoomGroupByCourseId({
      courseId: dataSource.id,
      onSuccess: (response) => {
        const data = response.data;
        setSessionGroup(data);
      },
    });
  }

  const handleAddToCart = async (isBuy) => {
    let id =
      dataSource?.type !== "workshop" && !dataSource?.isGroup
        ? dataSource?.id
        : currentSessionGroup?.id;
    let isDuplicate = _.find(
      myCart?.items,
      (n) =>
        n.courseId === id ||
        n.sessionGroupId === id ||
        n.packageId === id ||
        n.teacherId === id
    );
    if (isDuplicate) {
      if (isBuy) {
        navigate("/cart");
      } else {
        warning("มีรายการนี้แล้ว !");
      }
    } else {
      await addToCart({
        [dataSource?.type === "workshop" || dataSource?.isGroup
          ? `sessionGroupId`
          : dataSource?.type === "package"
          ? `packageId`
          : `courseId`]: id,
        referenceCode,
      });
      if (isBuy) {
        navigate("/cart");
      }
    }
  };

  const handleCopyUrl = () => {
    copy(sharingUrl).then(() => {
      _handleshareCourseClose();
      success("คัดลอกแล้ว !");
    });
  };

  const warning = (text) => {
    messageApi.open({
      type: "warning",
      content: `${text}`,
    });
  };

  const success = (text) => {
    messageApi.open({
      type: "success",
      content: `${text}`,
    });
  };

  const generateShareCourse = async () => {
    await getCodeReference({ courseId: dataSource?.id });
    _handleshareCourseOpen();
  };

  const PurchaseSection = () => (
    <div className="video-content">
      <div className="d-flex">
        <h2 className="me-2">THB {numeral(dataSource?.price).format("0,0")}</h2>
        {dataSource?.delPrice && (
          <del className="opacity-50">
            THB {numeral(dataSource?.delPrice).format("0,0")}
          </del>
        )}
      </div>

      {!roleUser || roleUser == ROLE_USER.student ? (
        <>
          <button
            className="w-100 mt-2 btn btn-primary"
            onClick={() => {
              handleAddToCart(true);
            }}
            disabled={
              (dataSource?.type === "workshop" || dataSource?.isGroup) &&
              currentSessionGroup === null
            }
          >
            ซื้อตอนนี้
          </button>
          <button
            className="w-100 btn btn-outline mt-2"
            onClick={() => {
              handleAddToCart(false);
            }}
            disabled={
              (dataSource?.type === "workshop" || dataSource?.isGroup) &&
              currentSessionGroup === null
            }
          >
            เพิ่มไปยังรถเข็น
          </button>

          <span className="text-center">รับประกันคืนเงินภายใน 30 วัน</span>
          <a
            style={{ cursor: "pointer" }}
            className="share"
            onClick={generateShareCourse}
          >
            <i className="fa fa-share"></i> แชร์
          </a>
        </>
      ) : null}
    </div>
  );

  return (
    <>
      {dataSource?.type !== "workshop" && !dataSource?.isGroup ? (
        <div className="widget video-widget">
          {contextHolder}
          <div className="course-video">
            {dataSource?.type === "online" ? (
              <ReactPlayer
                url={dataSource?.previewVideoUrl}
                width={"100%"}
                height={"auto"}
                controls
              />
            ) : (
              <div
                style={{ maxHeight: "260px", overflow: "hidden" }}
                className="center"
              >
                <img
                  style={{ width: "100%", height: "auto", objectFit: "cover" }}
                  src={
                    dataSource?.coverImgUrl ? dataSource?.coverImgUrl : NO_IMAGE
                  }
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = NO_IMAGE;
                  }}
                  alt="course-cover"
                />
              </div>
            )}
          </div>
          <PurchaseSection />
        </div>
      ) : dataSource?.type === "workshop" ? (
        <div className="widget widget_categories">
          <h3 className="widget_title">รอบกิจกรรม</h3>
          <ul>
            {sessionGroup?.map((entry, index) => (
              <Button
                key={`sessionGroup-${index}`}
                type={`${
                  currentSessionGroup?.id === entry.id ? "primary" : "default"
                }`}
                style={{
                  borderRadius: "0px",
                  height: "auto",
                  padding: "8px 12px",
                }}
                className="w-100 mb-2 center"
                onClick={() => {
                  setCurrentSessionGroup(entry);
                }}
              >
                <div
                  className="w-100 d-flex align-items-center justify-content-between"
                  style={{
                    textAlign: "start",
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    paddingRight: "8px",
                  }}
                >
                  {`รอบ ${moment(entry.startTime).format(
                    "DD/MM/yyyy HH:MM"
                  )} - ${moment(entry.finishTime).format(
                    "DD/MM/yyyy HH:MM"
                  )} ที่ ${entry.locationName}`}
                </div>
              </Button>
            ))}
          </ul>
          <PurchaseSection />
        </div>
      ) : (
        <div className="widget widget_categories">
          <h3 className="widget_title">รอบกลุ่มเรียน</h3>
          <ul>
            {sessionGroup?.map((entry, index) => (
              <Button
                key={`zoomgroup-${index}`}
                type={`${
                  currentSessionGroup?.id === entry.id ? "primary" : "default"
                }`}
                style={{
                  borderRadius: "0px",
                  height: "auto",
                  padding: "8px 12px",
                }}
                className="w-100 mb-2 center"
                onClick={() => {
                  setCurrentSessionGroup(entry);
                  setSchedule(entry.schedule);
                }}
              >
                <div
                  className="w-100 d-flex align-items-center justify-content-between"
                  style={{
                    textAlign: "start",
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    paddingRight: "8px",
                  }}
                >
                  {`รอบที่ ${index + 1} ${moment(entry.startDate).format(
                    "DD/MM/yyyy"
                  )} - ${moment(entry.finishDate).format("DD/MM/yyyy")}`}
                </div>
              </Button>
            ))}
          </ul>
          <PurchaseSection />
        </div>
      )}

      <Modal
        title="แชร์คอร์สเรียนนี้"
        open={shareCourseOpen}
        onOk={_handleshareCourseClose}
        onCancel={_handleshareCourseClose}
        footer={null}
      >
        <Row className="mb-4 mt-3">
          <Input style={{ flex: "1" }} readOnly value={sharingUrl} />
          <div
            style={{ width: "80px", cursor: "pointer" }}
            className="center text-primary"
            onClick={handleCopyUrl}
          >
            <p>คัดลอก</p>
          </div>
        </Row>
      </Modal>
    </>
  );
};

export default SidebarCourses;
