import _ from "lodash";
import React from "react";

const Pagination = ({ page = 1, pageSize = 9, total = 18 }) => {
  return (
    <div className="sg-pagination text-center">
      <ul className="pagination justify-content-center mb-0">
        {[...Array(_.round(total / pageSize))].map((n, i) => {
          return (
            <li key={i}>
              <a
                className={`page-numbers ${i + 1 === page && "current"}`}
                href="#"
              >
                {i + 1}
              </a>
            </li>
          );
        })}
        <li className="float-right">
          <a className="page-numbers" href="#">
            <i className="fas fa-chevron-right"></i>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Pagination;
