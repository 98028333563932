import { Button, Tag, message } from "antd";
import { NO_IMAGE } from "assets/images";
import _ from "lodash";
import moment from "moment";
import { HiLocationMarker } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import courseService from "services/course.services";
import { AlertError } from "./alert/Alert";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { getRoleUser } from "utils";
import numeral from "numeral";
import TextWithPopover from "./textWithPopover";
import { TOGGLE_TO_LOAD } from "redux/reducers/cartSlice";
import masterService from "services/master.services";
import { useEffect, useState } from "react";

const WorkshopCard = ({ data }) => {
  const courseId = data?.id;
  const roleUser = getRoleUser();
  const toMyWorkshopPath = `/${roleUser}/workshop/${courseId}`;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myCart = useSelector((state) => state.cart);
  const [messageApi, contextHolder] = message.useMessage();
  const [isPurcharse, setIsPurcharse] = useState(false)

  const { data: getAllMyCourseId } = masterService.useQueryGetAllMyPurchase({});

  const { mutate: addToCart } = courseService.useMutationAddItemToCart(
    () => {
      success();
      dispatch(TOGGLE_TO_LOAD());
    },
    (err) => {
      const statusCode = err?.response?.status;
      const isForbidden = statusCode == 403;
      if (isForbidden) {
        navigate("/login");
      } else if (err?.response?.data?.status?.description !== "ข้อมูลซ้ำกับในระบบ") {
        AlertError({ text: errorToMessage(err) });
      }
    }
  );

  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "มีรายการนี้แล้ว !",
    });
  };

  const success = () => {
    messageApi.open({
      type: "success",
      content: "เพิ่มรายการลงตะกร้าเรียบร้อย !",
    });
  };

  const handleAddToCart = () => {
    let isDuplicate = _.find(myCart?.items, (n) => n.courseId === data?.id);
    if (isDuplicate) {
      warning();
    } else {
      addToCart({ courseId: data?.id });
    }
  };

  const handleGoToCourse = () => {
    navigate(toMyWorkshopPath);
  };

  const WORKSHOP_PATH = isPurcharse ? toMyWorkshopPath : `/workshops/${data?.id}`;

  useEffect(() => {
    if (getAllMyCourseId?.data) {
      setIsPurcharse(getAllMyCourseId.data.some(e => e.courseId === courseId))
    } else {
      setIsPurcharse(false)
    }
  }, [getAllMyCourseId])

  return (
    <div className="sa-course">
      {contextHolder}
      <span className="badges">
        {data?.delPrice > 0 && <del>{`THB ${data?.delPrice || 0}`}</del>}{" "}
        {`THB ${data?.price || 0}`}
      </span>
      <div className="course-thumb">
        <div style={{ height: "260px" }} className="center">
          <img
            style={{ objectFit: "cover", height: "100%", width: "100%" }}
            src={data?.coverImgUrl || NO_IMAGE}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = NO_IMAGE;
            }}
            alt="cover-workshop"
            className="img-fluid"
          />
        </div>
        <div className="overlay">
          <div className="icons">
            {!isPurcharse && (
              <ul className="global-list">
                <li>
                  <a href={WORKSHOP_PATH}>
                    <i className="fas fa-link"></i>
                  </a>
                </li>
              </ul>
            )}
          </div>
        </div>
        <div className="rating-date align-self-end">
          <div>
            <i className="fa fa-user me-2"></i>
            {`${data?.joiner || 0}/${data?.limitPerGroup || 0}`} คน
          </div>
          <div className="date">
            <span>
              <i className="far fa-clock"></i>
              {moment(data?.startTime).format("DD MMM YYYY")}
            </span>
          </div>
        </div>
      </div>

      <div className="course-info">
        <div className="info mb-2">
          <h2 className="title">
            <Link to={WORKSHOP_PATH}>{data?.title || "Workshop name"}</Link>
          </h2>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <a href={`https://www.google.com/maps?q=${data?.position?.lat},${data?.position?.lon}`} target="_blank">
              <div className="d-flex align-items-center">
                <HiLocationMarker className="me-2" />
                {<TextWithPopover text={data?.locationName || "Location name"} maxLength={20} />}
              </div>
            </a>
            <Tag color="blue">Workshop</Tag>
          </div>
        </div>
        <div>
          <ul
            className="global-list"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <li>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: "163px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    marginRight: "8px",
                    width: "32px",
                    height: "32px",
                    borderRadius: "100px",
                    backgroundColor: "grey",
                    overflow: "hidden",
                  }}
                  className="center"
                >
                  <img
                    src={data?.teacherImg || NO_IMAGE}
                    alt="profile"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = NO_IMAGE;
                    }}
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
                {data?.teacherName || "Teacher name"}
              </div>
            </li>
          </ul>
        </div>
        <div className="sa-meta">
          <ul
            className="global-list"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <li>
              <a className="center">
                <p style={{ margin: "0px" }} className="text-primary">
                  {numeral(data?.price).format("0,0")} บาท
                </p>
                {
                  data?.delPrice ? (
                    <del
                      style={{
                        marginLeft: "8px",
                        fontWeight: "lighter",
                        fontSize: "14px",
                      }}
                    >
                      {numeral(data?.delPrice).format("0,0")}.-
                    </del>
                  )
                    :
                    null
                }

              </a>
            </li>
            {_.includes(getAllMyCourseId?.data, courseId) ? (
              <li>
                <Button type="primary" onClick={handleGoToCourse}>
                  ไปยังเวิร์กชอป
                </Button>
              </li>
            ) : (
              <li>
                <Link to={`/workshops/${data?.id}`}>
                  ดูรอบกิจกรรม
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default WorkshopCard;
