import { Row } from "antd";
import moment from "moment";
import { useMemo } from "react";

const BookingList = ({
  type = "remove",
  isEditable,
  bookingDate,
  learnTime,
  onClick,
}) => {
  const isTypeName = useMemo(() => {
    if (type === "remove") {
      return "ลบ";
    } else if (type === "edit") {
      return "แก้ไข";
    } else if (type === "save") {
      return "บันทึก";
    }
  }, [type]);

  return (
    <Row key={bookingDate + learnTime} className="mb-2">
      <div
        style={{
          border: `1px solid ${type === "edit" ? "#ffccc7" : "#d9d9d9"}`,
          backgroundColor: `${type === "edit" && "#fff2f0"}`,
        }}
        className="w-100 p-3 center d-flex justify-content-between"
      >
        <p style={{ fontSize: "16px" }} className="m-0 text-black">
          {moment(bookingDate).format("DD MMMM YYYY")} - เวลา : {learnTime} น.
          {type === "edit" && (
            <span style={{ color: "#DA2128" }} className="ms-3">
              ( ไม่สามารถจองได้ ! )
            </span>
          )}
        </p>

        {isEditable && (
          <>
            <p
              style={{
                color: "#DA2128",
                cursor: "pointer",
                fontSize: "16px",
              }}
              className={`m-0 ${type !== "remove" && "text-primary"}`}
              onClick={onClick}
            >
              {type === "done" && isEditable ? "แก้ไข" : isTypeName}
            </p>
          </>
        )}
      </div>
    </Row>
  );
};

export default BookingList;
