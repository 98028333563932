import { Button, Col, DatePicker, Form, Input, Modal, Row, Upload } from "antd";
import PreviewImageModal from "components/PreviewImageModal";
import {
  AlertClosed,
  AlertConfirm,
  AlertError,
  AlertLoading,
  AlertSuccess,
} from "components/alert/Alert";
import { thaiLocale } from "constants/thaiLocale/thaiLocale";
import { dummyRequest } from "hooks/dummyRequest";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { onUploadFile } from "hooks/onUploadFile";
import { toDateFormat } from "hooks/toDateFormat";
import useUploadFileHook from "hooks/useUploadFileHook";
import _ from "lodash";
import { useEffect } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import teacherBackOfficeService from "services/backoffice/teacherBackoffice.services";
import { getUserId } from "utils";

const AwardFormModal = ({ data, open, onCancel, refetch }) => {
  const [form] = Form.useForm();

  const {
    fileList,
    setFileList,
    previewImage,
    previewOpen,
    handlePreview: _handlePreviewUpload,
    handleChange: _handleChangeUpload,
    handleCancel: _handleCancelUpload,
  } = useUploadFileHook();

  const { mutate: createAward, isLoading: isLoadingCreate } =
    teacherBackOfficeService.useMutationCreateTeacherAward(
      () => {
        AlertClosed();
        onCancel();
        refetch();
        AlertSuccess({ title: "เพิ่มผลงานสำเร็จ!" });
        form.resetFields();
      },
      (err) => {
        AlertClosed();
        onCancel();
        AlertError({ text: `${errorToMessage(err)}` });
      }
    );

  const { mutate: updateAward, isLoading: isLoadingUpdate } =
    teacherBackOfficeService.useMutationUpdateTeacherAward(
      () => {
        AlertClosed();
        onCancel();
        refetch();
        AlertSuccess({ title: "อัปเดตผลงานสำเร็จ!" });
      },
      (err) => {
        AlertClosed();
        onCancel();
        AlertError({ text: `${errorToMessage(err)}` });
      }
    );
  const { mutate: deleteAward, isLoading: isLoadingDelete } =
    teacherBackOfficeService.useMutationDeleteTeacherAward(
      () => {
        AlertClosed();
        onCancel();
        refetch();
        AlertSuccess({ title: "ลบผลงานสำเร็จ!" });
      },
      (err) => {
        AlertClosed();
        onCancel();
        AlertError({ text: `${errorToMessage(err)}` });
      }
    );

  const onFinish = async (values) => {
    let prefix = `teacherAward/${getUserId()}`;
    let _award = {
      ...data,
      ...values,
      startDate: toDateFormat(values?.startDate?.$d),
      endDate: toDateFormat(values?.endDate?.$d),
    };
    if (data?.id) {
      // update
      let images_new = [];
      let images_delete = [];
      if (fileList.length > 0) {
        let newUpload = _.filter(
          fileList,
          (x) => !_.some(data?.tempFileList, (y) => x.uid === y.uid)
        );
        if (newUpload) {
          for (let i = 0; i < newUpload.length; i++) {
            let image = await onUploadFile({
              prefix,
              fileList: [newUpload[i]],
            });
            images_new.push({
              fileUrl: image,
              flag: "insert",
              prefixPath: prefix,
            });
          }
        }

        let deleteOld = _.filter(
          data?.tempFileList,
          (x) => !_.some(fileList, (y) => x.uid === y.uid)
        );

        if (deleteOld) {
          for (let i = 0; i < deleteOld.length; i++) {
            images_delete.push({
              id: deleteOld[i]?.uid,
              teacherAwardId: data?.teacherAwardId,
              flag: "delete",
              prefixPath: prefix,
            });
          }
        }
      }

      let _update = {
        teacherAward: _.omit(_award, ["teacherAwardFiles"]),
        teacherAwardFiles: [
          ..._award?.teacherAwardFiles,
          ...images_new,
          ...images_delete,
        ],
      };
      updateAward({ values: _update });
    } else {
      let images_create = [];
      if (fileList.length > 0) {
        for (let i = 0; i < fileList.length; i++) {
          let img_name = await onUploadFile({
            prefix,
            fileList: [fileList[i]],
          });
          images_create.push(img_name);
        }
      }
      let _create = {
        teacherAward: {
          ..._award,
          teacherId: getUserId(),
          startDate: toDateFormat(_award.startDate),
          endDate: toDateFormat(_award.endDate),
        },
        teacherAwardFiles: _.map(images_create, (n) => {
          return { fileUrl: n, flag: "insert", prefixPath: prefix };
        }),
      };
      createAward({ values: _create });
    }
  };

  if (isLoadingCreate || isLoadingUpdate || isLoadingDelete) {
    AlertLoading({ text: "กำลังบันทึก..." });
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    } else {
      if (form.__INTERNAL__.name) {
        form.resetFields();
      }
      setFileList([]);
    }
  }, [data, form]);

  useEffect(() => {
    if (data?.tempFileList) {
      setFileList(data?.tempFileList);
    }
  }, [data?.id]);

  return (
    <Modal
      title={data ? "แก้ไขรางวัลผลงาน" : "เพิ่มรางวัล/ผลงาน"}
      open={open}
      onOk={() => {
        form.submit();
      }}
      onCancel={() => {
        if (!data) {
          form.resetFields();
          setFileList([]);
        } else {
          if (data?.tempFileList.length > 0) {
            setFileList(data?.tempFileList);
          }
        }
        onCancel();
      }}
      okText="บันทึก"
      cancelText="ยกเลิก"
    >
      <Form
        form={form}
        name="award-teacher-form"
        className="mt-4"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="ชื่อผลงาน"
          name="detail"
          rules={[
            {
              required: true,
              message: "กรุณาระบุชื่อผลงาน!",
            },
          ]}
        >
          <Input size="large" placeholder="ชื่อผลงาน" />
        </Form.Item>
        <Form.Item
          label="ประเภทรางวัล"
          name="type"
          rules={[
            {
              required: true,
              message: "กรุณาระบุประเภทรางวัล!",
            },
          ]}
        >
          <Input size="large" placeholder="ประเภทรางวัล" />
        </Form.Item>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item label="วันที่เริ่มต้น" name="startDate">
              <DatePicker
                locale={thaiLocale}
                placeholder="วันที่เริ่มต้น"
                size="large"
                className="w-100"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="วันที่สิ้นสุด" name="endDate">
              <DatePicker
                locale={thaiLocale}
                placeholder="วันที่สิ้นสุด"
                size="large"
                className="w-100"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="my-3">
          <p>อัปโหลดรุปภาพ</p>
        </Row>
        {/* {data?.teacherAwardFiles.length > 0 && (
          <Row className="mb-4" gutter={[8, 8]}>
            {data?.teacherAwardFiles?.map((n) => (
              <Col
                className="center"
                span={6}
                style={{
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <Image
                  width={120}
                  height={120}
                  style={{ objectFit: "cover" }}
                  src={n?.fileUrl ? n?.fileUrl : NO_IMAGE}
                  alt="award-img"
                />
              </Col>
            ))}
          </Row>
        )} */}

        <Row className="mb-4">
          <Col span={24}>
            <Upload
              accept="image/png, image/jpeg"
              listType="picture-card"
              fileList={fileList}
              customRequest={dummyRequest}
              onPreview={_handlePreviewUpload}
              onChange={_handleChangeUpload}
            >
              <div className="d-flex flex-column align-items-center">
                <MdOutlineFileUpload size={20} />
                <p>อัปโหลดรูป</p>
              </div>
            </Upload>
          </Col>
        </Row>
        {data && (
          <Button
            danger
            block
            className="mb-4"
            onClick={() => {
              AlertConfirm({
                text: "คุณต้องการลบรายการนี้หรือไม่ ?",
                onOk: () => {
                  deleteAward({ awardId: data?.id });
                },
              });
            }}
          >
            ลบ
          </Button>
        )}
      </Form>
      <PreviewImageModal
        open={previewOpen}
        onCancel={_handleCancelUpload}
        previewImage={previewImage}
      />
    </Modal>
  );
};

export default AwardFormModal;
