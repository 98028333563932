import { Button, Card, Input, Progress, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COURSE_DETAIL_SECTION } from "redux/reducers/courseSlice";
import { SET_CURRENT_UNIT } from "redux/reducers/courseSlice";
import { SET_COURSE_PAGE } from "redux/reducers/courseSlice";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";

const Quiz = ({ data, type, refetch: refetchCourse, isLoadingQuiz }) => {
  const currentUnit = useSelector((state) => state.course.currentUnitData);
  const courseDetail = useSelector((state) => state.course.courseDetail);
  const unitDetail = useSelector((state) => state.course.unitDetail);
  const dispatch = useDispatch();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [quizzes, setQuizzes] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [isStart, setIsStart] = useState(false);
  const [isLastUnit, setIsLastUnit] = useState(unitDetail?.data?.findIndex(e => e.id === currentUnit?.id) === unitDetail?.data?.length - 1 || false)
  const [answerRes, setAnswerRes] = useState({})
  const [quizType, setQuizType] = useState(null)

  const { mutateAsync: saveAnswer } = courseBackOfficeServices.useMutationSaveAnswer()

  const handleNextQuestion = async () => {
    if (currentQuestion === quizzes.length - 1) {
      const answers = Object.entries(selectedAnswers).map(([key, value]) => ({
        quizId: quizzes[key]?.id,
        answer: value,
      }));
      const response = await saveAnswer({ answers, type });
      setAnswerRes(response)
      setIsDone(true);
    }
    setCurrentQuestion(currentQuestion + 1);

    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [currentQuestion + 1]: null,
    }));
  };

  const handleRetest = () => {
    setCurrentQuestion(0)
    setSelectedAnswers({})
    setIsDone(false);
  };

  const handleBackQuestion = () => {
    setCurrentQuestion(currentQuestion - 1);
  };

  const handleAnswerChange = (choice) => {
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [currentQuestion]: choice,
    }));
  };

  const handleClickButton = () => {
    dispatch(SET_COURSE_PAGE(COURSE_DETAIL_SECTION.VIDEO));
  };

  const handleNextButton = () => {
    const nextIndex = unitDetail?.data?.findIndex(e => e.id === currentUnit?.id) + 1 || 0
    dispatch(SET_CURRENT_UNIT(nextIndex));
  };

  const handleFinishButton = () => {
    refetchCourse()
    dispatch(SET_CURRENT_UNIT(0));
  };

  const calculateProgress = () =>
    `${(quizzes.length < currentQuestion ? currentQuestion + 1 : currentQuestion) + 1
    } / ${quizzes.length}`;

  const isNextButtonDisabled =
    selectedAnswers[currentQuestion] === null ||
    selectedAnswers[currentQuestion] === undefined;

  useEffect(() => {
    if (currentUnit?.id && courseDetail?.type === "online") {
      setIsStart(false)
    }
  }, [currentUnit, courseDetail]);

  useEffect(() => {
    if (!data?.isDone && data?.quizzes?.length) {
      setIsLastUnit(unitDetail?.data?.findIndex(e => e.id === currentUnit?.id) === unitDetail?.data?.length - 1 || false)
      setQuizzes(data.quizzes);
      setCurrentQuestion(0);
      setSelectedAnswers({});
      setIsDone(false);
      setQuizType(data?.quizzes?.[0].quizType || null);
    }
  }, [data]);

  return (
    <div style={{ padding: "20px", width: "100%" }}>
      {isStart ? currentQuestion < quizzes?.length ? (
        <>
          <Progress
            percent={((currentQuestion + 1) / quizzes.length) * 100}
            status="active"
            format={() => calculateProgress()}
            style={{ marginBottom: "10px" }}
          />
          <Card title={`คำถามที่ ${currentQuestion + 1}`}>
            <p>{quizzes[currentQuestion]?.question}</p>
            <div className="row">
              {quizzes[currentQuestion]?.quizType === "choosing" ? (
                <>
                  <div className="col-md-6 mb-2">
                    <Button
                      className={`w-100 h-100 py-4 ${selectedAnswers[currentQuestion] === "A"
                        ? "ant-btn-primary"
                        : ""
                        }`}
                      onClick={() => handleAnswerChange("A")}
                    >
                      {quizzes[currentQuestion]?.choiceA}
                    </Button>
                  </div>
                  <div className="col-md-6 mb-2">
                    <Button
                      className={`w-100 h-100 py-4 ${selectedAnswers[currentQuestion] === "B"
                        ? "ant-btn-primary"
                        : ""
                        }`}
                      onClick={() => handleAnswerChange("B")}
                    >
                      {quizzes[currentQuestion]?.choiceB}
                    </Button>
                  </div>
                  <div className="col-md-6 mb-2">
                    <Button
                      className={`w-100 h-100 py-4 ${selectedAnswers[currentQuestion] === "C"
                        ? "ant-btn-primary"
                        : ""
                        }`}
                      onClick={() => handleAnswerChange("C")}
                    >
                      {quizzes[currentQuestion]?.choiceC}
                    </Button>
                  </div>
                  <div className="col-md-6 mb-2">
                    <Button
                      className={`w-100 h-100 py-4 ${selectedAnswers[currentQuestion] === "D"
                        ? "ant-btn-primary"
                        : ""
                        }`}
                      onClick={() => handleAnswerChange("D")}
                    >
                      {quizzes[currentQuestion]?.choiceD}
                    </Button>
                  </div>
                </>
              ) : (
                <div className="col-md-12 mb-2">
                  <Input.TextArea
                    rows={4}
                    value={selectedAnswers[currentQuestion] || ""}
                    onChange={(e) => handleAnswerChange(e.target.value)}
                  />
                </div>
              )}
            </div>
            <div className="d-flex flex-between">
              <Button
                style={{ marginTop: "10px", marginRight: "10px" }}
                onClick={handleBackQuestion}
                disabled={currentQuestion === 0}
              >
                กลับ
              </Button>
              <Button
                style={{ marginTop: "10px" }}
                onClick={handleNextQuestion}
                disabled={isNextButtonDisabled}
              >
                {currentQuestion === quizzes.length - 1 ? "ส่งคำตอบ" : "ต่อไป"}
              </Button>
            </div>
          </Card>
        </>
      ) : (
        <Card className="tw-flex tw-justify-center tw-items-center tw-min-h-64">
          {isDone ? (
            <div className="tw-text-center">
              <h2>ทำแบบทดสอบสำเร็จ</h2>
              {
                type === COURSE_DETAIL_SECTION.POST_QUIZ && answerRes?.totalPoint && quizType === "choosing" ?
                  <>
                    <p className="tw-font-extralight">ผลคะแนนของคุณคือ</p>
                    <div className="tw-flex tw-justify-between tw-items-center tw-py-10 tw-w-full">
                      <div className="tw-mx-10">
                        <div className="tw-w-40 tw-h-40 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-bg-light_primary tw-border-solid tw-border-8 tw-border-light_primary">
                          <h5 className="tw-m-0 tw-text-white">{answerRes.totalPoint}</h5>
                        </div>
                        <p className="tw-font-light">คะแนนเต็ม</p>
                      </div>
                      <div className="tw-mx-10">
                        <div className="tw-w-40 tw-h-40 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-bg-light_primary tw-border-solid tw-border-8 tw-border-primary">
                          <h5 className="tw-m-0 tw-text-white">{answerRes.prePoint}</h5>
                        </div>
                        <p className="tw-font-light">คะแนนก่อนเรียนที่ทำได้</p>
                      </div>
                      <div className="tw-mx-10">
                        <div className="tw-w-40 tw-h-40 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-bg-light_primary tw-border-solid tw-border-8 tw-border-dark_primary">
                          <h5 className="tw-m-0 tw-text-white">{answerRes.postPoint}</h5>
                        </div>
                        <p className="tw-font-light">คะแนนหลังเรียนที่ทำได้</p>
                      </div>
                    </div>
                    <Button className="tw-mr-2" onClick={handleRetest}>
                      ทำแบบทดสอบอีกครั้ง
                    </Button>
                  </>
                  :
                  type === COURSE_DETAIL_SECTION.POST_QUIZ && answerRes?.totalPoint && quizType === "writing" ?
                    <>
                      <p className="tw-font-extralight">คำตอบของคุณจะถูกตรวจในภายหลังโดยอาจารย์ผู้สอน กรุณารอรับอีเมลแจ้งเตือนเมื่ออาจารย์ตรวจคำตอบของคุณแล้ว</p>
                    </>
                    : null
              }
              <Button type="primary" onClick={type === COURSE_DETAIL_SECTION.PRE_QUIZ ? handleClickButton : isLastUnit ? handleFinishButton : handleNextButton}>
                {type === COURSE_DETAIL_SECTION.PRE_QUIZ ? "เข้าสู่บทเรียน" : isLastUnit ? "จบบทเรียน" : "ไปเนื้อหาถัดไป"}
              </Button>
            </div>
          ) : (
            <Spin />
          )}
        </Card>
      ) : (
        <Card className="tw-flex tw-justify-center tw-items-center tw-h-64">
          <div className="tw-text-center">
            {
              !isLoadingQuiz ?
                <>
                  <h3>
                    {type === COURSE_DETAIL_SECTION.PRE_QUIZ ? "แบบทดสอบก่อนเรียน" : "แบบทดสอบหลังเรียน"}
                  </h3>
                  <p className="mb-4">
                    {type === COURSE_DETAIL_SECTION.PRE_QUIZ ? "วัตถุประสงค์ของการทดสอบเพื่อวัดความรู้ความเข้าใจของนักเรียนก่อนบทเรียน" : "วัตถุประสงค์ของการทดสอบเพื่อวัดความรู้ความเข้าใจของนักเรียนหลังบทเรียน"}
                  </p>
                  <Button onClick={() => { setIsStart(true) }}>
                    เริ่มทำแบบทดสอบ
                  </Button>
                </> :
                <Spin />
            }

          </div>
        </Card>
      )}
    </div>
  );
};

export default Quiz;
