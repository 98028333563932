import { course_02, krunut_cover } from "assets/images/course";
import RatingStar from "components/RatingStar";

const QuoteSection = () => {
  return (
    <>
      <div className="sa-section">
        <div
          className="section-content section-padding text-center jarallax section-before"
          style={{
            backgroundImage: `url(${course_02})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
          //   style="background-image: url(images/bg/s1.jpg);"
        >
          <div className="container">
            <div className="testimonial-slider">
              <div className="testimonial">
                <div className="testimonial-info">
                  <div className="icon">
                    <span>
                      <i className="fas fa-quote-left"></i>
                    </span>
                  </div>
                  <h2>
                    Allowing for field strengths are and ceramic magnets, but
                    these are also amongweakesttypes the ferrite magnets are
                    mainly.
                  </h2>
                  <div className="testimonial-footer">
                    <div className="author">
                      <img
                        style={{ objectFit: "cover" }}
                        src={krunut_cover}
                        alt="cover"
                        className="img-fluid img-circle"
                      />
                    </div>
                    <div className="testimonial-text">
                      <div className="testimonial-ratings">
                        <ul className="global-list">
                          <RatingStar rating={5} />
                        </ul>
                      </div>
                      <div className="testimonial-title">
                        <h3>ครูนัท ณวภัสร์</h3>
                        <span>Founder, Tones Music</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuoteSection;
