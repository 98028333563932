import Carousel from "components/carousel";
import { jarallax } from "jarallax";
import { useEffect } from "react";
import AboutUsSection from "./AboutUsSection";
import CourseListSection from "./CourseListSection";
import QuoteSection from "./QuoteSection";
import TutorSection from "./TutorSection";
import WhyWeJoinSection from "./WhyWeJoinSection";
import WorkshopSection from "./WorkshopSection";
import PackageListSection from "./PackageListSection";

// banner image size : 1920 x 700

export default function Home() {
  useEffect(() => {
    jarallax(document.querySelectorAll(".jarallax"), {
      speed: 0.2,
    });
  }, []);
  return (
    <>
      <Carousel
        imgList={[
          {
            extendsClass: "",
            img: "assets/images/bg/s1.jpg",
            active: true,
          },
          {
            extendsClass: "text-center",
            img: "assets/images/bg/s5.jpg",
          },
          {
            extendsClass: "text-end",
            img: "assets/images/bg/s3.jpg",
          },
        ]}
      />
      <AboutUsSection />
      <WhyWeJoinSection />
      {/* <CatagorySection /> */}
      <CourseListSection />
      <TutorSection />
      <WorkshopSection />
      <PackageListSection />
      <QuoteSection />
    </>
  );
}
