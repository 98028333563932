import adminCourseService from "services/adminCourse.services";
import { Button, Image, Row, Table, Tabs } from "antd";
import { NO_IMAGE } from "assets/images";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import { localeCustomTH } from "constants/thaiLocale/localeCustomTH";
import { errorToMessage } from "hooks/functions/errorToMessage";
import useModalHook from "hooks/useModalHook";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { IoIosInformationCircleOutline } from "react-icons/io";
import WorkshopDetailsModal from "./WorkshopDetailsModal";

const AdminManageWorkshop = () => {
  const [detail, setDetail] = useState(null);
  const [keyTab, setKeyTab] = useState(1);

  const {
    open: openWorkshopDetails,
    handleOpen: _handleOpenWorkshopDetails,
    handleClose: _handleCloseWorkshopDetails,
  } = useModalHook();

  const {
    data: dataWorkshop,
    isFetching: isLoadingWorkshop,
    refetch: refetchWorkshop,
  } = adminCourseService.useQueryGetAllWorkshop({
    queryObj: {
      paging: {
        limit: 10,
        start: 0,
      },
      isApprove: keyTab === 1,
      isReject: false,
    },
  });

  const { mutate: approveWorkshop } =
    adminCourseService.useMutationApproveWorkshop(
      () => {
        AlertSuccess({});
        refetchWorkshop();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );
  const { mutate: deleteWorkshop } =
    adminCourseService.useMutationDeleteWorkshop(
      () => {
        AlertSuccess({});
        refetchWorkshop();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const renderContent = () => {
    return (
      <Row>
        <Table
          locale={localeCustomTH.Table}
          loading={isLoadingWorkshop}
          scroll={{ x: true }}
          className="w-100 text-nowrap"
          bordered
          dataSource={dataWorkshop?.data}
          columns={courseHeaderTable}
        />
      </Row>
    );
  };

  const courseHeaderTable = [
    {
      title: "ลำดับ",
      render: (_, {}, index) => index + 1,
    },
    {
      title: "ภาพหน้าปก",
      dataIndex: "coverImgUrl",
      key: "coverImgUrl",
      render: (_, { coverImgUrl }) => (
        <div style={{ minWidth: "80px" }} className="center">
          <Image
            style={{ borderRadius: "4px" }}
            width={60}
            height={60}
            src={coverImgUrl || NO_IMAGE}
            fallback={NO_IMAGE}
            alt="profile_img"
          />
        </div>
      ),
    },
    {
      title: "ชื่อเวิร์กชอป",
      dataIndex: "title",
      key: "title",
      render: (text) => <div style={{ minWidth: "200px" }}>{text}</div>,
    },

    {
      title: "หมวดหมู่",
      dataIndex: "category",
      render: (text) => <div style={{ minWidth: "80px" }}>{text}</div>,
    },
    {
      title: "ราคาปกติ",
      dataIndex: "delPrice",
      render: (text) => (
        <div style={{ minWidth: "60px" }}>{numeral(text).format("0,0")}</div>
      ),
    },
    {
      title: "ราคาสุทธิ",
      dataIndex: "price",
      render: (text) => (
        <div style={{ minWidth: "60px" }}>{numeral(text).format("0,0")}</div>
      ),
    },
    {
      title: "ชื่อครูผู้สอน",
      dataIndex: "teacherName",
      render: (text) => <div style={{ minWidth: "80px" }}>{text}</div>,
    },
    {
      title: "วันที่สร้างเวิร์กชอป",
      dataIndex: "date",
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      fixed: "right",
      render: (_, record) => {
        const { id } = record;
        return (
          <>
            {keyTab === 1 ? (
              <div className="center gap-2">
                <Button
                  className="center"
                  onClick={() => {
                    _handleOpenWorkshopDetails();
                    setDetail(record);
                  }}
                >
                  <IoIosInformationCircleOutline />
                </Button>
                <Button
                  className="center"
                  danger
                  onClick={() => {
                    AlertConfirm({
                      onOk: () => {
                        deleteWorkshop({ workshopId: id });
                      },
                    });
                  }}
                >
                  <AiOutlineDelete />
                </Button>
              </div>
            ) : (
              <div className="d-flex gap-2">
                <Button
                  className="center"
                  onClick={() => {
                    _handleOpenWorkshopDetails();
                    setDetail(record);
                  }}
                >
                  <IoIosInformationCircleOutline />
                </Button>
                <Button
                  className="w-100"
                  type="primary"
                  onClick={() => {
                    AlertConfirm({
                      onOk: () => {
                        approveWorkshop({
                          workshopId: id,
                          approveStatus: true,
                        });
                      },
                    });
                  }}
                >
                  อนุมัติ
                </Button>
                <Button
                  className="w-100"
                  danger
                  onClick={() => {
                    AlertConfirm({
                      onOk: () => {
                        approveWorkshop({
                          workshopId: id,
                          approveStatus: false,
                        });
                      },
                    });
                  }}
                >
                  ไม่อนุมัติ
                </Button>
              </div>
            )}
          </>
        );
      },
    },
  ];

  const courseTabs = [
    {
      key: 1,
      label: `ออนไลน์ `,
      children: renderContent(),
    },
    {
      key: 2,
      label: `รออนุมัติ `,
      children: renderContent(),
    },
  ];

  useEffect(() => {
    refetchWorkshop();
  }, [keyTab]);

  return (
    <>
      <Row>
        <Tabs
          className="w-100"
          type="card"
          defaultActiveKey="1"
          items={courseTabs}
          onChange={(key) => setKeyTab(key)}
        />
      </Row>
      <WorkshopDetailsModal
        id={detail?.id}
        open={openWorkshopDetails}
        onCancel={() => {
          _handleCloseWorkshopDetails();
          setDetail(null);
        }}
      />
    </>
  );
};

export default AdminManageWorkshop;
