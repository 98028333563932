import { Checkbox, Form, Input, Modal, Radio } from "antd";
import { AlertError, AlertSuccess } from "components/alert/Alert";
import { courseTypeToText } from "hooks/functions/courseTypeToText";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
const { TextArea } = Input;

const CourseOtherModal = ({
  isEditable,
  data,
  total,
  index,
  open,
  onCancel,
  refetch,
}) => {
  const { courseId } = useParams();
  const [form] = Form.useForm();
  const [fieldsChange, setFieldsChange] = useState({});

  const { mutate: createUnit } = courseBackOfficeServices.useMutationCreateUnit(
    () => {
      refetch();
      onCancel();
      AlertSuccess({ text: "สร้างบทเรียนสำเร็จ!" });
      form.resetFields();
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );
  const { mutate: updateUnit } = courseBackOfficeServices.useMutationUpdateUnit(
    () => {
      refetch();
      onCancel();
      AlertSuccess({ text: "อัปเดตบทเรียนสำเร็จ!" });
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );

  const onFinish = async (values) => {
    if (data?.id) {
      //update unit
      let _update = {
        ...data,
        ...values,
      };
      updateUnit({ values: _update });
    } else {
      //create unit
      let _create = {
        courseId,
        ...values,
      };
      createUnit({ values: _create });
    }
  };

  useEffect(() => {
    if (data?.id) {
      let fields = {
        ...data,
        type: courseTypeToText(data?.type),
        isHomework: !!data?.homework,
      };
      form.setFieldsValue(fields);
      setFieldsChange(fields);
    } else {
      if (form.__INTERNAL__.name) {
        form.resetFields();
      }
      setFieldsChange(null);
    }
  }, [data, form]);

  return (
    <Modal
      title={
        isEditable
          ? data
            ? "แก้ไขบทเรียน"
            : "เพิ่มบทเรียนออนไลน์"
          : "รายละเอียดบทเรียน"
      }
      open={open}
      okButtonProps={{ disabled: !isEditable }}
      onOk={() => {
        form.submit();
      }}
      onCancel={onCancel}
      okText="บันทึก"
      cancelText="ยกเลิก"
    >
      <Form
        form={form}
        name="course-zoom-form"
        className="mt-4"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >

        {data?.id ? (
          <h5>{`บทที่ ${index}`}</h5>
        ) : (
          <h5>{`บทที่ ${total + 1}`}</h5>
        )}
        
        <Form.Item
          label="หัวข้อการเรียน"
          name="unitName"
          rules={[
            {
              required: true,
              message: "กรุณาระบุหัวข้อการเรียน!",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="หัวข้อการเรียน"
            readOnly={!isEditable}
          />
        </Form.Item>

        <Form.Item
          shouldUpdate
          valuePropName="checked"
          name="isHomework"
          onChange={(e) => {
            setFieldsChange({
              ...fieldsChange,
              isHomework: e.target.checked,
            });
            if (e.target.checked) {
              form.setFieldsValue({ homeworkAnswerType: "message" });
            }
          }}
        >
          <Checkbox size="large" disabled={!isEditable}>
            การบ้าน
          </Checkbox>
        </Form.Item>
        {fieldsChange?.isHomework && (
          <>
            <Form.Item
              label="การบ้าน"
              name="homework"
              rules={[
                {
                  required: fieldsChange?.isHomework,
                  message: "กรุณาระบุการบ้าน!",
                },
              ]}
            >
              <TextArea
                rows={4}
                size="large"
                placeholder="การบ้าน"
                readOnly={!isEditable}
              />
            </Form.Item>

            <Form.Item
              initialValue={fieldsChange?.isHomework ? "message" : null}
              label="ประเภทการตอบการบ้าน"
              name="homeworkAnswerType"
            >
              <Radio.Group disabled={!isEditable}>
                <Radio value="message">ข้อความ</Radio>
                <Radio value="file">แนบไฟล์ (รูปภาพ / วิดีโอ)</Radio>
              </Radio.Group>
            </Form.Item>
          </>
        )}
        {/* {data && (
          <Button
            danger
            block
            className="mb-4"
            onClick={() => {
              AlertConfirm({
                text: "คุณต้องการลบรายการนี้หรือไม่ ?",
                onOk: () => {},
              });
            }}
          >
            ลบ
          </Button>
        )} */}
      </Form>
    </Modal>
  );
};

export default CourseOtherModal;
