import { Skeleton } from "../../../node_modules/antd/es/index";

const SkeletonCard = () => {
  return (
    <div className="sa-course">
      <div className="course-thumb">
        <div
          style={{ height: "260px" }}
          className="center tw-bg-gray-300"
        ></div>
      </div>
      <div className="course-info">
        <div className="info">
          <Skeleton active />
        </div>
      </div>
    </div>
  );
};

export default SkeletonCard;
