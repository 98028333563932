import { Button, Spin } from "antd";
import { logo_tonemusic } from "assets";
import { NOT_FOUND_IMG } from "assets/images";
import { AlertError } from "components/alert/Alert";
import { ROLE_USER } from "constants/roleUserConstant";
import _ from "lodash";
import numeral from "numeral";
import { adminTabMenu } from "pages/back-office/constants/adminTabMenu";
import { teacherTabMenu } from "pages/back-office/constants/teacherTabMenu";
import { userTabMenu } from "pages/back-office/constants/userTabMenu";
import { useEffect, useMemo, useState } from "react";
import { IoMenu } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LOAD_ITEM_TO_CART } from "redux/reducers/cartSlice";
import courseService from "services/course.services";
import { getIsKruNut, getRoleUser } from "utils";
import { getUserId, setToken, setUser } from "utils/index";

const CartItemPopup = ({ data }) => {
  return (
    <li className="remove-item">
      <div className="sa-course">
        <div
          style={{ height: "70px", width: "80px" }}
          className="center course-thumb"
        >
          <img
            style={{ objectFit: "cover", height: "100%", width: "100%" }}
            src={data?.coverImgUrl || NOT_FOUND_IMG}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = NOT_FOUND_IMG;
            }}
            alt="item-cover"
            className="img-fluid"
          />
        </div>

        <div className="course-info">
          <div style={{ paddingRight: "6px" }} className="info">
            <h2 className="title">
              <Link to={`/courses/${data?.courseId}`}>
                <p>{data?.title}</p>
                <p
                  className="text-primary"
                  style={{ fontSize: "14px", fontWeight: "400" }}
                >
                  {numeral(data?.price).format(0, 0)} บาท
                </p>
              </Link>
            </h2>
          </div>
        </div>
      </div>
    </li>
  );
};

const Menu = ({ mobileDropdown }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const myCart = useSelector((state) => state.cart);
  const [previousCart, setPreviousCart] = useState([]);
  const {
    data: getCourseInCart,
    isFetching: isLoadingCart,
    refetch,
  } = courseService.useQueryGetItemInCart({
    onSuccess: (data) => {
      if (!_.isEqual(myCart.items, data)) {
        dispatch(LOAD_ITEM_TO_CART(data));
        setPreviousCart([...data]);
      }
    },
    onError: (err) => {
      let msg = err?.response?.data?.status?.description;
      if (msg === "Invalid Token") {
        AlertError({
          text: msg,
          onOk: () => {
            navigate("/login");
          },
        });
      }
    },
  });
  const isAuthen = getUserId();
  const roleUser = getRoleUser();
  const isKruNut = getIsKruNut();

  // MENU ON TAB
  const menuAdmin = adminTabMenu[0]?.key || "profile";
  const menuTeacher = teacherTabMenu[0]?.key || "profile";
  const menuStudent = userTabMenu[0]?.key || "profile";
  const menuIsKruNut = _.filter(teacherTabMenu, (n) => n.isKruNut)[0]?.key;

  // FIRST MENU
  let firstMenu;
  if (roleUser == ROLE_USER.admin) {
    firstMenu = menuAdmin;
  } else if (roleUser == ROLE_USER.teacher) {
    firstMenu = menuTeacher;
  } else {
    firstMenu = menuStudent;
  }

  // PROFILE MENU
  const backofficeMenu = [
    (roleUser == ROLE_USER.teacher ||
      (roleUser == ROLE_USER.admin && isKruNut)) && {
      text: "ตารางกิจกรรม",
      link: `/${ROLE_USER.teacher}/schedule`,
    },
    roleUser == ROLE_USER.admin && isKruNut
      ? {
          text: "โปรไฟล์",
          subMenu: [
            { text: "โปรไฟล์แอดมิน", link: `/${ROLE_USER.admin}/${menuAdmin}` },
            {
              text: "โปรไฟล์คุณครู",
              link: `/${ROLE_USER.teacher}/${menuIsKruNut}`,
            },
          ],
        }
      : { text: "โปรไฟล์", link: `/${roleUser}/${firstMenu}` },
  ].filter(Boolean);

  const userMenuItems = [
    { text: "หน้าแรก", link: "/" },
    { text: "เกี่ยวกับ", link: "/about" },
    {
      text: "เรียนร้องเพลงกับครูนัท",
      subMenu: [
        { text: "เรียนออนไลน์ผ่านระบบ", link: "/courses/kru-nut/online" },
        { text: "เรียนตัวต่อตัวผ่าน Zoom", link: "/courses/kru-nut/zoom" },
        { text: "เรียนตัวต่อตัวที่ Studio", link: "/courses/kru-nut/studio" },
        {
          text: "เรียนตัวต่อตัวที่บ้านนักเรียน",
          link: "/courses/kru-nut/at_home",
        },
        { text: "เวิร์กชอป", link: "/workshops/kru-nut" },
        { text: "แพ็กเกจ", link: "/packages/kru-nut" },
      ],
    },
    {
      text: "คอร์สเรียนทั้งหมด",
      subMenu: [
        { text: "เรียนออนไลน์ผ่านระบบ", link: "/courses/all/online" },
        { text: "เรียนตัวต่อตัวผ่าน Zoom", link: "/courses/all/zoom" },
        { text: "เรียนตัวต่อตัวที่ Studio", link: "/courses/all/studio" },
        {
          text: "เรียนตัวต่อตัวที่บ้านนักเรียน",
          link: "/courses/all/at_home",
        },
        { text: "เวิร์กชอป", link: "/workshops/all" },
        { text: "แพ็กเกจ", link: "/packages/all" },
      ],
    },
    { text: "แนะนำผู้สอน", link: "/teachers" },
    { text: "ติดต่อเรา", link: "/contact" },
  ];

  const menuItems = useMemo(() => {
    if (roleUser) {
      if (roleUser !== ROLE_USER.student) {
        return backofficeMenu;
      } else {
        return [...userMenuItems, ...backofficeMenu];
      }
    } else {
      return userMenuItems;
    }
  });

  const _handleLogout = async () => {
    setUser(null);
    setToken(null);
    navigate("/login");
  };

  useEffect(() => {
    refetch();
    if (isAuthen) {
      mobileDropdown();
    }
  }, [myCart.toggleToLoad, isAuthen]);

  return (
    <div className="sa-menu">
      <nav className="navbar navbar-expand-lg">
        <div className="container tw-min-h-[70px]">
          <div className="navbar-brand tw-min-h-[70px] tw-flex tw-items-center">
            <Link to="/">
              <img src={logo_tonemusic} alt="Logo" className="img-fluid" />
            </Link>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <IoMenu />
            </span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-end me-2"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              {menuItems.map((menuItem, index) => (
                <li
                  className={
                    (menuItem.subMenu ? "sa-dropdown" : "") +
                    " text-nowrap nav-item"
                  }
                  key={index}
                >
                  {menuItem.subMenu ? (
                    <>
                      <a href="#">{menuItem.text}</a>
                      <ul className="sa-dropdown-menu">
                        {menuItem.subMenu.map((subMenuItem, subIndex) => (
                          <li key={subIndex}>
                            <Link to={subMenuItem.link}>
                              {subMenuItem.text}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </>
                  ) : (
                    <Link to={menuItem.link}>{menuItem.text}</Link>
                  )}
                </li>
              ))}
              {isAuthen ? (
                <li className="!tw-block lg:!tw-hidden">
                  <a onClick={_handleLogout}>ออกจากระบบ</a>
                </li>
              ) : (
                <>
                  <li className="!tw-block lg:!tw-hidden">
                    <a href="/login">เข้าสู่ระบบ</a>
                  </li>
                  <li className="!tw-block lg:!tw-hidden">
                    <a href="/register">ลงทะเบียน</a>
                  </li>
                </>
              )}
            </ul>
          </div>
          {!roleUser || roleUser == ROLE_USER.student ? (
            <div className="right-content">
              <div className="user-option">
                <ul className="global-list">
                  {/* :: MY CART ::--------- */}

                  <li className="sa-dropdown !tw-mr-0 !tw-mt-[8px] md:tw-mt-0">
                    <span className="cart-number">
                      {_.size(getCourseInCart)}
                    </span>
                    <i className="fas fa-cart-plus"></i>
                    <div className="sa-dropdown-menu">
                      {isLoadingCart ? (
                        <div className="w-100 center">
                          <Spin />
                        </div>
                      ) : _.size(getCourseInCart) < 1 ? (
                        <div
                          style={{ height: "80px" }}
                          className="w-100 center"
                        >
                          <p style={{ fontSize: "14px", opacity: "0.4" }}>
                            - ยังไม่มีรายการ -
                          </p>
                        </div>
                      ) : (
                        <>
                          <ul className="global-list">
                            {getCourseInCart?.map((item, i) => (
                              <CartItemPopup key={i} data={item} />
                            ))}
                          </ul>
                          <div className="total-price">
                            <span>
                              <strong>ยอดรวม : </strong>
                              {`${numeral(
                                _.sumBy(getCourseInCart, (n) => n.price)
                              ).format("0,0")} บาท`}
                            </span>
                          </div>

                          <Link to="/cart">
                            <Button className="w-100" type="primary">
                              ตะกร้าสินค้าของฉัน
                            </Button>
                          </Link>
                        </>
                      )}
                    </div>
                  </li>
                  <li></li>
                </ul>
              </div>
            </div>
          ) : null}
        </div>
      </nav>
    </div>
  );
};

export default Menu;
