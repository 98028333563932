import { Button, Divider, Input, Row, Tag } from "antd";
import useModalHook from "hooks/useModalHook";

import { NO_IMAGE } from "assets/images/index";
import { AlertConfirm, AlertSuccess } from "components/alert/Alert";
import { useState } from "react";
import { useSelector } from "react-redux";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import moment from "../../../../../../node_modules/moment-timezone/index";
import ReactPlayer from "../../../../../../node_modules/react-player/lib/index";
import SubmitHomeworkModal from "./SubmitHomeworkModal";
const { TextArea } = Input;

export default function Homework() {
  const course = useSelector((state) => state.course);
  const currentUnit = course?.currentUnitData;
  const isUpdateHomework = !!currentUnit?.submitHomework?.id;
  const conversations = currentUnit?.submitHomework?.conversations;
  const isPass = currentUnit?.submitHomework?.isPass || false;
  const [comment, setComment] = useState("");

  const {
    open: openSubmitHomework,
    handleOpen: _handleOpenSubmitHomework,
    handleClose: _handleCloseSubmitHomework,
  } = useModalHook();

  const { data: unitDetail, refetch: refetchUnitDetail } =
    courseBackOfficeServices.useQueryGetAllUnitByCourseId({
      courseId: currentUnit?.courseId,
    });

  const { mutate: createComment } =
    courseBackOfficeServices.useMutationCreateCommentInHomework(() => {
      AlertSuccess({});
      setComment("");
      refetchUnitDetail();
    });

  return (
    currentUnit?.homework && (
      <>
        <div className="course-info p-0 mt-4">
          <div className="you-learn">
            <div className="tw-flex tw-items-center tw-gap-2 tw-mb-4">
              <h4 style={{ margin: "0px" }}>การบ้าน {currentUnit?.unitName}</h4>
              <Tag
                color={currentUnit?.submitHomework?.isPass ? "green" : "red"}
              >
                {currentUnit?.submitHomework?.isPass
                  ? "ผ่านแล้ว"
                  : "ยังไม่ผ่าน"}
              </Tag>
            </div>
            <p>โจทย์การบ้าน : {currentUnit?.homework}</p>
          </div>

          <div>
            {!!currentUnit?.submitHomework?.id && (
              <div>
                <Divider />
                <u>
                  <b>
                    <p>คำตอบ :</p>
                  </b>
                </u>
                <p>
                  {currentUnit?.submitHomework?.answer ? (
                    <ul>
                      <li>{currentUnit?.submitHomework?.answer}</li>
                    </ul>
                  ) : (
                    <div>
                      {currentUnit?.submitHomework?.videoUrl && (
                        <ReactPlayer
                          url={currentUnit?.submitHomework?.videoUrl}
                          width={"100%"}
                          height={"auto"}
                          controls
                        />
                      )}
                    </div>
                  )}
                  <p className="tw-opacity-40 tw-mt-2">
                    อัปเดตเมื่อ :
                    {moment(currentUnit?.submitHomework?.updatedTime).format(
                      "DD/MM/YYYY - HH:mm:ss "
                    )}
                  </p>
                </p>
              </div>
            )}
            {isPass === false && (
              <div>
                <Button
                  type="primary"
                  style={{
                    borderRadius: "0px",
                    padding: "24px 60px",
                  }}
                  className="center"
                  onClick={_handleOpenSubmitHomework}
                >
                  {isUpdateHomework ? "อัปเดตคำตอบ" : "ส่งการบ้าน"}
                </Button>
              </div>
            )}
          </div>
          <Divider />
          <div>
            {!!conversations && (
              <div>
                <u>
                  <b>
                    <p>ความคิดเห็น :</p>
                  </b>
                </u>
                {conversations?.map((n, i) => {
                  return (
                    <div
                      key={`coversation-${i}`}
                      className="tw-mb-3 tw-w-full"
                      style={{
                        border: "1px solid #DDDD",
                        borderRadius: "4px",
                        padding: "12px",
                      }}
                    >
                      <div className="tw-flex tw-items-center tw-gap-2 tw-mb-2">
                        <div className="tw-w-[40px] tw-h-[40px] !tw-rounded-full tw-overflow-hidden">
                          <img
                            src={n?.thumbUrl || NO_IMAGE}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = NO_IMAGE;
                            }}
                            alt="user-cover"
                            className="tw-w-full tw-h-full tw-object-cover tw-object-center"
                          />
                        </div>
                        <div>
                          <p className="mb-0">{n?.name || "-"}</p>
                          <p className="mb-0 tw-opacity-40">
                            {n?.date
                              ? moment(n?.updatedTime).format(
                                  "DD/MM/YYYY เวลา HH:mm:ss น."
                                )
                              : "เวลา -"}
                          </p>
                        </div>
                      </div>
                      <Divider className="m-2" />
                      <p className="mb-0">{n?.text}</p>
                    </div>
                  );
                })}
                <div>
                  <TextArea
                    value={comment}
                    className="my-2"
                    size="large"
                    placeholder="เขียนแสดงความคิดเห็น"
                    onChange={(e) => {
                      let text = e.target.value.trim();
                      setComment(text);
                    }}
                  />
                  <Row className="d-flex justify-content-end tw-gap-2">
                    <Button
                      disabled={comment?.length < 1}
                      size="large"
                      type="primary"
                      onClick={() => {
                        AlertConfirm({
                          onOk: () => {
                            createComment({
                              homeworkId: currentUnit?.submitHomework?.id,
                              text: comment,
                            });
                          },
                        });
                      }}
                    >
                      แสดงความคิดเห็น
                    </Button>
                  </Row>
                </div>
              </div>
            )}
          </div>
        </div>
        <SubmitHomeworkModal
          data={currentUnit}
          open={openSubmitHomework}
          refetch={refetchUnitDetail}
          onCancel={_handleCloseSubmitHomework}
        />
      </>
    )
  );
}
