import { Avatar, Button, List } from "antd";
import { NO_IMAGE } from "assets/images";
import useModalHook from "hooks/useModalHook";
import moment from "moment";
import { useState } from "react";
import { IoMdBook } from "react-icons/io";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import StudentHomeworkModal from "./StudentHomeworkModal";

const RecentHomework = () => {
  const [student, setStudent] = useState(null);

  const {
    open: openHomeworkStudent,
    handleOpen: _handleOpenHomeworkStudent,
    handleClose: _handleCloseHomeworkStudent,
  } = useModalHook();

  const { data: getAllRecentHomework, isFetching: isLoadingRecentHomework } =
    courseBackOfficeServices.useQueryGetAllRecentHomework({});

  return (
    <>
      <List
        className="demo-loadmore-list"
        loading={isLoadingRecentHomework}
        itemLayout="horizontal"
        dataSource={getAllRecentHomework?.data || []}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Button
                key={`homework-check-${item?.studentId}`}
                type="primary"
                onClick={() => {
                  const student = {
                    studentId: item?.studentId,
                    name: item?.name,
                    courseId: item?.courseId,
                    email: item?.email,
                    thumbUrl: item?.thumbUrl,
                  };
                  setStudent(student);
                  _handleOpenHomeworkStudent();
                }}
              >
                ตรวจการบ้าน
              </Button>,
            ]}
          >
            <List.Item.Meta
              avatar={<Avatar size="large" src={item?.thumbUrl || NO_IMAGE} />}
              title={<p>{item?.name || "Username"}</p>}
              description={
                item?.date
                  ? moment(item?.date).format("DD/MM/YYYY เวลา HH:mm น.")
                  : "เวลา -"
              }
            />
            <div className="tw-flex tw-items-center tw-w-[200px] me-1">
              <div className="tw-flex tw-gap-2">
                <IoMdBook size={20} className="tw-w-[22px]" />
                <div className="tw-flex tw-items-center tw-w-[180px] tw-truncate tw-overflow-hidden">
                  <b>
                    <p className="tw-w-[170px] tw-truncate">
                      {item?.courseName || "-"}
                    </p>
                  </b>
                </div>
              </div>
            </div>
          </List.Item>
        )}
      />
      <StudentHomeworkModal
        data={student}
        open={openHomeworkStudent}
        onCancel={() => {
          _handleCloseHomeworkStudent();
          setStudent(null);
        }}
      />
    </>
  );
};

export default RecentHomework;
