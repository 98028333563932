import { Button } from "antd";
import { AlertError } from "components/alert/Alert";
import { AlertSuccess } from "components/alert/Alert";
import { errorToMessage } from "hooks/functions/errorToMessage";
import fileService from "services/file.services";

const CertificationPanel = ({ data, ...other }) => {

  const { mutate: dowloadCertificate } =
    fileService.useMutationDownloadCertificate(
      () => {
        AlertSuccess({});
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const _handleDownload = () => {
    dowloadCertificate({ courseId: data?.id });
  };

  return (
    <div className="widget widget_categories">
      <h3 className="widget_title">ใบรับรองการเรียน</h3>
      <ul>
        <Button
          type="primary"
          style={{ borderRadius: "0px" }}
          className="w-100 py-4  center"
          onClick={_handleDownload}
          {...other}
        >
          ดาวน์โหลดใบรับรอง
        </Button>
      </ul>
    </div>
  );
};

export default CertificationPanel;
