import { Button } from "antd";
import _ from "lodash";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { homeConstants } from "../constants/HomeConstants";

const PaginationSlideCard = ({
  page = 1,
  total = 0,
  limitPage = homeConstants.limitPage,
  setPage
}) => {
  return (
    <>
      {total > 4 && (
        <div className="tw-flex tw-gap-3 tw-items-center">
          <Button
            className="tw-flex tw-items-center tw-gap-1"
            disabled={page <= 1}
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          >
            <IoIosArrowBack />
            ก่อนหน้า
          </Button>
          <p>
            {page}/{_.ceil(total / limitPage)}
          </p>
          <Button
            className="tw-flex tw-items-center tw-gap-1"
            disabled={page >= _.ceil(total / limitPage)}
            onClick={() => {
              if (page * limitPage < total) {
                setPage(page + 1);
              }
            }}
          >
            ถัดไป
            <IoIosArrowForward />
          </Button>
        </div>
      )}
    </>
  );
};

export default PaginationSlideCard;
