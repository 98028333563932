import { useMutation, useQuery } from "react-query";
import createRequest from "utils/request";

const adminTeacherServices = {
  // :: ADMIN_TEACHER :: --------------------------------------------------
  useQueryGetRoleTeacher({ approval, onSuccess, onError }) {
    return useQuery(
      ["getAllUserRoleTeacher"],
      async () => {
        const path = `/user/getAllUserRoleTeacher`;
        const query = { params: { approval } };
        try {
          const res = await createRequest.get(path, query);
          return res.data;
        } catch (error) {
          throw error;
        }
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  //   ---------------------- :: USE_MUTATION :: --------------------
  useMutationApproveTeacher(onSuccess, onError) {
    return useMutation(
      async ({ id, approveStatus }) => {
        const path = `/user/updateApproveUser`;
        const query = { teacherId: id, approveStatus };
        try {
          const res = await createRequest.post(path, query);
          return res.data.data;
        } catch (error) {
          throw error;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};

export default adminTeacherServices;
