import { useMutation, useQuery } from "react-query";
import { getUserId } from "utils";
import createRequest from "utils/request";

const workshopBackofficeServices = {
  // USE QUERY PACKAGES
  useQueryGetAllMyTeacherWorkshop({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllMyTeacherWorkshop", queryObj],
      async () => {
        const pagingStr = JSON.stringify(queryObj?.paging);
        const path = `/workshop/getAllMyTeacherWorkshop`;
        const query = {
          params: { ...queryObj, paging: pagingStr },
        };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!getUserId(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetAllSessionGroupByWorkshopId({
    workshopId,
    isExpired,
    onSuccess,
    onError,
  }) {
    return useQuery(
      ["getAllSessionGroupByWorkshopId", workshopId, isExpired],
      async () => {
        const path = `/workshop/getAllSessionGroupByWorkshopId`;
        const query = { params: { workshopId, isExpired } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!workshopId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetAllMySessionGroupByWorkshopId({
    workshopId,
    isExpired,
    onSuccess,
    onError,
  }) {
    return useQuery(
      ["getAllMySessionGroupByWorkshopId", workshopId, isExpired],
      async () => {
        const path = `/workshop/getAllMySessionGroupByWorkshopId`;
        const query = { params: { workshopId, isExpired } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!workshopId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetWorkshopById({ id, onSuccess, onError }) {
    return useQuery(
      ["getWorkshopById", id],
      async () => {
        const path = `/workshop/getWorkshopById`;
        const query = { params: { id } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetAllMyStudentWorkshop({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllMyStudentWorkshop", getUserId()],
      async () => {
        const path = `/workshop/getAllMyStudentWorkshop`;
        const query = { params: { ...queryObj } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  // USE MUTATION PACKAGES
  useMutationCreateSessionGroup(onSuccess, onError) {
    return useMutation(
      async ({ payload }) => {
        const path = `/workshop/createSessionGroup`;
        const query = { ...payload };

        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateWorkshop(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/workshop/updateWorkshop`;
        const query = { ...values };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationCreateWorkshop(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/workshop/createWorkshop`;
        const query = { ...values };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDeleteWorkshop(onSuccess, onError) {
    return useMutation(
      async ({ workshopId }) => {
        const path = `/workshop/deleteWorkshop`;
        const query = { params: { workshopId } };

        const res = await createRequest.delete(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdateSessionGroup(onSuccess, onError) {
    return useMutation(
      async ({ payload }) => {
        const path = `/workshop/updateSessionGroup`;
        const query = { ...payload };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDeleteSessionGroup(onSuccess, onError) {
    return useMutation(
      async ({ sessionGroupId }) => {
        const path = `/workshop/deleteSessionGroup`;
        const query = { params: { sessionGroupId } };
        const res = await createRequest.delete(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};

export default workshopBackofficeServices;
