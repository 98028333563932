import { Row, Spin, Typography } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { NOT_FOUND_IMG } from "assets/images";
import Breadcrumb from "components/elements/Breadcrumb";
import Tabs from "components/elements/Tabs";
import TextWithPopover from "components/textWithPopover";
import { courseTypeOption } from "constants/options/courseTypeOption";
import _ from "lodash";
import moment from "moment";
import Calendar from "pages/back-office/components/Calendar";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import courseService from "services/course.services";
import masterService from "services/master.services";
import packageService from "services/package.services";
import { getRoleUser } from "utils";
import CourseDescription from "./CourseDescription";
import CourseReview from "./CourseReview";
import PopularCourses from "./PopularCourses";
import SidebarCourses from "./SidebarCourses";
import UnitCourse from "./UnitCourse";
import { convertListDataForCalendar } from "hooks/functions/convertListDataForCalendar";

const CourseDetails = ({ type: componentType }) => {
  const [courseType, setCourseType] = useState("คอร์สเรียน");
  const { id } = useParams();
  const navigate = useNavigate();
  const [type, setType] = useState(componentType);
  const [schedule, setSchedule] = useState({});
  const [scheduleInMonth, setScheduleInMonth] = useState(
    moment().format("YYYY-MM")
  );

  const { data: getAllMyCourseId } = masterService.useQueryGetAllMyPurchase({});
  const {
    data: dataCourse,
    isFetching: isLoadingCourse,
    refetch: refetchCourse,
  } = type === "package"
      ? packageService.useQueryGetPublicPackageById({ id })
      : courseService.useQueryGetCourseById({ id });

  const {
    data: getUnitCourse,
    isFetching: isLoadingUnitCourse,
    refetch: refetchUnitCourse,
  } = courseService.useQueryGetUnitByCourseId({ id });

  const {
    data: getCourseSchedule,
    isFetching: isLoadingCourseSchedule,
    refetch: refetchCourseSchedule,
  } = courseService.useQueryGetCourseSchedule({
    courseId: id,
    month: scheduleInMonth,
  });

  const isShowSchedule = useMemo(() => {
    const accept_type = ["zoom", "studio", "at_home"];
    return _.includes(accept_type, type);
  }, [type]);

  const eventTitleContent = (item) => {
    return (
      <TextWithPopover
        className="tw-ml-2"
        text={`${moment(item.startTime, "HH:mm")
          .locale("th")
          .format("HH:mm")} ${item.note || ""}`}
        maxLength={40}
      />
    );
  };

  const calendarPopover = (item) => {
    const startTimeFormatted = moment(
      item.startDate + item.startTime,
      "YYYY-MM-DDHH:mm"
    )
      .locale("th")
      .format("dddd, MMMM D ⋅ HH:mm");
    const finishTimeFormatted = moment(item.finishTime, "HH:mm")
      .locale("th")
      .format("HH:mm");
    return (
      <div onClick={(e) => e.stopPropagation()}>
        <Typography>
          <Paragraph className="!tw-font-kanit !tw-mb-0">
            {startTimeFormatted} – {finishTimeFormatted}
          </Paragraph>
          {item.note ? (
            <Paragraph className="!tw-font-kanit">
              หมายเหตุ: {item.note}
            </Paragraph>
          ) : null}
        </Typography>
      </div>
    );
  };

  const breadcrumb_item = [
    {
      name: "หน้าแรก",
      link: "/",
    },
    {
      name: courseType,
    },
    {
      name: `รายละเอียด${type === "workshop"
        ? "เวิร์กชอป"
        : type === "package"
          ? "แพ็กเกจ"
          : "คอร์สเรียน"
        }`,
    },
  ];

  const courseItems = [
    {
      id: 1,
      key: "description",
      label: "คำอธิบาย",
      children: (
        <CourseDescription courseId={dataCourse?.id} dataCourse={dataCourse} />
      ),
    },
    isShowSchedule && {
      id: 2,
      key: "schedule",
      label: "ตารางเรียน",
      children: (
        <Calendar
          placeholder="ตารางเรียน"
          listData={schedule}
          eventTitleContent={eventTitleContent}
          innerPopover={calendarPopover}
          readOnly={true}
          onMonthChange={(month) => setScheduleInMonth(month)}
        />
      ),
    },
    {
      id: 3,
      key: "reviews",
      label: `รีวิว${type === "workshop"
        ? "เวิร์กชอป"
        : type === "package"
          ? "แพ็กเกจ"
          : "คอร์สเรียน"
        }`,
      children: <CourseReview courseId={dataCourse?.id || 0} />,
      disabled: true
    },
  ].filter(Boolean);


  useEffect(() => {
    setType(dataCourse?.type || componentType);
  }, [dataCourse]);

  useEffect(() => {
    if (getCourseSchedule) {
      setSchedule(convertListDataForCalendar(getCourseSchedule));
    } else {
      setSchedule({})
    }
  }, [getCourseSchedule]);

  useEffect(() => {
    let isValidType = _.find(courseTypeOption, { value: type });
    if (isValidType) {
      setCourseType(isValidType?.label);
    }
    refetchCourse();
    if (isShowSchedule) {
      refetchCourseSchedule();
    }
    refetchUnitCourse();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [componentType, type, id]);

  useEffect(() => {
    const roleUser = getRoleUser();
    if(roleUser){
      const path = `/${roleUser}/${componentType}/${id}`;
      const isMyCourse = getAllMyCourseId?.data?.find(
        (e) => e.courseId === id || e.packageId === id || e.subscriptionId === id
      );
      if (isMyCourse) {
        navigate(path);
      }
    }
  }, [id, getAllMyCourseId]);

  return (
    <>
      <Breadcrumb items={breadcrumb_item} />
      <div className="sg-section">
        <div className="section-content course-details bg-white section-padding">
          <div className="container">
            {isLoadingCourse ? (
              <Row className="center py-4">
                <Spin />
              </Row>
            ) : (
              <div className="row">
                <div className="col-lg-8">
                  <div className="sa-course">
                    <div
                      style={{
                        maxWidth: "900px",
                        maxHeight: "500px",
                        overflow: "hidden",
                      }}
                      className="course-thumb center d-flex justify-content-center align-items-center"
                    >
                      <img
                        src={dataCourse?.coverImgUrl || NOT_FOUND_IMG}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = NOT_FOUND_IMG;
                        }}
                        alt="cover"
                        className="img-fluid object-fit-cover w-100 h-100"
                      />
                    </div>
                    <div className="course-info">
                      <h2 className="title">{`${dataCourse?.title || "Course title name"
                        } `}</h2>

                      <Tabs
                        items={
                          type === "online"
                            ? _.filter(courseItems, (n) => n?.id !== 2)
                            : courseItems
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="b-shadow sidebar course-sidebar">
                    <div className="widget-area">
                      <SidebarCourses
                        dataSource={dataCourse}
                        setSchedule={setSchedule}
                      />
                      {type !== "workshop" ? (
                        <UnitCourse
                          data={
                            type === "package"
                              ? dataCourse?.packageDetail
                              : getUnitCourse
                          }
                          type={type}
                        />
                      ) : null}
                      <PopularCourses />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseDetails;
