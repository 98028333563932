import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Table,
  Upload,
} from "antd";
import PreviewImageModal from "components/PreviewImageModal";
import {
  AlertConfirm,
  AlertError,
  AlertSuccess,
  AlertWarning,
} from "components/alert/Alert";
import { dummyRequest } from "hooks/dummyRequest";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { onUploadFile } from "hooks/onUploadFile";
import useModalHook from "hooks/useModalHook";
import useUploadFileHook from "hooks/useUploadFileHook";
import _ from "lodash";
import { useEffect, useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import packageBackofficeServices from "services/backoffice/packageBackoffice.services";
import PreviewPackageModal from "./PreviewPackageModal";
const { TextArea } = Input;
const PackageFormModal = ({ isEditable, data, open, onCancel, refetch }) => {
  const [tmpDelPrice, setTmpDelPrice] = useState(0)

  const {
    open: openUnitPreview,
    handleOpen: _handleOpenUnitPreview,
    handleClose: _handleCloseUnitPreview,
  } = useModalHook();

  const {
    fileList,
    setFileList,
    previewImage,
    previewOpen,
    handlePreview: _handlePreviewUpload,
    handleChange: _handleChangeUpload,
    handleCancel: _handleCancelUpload,
  } = useUploadFileHook();

  const limitPage = 5;
  const [page, setPage] = useState(1);

  const [form] = Form.useForm();
  const [preview, setPreview] = useState(null);
  const [nonePromotionPrice, setNonePromotionPrice] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  const { isFetching: isLoadingMyUnit } =
    courseBackOfficeServices.useQueryGetAllMyUnit({
      onSuccess: (res) => {
        const data = res?.data;
        const unitData = data?.map((n) => ({ ...n, key: n?.id }));
        setDataSource(unitData);
      },
    });

  const { mutate: createPackage } =
    packageBackofficeServices.useMutationCreatePackage(
      () => {
        refetch();
        AlertSuccess({});
        onCancel();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const { mutate: updatePackage } =
    packageBackofficeServices.useMutationUpdatePackage(
      () => {
        refetch();
        AlertSuccess({});
        onCancel();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const { mutate: deletePackage } =
    packageBackofficeServices.useMutationDeletePackage(
      () => {
        refetch();
        AlertSuccess({});
        onCancel();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
        onCancel();
      }
    );

  const onFinish = async (values) => {
    const prefix = "package/picture";
    let fileName =
      _.size(fileList) > 0
        ? await onUploadFile({ prefix, fileList: fileList })
        : data?.coverImg;

    if (data?.id) {
      // update
      const oldPicked = data?.packageDetail.map(({ id, unitId }) => ({ id, unitId }))
      const formattedData = selectedRowData.map(row => {
        const data = { ...row }
        const matchPick = oldPicked.find(old => old.unitId === row.id)
        if (matchPick) {
          data.unitId = row.id
          data.id = matchPick.id
        } else {
          data.unitId = row.id
          data.id = undefined
        }
        return data
      })
      const _update = {
        ...data,
        ...values,
        delPrice: values.nonePromotionPrice ? null : values?.delPrice,
        coverImg: fileName,
        prefixPath: _.size(fileList) > 0 ? prefix : null,
        packageDetail: formattedData,
      };
      updatePackage({ values: _update });
    } else {
      //create
      const _create = {
        ...values,
        delPrice: values.nonePromotionPrice ? null : values?.delPrice,
        coverImg: fileName,
        prefixPath: _.size(fileList) > 0 ? prefix : null,
        packageDetail: selectedRowData?.map((n) => ({ ...n, unitId: n?.id })),
      };
      createPackage({ values: _create });
    }
  };

  const headerTable = [
    {
      title: "บทเรียน",
      dataIndex: "unitName",
      key: "unitName",
    },
    {
      title: "ประเภท",
      dataIndex: "courseType",
      key: "courseType",
    },
    {
      title: "แสดงรายละเอียด",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              setPreview(record);
              _handleOpenUnitPreview();
            }}
          >
            รายละเอียด
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (open && data) {
      let _field = {
        ...data,
        nonePromotionPrice: data?.delPrice ? false : true,
      };
      form.setFieldsValue(_field);
      setNonePromotionPrice(data?.delPrice ? false : true);
      setTmpDelPrice(data?.delPrice || 0)
      const selectedData = data?.packageDetail?.map(e => e.unitId) || []
      setSelectedRowKeys(
        _.uniq(data?.packageDetail.map((n) => n.unitId) || [])
      );
      setSelectedRowData(_.uniq(dataSource?.filter(e => selectedData.includes(e.id)) || []));
      if (data?.coverImgUrl) {
        setFileList([{ url: data?.coverImgUrl, status: "done" }]);
      }
    } else {
      if (form.__INTERNAL__.name) {
        form.resetFields();
      }
      setFileList([]);
      setNonePromotionPrice(false);
      setSelectedRowData([]);
      setSelectedRowKeys([]);
    }
  }, [open, data]);

  return (
    <>
      <Modal
        title={
          isEditable
            ? data
              ? "แก้ไขแพ็กเกจ"
              : "เพิ่มแพ็กเกจ"
            : "รายละเอียดแพ็กเกจ"
        }
        open={open}
        okButtonProps={{ disabled: !isEditable }}
        onOk={() => {
          if (selectedRowKeys.length <= 0) {
            AlertWarning({ text: "กรุณาเลือกบทเรียนอย่างน้อย 1 รายการ" });
          } else {
            form.submit();
          }
        }}
        onCancel={() => {
          setSelectedRowData([]);
          setSelectedRowKeys([]);
          if (!data) {
            form.resetFields();
            setNonePromotionPrice(false);
          }
          onCancel();
        }}
        okText="บันทึก"
        cancelText="ยกเลิก"
        width={580}
      >
        <Form
          form={form}
          name="unit-teacher-form"
          className="mt-4"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          {(!isEditable && fileList?.length) || isEditable ? (
            <Row className="mb-3">
              <Col span={24}>
                {isEditable ? <p className="mb-2">อัปโหลดภาพหน้าปก</p> : null}
                <Upload
                  customRequest={dummyRequest}
                  fileList={fileList}
                  accept="image/*"
                  listType="picture-card"
                  maxCount={1}
                  onPreview={_handlePreviewUpload}
                  onChange={_handleChangeUpload}
                  disabled={!isEditable}
                >
                  {isEditable ? (
                    <div>
                      <MdOutlineFileUpload size={20} className="mb-2" />
                      <p> อัปโหลดภาพ</p>
                    </div>
                  ) : null}
                </Upload>
              </Col>
            </Row>
          ) : null}
          <Form.Item
            label="ชื่อแพ็กเกจ"
            name="title"
            rules={[
              {
                required: true,
                message: "กรุณาระบุชื่อแพ็กเกจ !",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="ชื่อแพ็กเกจ"
              readOnly={!isEditable}
            />
          </Form.Item>
          <Form.Item
            label="รายละเอียด"
            name="description"
            rules={[
              {
                required: true,
                message: "กรุณาระบุรายละเอียด!",
              },
            ]}
          >
            <TextArea
              rows={3}
              size="large"
              placeholder="รายละเอียด"
              readOnly={!isEditable}
            />
          </Form.Item>
          <Form.Item
            label="ราคาพิเศษ (ราคาก่อนลด)"
            name="delPrice"
            initialValue={0}
            onChange={e => {
              setTmpDelPrice(e?.target?.value)
            }}
            rules={[
              {
                required: nonePromotionPrice ? false : true,
                message: "กรุณาระบุราคาพิเศษ!",
              },
              {
                validator: async (_, value) => {
                  if (value && value <= form.getFieldValue("price")) {
                    throw new Error(
                      "ราคาพิเศษ (ราคาก่อนลด) ต้องมากกว่าราคาหลังลด!"
                    );
                  }
                },
              },
            ]}
          >
            <InputNumber
              min={0}
              disabled={nonePromotionPrice}
              className="w-100"
              size="large"
              placeholder="ราคาพิเศษ (ราคาก่อนลด)"
              readOnly={!isEditable}
            />
          </Form.Item>
          <Form.Item
            name="nonePromotionPrice"
            valuePropName="checked"
            initialValue={nonePromotionPrice}
            onChange={(e) => {
              setNonePromotionPrice(e.target.checked);
              form.setFieldsValue({
                delPrice: e.target.checked ? 0 : tmpDelPrice
              });
            }}
          >
            <div className="d-flex align-items-center gap-2">
              <Checkbox checked={nonePromotionPrice} size="large" disabled={!isEditable} />
              <p>ไม่มีราคาพิเศษ</p>
            </div>
          </Form.Item>

          <Form.Item
            label={nonePromotionPrice ? "ราคา" : "ราคาหลังลด"}
            name="price"
            rules={[
              {
                required: true,
                message: "กรุณาระบุราคา!",
              },
              {
                validator: async (_, value) => {
                  if (!nonePromotionPrice) {
                    if (value && value >= form.getFieldValue("delPrice")) {
                      throw new Error("ราคาหลังลดต้องน้อยกว่าราคาปกติ");
                    }
                  }
                },
              },
            ]}
          >
            <InputNumber
              min={0}
              className="w-100"
              size="large"
              label={nonePromotionPrice ? "ราคา" : "ราคาหลังลด"}
              readOnly={!isEditable}
            />
          </Form.Item>
          <p className="mb-2">{`เลือกบทเรียนที่จะรวมในแพ็กเกจ ( เลือกแล้ว ${selectedRowKeys.length} รายการ )`}</p>
          <Table
            loading={isLoadingMyUnit}
            bordered
            rowSelection={{
              selectedRowKeys,
              onChange: (rowKeys, rowData) => {
                setSelectedRowData(rowData);
                setSelectedRowKeys(rowKeys);
              },
              getCheckboxProps: (record) => ({
                disabled: !isEditable,
                key: record.key,
              }),
            }}
            columns={headerTable}
            dataSource={dataSource}
            pagination={{
              pageSize: 5,
            }}
          />
        </Form>
        {data?.id ? (
          <Button
            block
            danger
            className="mb-4"
            disabled={!isEditable}
            onClick={() => {
              AlertConfirm({
                text: "ต้องการลบแพ็กเกจนี้หรือไม่ ?",
                onOk: () => {
                  deletePackage({ packageId: data?.id });
                },
              });
            }}
          >
            ลบแพ็กเกจนี้
          </Button>
        ) : null}
      </Modal>
      <PreviewPackageModal
        data={preview}
        open={openUnitPreview}
        onCancel={() => {
          _handleCloseUnitPreview();
          setPreview(null);
        }}
      />
      <PreviewImageModal
        open={previewOpen}
        onCancel={_handleCancelUpload}
        previewImage={previewImage}
      />
    </>
  );
};

export default PackageFormModal;
