import { Button, Card, Col, Divider, Row } from "antd";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import moment from "moment";
import numeral from "numeral";
import paymentService from "services/payment.services";
import ProductCardHistory from "./ProductCardHistory";
import { errorToMessage } from "hooks/functions/errorToMessage";

const HistoryCard = ({
  data = {},
  tabs = 1,
  onClickActivate = () => { },
  onCancelOrder = () => { },
  refetchMyPayments = () => { },
}) => {
  const { mutate: cancelPayment } = paymentService.useMutationCancelPayment(
    () => {
      refetchMyPayments();
      AlertSuccess({});
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );

  return (
    <Card className="!tw-mb-4">
      <div className="tw-w-full">
        <div>
          <p>หมายเลขคำสั่งซื้อ : {data?.orderNo || data?.id}</p>
          {data?.method !== "online" && <p>หมายเลข tracking : {data?.trackingNo}</p>}
          <p>
            วันที่ทำรายการ :{" "}
            {moment(data?.paidTime).format("DD/MM/YYYY เวลา HH:mm น.")}
          </p>
        </div>
        <div>
          <p className="text-primary">
            ประเภทการชำระเงิน :{" "}
            {data?.method === "online" ? "ออนไลน์" : "ปลายทาง"}
          </p>
        </div>
        <Divider className="!tw-my-4" />
        <Row>
          <Col span={24}>
            {(data?.paymentDetail || []).map((data, index) => (
              <ProductCardHistory
                key={index}
                tabs={tabs}
                data={data}
                method={data?.method}
              />
            ))}
          </Col>
        </Row>
        <Divider className="!tw-my-4" />
        <div>
          <p>ราคารวม {numeral(data?.amount).format("0,0")} บาท</p>
          <p>ส่วนลด {numeral(data?.deduction).format("0,0")} บาท</p>
        </div>
        <Divider className="!tw-my-4" />
        <div className="tw-flex tw-justify-between tw-items-center">
          <div>
            <p className="tw-text-[18px] tw-font-normal tw-text-primary">
              รวมการสั่งซื้อ {numeral(data?.net).format("0,0")} บาท
            </p>
          </div>
          <div>
            {tabs === 1 ? (
              <>
                <Button
                  type="primary"
                  onClick={() => {
                    AlertConfirm({
                      onOk: () => {
                        cancelPayment({ paymentId: data?.id });
                      },
                    });
                  }}
                >
                  ยกเลิกคำสั่งซื้อ
                </Button>
                {data?.method !== "online" && (
                <Button
                  type="primary"
                  className="tw-ml-2"
                  onClick={() => {
                    onClickActivate();
                  }}
                >
                  เปิดใช้งานคอร์สเรียน
                </Button>)}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default HistoryCard;
