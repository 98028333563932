import { Button, Form, Input, Tabs } from "antd";
import { icon_line } from "assets/icons";
import { NOT_FOUND_IMG } from "assets/images";
import {
  AlertError,
  AlertLoading,
  AlertSuccess,
  AlertWarning,
} from "components/alert/Alert";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import masterService from "services/master.services";
import Swal from "sweetalert2";
import { LINE_CHANNEL_ID, REDIRECT_URI } from "utils";
import { bg_tonemusic } from "assets";

const backgroundStyle = {
  margin: 0,
  padding: 0,
  backgroundImage: `url(${bg_tonemusic})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  height: "100vh",
};

const RegisterPage = () => {
  const navigate = useNavigate();
  const [tabSelected, setTabSelected] = useState("student");

  const { mutate: postRegister, isLoading: isLoadingRegister } =
    masterService.useMutationRegister(
      (res) => {
        Swal.close();
        AlertSuccess({
          text: "สมัครสมาชิกเรียบร้อย!",
          onOk: () => {
            navigate("/login");
          },
        });
      },
      (err) => {
        AlertError({ text: `REGISTER :${err?.message}` });
      }
    );

  const onFinish = (values) => {
    const { password, confirm_password } = values;
    if (password !== confirm_password) {
      AlertWarning({ text: "กรุณารหัสผ่านให้ตรงกัน" });
    } else {
      postRegister({
        values: {
          ...values,
          role: tabSelected,
        },
      });
    }
  };

  if (isLoadingRegister) {
    AlertLoading({});
  }

  const generateRandomState = () => {
    const randomState = Math.random().toString(36).substring(2, 15);
    return randomState;
  };

  const state = generateRandomState();

  const handleLineLogin = () => {
    if (!LINE_CHANNEL_ID || !REDIRECT_URI) {
      console.error(
        "Invalid Line configuration",
        LINE_CHANNEL_ID,
        REDIRECT_URI
      );
      return;
    }

    const lineAuthUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CHANNEL_ID}&redirect_uri=${REDIRECT_URI}&state=${state}&scope=profile%20openid%20email`;
    // Redirect to Line Login
    window.location.href = lineAuthUrl;
  };

  const renderContent = () => {
    return (
      <Form
        className="mt-4"
        name="register_form"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="ชื่อจริง"
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please input your firstName!",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="นามสกุล"
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please input your lastName!",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="E-mail"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          label="รหัสผ่าน"
          name="password"
          rules={[
            {
              required: true,
              message: "กรุณาระบุรหัสผ่าน!",
            },
            {
              min: 8,
              message: "รหัสผ่านควรมีอย่างน้อย 8 ตัวขึ้นไป!",
            },
            {
              pattern: /^(?=.*[a-zA-Z])(?=.*\d).+$/,
              message: "รหัสผ่านควรมีตัวอักษรและตัวเลขอย่างน้อย 1 ตัว!",
            },
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>
        <Form.Item
          label="ยืนยันรหัสผ่าน"
          name="confirm_password"
          rules={[
            {
              required: true,
              message: "กรุณายืนยันรหัสผ่าน!",
            },
            {
              min: 8,
              message: "รหัสผ่านควรมีอย่างน้อย 8 ตัวขึ้นไป!",
            },
            {
              pattern: /^(?=.*[a-zA-Z])(?=.*\d).+$/,
              message: "รหัสผ่านควรมีตัวอักษรและตัวเลขอย่างน้อย 1 ตัว!",
            },
          ]}
        >
          <Input.Password size="large" />
        </Form.Item>

        <Form.Item>
          <Button
            style={{ height: "45px" }}
            type="primary"
            className="w-100 mt-3"
            htmlType="submit"
          >
            <p>สมัครสมาชิก</p>
          </Button>
        </Form.Item>

        <Button
          style={{ height: "45px" }}
          className="w-100 mb-3"
          onClick={handleLineLogin}
        >
          <div className=" center">
            <img
              width={24}
              src={icon_line || NOT_FOUND_IMG}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = NOT_FOUND_IMG;
              }}
              alt="line-icon"
              className="me-3"
            />
            <p>เข้าสู่ระบบด้วยไลน์</p>
          </div>
        </Button>

        <div className="middle-content my-2">
          <p>
            ฉันมีบัญชีสำหรับเข้าใช้งานแล้ว ?{" "}
            <Link to="/login">เข้าสู่ระบบ</Link>
          </p>
        </div>
      </Form>
    );
  };

  const tabItems = [
    {
      key: "student",
      label: "สำหรับนักเรียน",
      children: renderContent(),
    },
    {
      key: "teacher",
      label: "สำหรับครูผู้สอน",
      children: renderContent(),
    },
  ];

  return (
    <>
      <div style={backgroundStyle}>
        <div className="sg-section">
          <div className="section-content section-padding">
            <div className="container">
              <div className="ragister-account text-center">
                <div className="row">
                  <div className="col-lg-6 offset-lg-3">
                    <div className="account-content">
                      <div
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="d-flex w-100"
                      >
                        <h1 className="w-100">สมัครสมาชิก</h1>
                        <Link to="/" style={{ width: "200px" }}>
                          <u className="me-2">กลับสู่หน้าหลัก</u>
                        </Link>
                      </div>
                      <Tabs
                        className="ragister-form"
                        size="large"
                        defaultActiveKey="student"
                        items={tabItems}
                        onChange={(key) => setTabSelected(key)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
