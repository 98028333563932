import { DownloadOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Divider, Row, Select } from "antd";
import { thaiLocale } from "constants/thaiLocale/thaiLocale";
import { useState } from "react";
import fileService from "services/file.services";

const AdminReport = () => {
  const [reportName, setReportName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const reportOption = [
    { value: "downloadPaymentHistory", label: "ประวัติการชำระเงิน" },
    { value: "downloadPopularCourse", label: "สถิติคอร์สยอดนิยม" },
    {
      value: "downloadPopularCategoryCourse",
      label: "สถิติหมวดหมู่เครื่องดนตรียอดนิยม",
    },
    { value: "downloadPopularTypeCourse", label: "สถิติประเภทคอร์สยอดนิยม" },
  ];
  const { mutate: downloadPaymentHistory } =
    fileService.useMutationDownloadPaymentHistory(startDate, endDate);
  const { mutate: downloadPopularCourse } =
    fileService.useMutationDownloadPopularCourse(startDate, endDate);
  const { mutate: downloadPopularCategoryCourse } =
    fileService.useMutationDownloadPopularCategoryCourse(startDate, endDate);
  const { mutate: downloadPopularTypeCourse } =
    fileService.useMutationDownloadPopularTypeCourse(startDate, endDate);

  return (
    <>
      <h4 className="mb-4">รายงาน</h4>
      <Row className="mb-4">
        <Select
          size="large"
          className="w-100"
          placeholder="เลือกรายงาน"
          options={reportOption}
          onChange={(e) => {
            setReportName(e);
          }}
        />
      </Row>
      <Row gutter={[16, 16]} className="mb-4">
        <Col xs={24} sm={12}>
          <p className="mb-2">จากวันที่</p>
          <DatePicker
            format="DD/MM/YYYY"
            size="large"
            className="w-100"
            placeholder="วัน/เดือน/ปี"
            name="startDate"
            locale={thaiLocale}
            onChange={(date, dateString) => {
              setStartDate(dateString);
            }}
          />
        </Col>
        <Col xs={24} sm={12}>
          <p className="mb-2">ถึงวันที่</p>
          <DatePicker
            format="DD/MM/YYYY"
            size="large"
            className="w-100"
            placeholder="วัน/เดือน/ปี"
            locale={thaiLocale}
            onChange={(date, dateString) => {
              setEndDate(dateString);
            }}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[16, 16]} className="mb-4">
        <Col xs={24} sm={12}>
          <Button
            block
            size="large"
            type="primary"
            htmlType="submit"
            icon={<DownloadOutlined />}
            disabled={true}
          >
            ดาวน์โหลด PDF
          </Button>
        </Col>
        <Col xs={24} sm={12}>
          <Button
            block
            size="large"
            type="primary"
            icon={<DownloadOutlined />}
            disabled={reportName === "" || startDate === "" || endDate === ""}
            onClick={() => {
              if (reportName === "downloadPaymentHistory") {
                downloadPaymentHistory();
              } else if (reportName === "downloadPopularCourse") {
                downloadPopularCourse();
              } else if (reportName === "downloadPopularCategoryCourse") {
                downloadPopularCategoryCourse();
              } else if (reportName === "downloadPopularTypeCourse") {
                downloadPopularTypeCourse();
              }
            }}
          >
            ดาวน์โหลด Excel
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default AdminReport;
