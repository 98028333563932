import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Upload,
} from "antd";
import PreviewImageModal from "components/PreviewImageModal";
import { AlertClosed } from "components/alert/Alert";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import SelectCourseCategory from "components/elements/SelectCourseCategory";
import { dummyRequest } from "hooks/dummyRequest";
import { courseTypeToText } from "hooks/functions/courseTypeToText";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { onUploadFile } from "hooks/onUploadFile";
import { toDateFormat } from "hooks/toDateFormat";
import useUploadFileHook from "hooks/useUploadFileHook";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import workshopBackofficeServices from "services/backoffice/workshopBackoffice.services";
import { getUserId } from "utils";
const { TextArea } = Input;

const WorkshopFormModal = ({ isEditable, data, refetch, open, onCancel }) => {
  const [form] = Form.useForm();
  const [nonePromotionPrice, setNonePromotionPrice] = useState(false);
  const [tmpDelPrice, setTmpDelPrice] = useState(0)
  const {
    fileList,
    setFileList,
    previewImage,
    previewOpen,
    handlePreview: _handlePreviewUpload,
    handleChange: _handleChangeUpload,
    handleCancel: _handleCancelUpload,
  } = useUploadFileHook();

  const { mutate: deleteWorkshop, isLoading: isLoadingDelete } =
    workshopBackofficeServices.useMutationDeleteWorkshop(
      (res) => {
        const { code, description } = res.status;
        AlertClosed();
        if (code === "200") {
          refetch();
          AlertSuccess({});
        } else {
          AlertError({ text: description });
        }
      },
      (err) => {
        AlertClosed();
        AlertError({ text: errorToMessage(err) });
      }
    );

  const { mutate: createWorkshop } =
    workshopBackofficeServices.useMutationCreateWorkshop(
      () => {
        refetch();
        onCancel();
        AlertSuccess({
          text: `
          กรุณารอตรวจสอบเนื้อหาความถูกต้องและกดส่งอนุมัติเพื่อรอการอนุมัติจากแอดมิน
          หากเวิร์กชอปของท่านได้รับการอนุมัติแล้ว ท่านจะได้รับอีเมลแจ้งเตือน
          `,
        });
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );
  const { mutate: updateWorkshop } =
    workshopBackofficeServices.useMutationUpdateWorkshop(
      () => {
        refetch();
        onCancel();
        AlertSuccess({});
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const onFinish = async (values) => {
    const prefix = "workshop/picture";
    let fileName =
      _.size(fileList) > 0
        ? await onUploadFile({ prefix, fileList: fileList })
        : data?.coverImg;
    if (data?.id) {
      // update workshop
      let _deleteCourseEarn = _.map(
        _.differenceBy(data?.courseEarn, values.courseEarn, "id"),
        (item) => ({ ...item, flag: "delete" })
      );
      let _deleteCourseObjective = _.map(
        _.differenceBy(data?.courseObjective, values.courseObjective, "id"),
        (item) => ({ ...item, flag: "delete" })
      );

      let _update = {
        course: {
          ...data,
          ...values,
          startDate: toDateFormat(values?.startDate),
          delPrice: values.nonePromotionPrice ? null : values?.delPrice,
          coverImg: fileName,
          prefixPath: _.size(fileList) > 0 ? prefix : null,
        },
        courseEarn: [...(values?.courseEarn || []), ..._deleteCourseEarn],
        courseObjective: [
          ...(values?.courseObjective || []),
          ..._deleteCourseObjective,
        ],
      };
      updateWorkshop({ values: _update });
    } else {
      // create workshop
      let _create = {
        course: {
          ...data,
          ...values,
          startDate: toDateFormat(values?.startDate),
          delPrice: values.nonePromotionPrice ? null : values?.delPrice,
          type: "workshop",
          teacherId: getUserId(),
          coverImg: fileName,
          prefixPath: _.size(fileList) > 0 ? prefix : null,
        },
        courseEarn: _.map(values?.courseEarn, (n) => {
          return { ...n, flag: "insert", type: "earn" };
        }),
        courseObjective: _.map(values?.courseObjective, (n) => {
          return { ...n, flag: "insert", type: "objective" };
        }),
      };
      if (_.size(fileList) > 0 && fileName) {
        createWorkshop({ values: _create });
      } else if (_.size(fileList) < 1 && !fileName) {
        createWorkshop({ values: _create });
      }
    }
  };

  useEffect(() => {
    if (open && data) {
      let _field = {
        ...data,
        type: courseTypeToText(data?.type),
        nonePromotionPrice: data?.delPrice ? false : true,
      };
      form.setFieldsValue(_field);
      setNonePromotionPrice(data?.delPrice ? false : true);
      setTmpDelPrice(data?.delPrice || 0)
      setFileList([{ url: data?.coverImgUrl, status: "done" }]);
    } else {
      if (form.__INTERNAL__.name) {
        form.resetFields();
      }
      setNonePromotionPrice(false);
      setFileList([]);
    }
  }, [open, data]);

  return (
    <>
      <Modal
        title={
          isEditable
            ? data
              ? "แก้ไขเวิร์กชอป"
              : "เพิ่มเวิร์กชอป"
            : "รายละเอียดเวิร์กชอป"
        }
        open={open}
        okButtonProps={{ disabled: !isEditable }}
        onOk={() => {
          AlertConfirm({
            onOk: () => {
              form.submit();
            },
          });
        }}
        onCancel={() => {
          if (!data) {
            setFileList([]);
            form.resetFields();
          } else {
            if (data?.coverImgUrl) {
              setFileList([{ url: data?.coverImgUrl, status: "done" }]);
            }
          }
          onCancel();
        }}
        okText="บันทึก"
        cancelText="ยกเลิก"
      >
        <Form
          form={form}
          name="awards"
          className="mt-4"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          {(!isEditable && fileList?.length) || isEditable ? (
            <Row className="mb-3">
              <Col span={24}>
                {isEditable ? <p className="mb-2">อัปโหลดภาพหน้าปก</p> : null}
                <Upload
                  customRequest={dummyRequest}
                  fileList={fileList}
                  accept="image/png, image/jpeg"
                  listType="picture-card"
                  maxCount={1}
                  onPreview={_handlePreviewUpload}
                  onChange={_handleChangeUpload}
                  disabled={!isEditable}
                >
                  {isEditable ? (
                    <div>
                      <MdOutlineFileUpload size={20} className="mb-2" />
                      <p> อัปโหลดภาพ</p>
                    </div>
                  ) : null}
                </Upload>
              </Col>
            </Row>
          ) : null}

          <Form.Item
            label="ชื่อเวิร์กชอป"
            name="title"
            rules={[
              {
                required: true,
                message: "กรุณาระบุชื่อเวิร์กชอป!",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="ชื่อเวิร์กชอป"
              readOnly={!isEditable}
            />
          </Form.Item>
          <Form.Item
            label="รายละเอียด"
            name="description"
            rules={[
              {
                required: true,
                message: "กรุณาระบุรายละเอียด!",
              },
            ]}
          >
            <TextArea
              rows={4}
              size="large"
              placeholder="รายละเอียด"
              readOnly={!isEditable}
            />
          </Form.Item>

          <Form.List name="courseObjective">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <React.Fragment key={key}>
                      <Row className="center">
                        <Col flex={1}>
                          <Form.Item
                            {...restField}
                            name={[name, "description"]}
                            label={`วัตถุประสงค์ ${index + 1}`}
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุวัตถุประสงค์!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="วัตถุประสงค์"
                              readOnly={!isEditable}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          className="ms-2"
                          style={{
                            width: "48px",
                            paddingTop: "30px",
                            paddingBottom: "24px",
                          }}
                        >
                          <Button
                            className="w-100"
                            size="large"
                            danger
                            onClick={() => {
                              remove(name);
                            }}
                            disabled={!isEditable}
                          >
                            ลบ
                          </Button>
                        </Col>
                      </Row>
                    </React.Fragment>
                  );
                })}
                {isEditable ? (
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + เพิ่มวัตถุประสงค์
                    </Button>
                  </Form.Item>
                ) : null}
              </>
            )}
          </Form.List>

          <Form.List name="courseEarn">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <React.Fragment key={key}>
                      <Row className="center">
                        <Col flex={1}>
                          <Form.Item
                            {...restField}
                            name={[name, "description"]}
                            label={`สิ่งที่คุณจะได้รับในเวิร์กชอป ${index + 1}`}
                            rules={[
                              {
                                required: true,
                                message:
                                  "กรุณาระบุสิ่งที่คุณจะได้รับในเวิร์กชอป!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="สิ่งที่คุณจะได้รับในเวิร์กชอป"
                              readOnly={!isEditable}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          className="ms-2"
                          style={{
                            width: "48px",
                            paddingTop: "30px",
                            paddingBottom: "24px",
                          }}
                        >
                          <Button
                            className="w-100"
                            size="large"
                            danger
                            onClick={() => {
                              remove(name);
                            }}
                            disabled={!isEditable}
                          >
                            ลบ
                          </Button>
                        </Col>
                      </Row>
                    </React.Fragment>
                  );
                })}
                {
                  isEditable ?
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block>
                        + เพิ่มสิ่งที่คุณจะได้รับในเวิร์กชอป
                      </Button>
                    </Form.Item>
                    :
                    null
                }
              </>
            )}
          </Form.List>

          <Form.Item
            label="หมวดหมู่"
            name="category"
            rules={[
              {
                required: true,
                message: "กรุณาเลือกหมวดหมู่!",
              },
            ]}
          >
            <SelectCourseCategory size="large" disabled={!isEditable} />
          </Form.Item>

          <Form.Item
            label="ราคาพิเศษ (ราคาก่อนลด)"
            name="delPrice"
            initialValue={0}
            onChange={e => {
              setTmpDelPrice(e?.target?.value)
            }}
            rules={[
              {
                required: nonePromotionPrice ? false : true,
                message: "กรุณาระบุราคาพิเศษ!",
              },
              {
                validator: async (_, value) => {
                  if (value && value <= form.getFieldValue("price")) {
                    throw new Error(
                      "ราคาพิเศษ (ราคาก่อนลด) ต้องมากกว่าราคาหลังลด!"
                    );
                  }
                },
              },
            ]}
          >
            <InputNumber
              min={0}
              disabled={nonePromotionPrice}
              className="w-100"
              size="large"
              placeholder="ราคาพิเศษ (ราคาก่อนลด)"
              readOnly={!isEditable}
            />
          </Form.Item>

          <Form.Item
            name="nonePromotionPrice"
            valuePropName="checked"
            initialValue={nonePromotionPrice}
            onChange={(e) => {
              setNonePromotionPrice(e.target.checked);
              form.setFieldsValue({
                delPrice: e.target.checked ? 0 : tmpDelPrice
              });
            }}
          >
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                checked={nonePromotionPrice}
                size="large"
                disabled={!isEditable}
              />
              <p>ไม่มีราคาพิเศษ</p>
            </div>
          </Form.Item>

          <Form.Item
            label={nonePromotionPrice ? "ราคา" : "ราคาหลังลด"}
            name="price"
            rules={[
              {
                required: true,
                message: "กรุณาระบุราคา!",
              },
              {
                validator: async (_, value) => {
                  if (!nonePromotionPrice) {
                    if (value && value >= form.getFieldValue("delPrice")) {
                      throw new Error("ราคาหลังลดต้องน้อยกว่าราคาปกติ");
                    }
                  }
                },
              },
            ]}
          >
            <InputNumber
              min={0}
              className="w-100"
              size="large"
              placeholder={nonePromotionPrice ? "ราคา" : "ราคาหลังลด"}
              readOnly={!isEditable}
            />
          </Form.Item>
        </Form>
        {data?.id && (
          <Button
            block
            danger
            className="mb-4"
            onClick={() => {
              AlertConfirm({
                text: "ต้องการลบเวิร์กชอปนี้หรือไม่ ?",
                onOk: () => {
                  onCancel();
                  deleteWorkshop({ workshopId: data?.id });
                },
              });
            }}
            disabled={!isEditable}
          >
            ลบเวิร์กชอป
          </Button>
        )}
      </Modal>
      <PreviewImageModal
        open={previewOpen}
        onCancel={_handleCancelUpload}
        previewImage={previewImage}
      />
    </>
  );
};

export default WorkshopFormModal;
