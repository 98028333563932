import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    OPEN_LOADING: (state) => {
      state.loading = true;
    },
    CLOSE_LOADING: (state) => {
      state.loading = false;
    },
    SET_LOADING: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { OPEN_LOADING, CLOSE_LOADING, SET_LOADING } = loadingSlice.actions;
export default loadingSlice.reducer;
