import { Button, Card, Col, Divider, Empty, Row, Tabs } from "antd";
import { COLOR } from "constants/color";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";
import { courseTypeToText } from "hooks/functions/courseTypeToText";
import useModalHook from "hooks/useModalHook";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { IoBookOutline } from "react-icons/io5";
import { LiaEditSolid } from "react-icons/lia";
import { MdOutlineAccessTime } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import CourseOtherModal from "../course-modal/CourseOtherModal";
import courseService from "services/course.services";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { getIsKruNut } from "utils";
import dayjs from "dayjs";
import { LuUser2 } from "react-icons/lu";
import GroupModal from "../course-modal/GroupModal";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";

const CourseOther = ({ data, index, refetch = () => { } }) => {
  const navigate = useNavigate();
  const [editUnit, setEditUnit] = useState(null);
  const [editZoomGroup, setEditZoomGroup] = useState(null);
  const [tab, setTab] = useState(1);
  const [currentIndex, setCurrentIndex] = useState(1);
  const { courseId } = useParams();
  const isKruNut = getIsKruNut();

  const {
    data: flag
  } = courseService.useQueryGetCourseFlagById({ id: courseId });

  const {
    data: zoomGroupData,
    refetch: refetchGroup,
  } = courseBackOfficeServices.useQueryGetAllZoomGroupByCourseId({ id: courseId });

  const {
    open: openCreateUnit,
    handleOpen: _handleOpenCreateUnit,
    handleClose: _handleCloseCreateUnit,
  } = useModalHook();

  const {
    open: openCreateGroup,
    handleOpen: _handleOpenCreateGroup,
    handleClose: _handleCloseCreateGroup,
  } = useModalHook();

  const renderSessionContent = () => (
    <>
      <Row className="d-flex justify-content-between">
        <div>
          <h4>
            {`${data?.courseName} ( ${numeral(zoomGroupData?.total).format("0,0")} )`}
          </h4>
          <p>{courseTypeToText(data?.type)}</p>
        </div>
        <Button
          type="primary"
          onClick={() => {
            setEditZoomGroup(null);
            _handleOpenCreateGroup();
          }}
        >
          + เพิ่มกลุ่มเรียน
        </Button>
      </Row>
      <Divider />
      <Row>
        {zoomGroupData?.data ? (
          <Col span={24}>
            {zoomGroupData?.data?.map((n, i) => {
              const isNotAllow = dayjs(n.startDate).isBefore(dayjs(new Date()).add(7, 'day'))
              return (
                <Card
                  key={`zoomGroup-${i}`}
                  title={`กลุ่มที่ ${i + 1} รอบวันที่ ${moment(n?.startDate).format(
                    "DD/MM/YYYY"
                  )} - ${moment(n?.finishDate).format(
                    "DD/MM/YYYY"
                  )}`}
                  extra={
                    <div
                      className="text-primary center tw-cursor-pointer"
                      onClick={() => {
                        setEditZoomGroup({ ...n })
                        setCurrentIndex(i + 1);
                        _handleOpenCreateGroup();
                      }}
                    >
                      {isNotAllow ? (
                        <div className="d-flex align-items-center gap-1">
                          <IoMdInformationCircleOutline size={18} />
                          ดูรายละเอียด
                        </div>
                      ) : (
                        <div className="d-flex align-items-center gap-1">
                          <LiaEditSolid size={18} />
                          แก้ไข
                        </div>
                      )}
                    </div>
                  }
                  className="mb-3 course-list"
                  style={{
                    width: "100%",
                  }}
                >
                  <Row>
                    <Col span={13}>
                      <div className="d-flex align-items-center">
                        <LuUser2 className="me-2" />
                        <p className="my-1">{`จำนวนที่เปิดรับ ${numeral(
                          n?.limitPerGroup
                        ).format("0,0")} คน`}</p>
                      </div>
                    </Col>
                  </Row>
                </Card>
              );
            })}
          </Col>
        ) : (
          <div className="w-100 center p-4">
            <Empty description={EMPTY_DESCRIPTION} />
          </div>
        )}
      </Row>
    </>
  )

  const renderUnitContent = () => (
    <>
      <Row className="d-flex justify-content-between">
        <div>
          <h4>
            {`${data?.courseName} ( ${numeral(data?.total).format("0,0")} )`}
          </h4>
          <p>{courseTypeToText(data?.type)}</p>
        </div>
        {flag?.isReject || (isKruNut && !flag?.isPublish) ?
          <Button
            type="primary"
            onClick={() => {
              setEditUnit(null);
              _handleOpenCreateUnit();
            }}
          >
            + เพิ่มบทเรียน
          </Button>
          :
          null}
      </Row>
      <Divider />
      <Row>
        {data?.data ? (
          <Col span={24}>
            {data?.data?.map((n, i) => {
              return (
                <Card
                  key={`unit-${i}`}
                  title={`บทที่ ${i + 1} - ${n?.unitName}`}
                  extra={
                    <div
                      className="text-primary center tw-cursor-pointer"
                      onClick={() => {
                        setEditUnit(n);
                        setCurrentIndex(i + 1);
                        _handleOpenCreateUnit();
                      }}
                    >
                      {!flag?.isReject || (isKruNut && flag?.isPublish) ? (
                        <div className="d-flex align-items-center gap-1">
                          <IoMdInformationCircleOutline size={18} />
                          ดูรายละเอียด
                        </div>
                      ) : (
                        <div className="d-flex align-items-center gap-1">
                          <LiaEditSolid size={18} />
                          แก้ไข
                        </div>
                      )}
                    </div>
                  }
                  className="mb-3 course-list"
                  style={{
                    width: "100%",
                  }}
                >
                  <Row>
                    <Col span={12}>
                      {
                        !flag?.isApprove ?
                          <div>
                            <div className="d-flex align-items-center mt-1">
                              <IoBookOutline
                                color={COLOR.primary}
                                className="me-2"
                              />
                              <p className="text-primary me-4">{`การบ้าน : ${n?.homework ? n?.homework : "-ไม่มีการบ้าน-"
                                }`}</p>
                            </div>
                          </div> :
                          <a href={n?.link || "#"} target="_blank">
                            <div className="d-flex align-items-center mt-1">
                              <IoBookOutline
                                color={COLOR.primary}
                                className="me-2"
                              />
                              <p className="text-primary me-4">{`การบ้าน : ${n?.homework ? n?.homework : "-ไม่มีการบ้าน-"
                                }`}</p>
                            </div>
                          </a>
                      }
                      <div className="d-flex align-items-center mt-1">
                        <MdOutlineAccessTime className="me-2" />
                        <p>{`วันที่สร้าง : ${moment(n.date).format(
                          "DD/MM/YYYY"
                        )}`}</p>
                      </div>
                    </Col>
                    <Col
                      span={6}
                      className="d-flex justify-content-end align-items-end"
                    ></Col>
                  </Row>
                </Card>
              );
            })}
          </Col>
        ) : (
          <div className="w-100 center p-4">
            <Empty description={EMPTY_DESCRIPTION} />
          </div>
        )}
      </Row>
    </>
  )

  const tabCourse = [
    {
      key: 1,
      label: `บทเรียน`,
      children: renderUnitContent(),
    },
    {
      key: 2,
      label: `กลุ่ม`,
      children: renderSessionContent(),
      disabled: !flag?.isGroup
    },
  ]

  useEffect(() => {
    setCurrentIndex(0);
  }, [tab])

  return (
    <>
      {
        data?.type === "zoom" ? <Tabs
          size="large"
          activeKey={tab}
          items={tabCourse}
          onChange={(key) => setTab(key)}
        /> :
          renderUnitContent()
      }
      <CourseOtherModal
        isEditable={!flag || flag.isReject || (isKruNut && !flag?.isPublish)}
        data={editUnit}
        total={data?.total}
        open={openCreateUnit}
        onOk={_handleCloseCreateUnit}
        onCancel={_handleCloseCreateUnit}
        index={currentIndex}
        refetch={refetch}
      />
      <GroupModal
        isEditable={!editZoomGroup || !dayjs(editZoomGroup?.startDate).isBefore(dayjs(new Date()).add(7, 'day'))}
        data={editZoomGroup}
        index={currentIndex}
        total={zoomGroupData?.total || 0}
        open={openCreateGroup}
        onOk={_handleCloseCreateGroup}
        onCancel={_handleCloseCreateGroup}
        refetch={refetchGroup}
      />
    </>
  );
};

export default CourseOther;
