import { Button, Calendar, Col, Divider, Row, Select, Spin } from "antd";
import { COLOR } from "constants/color";
import { thaiLocale } from "constants/thaiLocale/thaiLocale";
import { disabledDateBeforeNow } from "hooks/functions/disabledDateBeforeNow";
import { toDateFormat } from "hooks/toDateFormat";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import courseService from "services/course.services";
import Swal from "sweetalert2";
import { getUserId } from "utils";
import BookingList from "./BookingList";
import {
  AlertConfirm,
  AlertError,
  AlertLoading,
  AlertSuccess,
  AlertWarning,
} from "./alert/Alert";

const wrapperStyle = {
  width: 300,
  border: `1px solid ${COLOR.primary}`,
  borderRadius: 4,
};

const timeStyle = {
  padding: "28px 40px",
  borderRadius: "0px",
  fontSize: "18px",
  fontWeight: "600",
};

const bookingTypeOption = [
  {
    value: "onetime",
    label: "แบบกำหนดเอง",
  },
  {
    value: "recurring",
    label: "แบบประจำ",
  },
];

function disabledDate(current) {
  return current && current < new Date().setHours(0, 0, 0, 0);
}
// :: --------------------------------------------------------

const CalendarBooking = ({ isHour, courseId, dataCourse, setIsReserved }) => {
  const maxTimeInDay = 2; // hours
  const maxTime = 10; // hours
  const [fullTimeBookig, setFullTimeBooking] = useState(maxTime);
  const [bookingType, setBookingType] = useState("onetime");
  const [bookingSlot, setBookingSlot] = useState([]);
  const [dateSelected, setDateSelected] = useState(
    moment().format("YYYY-MM-DD")
  );

  const {
    data: courseAvailableTime,
    isFetching: isLoadingTime,
    refetch: refetchTime,
  } = courseService.useQueryGetSlotAvailableTimeByTeacherAndDay({
    queryObj: {
      date: toDateFormat(dateSelected),
      dayOfWeek: _.toNumber(moment(dateSelected).format("d")), // NumberOfDay start at [Sunday = 0]
      hour: isHour,
      hourTravel: 0, // case [at_home]
      teacherId: dataCourse?.teacherId,
    },
  });

  const { mutate: createAppointment, isLoading: isLoadingCreateBooking } =
    courseService.useMutationCreateAppointment((res) => {
      Swal.close();
      let data = res?.data?.data;
      let not_booking = data?.some((n) => n.status === 0);
      setBookingSlot(data);
      setIsReserved(true);
      if (not_booking) {
        AlertError({
          text: "พบบางรายการไม่สามารถจองได้ กรุณาเลือกอีกครั้ง",
        });
      } else {
        AlertSuccess({ title: "จองเวลาเรียนสำเร็จ!", onOk: () => {} });
      }
    });

  if (isLoadingCreateBooking) {
    AlertLoading({});
  }

  const confirmBooking = () => {
    AlertConfirm({
      onOk: () => {
        if (fullTimeBookig > 0 && fullTimeBookig < 10) {
          AlertWarning({ text: `กรุณาเลือกให้ครบจำนวนชั่วโมงการเรียน` });
        } else {
          createAppointment({ booking: bookingSlot });
        }
      },
    });
  };

  const maximumDay = () => {
    let on_day = _.filter(
      bookingSlot,
      (n) => n.bookingDate === toDateFormat(dateSelected)
    );
    let _time = _.size(on_day) * isHour;
    return _time >= maxTimeInDay;
  };

  const handleRecurringBooking = async (time_slot) => {
    let recurring = isHour === 2 ? maxTime / 2 : maxTime;
    let toBookingDay = []; // recurring day
    toBookingDay.push(toDateFormat(dateSelected));
    for (let i = 0; i < recurring - 1; i++) {
      let next = moment(toBookingDay[i]).add(7, "days");
      toBookingDay.push(toDateFormat(next?._d));
    }

    // booking slot
    const { intervalStart, intervalLearn, intervalEnd } = time_slot;
    let booking = toBookingDay.map((date) => {
      return {
        bookingDate: toDateFormat(date),
        startTime: intervalStart,
        learnTime: intervalLearn,
        endTime: intervalEnd,
        teacherId: dataCourse?.teacherId,
        courseId: courseId,
        userId: getUserId(),
        numberOfHours: isHour,
        status: -1, //  [-1]: can delelte  [0]:can't booking , [1]: booked
      };
    });

    if (fullTimeBookig > 0) {
      let maxHours = await maximumDay();
      if (maxHours) {
        AlertWarning({
          text: `ไม่สามารถจองได้เกิน ${maxTimeInDay} ชม. ใน 1 วัน!`,
        });
      } else {
        let all_booking = [...bookingSlot]; // before booking
        let add_slot = fullTimeBookig / isHour; // available time to add
        for (let i = 0; i < add_slot; i++) {
          all_booking.push(booking[i]); // add more
        }
        setBookingSlot(all_booking);
      }
    } else {
      AlertWarning({ text: "จองครบแล้ว !" });
    }
  };

  const handleBooking = async (time_slot) => {
    let duplicate = _.find(
      bookingSlot,
      (slot) =>
        slot.bookingDate === toDateFormat(dateSelected) &&
        slot.learnTime === time_slot.intervalLearn
    );
    if (duplicate) {
      let remove = _.filter(bookingSlot, (x) => x !== duplicate);
      setBookingSlot(remove);
    } else {
      const { intervalStart, intervalLearn, intervalEnd } = time_slot;
      let booking = {
        bookingDate: toDateFormat(dateSelected),
        startTime: intervalStart,
        learnTime: intervalLearn,
        endTime: intervalEnd,
        teacherId: dataCourse?.teacherId,
        courseId: courseId,
        userId: getUserId(),
        numberOfHours: isHour,
        status: -1, // [-1]: can delelte  [0]:can't booking , [1]: booked
      };
      if (fullTimeBookig > 0) {
        let maxHours = await maximumDay();
        if (maxHours) {
          AlertWarning({
            text: `ไม่สามารถจองได้เกิน ${maxTimeInDay} ชม. ใน 1 วัน!`,
          });
        } else {
          let all_booking = [...bookingSlot, booking];
          setBookingSlot(all_booking);
        }
      } else {
        AlertWarning({ text: "จองครบแล้ว !" });
      }
    }
  };

  const handleRemove = (time_slot) => {
    let inBooking = _.filter(bookingSlot, (n) => n !== time_slot);
    setBookingSlot(inBooking);
  };

  useEffect(() => {
    refetchTime();
  }, [dateSelected, isHour, refetchTime]);

  useEffect(() => {
    let full_time = maxTime - _.size(bookingSlot) * isHour;
    setFullTimeBooking(full_time);
  }, [bookingSlot, isHour]);

  return (
    <>
      <Row>
        <Col>
          <div style={wrapperStyle}>
            <Calendar
              locale={thaiLocale}
              disabledDate={disabledDate}
              fullscreen={false}
              onChange={(date) => {
                setDateSelected(date?.$d);
              }}
            />
          </div>
        </Col>
        <Col className="ms-4">
          <div className="mb-3">
            <strong>
              รายการจองวันที่ {moment(dateSelected).format("DD MMMM YYYY")}{" "}
            </strong>
          </div>
          <p>ประเภทการจอง</p>
          <Select
            value={bookingType}
            style={{ width: "270px" }}
            placeholder="ประเภทการจอง"
            options={bookingTypeOption}
            onChange={(e) => {
              setBookingType(e);
            }}
          />
          {isLoadingTime ? (
            <Row className="w-100 p-3">
              <Spin />
            </Row>
          ) : _.size(courseAvailableTime) > 0 ? (
            <div
              className="mt-4"
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                gap: "12px",
              }}
            >
              {courseAvailableTime?.map((available, index) => {
                const isDateTimeSeleted = `${dateSelected} ${available?.intervalLearn}`;
                const isBeforeTime = disabledDateBeforeNow(isDateTimeSeleted);
                return (
                  <Button
                    key={index}
                    type={
                      _.find(
                        bookingSlot,
                        (x) =>
                          x.bookingDate === toDateFormat(dateSelected) &&
                          x.learnTime === available.intervalLearn
                      )
                        ? "primary"
                        : "default"
                    }
                    disabled={isBeforeTime}
                    style={timeStyle}
                    className="center"
                    onClick={() => {
                      if (bookingType === "recurring") {
                        handleRecurringBooking(available);
                      } else {
                        handleBooking(available);
                      }
                    }}
                  >
                    {available?.intervalLearn}
                  </Button>
                );
              })}
            </div>
          ) : (
            <div className="w-100 justify-content-center align-items-center p-4 text-center">
              <p className="opacity-50">ไม่พบเวลาว่างครูผู้สอน</p>
            </div>
          )}
        </Col>
      </Row>
      <Divider />
      <Row className="mt-4">
        <Col span={24}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex">
              <h5 className="me-3">รายการจองของฉัน</h5>
              {fullTimeBookig > 0 && (
                <h5>{`( เหลืออีก ${fullTimeBookig} ชม. )`}</h5>
              )}
            </div>
            <Button
              disabled={_.size(bookingSlot) < 1}
              style={{ height: "40px", borderRadius: "0px" }}
              type="primary"
              onClick={confirmBooking}
            >
              ยืนยันการจอง
            </Button>
            {/* <p
              style={{ cursor: "pointer", fontSize: "16px" }}
              className="text-primary"
              onClick={() => {
                setBookingSlot([]);
              }}
            >
              ลบทั้งหมด
            </p> */}
          </div>
          <ul className="mt-4 px-0">
            {/* {bookingSlot?.map((slot, i) => {
              return (
                <Row key={i} className="mb-2">
                  <div
                    style={{ border: "1px solid #d9d9d9" }}
                    className="w-100 p-3 center d-flex justify-content-between"
                  >
                    <p style={{ fontSize: "16px" }} className="m-0">
                      {moment(slot?.bookingDate).format("DD MMMM YYYY")} - Time
                      : {slot?.learnTime} น.
                    </p>
                    <p
                      style={{
                        color: "#DA2128",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      className="m-0"
                      onClick={() => {
                        handleRemove(slot);
                      }}
                    >
                      REMOVE
                    </p>
                  </div>
                </Row>
              );
            })} */}
            {bookingSlot?.map((slot, i) => {
              return (
                <BookingList
                  type={
                    slot.status === -1
                      ? "remove"
                      : slot.status === 1
                      ? "done"
                      : "edit"
                  }
                  bookingDate={slot.bookingDate}
                  learnTime={slot.learnTime}
                  isEditable={true}
                  onClick={() => {
                    slot.status === -1 && handleRemove(slot);
                  }}
                />
              );
            })}
          </ul>
        </Col>
      </Row>
    </>
  );
};

export default CalendarBooking;
