import { useMutation, useQuery } from "react-query";
import { getToken } from "utils";
import { getUserId } from "utils";
import createRequest from "utils/request";

const userBackOfficeServices = {
  useQueryGetUserProfile({ onSuccess, onError }) {
    return useQuery(
      ["getUserProfile", getUserId()],
      async () => {
        const path = `/user/getUserProfile`;

        const res = await createRequest.get(path);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!getToken(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetHistoryReference({ onSuccess, onError }) {
    const userId = getUserId();
    return useQuery(
      ["getHistoryReference", userId],
      async () => {
        const path = `/course/getHistoryReference`;
        const query = { params: { userId: userId } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!userId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetAllPromotionActive({ onSuccess, onError }) {
    return useQuery(
      ["getAllPromotionActive"],
      async () => {
        const path = `/promotion/getAllPromotionActive`;
        const query = { params: { isRedeem: true } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!getToken(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useCourseByUserId(userId, { onSuccess, onError }) {
    return useQuery(
      ["getCourseByUserId", userId],
      async () => {
        const path = `/user/getUserProfile`;
        const query = { params: { userId: userId } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!userId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  // --------------------------------- USER MUTATION -------------
  useMutationUpdateUserProfile(onSuccess, onError) {
    return useMutation(
      async (values) => {
        const path = `/user/updateUser`;
        const query = { ...values };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationRedeemPointByUser(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = "/user/redeemPointByUser";
        const query = { ...values, userId: getUserId() };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};

export default userBackOfficeServices;
