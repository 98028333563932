import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  course: {},
  unit: [],
  quiz: {},
  quizDetail: {},
  page: "course",
};

const quizSlice = createSlice({
  name: "quiz",
  initialState,
  reducers: {
    SET_QUIZ_PAGE: (state, action) => {
      state.page = action.payload;
    },
    SET_QUIZ_COURSE: (state, action) => {
      state.course = action.payload;
    },
    SET_QUIZ_UNIT: (state, action) => {
      state.unit = action.payload;
    },
    SET_QUIZ: (state, action) => {
      state.quiz = action.payload;
    },
    SET_QUIZ_DETAIL: (state, action) => {
      state.quizDetail = action.payload;
    },
    CLEAR_QUIZ_DATA: (state) => {
      state.course = {};
      state.unit = [];
      state.quiz = {};
      state.quizDetail = {};
      state.page = "course";
    },
  },
});

export const {
  SET_QUIZ_PAGE,
  SET_QUIZ_COURSE,
  SET_QUIZ,
  SET_QUIZ_UNIT,
  SET_QUIZ_DETAIL,
  CLEAR_QUIZ_DATA,
} = quizSlice.actions;
export default quizSlice.reducer;
