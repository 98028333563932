import { Select } from "antd";
import { courseTypeOption } from "constants/options/courseTypeOption";
import _, { values } from "lodash";
import React from "react";

const SelectCourseType = ({ withAll = false, ...props }) => {
  let _option = withAll
    ? courseTypeOption
    : _.filter(courseTypeOption, (n) => n.value !== "ทั้งหมด");
  return (
    <Select placeholder="เลือกประเภทการเรียน" options={_option} {...props} />
  );
};

export default SelectCourseType;
