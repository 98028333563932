import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div style={{ width: "100vw", height: "100vh" }} className="center">
      <div>
        <h1>404 PAGE NOT FOUND</h1>
        <Link to="/">
          <p style={{ textDecoration: "underline" }}>Back to Home Page</p>
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
