import {
  Avatar,
  Button,
  Col,
  Collapse,
  Divider,
  Input,
  Modal,
  Row,
  Spin,
  Tag,
} from "antd";
import { NO_IMAGE } from "assets/images";
import { AlertConfirm, AlertSuccess } from "components/alert/Alert";
import _ from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
const { TextArea } = Input;

const StudentHomeworkModal = ({ data, open, onCancel }) => {
  const [comment, setComment] = useState([]);
  const [dataUnit, setDataUnit] = useState([]);

  const { mutate: getStudentHomework, isLoading: isLoadingStudentHomework } =
    courseBackOfficeServices.useMutationGetStudentHomeworkByCourseId((res) => {
      const data = res?.data;
      setDataUnit(data);
    });

  const { mutate: createComment } =
    courseBackOfficeServices.useMutationCreateCommentInHomework(() => {
      AlertSuccess({});
      setComment([]);
      getStudentHomework({
        courseId: data?.courseId,
        studentId: data?.studentId,
      });
    });

  const { mutate: updateHomeworkStatus } =
    courseBackOfficeServices.useMutationUpdateHomeworkStatus(() => {
      AlertSuccess({});
      setComment([]);
      getStudentHomework({
        courseId: data?.courseId,
        studentId: data?.studentId,
      });
    });

  const renderCommentBox = (data) => {
    const commentId = data?.id;
    return (
      <>
        <p className="mt-4">แสดงความคิดเห็น</p>
        <TextArea
          disabled={data?.isPass}
          value={_.find(comment, (n) => n?.id === commentId)?.text}
          className="my-2"
          size="large"
          placeholder="เขียนแสดงความคิดเห็น"
          onChange={(e) => {
            let text = e.target.value.trim();
            let otherUnit = _.filter(comment, (n) => n?.id !== commentId);
            setComment([...otherUnit, { id: commentId, text: text }]);
          }}
        />
        <Row className="d-flex justify-content-end tw-gap-2">
          <Button
            disabled={data?.isPass}
            onClick={() => {
              AlertConfirm({
                title: "อนุมัติให้บทเรียนนี้ผ่านหรือไม่ ?",
                onOk: () => {
                  updateHomeworkStatus({ homeworkId: data?.id, isPass: true });
                },
              });
            }}
          >
            <div className="center tw-gap-2">
              <IoCheckmarkCircleOutline />
              <p>อนุมัติให้ผ่าน</p>
            </div>
          </Button>
          <Button
            type="primary"
            disabled={
              _.find(comment, (n) => n?.id === commentId)?.text
                ? false
                : true || data?.isPass === true
            }
            onClick={() => {
              createComment({
                homeworkId: data?.id,
                text: _.find(comment, (n) => n?.id === commentId)?.text,
              });
            }}
          >
            แสดงความคิดเห็น
          </Button>
        </Row>
      </>
    );
  };

  useEffect(() => {
    if (data) {
      getStudentHomework({
        courseId: data?.courseId,
        studentId: data?.studentId,
      });
    }
  }, [data]);

  return (
    <Modal
      title={"ตรวจการบ้าน"}
      width={800}
      open={open}
      onCancel={onCancel}
      footer={null}
      okText="บันทึก"
      cancelText="ยกเลิก"
    >
      <Row className="my-4">
        <Col span={14}>
          <div className="d-flex ">
            <Avatar
              className="me-4"
              size={{
                xs: 24,
                sm: 32,
                md: 40,
                lg: 64,
                xl: 80,
                xxl: 100,
              }}
              src={data?.thumbUrl || NO_IMAGE}
            />
            <div className="d-flex flex-column justify-content-center">
              <h5>{data?.name || "student name"}</h5>
              <p>{data?.email || "student contact"}</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Collapse
            items={_.map(dataUnit, (n, index) => ({
              key: index + 1,
              label: (
                <div className="tw-flex tw-items-center tw-gap-2">
                  {`บทที่ ${index + 1} - ${n?.unitName}`}{" "}
                  {n?.isPass && <Tag color={"green"}>ผ่านแล้ว</Tag>}
                </div>
              ),
              children: isLoadingStudentHomework ? (
                <Spin />
              ) : (
                <>
                  <Row>
                    <Col span={24}>
                      <p className="tw-font-bold tw-mb-2">รายละเอียด</p>
                      <p>
                        วันที่ส่ง :{" "}
                        {moment(n?.date).format("DD/MM/YYYY เวลา HH:mm:ss น.")}
                      </p>
                      <p>ประเภทการตอบ : {n?.videoUrl ? "วิดีโอ" : "ข้อความ"}</p>
                      <p>การบ้าน : {n?.homeworkTitle || "-"}</p>

                      <p className="tw-font-bold tw-mt-6 tw-mb-2">คำตอบ</p>
                      {n?.videoUrl ? (
                        <div>
                          <video
                            controls
                            style={{ width: "100%", borderRadius: "4px" }}
                          >
                            <source src={n?.videoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      ) : (
                        <p>{n?.answer || "-"}</p>
                      )}
                    </Col>
                  </Row>
                  <Divider />
                  <p className="tw-font-bold tw-mb-2">ความคิดเห็น :</p>
                  <div>
                    {n?.conversations?.map((n, i) => {
                      return (
                        <div
                          key={i}
                          className="tw-mb-3 tw-w-full"
                          style={{
                            border: "1px solid #DDDD",
                            borderRadius: "4px",
                            padding: "8px",
                          }}
                        >
                          <div className="tw-flex tw-items-center tw-gap-4 tw-mb-4">
                            <div className="tw-w-[40px] tw-h-[40px] !tw-rounded-full tw-overflow-hidden">
                              <img
                                src={n?.thumbUrl || NO_IMAGE}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = NO_IMAGE;
                                }}
                                alt="cover"
                                className="tw-w-full tw-h-full tw-object-cover tw-object-center"
                              />
                            </div>
                            <div>
                              <p>{n?.name || "-"}</p>
                              <p className="tw-opacity-40">
                                {n?.date
                                  ? moment(n?.date).format(
                                      "DD/MM/YYYY เวลา HH:mm:ss น."
                                    )
                                  : "-"}
                              </p>
                            </div>
                          </div>
                          <p>{n?.text}</p>
                        </div>
                      );
                    })}
                  </div>
                  {renderCommentBox(n)}
                </>
              ),
            }))}
            defaultActiveKey={[]}
            onChange={(e) => {}}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default StudentHomeworkModal;
