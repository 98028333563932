import { Avatar, Button, List } from "antd";
import { NO_IMAGE } from "assets/images";
import useModalHook from "hooks/useModalHook";
import moment from "moment";
import { useState } from "react";
import { IoMdBook } from "react-icons/io";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import CheckQuizModal from "./CheckQuizModal";
import { Spin } from "../../../../../node_modules/antd/es/index";
import { IoTimeOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";

const RecentQuiz = () => {
  const [student, setStudent] = useState(null);

  const {
    open: openCheckQuizModal,
    handleOpen: _handleOpenCheckQuizModal,
    handleClose: _handleCloseCheckQuizModal,
  } = useModalHook();

  const {
    data: getAllRecentQuiz,
    refetch: refetchList,
    isFetching: isLoadingRecentQuiz,
  } = courseBackOfficeServices.useQueryGetAllRecentQuiz({});

  return (
    <>
      <div className="tw-flex tw-flex-col tw-gap-2 tw-w-full tw-overflow-hidden">
        {isLoadingRecentQuiz ? (
          <Spin />
        ) : (
          getAllRecentQuiz?.data.map((item) => {
            return (
              <section
                style={{ borderBottom: "0.3px solid gray" }}
                className="tw-flex tw-items-center tw-gap-3 py-3"
              >
                <div className="tw-flex tw-w-[42px] tw-h-[42px] tw-justify-center tw-items-center tw-object-center tw-overflow-hidden tw-rounded-full">
                  <img
                    src={item?.thumbUrl || NO_IMAGE}
                    alt="cover"
                    className="tw-object-cover tw-w-full tw-h-full tw-object-center"
                  />
                </div>
                <div className="tw-w-full tw-flex tw-flex-col tw-gap-3 lg:tw-flex-row lg:tw-items-center lg:tw-justify-between">
                  <div>
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <AiOutlineUser />
                      <b>
                        <p>{item?.name || "Username"}</p>
                      </b>
                    </div>
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <IoMdBook size={16} />
                      <div className="tw-flex tw-items-center tw-w-full tw-truncate tw-overflow-hidden">
                        <p className="tw-truncate">{item?.courseName || "-"}</p>
                      </div>
                    </div>

                    <p className="tw-opacity-50 tw-flex tw-gap-2 tw-items-center">
                      <span>
                        <IoTimeOutline size={16} />
                      </span>
                      <span>
                        {item?.date
                          ? moment(item?.date).format(
                              "DD/MM/YYYY เวลา HH:mm น."
                            )
                          : "เวลา -"}
                      </span>
                    </p>
                  </div>
                  <Button
                    key={`homework-check-${item?.studentId}`}
                    type="primary"
                    onClick={() => {
                      setStudent(item);
                      _handleOpenCheckQuizModal();
                    }}
                  >
                    ตรวจแบบทดสอบ
                  </Button>
                </div>
              </section>
            );
          })
        )}
      </div>
      <CheckQuizModal
        data={student}
        open={openCheckQuizModal}
        refetchList={refetchList}
        onCancel={() => {
          _handleCloseCheckQuizModal();
          setStudent(null);
        }}
      />
    </>
  );
};

export default RecentQuiz;
