export const instrumentOption = [
  {
    value: "ทั้งหมด",
    label: "ทั้งหมด",
  },
  {
    value: "กีต้าร์",
    label: "กีต้าร์",
  },
  {
    value: "ร้องเพลง",
    label: "ร้องเพลง",
  },
  {
    value: "เบส",
    label: "เบส",
  },
  {
    value: "เปียโน",
    label: "เปียโน",
  },
  {
    value: "กลอง",
    label: "กลอง",
  },
  {
    value: "ไวโอลิน",
    label: "ไวโอลิน",
  },
  // -----------------
  {
    value: "อื่นๆ",
    label: "อื่นๆ",
  },
];
