import { Button, Row, Table, Tabs, Tag } from "antd";
import { localeCustomTH } from "constants/thaiLocale/localeCustomTH";
import useModalHook from "hooks/useModalHook";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useState } from "react";
import userBackOfficeServices from "services/backoffice/userBackoffice.services";
import promotionService from "services/promotion.services";
import PromotionListFormModal from "./PromotionListFormModal";

const UserPoint = () => {
  const [tab, setTab] = useState(1);
  const {
    open: openPromotionList,
    handleOpen: _handleOpenPromotionList,
    handleClose: _handleClosePromotionList,
  } = useModalHook();

  const {
    data: dataMyPoint,
    isFetching: isLoadingMyPoint,
    refetch: refetchMyPoint,
  } = userBackOfficeServices.useQueryGetHistoryReference({});

  const {
    data: dataMyPromotion,
    isFetching: isLoadingMyPromotion,
    refetch: refetchMyPromotion,
  } = promotionService.useQueryGetAvailablePromotion({});

  const dataSourceMyPoint =
    dataMyPoint?.[1]?.historyReference?.map((data) => ({
          key: data.rowNum,
          massage: data.massage,
          date: moment(data.date).format("LL"),
        }))
      || [];

  const dataSourceMyPromotion = dataMyPromotion
    ? dataMyPromotion.data?.map((data) => ({
        key: data.id,
        code: data.code,
        redeemPoint: data.redeemPoint,
        deductionPercentage: data.deductionPercentage,
        limitAmount: data.limitAmount,
        useStatus: [data.useStatus],
        redeemDate: moment(data.redeemDate).format("LL"),
        useTime: moment(data.useTime).format("LLL"),
      }))
    : [];

  const myPointColumns = [
    {
      title: "วันที่",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "ข้อความ",
      dataIndex: "massage",
      key: "massage",
    },
  ];

  const myPromotionColumns = [
    {
      title: "โค้ดโปรโมชั่น",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "เปอร์เซ็นต์ส่วนลด (%)",
      dataIndex: "deductionPercentage",
      key: "deductionPercentage",
    },
    {
      title: "ส่วนลดสูงสุด (บาท)",
      dataIndex: "limitAmount",
      key: "limitAmount",
    },
    {
      title: "วันที่แลกโค้ด",
      dataIndex: "redeemDate",
      key: "redeemDate",
    },
    {
      title: "สถานะ",
      dataIndex: "useStatus",
      key: "useStatus",
      render: (_, { useStatus }) => (
        <>
          {useStatus.map((s) => {
            let color = s === 0 ? "warning" : "success";
            return (
              <>
                {s === 0 ? (
                  <Tag color={color} key={s}>
                    ยังไม่ถูกใช้
                  </Tag>
                ) : (
                  <Tag color={color} key={s}>
                    ถูกใช้แล้ว
                  </Tag>
                )}
              </>
            );
          })}
        </>
      ),
    },
    {
      title: "วันที่ใช้โค้ด",
      dataIndex: "useTime",
      key: "useTime",
    },
  ];

  const renderContent = () => {
    return (
      <>
        {tab === 1 && (
          <Table
            locale={localeCustomTH.Table}
            bordered
            loading={isLoadingMyPromotion}
            dataSource={dataSourceMyPoint}
            columns={myPointColumns}
          />
        )}

        {tab === 2 && (
          <Table
            scroll={{ x: true }}
            className="w-100 text-nowrap"
            locale={localeCustomTH.Table}
            bordered
            loading={isLoadingMyPoint}
            dataSource={dataSourceMyPromotion}
            columns={myPromotionColumns}
          />
        )}
      </>
    );
  };

  const tabPoint = [
    {
      key: 1,
      label: `รายละเอียดคะแนน`,
      children: renderContent(),
    },
    {
      key: 2,
      label: `โค้ดของฉัน`,
      children: renderContent(),
    },
  ];

  const onChange = (key) => {
    setTab(key);
  };

  useEffect(() => {
    window.scrollTo(0, 440);
  }, [tab]);

  return (
    <>
      <Row className="my-4 justify-content-between align-items-center">
        <h4 className="m-0 p-0">{`คะแนนที่มี : ${numeral(
          dataMyPoint?.[0].point?.point || 0
        ).format("0,0")}`}</h4>
        <Button
          style={{ height: "48px", width: "160px" }}
          size="small"
          type="primary"
          onClick={() => {
            _handleOpenPromotionList();
          }}
        >
          แลกคะแนน
        </Button>
      </Row>
      <Tabs
        size="large"
        defaultActiveKey={1}
        items={tabPoint}
        onChange={onChange}
      />
      <PromotionListFormModal
        open={openPromotionList}
        onCancel={() => {
          _handleClosePromotionList();
          refetchMyPoint();
          refetchMyPromotion();
        }}
      />
    </>
  );
};

export default UserPoint;
