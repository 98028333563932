import { Card, Spin } from "antd";
import useModalHook from "hooks/useModalHook";
import { FiExternalLink } from "react-icons/fi";
import { IoCalendar } from "react-icons/io5";
import { RiGroupLine } from "react-icons/ri";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import moment from "moment";
import CalendarModal from "pages/back-office/teacher/course/course-modal/CalendarModal";
const ZoomGroupDetail = ({ courseId }) => {
  const {
    open: openCalendar,
    handleOpen: _handleOpenCalendar,
    handleClose: _handleCloseCalendar,
  } = useModalHook();

  const { data: getZoomGroup, isLoading: isLoadingGroup } =
    courseBackOfficeServices.useQueryGetAllZoomGroupByCourseId({
      id: courseId,
    });
  return (
    <div>
      {isLoadingGroup ? (
        <Spin />
      ) : (
        getZoomGroup?.data?.map((n, i) => {
          return (
            <Card key={i} className="tw-mb-4">
              <div className="tw-flex tw-justify-between tw-items-center">
                <p className="tw-font-bold">
                  รอบที่ {i + 1} : {moment(n?.startDate).format("L")} -{" "}
                  {moment(n?.finishDate).format("L")}
                </p>
                <div
                  className="tw-flex tw-items-center tw-gap-2 text-primary tw-p-2 tw-cursor-pointer"
                  onClick={() => {
                    _handleOpenCalendar();
                  }}
                >
                  <IoCalendar />
                  <p>ตารางเรียน</p>
                </div>
              </div>
              <p className="tw-flex tw-items-center tw-gap-2">
                <RiGroupLine />
                จำนวนต่อกลุ่ม : {n?.limitPerGroup} คน
              </p>
              <p className="tw-flex tw-items-center tw-gap-2">
                <FiExternalLink /> ลิงก์กลุ่ม :{" "}
                <a href={n?.linkGroup || "#"}>{n?.linkGroup}</a>
              </p>
              <p className="tw-flex tw-items-center tw-gap-2">
                <FiExternalLink /> ลิงก์ Zoom :{" "}
                <a href={n?.linkZoom || "#"}>{n?.linkZoom}</a>
              </p>
              <CalendarModal
                data={n?.schedule}
                open={openCalendar}
                onCancel={_handleCloseCalendar}
              />
            </Card>
          );
        })
      )}
    </div>
  );
};

export default ZoomGroupDetail;
