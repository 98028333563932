import ImageCover from "components/ImageCover";
import { Link } from "react-router-dom";

const course_img =
  "https://images.unsplash.com/photo-1501504905252-473c47e087f8?auto=format&fit=crop&q=80&w=1974&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

export default function About() {
  const headerStyle = {
    fontSize: "1.5rem",
    lineHeight: "2rem",
    fontWeight: 700,
    letterSpacing: " -0.025em",
    color: "#111827",
  };

  const imageStyle = {
    objectFit: "cover",
    width: "100%",
    height: "auto",
  };

  const imageContainer = {
    marginTop: "4rem",
    width: "max-content",
    height: "250px",
    overflow: "hidden",
  };

  return (
    <>
      <ImageCover />
      <div className="sa-section">
        <div className="section-content sa-about section-padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="about-thumb">
                  <img
                    src={course_img}
                    alt="cover"
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="section-header">
                  <div className="title">
                    <h1>ABOUT US</h1>
                    <span>About</span>
                  </div>
                </div>
                <div className="about-info">
                  <h2>เกี่ยวกับ โทนส์ มิวสิค</h2>
                  <p>
                    โทนส์มิวสิค ก่อตั้ง โดย ครูนัท ณวภัสร์ หรือ นัท ไมค์ทองคำ2
                    ศิลปิน นักร้องลูกทุ่ง ที่เติบโตจากเส้นการประกวด
                    จนมีผลงานเพลงแรกเป็นของตนเอง คือ “นางฟ้าตาน้ำผึ้ง”
                    มีประสบการณ์ในการร้องเพลงและผ่านเวทีการประกวด มากกว่า 17ปี
                    เรียนจบปริญญาตรี เอกขับร้องลูกทุ่ง สาขาดนตรีและการแสดง
                    จากมหาวิทยาลัยบูรพา , หลักสูตรประกาศนียบัตรวิชาชีพครู
                    มหาวิทยาลัย มหาจุฬาลงกรณราชวิทยาลัย และ ปัจจุบัน (2566)
                    กำลังศึกษาปริญญาโท ดนตรีศึกษา สาขาวิชาศิลปศึกษา
                    มหาวิทยาลัยศรีนครินทรวิโรฒ
                    ซึ่งเป็นหลักสูตรที่เกี่ยวข้องกับการจัดการเรียนการสอนดนตรีโดยตรง
                  </p>
                  <ul className="global-list">
                    <li> เน้นการพัฒนาศักยภาพผู้เรียนขั้นสุด</li>
                    <li> ช่วยให้ทุกท่านประหยัดเวลา และค่าใช้จ่ายมากยิ่งขึ้น</li>
                    <li>แพลตฟอร์ม ที่เรียนได้ทุกวัย คอร์สเรียนที่หลากหลาย</li>
                  </ul>
                  <Link to="/register" className="btn btn-primary">
                    สมัครเรียนตอนนี้
                  </Link>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: "4rem" }}>
              <h2 style={headerStyle}>เกี่ยวกับ โทนส์ มิวสิค</h2>
              <p style={{ marginTop: "1.5rem" }}>
                โทนส์มิวสิค ก่อตั้ง โดย ครูนัท ณวภัสร์ หรือ นัท ไมค์ทองคำ2
                ศิลปินนักร้องลูกทุ่ง ที่เติบโตจากเส้นการประกวด จนมีผลงานเพลงแรก
                เป็นของตนเอง คือ “นางฟ้าตาน้ำผึ้ง”
                มีประสบการณ์ในการร้องเพลงและผ่านเวทีการประกวด มากกว่า 17ปี
                เรียนจบปริญญาตรี เอกขับร้องลูกทุ่ง สาขาดนตรีและการแสดง
                จากมหาวิทยาลัยบูรพา , หลักสูตรประกาศนียบัตรวิชาชีพครู
                มหาวิทยาลัย มหาจุฬาลงกรณราชวิทยาลัย และ ปัจจุบัน (2566)
                กำลังศึกษาปริญญาโท ดนตรีศึกษา สาขาวิชาศิลปศึกษา
                มหาวิทยาลัยศรีนครินทรวิโรฒ
              </p>
              <p style={{ marginTop: "2rem" }}>
                ซึ่งเป็นหลักสูตรที่เกี่ยวข้องกับการจัดการเรียนการสอนดนตรีโดยตรงครูนัท....มีความตั้งใจอยากสร้างแพลตฟอร์มสอนดนตรี
                โดยรวบรวมครูเก่ง ที่มีประสบการณ์
                จากมหาวิทยาลัยหรือจบจากมหาวิทยาลัยด้านดนตรีทั่วประเทศ มารวมไว้
                ที่ โทนส์ มิวสิค เพื่อให้เป็นช่องทางให้ผู้เรียน ได้เรียนกับครู
                ที่จบจากมหาวิทยาลัยในฝันหรือมหาวิทยาลัยที่ผู้เรียนอยากศึกษาต่อ
              </p>

              <div style={imageContainer}>
                <img
                  style={imageStyle}
                  src="assets/images/others/desc1.png"
                  alt=""
                />
              </div>
            </div>

            <div className="row" style={{ marginTop: "4rem" }}>
              <p style={{ marginTop: "1.5rem" }}>
                ซึ่งนอกเหนือจากที่จะได้ฝึกทักษะที่เข้มข้นแล้ว
                ยังเปรียบเสมือนได้ประสบการณ์ตรงจากรุ่นพี่
                ซึ่งจะทำให้ผู้เรียนสามารถเข้ามหาวิทยาลัยที่ชื่นชอบ
                หรือที่ต้องได้อย่างมั่นใจ
                ในรูปแบบการเรียนที่สะดวกสบายมากยิ่งขึ้นโทนส์มิวสิค มีนโยบาย
                การจัดการเรียนการสอน ที่เน้นการพัฒนาศักยภาพผู้เรียน ขั้นสุด
                ในรูปแบบออนไลน์ ที่ได้ใกล้ชิดครูผู้สอน
                มีการวัดผลประเมินผลในการเรียนอย่างชัดเจน
                ซึ่งหากผู้เรียนสามารถทำแบบทดสอบผ่านเกณฑ์ ที่ครูผู้สอนได้กำหนด
                โทนส์ มิวสิค มีการเตรียมความพร้อม
                โดยการที่ให้ครูผู้สอนเตรียมการเรียนการสอน ที่มีการออกแบบหลักสูตร
                หรือ การจัดการเรียนการสอน ที่เรียกว่า Advance Course ที่จะอัพ
                Skill ให้กับผู้เรียนโดยที่ไม่มีค่าใช้จ่ายเพิ่ม
              </p>
              <p style={{ marginTop: "2rem" }}>
                สำหรับบทเรียนนั้นๆรูปแบบการใช้งานที่แสนง่าย
                ช่วยให้ทุกท่านประหยัดเวลา และค่าใช้จ่ายมากยิ่งขึ้น
                สะดวกสบายมากยิ่งขึ้น ประหยัดเวลาผู้ปกครอง
                ผู้เรียนมีเวลาเหลือในการทำสิ่งอื่นมากยิ่งขึ้น
                ช่วยประหยัดค่าใช้จ่ายในการเดินทาง การเรียนที่ยืดหยุ่น
                อยู่ที่ไหนก็สามารถเรียนได้ สามารถหาครูที่เก่ง ที่อยู่ใกล้บ้าน
                ให้ครูไปสอนที่บ้าน หรือ ไปเรียนกับครูที่อยู่ใกล้บ้าน
                ก็สามารถทำได้
              </p>

              <div style={imageContainer}>
                <img
                  style={imageStyle}
                  src="assets/images/others/desc2.png"
                  alt=""
                />
              </div>

              <p style={{ marginTop: "1.5rem" }}>
                แพลตฟอร์ม ที่เรียนได้ทุกวัย คอร์สเรียนที่หลากหลาย
                วัยผู้ใหญ่ก็สามารถเรียนได้ มีการออกแบบคอร์สเรียนอย่างใส่ใจ
                เพื่อให้เหมาะสมกับทุกวัย วัยผู้ใหญ่ก็สามารถเรียนได้
                ซึ่งจะเป็นพื้นที่ให้ผู้ใหญ่ได้เรียนรู้อย่างไม่รู้จบ
                สามารถใช้เวลาว่างให้เกิดประโยชน์ ช่วยลดความเหงา ความเศร้า
                ความเครียด เสริมสร้างด้านการจดจำ กิจกรรมดนตรีที่สร้างสรรค์
                ที่จะใช้ให้ผู้ใหญ่มีความสุข และยังช่วยเสริมสร้างทั้งสุขภาพกายและ
                สุขภาพใจให้แข็งแรงมากยิ่งขึ้น
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
