import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};

const checkQuizSlice = createSlice({
  name: "checkQuiz",
  initialState,
  reducers: {
    SET_CHECK_QUIZ_DATA: (state, action) => {
      state.data = action.payload;
    },
    SET_CHECK_PRE_QUIZ: (state, action) => {
      const { dataIndex, preQuizIndex, preData, result } = action.payload;
      state.data[dataIndex].preQuiz[preQuizIndex] = {
        ...preData,
        result,
      };
    },
    SET_CHECK_POST_QUIZ: (state, action) => {
      const { dataIndex, postQuizIndex, postData, result } = action.payload;
      state.data[dataIndex].postQuiz[postQuizIndex] = {
        ...postData,
        result,
      };
    },
    CLEAR_CHECK_QUIZ: (state) => {
      state.data = [];
    },
  },
});

export const {
  SET_CHECK_QUIZ_DATA,
  SET_CHECK_PRE_QUIZ,
  SET_CHECK_POST_QUIZ,
  CLEAR_CHECK_QUIZ,
} = checkQuizSlice.actions;
export default checkQuizSlice.reducer;
