import { ConfigProvider } from "antd";
import Preloader from "components/preLoader";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import App from "./App";
import { antdTheme } from "./antd-theme";
import "./index.css";
import store from "./redux/store.js";
import reportWebVitals from "./reportWebVitals";
// import locale from "antd/locale/th_TH";
import moment from "moment";
import "moment/locale/th"; // Import the Thai locale
moment.locale("th");

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ConfigProvider theme={antdTheme}>
      <Preloader />
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
        </Provider>
      </QueryClientProvider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
