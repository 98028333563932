export const titleNameOption = [
  {
    value: "MR",
    label: "นาย",
  },
  {
    value: "MISS",
    label: "นาง",
  },
  {
    value: "MRS",
    label: "นางสาว",
  },
  {
    value: "Other",
    label: "อื่นๆ",
  },
];
