export const COURSE_SLIDE_CONFIG = (count) => {
  const limit = 4;
  return {
    infinite: count > limit,
    dots: false,
    arrows: true,
    slidesToShow: limit,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    pauseOnHover: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          infinite: count > 3,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          infinite: count > 2,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          infinite: count > 1,
          slidesToShow: 1,
        },
      },
    ],
  };
};
