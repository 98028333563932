import WritingReview from "components/WritingReview";
import { AlertSuccess } from "components/alert/Alert";
import React from "react";
import courseService from "services/course.services";
import { AlertError } from "../../../../../components/alert/Alert";
import { errorToMessage } from "../../../../../hooks/functions/errorToMessage";
import { Col, Divider, Row } from "antd";
import ReviewCard from "../../../../../components/ReviewCard";

export default function Review({ currentUnit }) {
  const courseId = currentUnit?.courseId;
  const unitId = currentUnit?.id;

  const { data: getReview } = courseService.useQueryGetReviewByCourseId({
    id: courseId,
  });
  const { mutate: createReview } =
    courseService.useMutationCreateReviewInCourse(
      (res) => {
        AlertSuccess({});
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const { mutate: updateReview } =
    courseService.useMutationUpdateReviewInCourse(
      (res) => {
        AlertSuccess({});
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  return (
    <>
      <h4 className="mt-4">รีวิวคอร์สเรียน</h4>
      <Row>
        <Col span={24}>
          {[...Array(2)].map((n, index) => (
            <ReviewCard key={`review-${index}`} />
          ))}
        </Col>
      </Row>
      <WritingReview
        onFinish={(e) => {
          const { rating, review } = e;
          createReview({ courseId, unitId, rating, text: review });
        }}
      />
    </>
  );
}
