import { Button, Row, Table, Tabs } from "antd";
import { AlertError, AlertSuccess } from "components/alert/Alert";
import { localeCustomTH } from "constants/thaiLocale/localeCustomTH";
import { errorToMessage } from "hooks/functions/errorToMessage";
import useModalHook from "hooks/useModalHook";
import { useState } from "react";
import fileService from "services/file.services";
import paymentService from "services/payment.services";
import TrackingModal from "./TrackingModal";
import moment from "moment";

const AdminManageOrder = () => {
  const {
    open: openTrackingNo,
    handleOpen: _handleOpenTrackingNo,
    handleClose: _handleCloseTrackingNo,
  } = useModalHook();

  const [paymentId, setPaymentId] = useState("");
  const [keyTabs, setKeyTabs] = useState(1);

  const onCheckStatus = (tab) => {
    if (tab === 1) {
      return "created";
    } else if (tab === 2) {
      return "pending";
    } else if (tab === 3) {
      return "successful";
    } else if (tab === 4) {
      return "failed";
    }
  };

  const { data: getAllPayment, refetch: refetchPayment } =
    paymentService.useQueryGetAllPayment({
      queryObj: {
        status: onCheckStatus(keyTabs),
      },
    });

  const { mutate: dowloadActivateCode } =
    fileService.useMutationDownloadDeliveryCode(
      () => {
        AlertSuccess({});
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const OrderTable = () => {
    return (
      <Row>
        <Table
          locale={localeCustomTH.Table}
          // loading={isLoading}
          scroll={{ x: true }}
          className="w-100 text-nowrap"
          bordered
          dataSource={getAllPayment?.data || []}
          columns={orderHeaderTable}
        />
      </Row>
    );
  };

  const _handlePrintCode = (record) => {
    dowloadActivateCode({ paymentId: record?.id });
  };

  const orderHeaderTable = [
    {
      title: "เลขออเดอร์",
      dataIndex: "orderNo",
      key: "orderNo",
    },
    {
      title: "ชื่อผู้สั่งซื้อ",
      dataIndex: "payeeName",
      key: "payeeName",
    },
    {
      title: "ที่อยู่จัดส่ง",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "เบอร์โทรศัพท์",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "ยอดรวมคำสั่งซื้อ",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "ส่วนลด",
      dataIndex: "deduction",
      key: "deduction",
    },
    {
      title: "ยอดรวมสุทธิ",
      dataIndex: "net",
      key: "net",
    },
    {
      title: "วิธีการชำระเงิน",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "วันที่ทำรายการ",
      dataIndex: "paidTime",
      key: "paidTime",
      render: (text) => moment(text).format("DD/MM/YYYY เวลา HH:mm:ss น."),
    },
    {
      title: "สถานะคำสั่งซื้อ",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "จัดการ",
      dataIndex: "manage",
      key: "manage",
      align: "center",
      render: (text, record) => {
        return keyTabs === 1 ? (
          <div className="tw-flex tw-items-center tw-gap-2">
            <Button
              onClick={() => {
                _handlePrintCode(record);
              }}
            >
              พิมพ์โค้ด
            </Button>
            <Button
              type="primary"
              onClick={() => {
                _handleOpenTrackingNo();
                setPaymentId(record?.id);
              }}
            >
              จัดส่ง
            </Button>
          </div>
        ) : (
          <p>-</p>
        );
      },
    },
  ];

  const orderTabs = [
    {
      key: 1,
      label: `ที่ต้องจัดส่ง`,
      children: <OrderTable />,
    },
    {
      key: 2,
      label: `กำลังจัดส่ง`,
      children: <OrderTable />,
    },
    {
      key: 3,
      label: `สำเร็จ`,
      children: <OrderTable />,
    },
    {
      key: 4,
      label: `การยกเลิก`,
      children: <OrderTable />,
    },
  ];

  return (
    <>
      <Row>
        <Tabs
          className="w-100"
          type="card"
          defaultActiveKey={1}
          items={orderTabs}
          onChange={(key) => setKeyTabs(key)}
        />
      </Row>
      <TrackingModal
        paymentId={paymentId}
        open={openTrackingNo}
        onCancel={_handleCloseTrackingNo}
        refetch={refetchPayment}
      />
    </>
  );
};

export default AdminManageOrder;
