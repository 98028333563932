import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Upload,
} from "antd";
import PreviewImageModal from "components/PreviewImageModal";
import {
  AlertClosed,
  AlertConfirm,
  AlertError,
  AlertLoading,
  AlertSuccess,
} from "components/alert/Alert";
import SelectCourseCategory from "components/elements/SelectCourseCategory";
import SelectCourseType from "components/elements/SelectCourseType";
import Map from "components/map";
import { dummyRequest } from "hooks/dummyRequest";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { onUploadFile } from "hooks/onUploadFile";
import useUploadFileHook from "hooks/useUploadFileHook";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { useSelector } from "react-redux";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import { getUserId } from "utils";
const { TextArea } = Input;

// isPromotion (bool): มีราคาพิเศษไหม
// delPrice : ราคาปกติ (ราคาที่ขีดฆ่า)
// price : ราคาขาย (ราคาสำหรับซื้อ หรือ ราคาพิเศษ)

const CourseFormModal = ({ isEditable, data, open, onCancel, refetch }) => {
  const [form] = Form.useForm();
  const [fieldsChange, setFieldsChange] = useState({});
  const [nonePromotionPrice, setNonePromotionPrice] = useState(false);
  const profile = useSelector((state) => state.profile);
  const [tmpDelPrice, setTmpDelPrice] = useState(0)
  const [previewVideo, setPreviewVideo] = useState(null);

  const {
    fileList,
    setFileList,
    previewImage,
    previewOpen,
    handlePreview: _handlePreviewUpload,
    handleChange: _handleChangeUpload,
    handleCancel: _handleCancelUpload,
  } = useUploadFileHook();

  const {
    fileList: previewFileList,
    setFileList: setPreviewFileList,
    previewVideoOpen,
    handlePreview: _handlePreviewVideoUpload,
    handleChange: _handleChangeVideoUpload,
    handleCancel: _handleCancelVideoUpload,
  } = useUploadFileHook();

  const { mutate: createCourse, isLoading: isLoadingCreate } =
    courseBackOfficeServices.useMutationCreateCourse(
      () => {
        setFileList([]);
        form.resetFields();
        refetch();
        AlertClosed();
        onCancel();
        AlertSuccess({
          text: "กรุณาสร้างบทเรียนของคอร์สให้สำเร็จก่อน และรอแอดมินตรวจสอบเพื่ออนุมัติ",
        });
      },
      (err) => {
        AlertClosed();
        onCancel();
        AlertError({ text: errorToMessage(err) });
      }
    );
  const { mutate: updateCourse, isLoading: isLoadingUpdate } =
    courseBackOfficeServices.useMutationUpdateCourse(
      () => {
        setFileList([]);
        form.resetFields();
        refetch();
        AlertClosed();
        onCancel();
        AlertSuccess({});
      },
      (err) => {
        AlertClosed();
        onCancel();
        AlertError({ text: errorToMessage(err) });
      }
    );
  const { mutate: deleteCourse, isLoading: isLoadingDelete } =
    courseBackOfficeServices.useMutationDeleteCourse(
      (res) => {
        const { code, description } = res.status;
        AlertClosed();
        if (code === "200") {
          refetch();
          AlertSuccess({});
        } else {
          AlertError({ text: description });
        }
      },
      (err) => {
        AlertClosed();
        AlertError({ text: errorToMessage(err) });
      }
    );

  const onFinish = async (values) => {
    const prefix = `course/media`;
    let fileName =
      _.size(fileList) > 0 && fileList[0]?.name !== data?.coverImg
        ? await onUploadFile({ prefix, fileList: fileList })
        : fileList[0]?.name === data?.coverImg ? data?.coverImg : null;

    let previewVideoFileName =
      _.size(previewFileList) > 0
        ? await onUploadFile({ prefix, fileList: previewFileList })
        : data?.previewVideo;

    if (data?.id) {
      // update course
      let _deleteCourseEarn = data?.courseEarn?.filter(itemB => !values?.courseEarn?.some(itemA => itemA.id === itemB.id))?.map(e => ({ ...e, flag: "delete" })) || []
      let _deleteCourseObjective = data?.courseObjective?.filter(itemB => !values?.courseObjective?.some(itemA => itemA.id === itemB.id))?.map(e => ({ ...e, flag: "delete" })) || []
      let _updateCourseEarn = values?.courseEarn?.filter(itemB => !itemB?.id || data?.courseEarn?.some(itemA => itemA.id === itemB.id))?.map(e => ({ ...e, flag: e.id ? "update" : "insert", type: "earn" })) || []
      let _updateCourseObjective = values?.courseObjective?.filter(itemB => !itemB?.id || data?.courseObjective?.some(itemA => itemA.id === itemB.id))?.map(e => ({ ...e, flag: e.id ? "update" : "insert", type: "objective" })) || []

      let _update = {
        course: {
          ...data,
          ...values,
          delPrice: values.nonePromotionPrice ? null : values?.delPrice,
          coverImg: fileName,
          previewVideo: previewVideoFileName,
        },
        courseEarn: [..._updateCourseEarn, ..._deleteCourseEarn],
        courseObjective: [..._updateCourseObjective, ..._deleteCourseObjective],
      };
      updateCourse({ values: _update });
    } else {
      // create course
      let _create = {
        course: {
          ...data,
          ...values,
          delPrice: values.nonePromotionPrice ? null : values?.delPrice,
          teacherId: getUserId(),
          coverImg: fileName,
          previewVideo: previewVideoFileName,
          prefixPath: prefix,
        },
        courseEarn: _.map(values?.courseEarn, (n) => {
          return { ...n, flag: "insert", type: "earn" };
        }),
        courseObjective: _.map(values?.courseObjective, (n) => {
          return { ...n, flag: "insert", type: "objective" };
        }),
      };

      createCourse({ values: _create });
    }
  };

  if (isLoadingCreate || isLoadingUpdate || isLoadingDelete) {
    AlertLoading({});
  }

  useEffect(() => {
    if (open && data) {
      let _field = {
        ...data,
        nonePromotionPrice: !data?.delPrice,
      };
      form.setFieldsValue(_field);
      setFieldsChange(_field);
      setNonePromotionPrice(!data?.delPrice);
      setTmpDelPrice(data?.delPrice || 0)
      if (data?.coverImg) {
        setFileList([{ name: data?.coverImg, url: data?.coverImgUrl, status: "done" }]);
      }
      if (data?.previewVideo) {
        setPreviewVideo({ name: data.previewVideo, url: data?.previewVideoUrl })
      }
    } else {
      if (form.__INTERNAL__.name) {
        form.resetFields();
      }
      setFieldsChange(null);
      setNonePromotionPrice(false);
      setTmpDelPrice(0)
      setFileList([]);
      setPreviewVideo(null)
    }
  }, [open, data]);

  return (
    <>
      <Modal
        title={
          data
            ? data?.isReject
              ? "แก้ไขคอร์สเรียน"
              : "รายละเอียดคอร์สเรียน"
            : "สร้างคอร์สเรียน"
        }
        open={open}
        okButtonProps={{ disabled: !isEditable }}
        onOk={() => {
          AlertConfirm({
            onOk: () => {
              form.submit();
            },
          });
        }}
        onCancel={() => {
          if (!data) {
            setFileList([]);
            form.resetFields();
          } else if (data?.coverImgUrl) {
            setFileList([{ url: data?.coverImgUrl, status: "done" }]);
          }
          onCancel();
        }}
        okText="บันทึก"
        cancelText="ยกเลิก"
      >
        <Form
          form={form}
          name="course-teacher-form"
          className="mt-4"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          {(!isEditable && fileList?.length) || isEditable ? (
            <Row className="mb-3">
              <Col span={24}>
                {isEditable ? (
                  <p className="mb-2">อัปโหลดภาพหน้าปกคอร์ส</p>
                ) : null}
                <Upload
                  customRequest={dummyRequest}
                  fileList={fileList}
                  accept="image/png, image/jpeg"
                  listType="picture-card"
                  maxCount={1}
                  onPreview={_handlePreviewUpload}
                  onChange={_handleChangeUpload}
                  disabled={!isEditable}
                >
                  {isEditable ? (
                    <div>
                      <MdOutlineFileUpload size={20} className="mb-2" />
                      <p> อัปโหลดภาพ</p>
                    </div>
                  ) : null}
                </Upload>
              </Col>
            </Row>
          ) : null}

          {(!isEditable && previewVideo) || isEditable ? (
            <Row>
              <Col span={24}>
                {isEditable ? <p className="mb-2">อัปโหลดวิดีโอตัวอย่าง</p> : null}
                <Form.Item
                  name="previewVideo"
                  rules={[
                    {
                      required: !previewVideo,
                      message: "กรุณาอัปโหลดวิดีโอ",
                    },
                  ]}
                >
                  <div>
                    {previewVideo ? (
                      <div>
                        <video
                          controls
                          style={{ width: "100%", borderRadius: "4px" }}
                        >
                          <source src={previewVideo?.url} type="video/mp4" />
                          <track kind="captions" />
                        </video>
                        <Row>
                          <Card className="w-100">
                            <div className="d-flex justify-content-between align-items-center">
                              <p
                                style={{
                                  width: "max-content",
                                  maxWidth: "320px",
                                }}
                              >
                                ชื่อไฟล์วิดีโอ : {previewVideo?.file?.name || previewVideo?.name}
                              </p>
                              {isEditable ? (
                                <Button
                                  danger
                                  onClick={() => {
                                    setPreviewVideo(null);
                                    setPreviewFileList([]);
                                  }}
                                >
                                  ลบวิดีโอ
                                </Button>
                              ) : null}
                            </div>
                          </Card>
                        </Row>
                      </div>
                    ) : (
                      <Upload
                        fileList={previewFileList}
                        customRequest={dummyRequest}
                        accept="video/*"
                        listType="picture-card"
                        maxCount={1}
                        beforeUpload={(file) => {
                          let videoUrl = URL.createObjectURL(file);
                          setPreviewVideo({ file, url: videoUrl });

                          return new Promise((resolve) => {
                            const video = document.createElement("video");
                            video.preload = "metadata";
                            video.onloadedmetadata = () => {
                              window.URL.revokeObjectURL(video.src);
                              resolve(file);
                            };
                            video.src = URL.createObjectURL(file);
                          });
                        }}
                        onPreview={_handlePreviewVideoUpload}
                        onChange={_handleChangeVideoUpload}
                        disabled={!isEditable}
                      >
                        {isEditable ? (
                          <div>
                            <MdOutlineFileUpload size={20} className="mb-2" />
                            <p> อัปโหลดวิดีโอ</p>
                          </div>
                        ) : null}
                      </Upload>
                    )}
                  </div>
                </Form.Item>
              </Col>
            </Row>
          ) : null}

          <Form.Item
            label="ชื่อคอร์สเรียน"
            name="title"
            rules={[
              {
                required: true,
                message: "กรุณาระบุชื่อคอร์สเรียน!",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="ชื่อคอร์สเรียน"
              readOnly={!isEditable}
            />
          </Form.Item>

          <Form.Item
            label="ราคาพิเศษ (ราคาก่อนลด)"
            name="delPrice"
            initialValue={0}
            onChange={e => {
              setTmpDelPrice(e?.target?.value)
            }}
            rules={[
              {
                required: !nonePromotionPrice,
                message: "กรุณาระบุราคาพิเศษ!",
              },
              {
                validator: async (_, value) => {
                  if (value && value <= form.getFieldValue("price")) {
                    throw new Error(
                      "ราคาพิเศษ (ราคาก่อนลด) ต้องมากกว่าราคาหลังลด!"
                    );
                  }
                },
              },
            ]}
          >
            <InputNumber
              min={0}
              disabled={nonePromotionPrice}
              className="w-100"
              size="large"
              placeholder="ราคาพิเศษ (ราคาก่อนลด)"
              readOnly={!isEditable}
            />
          </Form.Item>
          <Form.Item
            name="nonePromotionPrice"
            valuePropName="checked"
            initialValue={nonePromotionPrice}
            onChange={(e) => {
              setNonePromotionPrice(e.target.checked);
              form.setFieldsValue({
                delPrice: e.target.checked ? 0 : tmpDelPrice
              });
            }}
          >
            <div className="d-flex align-items-center gap-2">
              <Checkbox
                checked={nonePromotionPrice}
                size="large"
                disabled={!isEditable}
              />
              <p>ไม่มีราคาพิเศษ</p>
            </div>
          </Form.Item>

          <Form.Item
            label={nonePromotionPrice ? "ราคา" : "ราคาหลังลด"}
            name="price"
            rules={[
              {
                required: true,
                message: "กรุณาระบุราคา!",
              },
              {
                validator: async (_, value) => {
                  if (!nonePromotionPrice) {
                    if (value && value >= form.getFieldValue("delPrice")) {
                      throw new Error("ราคาหลังลดต้องน้อยกว่าราคาปกติ");
                    }
                  }
                },
              },
            ]}
          >
            <InputNumber
              min={0}
              className="w-100"
              size="large"
              label={nonePromotionPrice ? "ราคา" : "ราคาหลังลด"}
              readOnly={!isEditable}
            />
          </Form.Item>
          <Form.Item
            label="รายละเอียดคอร์สเรียน"
            name="description"
            rules={[
              {
                required: true,
                message: "กรุณาระบุรายละเอียดคอร์สเรียน!",
              },
            ]}
          >
            <TextArea
              rows={4}
              size="large"
              placeholder="รายละเอียดคอร์สเรียน"
              readOnly={!isEditable}
            />
          </Form.Item>

          <Form.List name="courseObjective">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <React.Fragment key={key}>
                      <Row className="center">
                        <Col flex={1}>
                          <Form.Item
                            {...restField}
                            name={[name, "description"]}
                            label={`วัตถุประสงค์ ${index + 1}`}
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุวัตถุประสงค์!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="วัตถุประสงค์"
                              readOnly={!isEditable}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          className="ms-2"
                          style={{
                            width: "48px",
                            paddingTop: "30px",
                            paddingBottom: "24px",
                          }}
                        >
                          <Button
                            className="w-100"
                            size="large"
                            danger
                            onClick={() => {
                              remove(name);
                            }}
                            disabled={!isEditable}
                          >
                            ลบ
                          </Button>
                        </Col>
                      </Row>
                    </React.Fragment>
                  );
                })}
                {isEditable ? (
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + เพิ่มวัตถุประสงค์
                    </Button>
                  </Form.Item>
                ) : null}
              </>
            )}
          </Form.List>

          <Form.List name="courseEarn">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <React.Fragment key={key}>
                      <Row className="center">
                        <Col flex={1}>
                          <Form.Item
                            {...restField}
                            name={[name, "description"]}
                            label={`สิ่งที่คุณจะได้รับในคอร์ส ${index + 1}`}
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุสิ่งที่คุณจะได้รับในคอร์ส!",
                              },
                            ]}
                          >
                            <Input
                              size="large"
                              placeholder="สิ่งที่คุณจะได้รับในคอร์ส"
                              readOnly={!isEditable}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          className="ms-2"
                          style={{
                            width: "48px",
                            paddingTop: "30px",
                            paddingBottom: "24px",
                          }}
                        >
                          <Button
                            className="w-100"
                            size="large"
                            danger
                            onClick={() => {
                              remove(name);
                            }}
                            disabled={!isEditable}
                          >
                            ลบ
                          </Button>
                        </Col>
                      </Row>
                    </React.Fragment>
                  );
                })}
                {isEditable ? (
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()} block>
                      + เพิ่มสิ่งที่คุณจะได้รับในคอร์ส
                    </Button>
                  </Form.Item>
                ) : null}
              </>
            )}
          </Form.List>

          <Form.Item
            label="หมวดหมู่คอร์สเรียน"
            name="category"
            rules={[
              {
                required: true,
                message: "กรุณาระบุหมวดหมู่คอร์สเรียน!",
              },
            ]}
          >
            <SelectCourseCategory size="large" disabled={!isEditable} />
          </Form.Item>

          <Form.Item
            label="รูปแบบการเรียน"
            name="type"
            rules={[
              {
                required: true,
                message: "กรุณาระบุรูปแบบการเรียน!",
              },
            ]}
          >
            <SelectCourseType
              size="large"
              onChange={(e) => {
                setFieldsChange({ ...fieldsChange, type: e });
              }}
              disabled={!isEditable}
            />
          </Form.Item>

          {fieldsChange?.type === "online" && (
            <>
              <Form.Item
                shouldUpdate
                valuePropName="checked"
                name="isChallenge"
                onChange={(e) => {
                  setFieldsChange({
                    ...fieldsChange,
                    isChallenge: e.target.checked,
                  });
                }}
              >
                <Checkbox size="large" disabled={!isEditable}>
                  มีการทำ Challenge
                </Checkbox>
              </Form.Item>
              {fieldsChange?.isChallenge && (
                <Form.Item
                  shouldUpdate
                  label="ระยะเวลาการทำ Challenge (วัน)"
                  name="durationChallenge"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุระยะเวลาการทำ Challenge! (วัน)",
                    },
                  ]}
                >
                  <InputNumber
                    size="large"
                    className="w-100"
                    placeholder="ระยะเวลาการทำ Challenge (วัน)"
                    readOnly={!isEditable}
                  />
                </Form.Item>
              )}
            </>
          )}

          {fieldsChange?.type === "zoom" && (
            <>
              <p style={{ opacity: "0.5" }} className="mb-3">
                ** จำนวนชั่วโมงการเรียน 10 ชั่วโมง/คอร์สเรียน
              </p>
              <Form.Item
                label="รูปแบบการสอน"
                name="isGroup"
                initialValue={0}
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุ จำนวนนักเรียนในกลุ่ม !",
                  },
                ]}
              >
                <Radio.Group
                  onChange={(e) => {
                    setFieldsChange({
                      ...fieldsChange,
                      isGroup: e.target.value,
                    });
                  }}
                  disabled={!isEditable}
                >
                  <Radio value={false}>แบบเดี่ยว</Radio>
                  <Radio value={true}>แบบกลุ่ม</Radio>
                </Radio.Group>
              </Form.Item>

              {!fieldsChange?.isGroup && (
                <Form.Item
                  label="ลิงค์ zoom"
                  name="linkZoom"
                  rules={[
                    {
                      required: true,
                      message: "กรุณาระบุlinkZoom !",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="ลิงค์ zoom"
                    readOnly={!isEditable}
                  />
                </Form.Item>
              )}
            </>
          )}

          {fieldsChange?.type === "studio" && (
            <>
              <p style={{ opacity: "0.5" }} className="mb-3">
                ** จำนวนชั่วโมงการเรียน 10 ชั่วโมง/คอร์สเรียน
              </p>
              <Form.Item
                label="ชื่อสถานที่"
                name="locationName"
                initialValue={
                  fieldsChange?.locationName ||
                  data?.locationName ||
                  profile?.profile?.locationName
                }
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุชื่อสถานที่ !",
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder="ชื่อสถานที่"
                  readOnly={!isEditable}
                />
              </Form.Item>
              <Form.Item
                label="พิกัดสถานที่"
                name="position"
                initialValue={
                  fieldsChange?.position ||
                  data?.position ||
                  profile?.profile?.position
                }
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุ พิกัดสถานที่ !",
                  },
                ]}
                onChange={(e) => {
                  setFieldsChange({
                    ...fieldsChange,
                    position: e,
                  });
                }}
              >
                <Map size="large" placeholder="พิกัด" readOnly={!isEditable} />
              </Form.Item>
            </>
          )}
        </Form>
        {data?.id && (
          <Button
            block
            danger
            className="mb-4"
            onClick={() => {
              AlertConfirm({
                text: "ต้องการลบคอร์สเรียนนี้หรือไม่ ?",
                onOk: () => {
                  onCancel();
                  deleteCourse({ courseId: data?.id });
                },
              });
            }}
            disabled={!isEditable}
          >
            ลบคอร์สเรียน
          </Button>
        )}
      </Modal>
      <PreviewImageModal
        open={previewOpen}
        onCancel={_handleCancelUpload}
        previewImage={previewImage}
      />
    </>
  );
};

export default CourseFormModal;
