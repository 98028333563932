import { Button } from "antd";
import { bg_tonemusic, logo_mini_tonemusic } from "assets";
import { icon_line } from "assets/icons";
import { NOT_FOUND_IMG } from "assets/images";
import { AlertClosed, AlertError, AlertLoading } from "components/alert/Alert";
import { COLOR } from "constants/color";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import masterService from "services/master.services";
import { LINE_CHANNEL_ID, REDIRECT_URI } from "utils";
import { setToken, setUser } from "../../utils/index";

const backgroundStyle = {
  margin: 0,
  padding: 0,
  backgroundImage: `url(${bg_tonemusic})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  height: "100vh",
};

const text = {
  color: "#424242",
  opacity: "0.6",
  fontSize: "20px",
};

const header = {
  color: COLOR.primary,
  fontWeight: "bold",
  fontSize: "40px",
  lineHeight: "34px",
};

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);

  const { mutate: postLogin } = masterService.useMutationLogin(
    (res) => {
      AlertClosed();
      if (res?.loginStatus) {
        const token = res.token || null;
        const userInfo = res?.userInfo?.userData || null;
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 2);
        setToken(token);
        setUser(userInfo);
        navigate("/");
      } else {
        // let msg = res?.loginMsg;
        AlertError({ text: `ไม่พบผู้ใช้งาน!` });
      }
    },
    (err) => {
      AlertClosed();
      AlertError({ text: errorToMessage(err) });
    }
  );

  const handleLogin = async () => {
    if (email === null || password === null) {
      return window.alert("กรุณาระบุรหัสผ่านให้ครบ!");
    }
    let values = { email, password };
    AlertLoading({});
    postLogin({ values });
  };

  const generateRandomState = () => {
    const randomState = Math.random().toString(36).substring(2, 15);
    return randomState;
  };

  const state = generateRandomState();

  const handleLineLogin = () => {
    if (!LINE_CHANNEL_ID || !REDIRECT_URI) {
      console.error(
        "Invalid Line configuration",
        LINE_CHANNEL_ID,
        REDIRECT_URI
      );
      return;
    }

    const lineAuthUrl = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${LINE_CHANNEL_ID}&redirect_uri=${REDIRECT_URI}&state=${state}&scope=profile%20openid%20email`;
    // Redirect to Line Login
    window.location.href = lineAuthUrl;
  };
  return (
    <div style={backgroundStyle}>
      <div className="sg-section tw-h-screen tw-flex tw-justify-center tw-items-center">
        <div className="section-content">
          <div className="container">
            <div className="ragister-account text-center">
              <div className="row">
                <div className="col-lg-6">
                  <div className="account-content">
                    <div
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="d-flex w-100"
                    >
                      <h1 className="w-100">เข้าสู่ระบบ</h1>
                      <Link to="/" style={{ width: "200px" }}>
                        <u className="me-2">กลับสู่หน้าหลัก</u>
                      </Link>
                    </div>
                    <div className="ragister-form">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "32px 0px",
                        }}
                      >
                        <img
                          style={{ width: "60px", height: "60px" }}
                          src={logo_mini_tonemusic}
                          alt="music icon"
                        />
                        <div className="ms-4 text-start">
                          <p style={header}>Tones Music</p>
                          <p style={text}>Education Course</p>
                        </div>
                      </div>
                      <div className="form-group text-left mb-0">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          required="required"
                          placeholder="example@gmail.com"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group text-left mb-0">
                        <label>Password</label>
                        <input
                          type="password"
                          className="form-control"
                          required="required"
                          placeholder="***********"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>

                      <Button
                        style={{ height: "45px" }}
                        type="primary"
                        className="w-100"
                        onClick={handleLogin}
                      >
                        <p>เข้าสู่ระบบ</p>
                      </Button>
                      <Button
                        style={{ height: "45px" }}
                        className="w-100 my-3"
                        onClick={handleLineLogin}
                      >
                        <div className=" center">
                          <img
                            width={24}
                            src={icon_line || NOT_FOUND_IMG}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = NOT_FOUND_IMG;
                            }}
                            alt="line-icon"
                            className="me-3"
                          />
                          <p>เข้าสู่ระบบด้วยไลน์</p>
                        </div>
                      </Button>

                      <div className="middle-content my-2">
                        <p className="mb-2">
                          ฉันยังไม่มีบัญชี ?
                          <Link to="/register" className="text-primary ms-2">
                            ลงทะเบียน
                          </Link>
                        </p>
                        <u>
                          <Link
                            to="/forgetPassword"
                            className="ms-2 !tw-text-[#5A5A5A]"
                          >
                            ลืมรหัสผ่าน ?
                          </Link>
                        </u>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
