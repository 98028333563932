import moment from "moment"
// example data : [{
// finishTime: "2024-07-01 22:00:00",
// startTime: "2024-07-01 20:00:00"
// }]

export const convertListDataForCalendar = (data)=>{
    const formatted = {}
		data?.forEach(({ id, startTime: _startTime, finishTime: _finishTime, ...rest }) => {
			const startDate = _startTime.split(" ")[0]
			const startTime = _startTime.split(" ")[1]
			const finishDate = _finishTime.split(" ")[0]
			const finishTime = _finishTime.split(" ")[1]
			if (!formatted[startDate]) {
				if (startDate === finishDate) {
					formatted[startDate] = [{ startDate, startTime, finishDate, finishTime, ...rest }]
				} else {
					let _startDate = new Date(startDate)
					let _finishDate = new Date(finishDate)
					while (_startDate <= _finishDate) {
						const dateStr = moment(_startDate).locale('en').format("YYYY-MM-DD")
						if (!formatted[dateStr]) {
							formatted[dateStr] = [{ startDate, startTime: dateStr != startDate ? "00:00" : startTime, finishDate, finishTime: dateStr != finishDate ? "00:00" : finishTime, ...rest }]
						} else {
							formatted[dateStr].push({ startDate, startTime: dateStr != startDate ? "00:00" : startTime, finishDate, finishTime: dateStr != finishDate ? "00:00" : finishTime, ...rest })
						}
						_startDate.setDate(_startDate.getDate() + 1)
					}
				}

			} else {
				formatted[startDate].push({ startDate, startTime, finishDate, finishTime, ...rest })
			}
		})
		return formatted ?? {}
}
