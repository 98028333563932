import { useQuery } from "react-query";
import { PUBLIC_API } from "utils";
import createRequest from "../utils/request";

const teacherServices = {
  // :: TEACHER :: --------------------------------------------------
  useQueryGetAllTeacher({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllTeacher", queryObj],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `${PUBLIC_API}/teacher/getAllTeacherActive`;
        const query = { params: { ...queryObj, paging: pagingStr } };
        const res = await createRequest.get(path, query);
        return res?.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetTeacherProfileById({ id, onSuccess, onError }) {
    return useQuery(
      ["getTeacherProfileById", id],
      async () => {
        const path = `${PUBLIC_API}/teacher/getTeacherProfileById`;
        const query = { params: { id } };
        const res = await createRequest.get(path, query);
        return res.data.data[0]?.teacherProfile;
      },
      {
        onSuccess,
        onError,
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  useQueryGetTeacherDetailById({ id, onSuccess, onError }) {
    return useQuery(
      ["getTeacherDetailById", id],
      async () => {
        const path = `${PUBLIC_API}/teacher/getTeacherDetailById`;
        const query = { params: { id } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetTeacherAwardByTeacherId({ id, onSuccess, onError }) {
    return useQuery(
      ["getTeacherAwardByTeacherId", id],
      async () => {
        const path = `${PUBLIC_API}/teacher/getTeacherAwardByTeacherId`;
        const query = { params: { teacherId: id } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!id,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
};

export default teacherServices;
