import { Button } from "antd";
import { toFormatDurationTime } from "hooks/functions/toFormatDurationTime";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLEAR_DURATION_VIDEO, SET_CURRENT_UNIT } from "redux/reducers/courseSlice";

const UnitPanel = () => {
  const dispatch = useDispatch();
  const [unit, setUnit] = useState(0);
  const course = useSelector((state) => state.course);
  const courseType = course?.courseDetail?.type;
  const currentUnit = course?.currentUnitData;
  const unitDetail = course?.unitDetail;
  const dataUnit = course?.unitDetail?.data;

  useEffect(() => {
    const index = unitDetail?.data?.findIndex(e => e.id === currentUnit?.id) || 0
    setUnit(index);
    dispatch(CLEAR_DURATION_VIDEO());
  }, [currentUnit])

  return (
    <div className="widget widget_categories">
      <h3 className="widget_title">หลักสูตรการเรียนรู้</h3>

      <ul>
        {_.size(dataUnit) > 0 ? (
          dataUnit?.map((n, index) => (
            <Button
              key={`unit-${index}`}
              type={`${unit === index ? "primary" : "default"}`}
              style={{
                borderRadius: "0px",
                height: "auto",
                padding: "8px 12px",
              }}
              className="w-100 mb-2 center"
              // disabled={courseType === "online" ? n?.isAllow : false}
              onClick={() => {
                dispatch(SET_CURRENT_UNIT(index));
              }}
            >
              <div
                className="w-100"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    textAlign: "start",
                    whiteSpace: "pre-wrap",
                    overflowWrap: "break-word",
                    paddingRight: "8px",
                  }}
                >
                  {`บทที่ ${index + 1} : ${n?.unitName}`}
                </div>
                {courseType === "online" && (
                  <div style={{ width: "80px", textAlign: "end" }}>
                    {toFormatDurationTime(n?.videoTime)}
                  </div>
                )}
              </div>
            </Button>
          ))
        ) : (
          <p className="center p-3">ไม่พบข้อมูล</p>
        )}
      </ul>
    </div>
  );
};

export default UnitPanel;
