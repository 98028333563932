import { Modal, Row, Tabs, Tag } from "antd";
import { NO_IMAGE } from "assets/images/index";
import { courseTypeToText } from "hooks/functions/courseTypeToText";
import { useState } from "react";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import CourseDetailSection from "./CourseDetailSection";
import UnitDetailSection from "./UnitDetailSection";
import ZoomGroupDetail from "./ZoomGroupDetail";

const KEY_TABS = {
  course: {
    key: "course",
    label: "รายละเอียดคอร์ส",
  },
  unit: {
    key: "unit",
    label: "บทเรียน",
  },
  group: {
    key: "group",
    label: "กลุ่ม",
  },
};

const CourseDetailsModal = ({ data, open, onCancel }) => {
  const courseId = data?.id;
  const isGroup = data?.isGroup;

  const [unitItem, setUnitItem] = useState(null);
  const [tab, setTab] = useState(KEY_TABS.course.key);

  // GET COURSE DETAILS
  const { data: courseDetail, isLoading: isLoadingCourse } =
    courseBackOfficeServices.useQueryGetCourseById({
      id: courseId,
    });

  // GET UNIT
  const { data: getUnit, isLoading: isLoadingUnit } =
    courseBackOfficeServices.useQueryGetAllUnitByCourseId({
      courseId: courseId,
    });

  const headerTabs = [
    {
      key: KEY_TABS.course.key,
      label: KEY_TABS.course.label,
      children: (
        <CourseDetailSection
          courseDetail={courseDetail}
          isLoading={isLoadingCourse}
        />
      ),
    },
    {
      key: KEY_TABS.unit.key,
      label: KEY_TABS.unit.label,
      children: (
        <UnitDetailSection
          getUnit={getUnit}
          isLoading={isLoadingUnit}
          unitItem={unitItem}
          setUnitItem={setUnitItem}
        />
      ),
    },
    {
      key: KEY_TABS.group.key,
      label: KEY_TABS.group.label,
      disabled: !isGroup,
      children: <ZoomGroupDetail courseId={courseId} />,
    },
  ];

  return (
    <Modal
      title="รายละเอียด"
      width={600}
      open={open}
      onOk={() => {}}
      onCancel={() => {
        onCancel();
        setTab(KEY_TABS.course.key);
        setUnitItem(null);
      }}
      footer={false}
    >
      <Row className="my-4">
        <div className="d-flex justify-content-between ">
          <div className="d-flex align-items-center">
            <div
              className="d-flex justify-content-center align-items-center me-4"
              style={{
                width: "80px",
                height: "80px",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <img
                className="w-100 h-100 object-fit-cover"
                src={data?.teacherImg || NO_IMAGE}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = NO_IMAGE;
                }}
                alt="images-cover"
              />
            </div>
            <div>
              <h5>{`${data?.teacherName}`}</h5>
              <p className="mb-2">{data?.title}</p>
              <Tag color="gold">{courseTypeToText(data?.type)}</Tag>
              {data?.type === "zoom" && (
                <Tag color="green">{isGroup ? "แบบกลุ่ม" : "แบบเดี่ยว"}</Tag>
              )}
            </div>
          </div>
        </div>
      </Row>
      <Tabs
        activeKey={tab}
        defaultActiveKey={KEY_TABS.course.key}
        items={headerTabs}
        onChange={(key) => setTab(key)}
      />
    </Modal>
  );
};

export default CourseDetailsModal;
