import { FaRegUserCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { getUser, setToken, setUser } from "utils";
import Menu from "./Menu";

const Header = ({ mobileDropdown }) => {
  const navigate = useNavigate();
  const userInfo = getUser();

  const handleLogout = async () => {
    setUser(null);
    setToken(null);
    navigate("/login");
  };

  return (
    <header className="sa-header">
      <div className="sa-topbar">
        <div className="container">
          <div className="topbar-content">
            <div className="left-content">
              <ul style={{ cursor: "default" }} className="global-list">
                <li>
                  <div className="center text-white">
                    <i className="far fa-envelope"></i>
                    <p>tonesmusiccompany@gmail.com</p>
                  </div>
                </li>
                <li>
                  <div className="center text-white">
                    <i className="fas fa-phone-volume"></i>
                    <p>083-288-8212</p>
                  </div>
                </li>
              </ul>
            </div>
            <div className="right-content">
              {userInfo ? (
                <ul className="global-list">
                  <li className="center">
                    <div
                      style={{ cursor: "default" }}
                      className="me-3 center text-white"
                    >
                      <FaRegUserCircle size={20} className="me-2" />
                      <p>{userInfo?.name || "username"}</p>
                    </div>
                    <p className="me-3">|</p>
                    <p
                      style={{ color: "white", cursor: "pointer" }}
                      onClick={handleLogout}
                    >
                      ออกจากระบบ
                    </p>
                  </li>
                </ul>
              ) : (
                <ul className="global-list">
                  <li className="center">
                    <Link to="/register" className="me-3">
                      <div className="btn btn-primary">
                        สมัครสมาชิก <i className="fas fa-sign-in-alt"></i>
                      </div>
                    </Link>
                    <p className="me-3">|</p>
                    <Link to="/login">
                      <p>เข้าสู่ระบบ</p>
                    </Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>

      <Menu mobileDropdown={() => mobileDropdown()} />
    </header>
  );
};

export default Header;
