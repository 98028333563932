import { Divider, Spin } from "antd";
import { NO_IMAGE } from "assets/images/index";
import { courseTypeToText } from "hooks/functions/courseTypeToText";
import numeral from "numeral";
import moment from "moment";
const CourseDetailSection = ({ courseDetail, isLoading }) => {
  const data = courseDetail;
  return isLoading ? (
    <Spin />
  ) : (
    <div>
      <h4 className="tw-text-[18px]">ชื่อคอร์ส : {data?.title}</h4>
      <p>ประเภท : {courseTypeToText(data?.type)}</p>
      <p>หมวดหมู่ : {data?.category}</p>
      <img
        className="tw-w-full center tw-max-h-[200px] tw-rounded-lg tw-my-4 tw-object-cover tw-object-center"
        src={data?.coverImgUrl ?? NO_IMAGE}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = NO_IMAGE;
        }}
        alt="cover"
      />
      <p>รายละเอียดคอร์สเรียน : {data?.description}</p>
      <p>
        ราคาปกติ : <del>{numeral(data?.delPrice).format("0,0")}</del>
      </p>
      <p>ราคาพิเศษ : {numeral(data?.price).format("0,0")}</p>
      <p>วันที่สร้างคอร์ส : {moment(data?.data).format("LLL")}</p>
      <p>มีการทำ Chalenge : {data?.isChallenge ? "ใช่" : "ไม่"}</p>
      {data?.isChallenge && (
        <p>ระยะเวลาการทำ Chalenge : {data?.durationChallenge}</p>
      )}
      <p>คอร์สเรียนแบบกลุ่ม : {data?.isGroup ? "ใช่" : "ไม่"}</p>
      {data?.position?.lat && data?.position?.lon && (
        <p>
          ตำแหน่ง : lat: {data?.position?.lat} , lon : {data?.position?.lon}
        </p>
      )}

      <Divider />

      {data?.courseEarn?.length > 0 && (
        <>
          <h4 className="tw-text-[18px] tw-mt-4">สิ่งที่จะได้รับ</h4>
          <ul>
            {data?.courseEarn.map((n, i) => (
              <li key={i}>{n?.description}</li>
            ))}
          </ul>
        </>
      )}

      {data?.courseObjective?.length > 0 && (
        <>
          <h4 className="tw-text-[18px] tw-mt-4">วัตถุประสงค์</h4>
          <ul>
            {data?.courseObjective.map((n, i) => (
              <li key={i}>{n?.description}</li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default CourseDetailSection;
