import { Avatar, Button, Col, Collapse, Divider, Modal, Row, Spin } from "antd";
import { NO_IMAGE } from "assets/images";
import {
  AlertConfirm,
  AlertError,
  AlertSuccess,
  AlertWarning,
} from "components/alert/Alert";
import { errorToMessage } from "hooks/functions/errorToMessage";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { IoMdCheckboxOutline } from "react-icons/io";
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  SET_CHECK_POST_QUIZ,
  SET_CHECK_PRE_QUIZ,
  SET_CHECK_QUIZ_DATA,
} from "redux/reducers/checkQuizSlice";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import { Radio, message } from "../../../../../node_modules/antd/es/index";

const checkOptions = [
  {
    label: (
      <div className="tw-flex tw-items-center tw-gap-1">
        <IoCloseCircleOutline />
        <p>ไม่ผ่าน</p>
      </div>
    ),
    value: "failed",
  },
  {
    label: (
      <div className="tw-flex tw-items-center tw-gap-1">
        <IoCheckmarkCircleOutline />
        <p>ผ่าน</p>
      </div>
    ),
    value: "pass",
  },
];

const CheckQuizModal = ({ data, open, onCancel, refetchList }) => {
  const dispatch = useDispatch();
  const checkQuizData = useSelector((state) => state.checkQuiz.data);

  const { data: getWritingQuiz, isLoading } =
    courseBackOfficeServices.useQueryGetWritingQuizByCourseId({
      queryObj: {
        studentId: data?.studentId,
        courseId: data?.id,
      },
    });

  const { mutate: saveAnswer } =
    courseBackOfficeServices.useMutationSaveAnswerForWritingQuiz(
      () => {
        AlertSuccess({});
        onCancel();
        refetchList();
      },
      (err) => AlertError({ text: errorToMessage(err) })
    );

  const checkedResult = useMemo(() => {
    return _.flatMap(checkQuizData, (n) =>
      _.map(
        _.filter([...n.preQuiz, ...n.postQuiz], (item) =>
          _.has(item, "result")
        ),
        (item) => _.pick(item, ["id", "result"])
      )
    );
  }, [checkQuizData]);

  useEffect(() => {
    if (!!getWritingQuiz?.data) {
      dispatch(SET_CHECK_QUIZ_DATA(getWritingQuiz?.data));
    }
  }, [getWritingQuiz]);

  return (
    <Modal
      title={"ตรวจแบบทดสอบ"}
      width={800}
      open={open}
      onCancel={onCancel}
      footer={null}
      okText="บันทึก"
      cancelText="ยกเลิก"
    >
      <Row className="my-4">
        <Col span={14}>
          <div className="d-flex ">
            <Avatar
              className="me-4"
              size={{
                xs: 24,
                sm: 32,
                md: 40,
                lg: 64,
                xl: 80,
                xxl: 100,
              }}
              src={data?.thumbUrl || NO_IMAGE}
            />
            <div className="d-flex flex-column justify-content-center">
              <h5>{data?.name || "student name"}</h5>
              <p>{data?.email || "student contact"}</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Collapse
            items={_.map(checkQuizData || [], (n, index) => ({
              key: index + 1,
              label: n?.unitName,
              children: isLoading ? (
                <Spin />
              ) : (
                <>
                  {n?.preQuiz.length <= 0 && n?.postQuiz.length <= 0 && (
                    <div className="tw-flex tw-justify-center tw-items-center tw-p-2">
                      <p className="tw-opacity-40">- ยังไม่มีแบบทดสอบ -</p>
                    </div>
                  )}
                  <div>
                    {n?.preQuiz.length > 0 && (
                      <u>
                        <p className="font-bold mb-2">แบบทดสอบก่อนเรียน</p>
                      </u>
                    )}
                    <div>
                      {n?.preQuiz?.map((pre, preQuizIndex) => {
                        return (
                          <div key={pre.id}>
                            <p>
                              ข้อ {pre?.seq} -โจทย์ : {pre?.question}
                            </p>
                            <p>คำตอบ : {pre?.answer}</p>
                            <Radio.Group
                              className="tw-mt-2"
                              optionType="button"
                              options={checkOptions}
                              value={
                                pre.result
                                  ? "pass"
                                  : pre.result === false
                                  ? "failed"
                                  : null
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                const checked = value === "pass";
                                dispatch(
                                  SET_CHECK_PRE_QUIZ({
                                    dataIndex: index,
                                    preQuizIndex,
                                    preData: pre,
                                    result: checked,
                                  })
                                );
                                message.open({
                                  type: "success",
                                  content: "ตรวจสำเร็จ!",
                                });
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {n?.preQuiz.length > 0 && <Divider />}
                  <div>
                    {n?.postQuiz.length > 0 && (
                      <u>
                        <p className="font-bold mb-2">แบบทดสอบหลังเรียน</p>
                      </u>
                    )}
                    <div>
                      {n?.postQuiz?.map((post, postQuizIndex) => {
                        return (
                          <div key={post.id}>
                            <p>
                              ข้อ {post?.seq} - โจทย์ : {post?.question}
                            </p>
                            <p>คำตอบ : {post?.answer}</p>
                            <Radio.Group
                              className="tw-mt-2"
                              optionType="button"
                              options={checkOptions}
                              value={
                                post.result
                                  ? "pass"
                                  : post.result === false
                                  ? "failed"
                                  : null
                              }
                              onChange={(e) => {
                                const value = e.target.value;
                                const checked = value === "pass";
                                dispatch(
                                  SET_CHECK_POST_QUIZ({
                                    dataIndex: index,
                                    postQuizIndex,
                                    postData: post,
                                    result: checked,
                                  })
                                );
                                message.open({
                                  type: "success",
                                  content: "ตรวจสำเร็จ!",
                                });
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              ),
            }))}
            defaultActiveKey={[]}
            onChange={(e) => {}}
          />
        </Col>
      </Row>
      <div className="tw-my-4 tw-flex tw-items-center tw-justify-between">
        <div className="tw-flex tw-items-center tw-gap-2">
          <IoMdCheckboxOutline />
          <p>ตรวจแล้ว {checkedResult.length} รายการ </p>
        </div>
        <Button
          type="primary"
          onClick={() => {
            AlertConfirm({
              onOk: () => {
                saveAnswer({
                  answers: checkedResult || [],
                  studentId: data?.studentId || "",
                });
              },
            });
          }}
        >
          บันทึกการตรวจ
        </Button>
      </div>
    </Modal>
  );
};

export default CheckQuizModal;
