import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Modal,
  Row,
  Spin,
} from "antd";
import BookingList from "components/BookingList";
import CalendarBooking from "components/CalendarBooking";
import { AlertError, AlertSuccess } from "components/alert/Alert";
import { courseTypeOption } from "constants/options/courseTypeOption";
import { thaiLocale } from "constants/thaiLocale/thaiLocale";
import { disabledFutureDate } from "hooks/functions/disabledFutureDate";
import { toDateFormat } from "hooks/toDateFormat";
import useModalHook from "hooks/useModalHook";
import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import courseService from "services/course.services";
import { getUserId } from "utils";
import { IoLocation } from "react-icons/io5";
import { IoIosLink } from "react-icons/io";

const timeStyle = {
  padding: "28px 40px",
  borderRadius: "0px",
  fontSize: "18px",
  fontWeight: "600",
};

const CourseSchedule = ({ type, courseId, dataCourse }) => {
  const [dateSelected, setDateSelected] = useState(null);
  const [newBookingTime, setNewBookingTime] = useState(null);
  const [editBooking, setEditBooking] = useState(null);
  const [availableTime, setAvailableTime] = useState([]);
  const [isReserved, setIsReserved] = useState(false);
  const [isHour, setIsHour] = useState(0);

  const {
    open: openEditBooking,
    handleOpen: _handleOpenEditBooking,
    handleClose: _handleCloseEditBooking,
  } = useModalHook();

  const {
    data: getAppointment,
    isFetching: isLoadingAppointment,
    refetch: refetchAppointment,
  } = courseService.useQueryGetAppointment({
    courseId,
  });

  const { mutate: getAvailableSlot, isLoading: isLoadingTime } =
    courseService.useMutationGetSlotAvailableTimeByTeacherAndDay((res) => {
      setAvailableTime(res);
    });

  const { mutate: updateAppointment } =
    courseService.useMutationUpdateAppointment(
      () => {
        _handleCloseEditBooking();
        setDateSelected(null);
        setNewBookingTime(null);
        AlertSuccess({});
        setIsReserved(true);
      },
      (err) => {
        AlertError({ text: `${err?.message}` });
      }
    );

  function disabledDate(current) {
    let selectedDate = editBooking?.bookingDate;
    return (
      current.isBefore(moment(selectedDate).subtract(7, "days")) ||
      current.isAfter(moment(selectedDate).add(7, "days"))
    );
  }

  const handleNewBooking = (time_slot) => {
    const { intervalStart, intervalLearn, intervalEnd } = time_slot;
    let booking = {
      id: editBooking?.id,
      bookingDate: toDateFormat(dateSelected),
      startTime: intervalStart,
      learnTime: intervalLearn,
      endTime: intervalEnd,
      teacherId: dataCourse?.teacherId,
      courseId: courseId,
      userId: getUserId(),
      numberOfHours: editBooking?.numberOfHours || 1,
      status: -1, // [-1]: can delelte  [0]:can't booking , [1]: booked
    };
    setNewBookingTime([booking]);
  };

  const selectHour = () => {
    return (
      <Col span={24}>
        {isHour !== 2 && (
          <Button
            style={{ borderRadius: "0px", padding: "40px 24px" }}
            className="w-100 mb-2 d-flex justify-content-between align-items-center"
          >
            <div>1 hour - Regular Lesson (1 ชั่วโมง)</div>
            {isHour === 1 ? (
              <Button
                danger
                style={{ borderRadius: "0px", padding: "22px 40px" }}
                className="center"
                onClick={() => setIsHour(0)}
              >
                ยกเลิก
              </Button>
            ) : (
              <Button
                type="primary"
                style={{ borderRadius: "0px", padding: "22px 40px" }}
                className="center"
                onClick={() => setIsHour(1)}
              >
                จองเวลา
              </Button>
            )}
          </Button>
        )}
        {isHour !== 1 && (
          <Button
            style={{ borderRadius: "0px", padding: "40px 24px" }}
            className="w-100 mb-2 d-flex justify-content-between align-items-center"
          >
            <div>2 hour - Extra Lesson ( 2 ชั่วโมง)</div>
            {isHour === 2 ? (
              <Button
                danger
                style={{ borderRadius: "0px", padding: "22px 40px" }}
                className="center"
                onClick={() => setIsHour(0)}
              >
                ยกเลิก
              </Button>
            ) : (
              <Button
                type="primary"
                style={{ borderRadius: "0px", padding: "22px 40px" }}
                className="center"
                onClick={() => setIsHour(2)}
              >
                จองเวลา
              </Button>
            )}
          </Button>
        )}
      </Col>
    );
  };
  const items = [
    {
      key: "1",
      label: `คอร์สเรียน${
        _.find(courseTypeOption, (n) => n.value === type)?.label
      }`,
      children: <Row>{selectHour()}</Row>,
    },
  ];
  useEffect(() => {
    refetchAppointment();
  }, [isReserved, newBookingTime]);

  const locationUrl = useMemo(() => {
    if (dataCourse?.type === "studio") {
      const isLat = dataCourse?.position?.lat;
      const isLon = dataCourse?.position?.lon;
      const url = `https://www.google.com/maps?q=${isLat},${isLon}`;
      return !!isLat && !!isLon ? url : "#";
    }
  }, [dataCourse]);

  return (
    <>
      {dataCourse?.type === "studio" && (
        <div>
          <h4>สถานที่เรียน</h4>
          <div className="tw-flex tw-gap-2 tw-items-center">
            <IoLocation />
            <a href={locationUrl} target="_blank">
              {dataCourse?.locationName || "Location Name"}
            </a>
          </div>
        </div>
      )}

      {dataCourse?.type === "zoom" && (
        <div>
          <h4>ลิงก์สำหรับการเรียน</h4>
          <div className="tw-flex tw-gap-2 tw-items-center">
            <IoIosLink />
            <a href={dataCourse?.linkZoom || "#"} target="_blank">
              {dataCourse?.linkZoom || "Link Zoom"}
            </a>
          </div>
        </div>
      )}

      <h4>
        {dataCourse?.type === "online"
          ? "ข้อมูลคอร์สเรียน"
          : "ข้อมูลการจองเวลาเรียน"}
      </h4>
      {type === "online" ? (
        <ul className="mt-4 global-list">
          <li>
            {/* <strong>className Start:</strong> */}
            <span>{dataCourse?.description}</span>
          </li>
        </ul>
      ) : (
        <>
          {isLoadingAppointment ? (
            <Spin />
          ) : getAppointment ? (
            <>
              <Row>
                <ul className="mt-4 px-0 w-100">
                  {getAppointment?.map((slot, i) => {
                    const DAY_BEFORE = 3; // แก้ไขได้ก่อนกี่วัน
                    const editBefore = moment().subtract(DAY_BEFORE, "days");
                    const bookingDay = moment(slot.bookingDate);
                    const isEditable =
                      bookingDay.isAfter(editBefore) &&
                      disabledFutureDate(bookingDay);
                    return (
                      <BookingList
                        key={i}
                        type={slot.status === 1 ? "done" : "edit"}
                        bookingDate={slot.bookingDate}
                        learnTime={slot.learnTime}
                        isEditable={isEditable}
                        onClick={() => {
                          if (slot.status === 0 || isEditable) {
                            setEditBooking(slot);
                            _handleOpenEditBooking();
                            setIsReserved(false);
                          }
                        }}
                      />
                    );
                  })}
                </ul>
              </Row>
            </>
          ) : (
            <>
              <Collapse
                className="mb-4"
                items={items}
                defaultActiveKey={["1"]}
              />
              {isHour !== 0 && (
                <CalendarBooking
                  isHour={isHour}
                  courseId={courseId}
                  dataCourse={dataCourse}
                  setIsReserved={setIsReserved}
                />
              )}
            </>
          )}
        </>
      )}

      <Modal
        title="แก้ไขเวลา"
        open={openEditBooking}
        onOk={() => {
          updateAppointment({ booking: newBookingTime[0] });
        }}
        onCancel={() => {
          _handleCloseEditBooking();
          setNewBookingTime(null);
          setDateSelected(null);
        }}
        okText="บันทึก"
        cancelText="ยกเลิก"
      >
        <p>{`${moment(editBooking?.bookingDate)?.format(
          "DD MMMM YYYY"
        )} -เวลา ${editBooking?.learnTime} น.`}</p>
        <Divider />

        <p className="mb-2">กรุณาเลือกวัน</p>
        <DatePicker
          locale={thaiLocale}
          style={{ width: "200px" }}
          placeholder="กรุณาเลือกวันอื่น"
          disabledDate={disabledDate}
          onChange={(date) => {
            let isDate = date?.$d;
            setDateSelected(isDate);
            setNewBookingTime(null);
            getAvailableSlot({
              queryObj: {
                date: toDateFormat(isDate),
                dayOfWeek: _.toNumber(
                  moment(editBooking?.bookingDate).format("d")
                ), // NumberOfDay start at [Sunday = 0]
                hour: editBooking?.numberOfHours || 1,
                hourTravel: 0, // case [at_home]
                teacherId: dataCourse?.teacherId,
              },
            });
          }}
        />

        <Row gutter={[8, 8]} className="my-4">
          {isLoadingTime ? (
            <Spin />
          ) : (
            dateSelected &&
            (newBookingTime ? (
              <Col span={24}>
                <BookingList
                  key={newBookingTime?.id}
                  type="remove"
                  bookingDate={newBookingTime[0]?.bookingDate}
                  learnTime={newBookingTime[0]?.learnTime}
                  onClick={() => {
                    setNewBookingTime(null);
                  }}
                />
              </Col>
            ) : (
              availableTime?.map((available, index) => {
                return (
                  <Col span={8}>
                    <Button
                      key={index}
                      style={timeStyle}
                      className="center w-100"
                      onClick={() => {
                        handleNewBooking(available);
                      }}
                    >
                      {available?.intervalLearn}
                    </Button>
                  </Col>
                );
              })
            ))
          )}
        </Row>
      </Modal>
    </>
  );
};

export default CourseSchedule;
