import _ from "lodash";
import { useMutation, useQuery } from "react-query";
import { getUserId } from "utils";
import createRequest from "../utils/request";
const fileDownload = require("js-file-download");
const getFileName = (headers) => {
  let filename = "";
  const disposition = headers["content-disposition"];
  if (disposition && disposition.indexOf("attachment") !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, "");

      // Decode URL-encoded filename
      filename = decodeURIComponent(filename);
    }
  }
  return filename;
};

const fileService = {
  // :: FILE SERVICE :: --------------------------------------------------
  useQueryGetFile({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getFile", queryObj],
      async () => {
        const { key, prefix } = queryObj;
        const path = `/file/getPublicUrl`;
        const query = { params: { key, prefix } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },

  //   ---------------------- :: USE_MUTATION :: --------------------
  useMutationUploadFile(onSuccess, onError) {
    return useMutation(
      async ({ prefix, fileList }) => {
        const formData = new FormData();
        formData.append("prefix", prefix);
        formData.append("file", fileList[0]?.originFileObj);
        if (_.size(fileList) > 0) {
          const path = `/file/uploadFile`;
          const res = await createRequest.post(path, formData);
          return res.data.data;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDownloadTemplateQuiz(courseId) {
    return useMutation(async () => {
      const path = `/file/downloadTemplateQuiz`;
      const query = { courseId };
      // Assuming createRequest is a function that makes an HTTP request
      const response = await createRequest.get(path, {
        params: query,
        responseType: "blob",
      });
      if (
        response.headers["content-type"] !== "application/json" &&
        (response.status === 200 || response.status === 204)
      ) {
        fileDownload(response.data, getFileName(response.headers));
      }
    });
  },
  useMutationDownloadExamResultAndAssessment(startDate, endDate) {
    return useMutation(async () => {
      const path = `/file/downloadExamResultAndAssessment`;
      const query = { id: getUserId(), startDate, endDate };
      const response = await createRequest.get(path, {
        params: query,
        responseType: "blob",
      });
      if (
        response.headers["content-type"] !== "application/json" &&
        (response.status === 200 || response.status === 204)
      ) {
        fileDownload(response.data, getFileName(response.headers));
      }
    });
  },
  useMutationDownloadSentHomework(startDate, endDate) {
    return useMutation(async () => {
      const path = `/file/downloadSentHomework`;
      const query = { id: getUserId(), startDate, endDate };
      const response = await createRequest.get(path, {
        params: query,
        responseType: "blob",
      });
      if (
        response.headers["content-type"] !== "application/json" &&
        (response.status === 200 || response.status === 204)
      ) {
        fileDownload(response.data, getFileName(response.headers));
      }
    });
  },
  useMutationDownloadPaymentHistory(startDate, endDate) {
    return useMutation(async () => {
      const path = `/file/downloadPaymentHistory`;
      const query = { startDate, endDate };
      try {
        const response = await createRequest.get(path, {
          params: query,
          responseType: "blob",
        });
        if (
          response.headers["content-type"] !== "application/json" &&
          (response.status === 200 || response.status === 204)
        ) {
          fileDownload(response.data, getFileName(response.headers));
        }
      } catch (error) {
        throw error;
      }
    });
  },
  useMutationDownloadPopularCourse(startDate, endDate) {
    return useMutation(async () => {
      const path = `/file/downloadPopularCourse`;
      const query = { startDate, endDate };
      const response = await createRequest.get(path, {
        params: query,
        responseType: "blob",
      });
      if (
        response.headers["content-type"] !== "application/json" &&
        (response.status === 200 || response.status === 204)
      ) {
        fileDownload(response.data, getFileName(response.headers));
      }
    });
  },
  useMutationDownloadPopularCategoryCourse(startDate, endDate) {
    return useMutation(async () => {
      const path = `/file/downloadPopularCategoryCourse`;
      const query = { startDate, endDate };
      const response = await createRequest.get(path, {
        params: query,
        responseType: "blob",
      });
      if (
        response.headers["content-type"] !== "application/json" &&
        (response.status === 200 || response.status === 204)
      ) {
        fileDownload(response.data, getFileName(response.headers));
      }
    });
  },

  useMutationDownloadPopularTypeCourse(startDate, endDate) {
    return useMutation(async () => {
      const path = `/file/downloadPopularTypeCourse`;
      const query = { startDate, endDate };
      const response = await createRequest.get(path, {
        params: query,
        responseType: "blob",
      });
      if (
        response.headers["content-type"] !== "application/json" &&
        (response.status === 200 || response.status === 204)
      ) {
        fileDownload(response.data, getFileName(response.headers));
      }
    });
  },
  useMutationDownloadDeliveryCode(onSuccess, onError) {
    return useMutation(
      async ({ paymentId }) => {
        const path = `/file/downloadCod`;
        const query = { paymentId };
        // Assuming createRequest is a function that makes an HTTP request
        const response = await createRequest.get(path, {
          params: query,
          responseType: "blob",
        });
        if (
          response.headers["content-type"] !== "application/json" &&
          (response.status === 200 || response.status === 204)
        ) {
          fileDownload(response.data, getFileName(response.headers));
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDownloadCertificate(onSuccess, onError) {
    return useMutation(
      async ({ courseId }) => {
        const path = `/file/getCertificate`;
        const query = { courseId };
        // Assuming createRequest is a function that makes an HTTP request
        const response = await createRequest.get(path, {
          params: query,
          responseType: "blob",
        });
        if (
          response.headers["content-type"] !== "application/json" &&
          (response.status === 200 || response.status === 204)
        ) {
          fileDownload(response.data, getFileName(response.headers));
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};
export default fileService;
