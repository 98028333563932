import { Tabs } from "antd";
import RecentQuiz from "./RecentQuiz";

const QuizPage = () => {
  const headerTabs = [
    {
      key: 1,
      label: `ส่งแบบทดสอบล่าสุด`,
      children: <RecentQuiz />,
    },
  ];

  return <Tabs size="large" defaultActiveKey={1} items={headerTabs} />;
};

export default QuizPage;
