import axios from "axios";
import { readAndCompressImage } from "browser-image-resizer";
import {
  AlertClosed,
  AlertError,
  AlertLoading,
  AlertUpdateLoading,
} from "components/alert/Alert";
import _ from "lodash";
import { URL_API, getToken } from "utils";

const MAX_IMAGE_SIZE_MB = 1; //MB
let swalInstance = null;

export const onUploadFile = async ({
  prefix = "",
  fileList = [],
  showProgress = false,
}) => {
  try {
    if (_.size(fileList) > 0) {
      if (typeof fileList[0] === "object" && !fileList[0]?.type || typeof fileList[0] !== "object") {
        return
      }
      let file = fileList[0]?.originFileObj || fileList[0];
      const fileType = file.type == "image/jpeg" || file.type == "image/png"; // image only
      console.log(`:: FILE SIZE : ${_.round(file.size / 1024 / 1024, 2)} MB`);

      if (file.size > MAX_IMAGE_SIZE_MB * 1024 * 1024 && fileType) {
        const resizedFile = await resizeImage(file); // resize image
        file = resizedFile;
        console.log(
          `:: RE-SIZE : ${_.round(resizedFile.size / 1024 / 1024, 2)} MB`
        );
      }

      swalInstance = AlertLoading({ text: `กำลังอัปโหลดไฟล์...` });
      const formData = new FormData();
      formData.append("prefix", prefix);
      formData.append("file", file); // new size

      const path = `${URL_API}/file/uploadFile`;
      const headers = {
        Authorization: `Bearer  ${getToken()}`,
        "Content-Type": "multipart/form-data",
      };

      const config = {
        headers,
        onUploadProgress: (e) => {
          const percent = Math.round((e.loaded * 100) / e.total);
          if (showProgress) AlertUpdateLoading({ percent: percent });
          // percent % 20 === 0 && console.log(`Upload Progress: ${percent}%`);
        },
      };

      const res = await axios.post(path, formData, config);
      const file_name = res?.data?.data;

      AlertClosed();
      if (swalInstance) swalInstance = null;
      return file_name;
    }
    return;
  } catch (error) {
    AlertError({
      title: "อัปโหลดไฟล์ไม่สำเร็จ!",
      text: "เนื่องจากขนาดไฟล์มีขนาดใหญ่เกินไป!",
    });
  }
};

// resizeImage ::
const resizeImage = async (file) => {
  const resizedFile = await readAndCompressImage(file, {
    maxSizeMB: MAX_IMAGE_SIZE_MB,
  });
  return new File([resizedFile], file.name, { type: file.type });
};
