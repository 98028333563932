import { Button, Card, Col, Divider, Empty, Row, Spin } from "antd";
import { NO_IMAGE } from "assets/images";
import { COLOR } from "constants/color";
import { courseTypeToText } from "hooks/functions/courseTypeToText";
import useModalHook from "hooks/useModalHook";
import moment from "moment";
import numeral from "numeral";
import { useState } from "react";
import { IoBookOutline } from "react-icons/io5";
import { LiaEditSolid, LiaInfoSolid } from "react-icons/lia";
import { MdOutlineAccessTime, MdOutlineQuiz } from "react-icons/md";
import ReactPlayer from "react-player";
import { useNavigate, useParams } from "react-router-dom";
import CourseOnlineModal from "../course-modal/CourseOnlineModal";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";
import QuizModal from "components/modal/quizModal";
import teacherBackOfficeService from "services/backoffice/teacherBackoffice.services";
import courseService from "services/course.services";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { getIsKruNut } from "utils";

const CourseOnline = ({ data, refetch = () => { } }) => {
  const navigate = useNavigate();
  const [editUnit, setEditUnit] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(1);
  const { courseId } = useParams();
  const isKruNut = getIsKruNut();

  const {
    data: dataCourse
  } = courseService.useQueryGetCourseFlagById({ id: courseId });

  const {
    open: openCreateUnit,
    handleOpen: _handleOpenCreateUnit,
    handleClose: _handleCloseCreateUnit,
  } = useModalHook();

  const {
    open: openQuiz,
    handleOpen: _handleOpenQuiz,
    handleClose: _handleCloseQuiz,
  } = useModalHook();

  const handleOk = async (saveData) => {
    const quizTypeLabel = { ปรนัย: "choosing", อัตนัย: "writing" };
    const unitNames = data?.data?.reduce((acc, { id, unitName }) => {
      acc[unitName] = id;
      return acc;
    }, {});
    const formattedData = saveData
      ?.filter((e) => Object.keys(unitNames).includes(e.sheetName))
      ?.map(({ sheetName, data }) => {
        const replaceData = data?.map(({ type, quizType, ...rest }) => {
          return {
            quizType: quizTypeLabel[quizType],
            ...rest,
          };
        });
        return { id: unitNames[sheetName], data: replaceData };
      });

    await teacherBackOfficeService.uploadQuiz(formattedData);
    _handleCloseQuiz();
  };

  return (
    <>
      <Row className="d-flex justify-content-between">
        {data ? (
          <div>
            <h4>
              {`${data?.courseName} ( ${numeral(data?.total).format("0,0")} )`}
            </h4>
            <p>{courseTypeToText("online")}</p>
          </div>
        ) : (
          <Spin />
        )}
        {dataCourse?.isReject || (isKruNut && !dataCourse?.isPublish) ? <div className="d-flex gap-2">
          {data?.total ? <Button type="primary" onClick={() => {
            _handleOpenQuiz();
          }}>
            + สร้างแบบทดสอบ
          </Button> : null}
          <Button
            type="primary"
            onClick={() => {
              setEditUnit(null);
              _handleOpenCreateUnit();
            }}
          >
            + เพิ่มบทเรียน
          </Button>
        </div> : null}
      </Row>
      <Divider />
      <Row>
        {data?.data ? (
          <Col span={24}>
            {data?.data?.map((n, i) => {
              return (
                <Card
                  key={i}
                  title={`บทที่ ${i + 1} - ${n?.unitName}`}
                  extra={
                    <a
                      className="text-primary center"
                      onClick={() => {
                        setEditUnit(n);
                        setCurrentIndex(i + 1);
                        _handleOpenCreateUnit();
                      }}
                    >
                      {!dataCourse?.isReject || (isKruNut && dataCourse?.isPublish) ? (
                        <div className="d-flex align-items-center gap-1">
                          <IoMdInformationCircleOutline size={18} />
                          ดูรายละเอียด
                        </div>
                      ) : (
                        <div className="d-flex align-items-center gap-1">
                          <LiaEditSolid size={18} />
                          แก้ไข
                        </div>
                      )}
                    </a>
                  }
                  className="mb-3 course-list"
                  style={{
                    width: "100%",
                  }}
                >
                  <Row>
                    <Col span={10} className="pe-4">
                      <Row
                        className="center"
                        style={{
                          borderRadius: "4px",
                          overflow: "hidden",
                          height: "120px",
                        }}
                      >
                        {n?.videoUrl ? (
                          <ReactPlayer
                            url={n?.videoUrl}
                            height={"120px"}
                            controls
                          />
                        ) : (
                          <img
                            className="w-100 h-100"
                            style={{ objectFit: "cover" }}
                            src={NO_IMAGE}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = NO_IMAGE;
                            }}
                            alt="not found"
                          />
                        )}
                      </Row>
                    </Col>
                    <Col span={12}>
                      <p className="limited-text">
                        รายละเอียด : {n.description}
                      </p>

                      <div className="d-flex align-items-center mt-1"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          navigate(`/teacher/course/${courseId}/quiz/${n?.id}`);
                        }}
                      >
                        <div className="me-2" style={{ width: "10px" }}>
                          <MdOutlineQuiz color={COLOR.primary} />
                        </div>
                        <p className="text-primary me-4">{`ดูแบบทดสอบ`}</p>
                      </div>
                      {
                        !dataCourse?.isApprove ?
                          <div className="d-flex align-items-center mt-1">
                            <div className="me-2" style={{ width: "10px" }}>
                              <IoBookOutline color={COLOR.primary} />
                            </div>
                            <p className="text-primary me-4">{`การบ้าน : ${n?.homework || "ไม่มีการบ้าน"}`}</p>
                          </div>
                          :
                          <a className="d-flex align-items-center mt-1"
                            href={`/teacher/homework/${courseId}`}
                            target="_blank"
                          >
                            <div className="me-2" style={{ width: "10px" }}>
                              <IoBookOutline color={COLOR.primary} />
                            </div>
                            <p className="text-primary me-4">{`การบ้าน : ${n?.homework || "ไม่มีการบ้าน"}`}</p>
                          </a>
                      }
                      <div className="d-flex align-items-center mt-1">
                        <MdOutlineAccessTime className="me-2" />
                        <p>{`วันที่สร้าง : ${moment(n.date).format(
                          "DD/MM/YYYY"
                        )}`}</p>
                      </div>
                    </Col>
                    <Col
                      span={6}
                      className="d-flex justify-content-end align-items-end"
                    ></Col>
                  </Row>
                </Card>
              );
            })}
          </Col>
        ) : (
          <div className="w-100 center p-4">
            <Empty description={EMPTY_DESCRIPTION} />
          </div>
        )}
      </Row>
      <CourseOnlineModal
        isEditable={!dataCourse || dataCourse.isReject || (isKruNut && !dataCourse?.isPublish)}
        data={editUnit}
        total={data?.total}
        open={openCreateUnit}
        onOk={_handleCloseCreateUnit}
        onCancel={_handleCloseCreateUnit}
        index={currentIndex}
        refetch={refetch}
      />
      <QuizModal
        courseId={courseId}
        isOpen={openQuiz}
        open={_handleOpenQuiz}
        onOk={handleOk}
        onCancel={_handleCloseQuiz}
      />
    </>
  );
};

export default CourseOnline;
