import { useQuery } from "react-query";
import createRequest from "utils/request";

const adminUserServices = {
  // :: ADMIN_USER_STUDENT :: --------------------------------------------------
  useQueryGetRoleStudent({ onSuccess, onError }) {
    return useQuery(
      ["getAllUserRoleStudent"],
      async () => {
        const path = `/user/getAllUserRoleStudent`;
        try {
          const res = await createRequest.get(path);
          return res.data;
        } catch (error) {
          throw error;
        }
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
};

export default adminUserServices;
