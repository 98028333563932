import ReviewCard from "components/ReviewCard";
import WritingReview from "components/WritingReview";
import courseService from "services/course.services";

const CourseReview = ({ courseId }) => {
  const { data: dataReview } = courseService.useQueryGetReviewByCourseId({
    id: courseId,
  });
  return (
    <>
      <div className="all-reviews">
        {[...(dataReview?.data || [])].map((data, index) => (
          <ReviewCard key={`review-${index}`} data={data} />
        ))}
      </div>
    </>
  );
};

export default CourseReview;
