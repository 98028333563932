import React from "react";
import { Link } from "react-router-dom";
import { getUser } from "utils";

export default function Carousel({ imgList = [] }) {
  const isUser = getUser();
  const carousel = (e, i) => {
    return (
      <div
        key={`carousel-card-${i}`}
        className={`carousel-item ${e.extendsClass} ${e.active ? "active" : ""
          }`}
      >
        <div
          className="carousel-item-content section-before"
          style={{ backgroundImage: `url(${e.img})` }}
        >
          <div className="container">
            <div className="hero-text">
              <h1 data-animation="animated fadeInDown">
                Best online <span>course</span>
              </h1>
              <p data-animation="animated fadeInDown">
                เหมาะสมกับทุกวัย วัยผู้ใหญ่ก็สามารถเรียนได้
                ซึ่งจะเป็นพื้นที่ให้ผู้ใหญ่ได้เรียนรู้อย่างไม่รู้จบ
                สามารถใช้เวลาว่างให้เกิดประโยชน์ ช่วยลดความเหงา ความเศร้า
                ความเครียด เสริมสร้างด้านการจดจำ กิจกรรมดนตรีที่สร้างสรรค์
                ที่จะใช้ให้ผู้ใหญ่มีความสุข และยังช่วยเสริมสร้างทั้งสุขภาพกาย
                และสุขภาพใจให้แข็งแรงมากยิ่งขึ้น
              </p>
              {!isUser?.id && (
                <div className="buttons">
                  <Link to="/login"
                    className="btn btn-primary"
                    data-animation="animated fadeInRight"
                  >
                    เข้าสู่ระบบ
                  </Link>
                  <Link to="/register"
                    className="btn btn-white"
                    data-animation="animated fadeInLeft"
                  >
                    ลงทะเบียน
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div
        id="hero-slider"
        className="carousel slide hero-content"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {imgList?.map((e, i) => carousel(e, i))}
        </div>

        <div className="carousel-indicators">
          {imgList?.map((e, i) => (
            <button
              key={`carousel-btn-${i}`}
              type="button"
              data-bs-target="#hero-slider"
              data-bs-slide-to={`${i}`}
              className={`${e.active ? "active" : ""}`}
              aria-current={`${e.active ? "active" : ""}`}
              aria-label={`Slide ${i}`}
            />
          ))}
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#hero-slider"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#hero-slider"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </>
  );
}
