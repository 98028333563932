import { ROLE_USER } from "constants/roleUserConstant";
import { v4 as uuidv4 } from 'uuid';

const ROLE_PATH = ROLE_USER.student;
export const userTabMenu = [
  {
    id: uuidv4(),
    key: "profile",
    name: "โปรไฟล์",
    desc: "จัดการข้อมูลโปรไฟล์ของคุณ",
    path: `/${ROLE_PATH}/profile`,
    disabled: false,
  },
  {
    id: uuidv4(),
    key: "account",
    name: "บัญชีของฉัน",
    desc: "จัดการข้อมูลบัญชีของคุณ",
    path: `/${ROLE_PATH}/account`,
    disabled: false,
  },
  {
    id: uuidv4(),
    key: "course",
    name: "คอร์สเรียนของฉัน",
    desc: "จัดการข้อมูลคอร์สเรียนของคุณ",
    path: `/${ROLE_PATH}/course`,
    disabled: false,
  },
  {
    id: uuidv4(),
    key: "workshop",
    name: "เวิร์กชอป",
    desc: "จัดการข้อมูลเวิร์กชอปของคุณ",
    path: `/${ROLE_PATH}/workshop`,
    disabled: false,
  },
  {
    id: uuidv4(),
    key: "point",
    name: "คะแนนสะสม",
    desc: "จัดการข้อมูลคะแนนสะสมของคุณ",
    path: `/${ROLE_PATH}/point`,
    disabled: true,
  },
  {
    id: uuidv4(),
    key: "package",
    name: "แพ็กเกจ",
    desc: "จัดการข้อมูลแพ็กเกจของคุณ",
    path: `/${ROLE_PATH}/package`,
    disabled: true,
  },
  {
    id: uuidv4(),
    key: "purchase",
    name: "การซื้อของฉัน",
    desc: "รายละเอียดคำสั่งซื้อ",
    path: `/${ROLE_PATH}/purchase`,
    disabled: false,
  },
];
