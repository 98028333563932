import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

export const TABS_VALUE = {
  ONLINE: "online",
  DRAFT: "draft",
  WAITING: "waiting",
};

const initialState = {
  tab: TABS_VALUE.ONLINE,
};

const packageSlice = createSlice({
  name: "package",
  initialState,
  reducers: {
    SET_TAB: (state, action) => {
      state.tab = action.payload;
    },
  },
});

export const { SET_TAB } = packageSlice.actions;
export default packageSlice.reducer;
