import { Button } from "antd";
import { bg_tonemusic, logo_mini_tonemusic } from "assets";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import { COLOR } from "constants/color";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { useState } from "react";
import { Link } from "react-router-dom";
import masterService from "services/master.services";

const backgroundStyle = {
  margin: 0,
  padding: 0,
  backgroundImage: `url(${bg_tonemusic})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  height: "100vh",
};

const text = {
  color: "#424242",
  opacity: "0.6",
  fontSize: "20px",
};

const header = {
  color: COLOR.primary,
  fontWeight: "bold",
  fontSize: "40px",
  lineHeight: "34px",
};

const ForgetPassword = () => {
  const [email, setEmail] = useState(null);

  const { mutate: forgetPassword } = masterService.useMutationForgetPassword(
    () => {
      AlertSuccess({
        text: "กรุณาตรวจสอบ Email และคลิ๊ก Link เพื่อเปลี่ยนรหัสผ่าน",
      });
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );

  const _handleResetPassword = () => {
    AlertConfirm({
      onOk: () => {
        forgetPassword({ email });
      },
    });
  };

  return (
    <div style={backgroundStyle}>
      <div className="sg-section tw-h-screen tw-flex tw-justify-center tw-items-center">
        <div className="section-content tw-mt-[-250px]">
          <div className="container">
            <div className="ragister-account text-center">
              <div className="row">
                <div className="col-lg-6">
                  <div className="account-content">
                    <div
                      style={{
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="d-flex w-100"
                    >
                      <h1 className="w-100">ลืมรหัสผ่าน</h1>
                      <Link to="/" style={{ width: "200px" }}>
                        <u className="me-2">กลับสู่หน้าหลัก</u>
                      </Link>
                    </div>
                    <div className="ragister-form">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "32px 0px",
                        }}
                      >
                        <img
                          style={{ width: "60px", height: "60px" }}
                          src={logo_mini_tonemusic}
                          alt="music icon"
                        />
                        <div className="ms-4 text-start">
                          <p style={header}>Tones Music</p>
                          <p style={text}>Education Course</p>
                        </div>
                      </div>
                      <div className="form-group text-left mb-0">
                        <label>Email</label>
                        <input
                          type="email"
                          className="form-control"
                          required="required"
                          placeholder="example@gmail.com"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                      <Button
                        style={{ height: "45px" }}
                        type="primary"
                        className="w-100"
                        onClick={_handleResetPassword}
                      >
                        <p>ลืมรหัสผ่าน</p>
                      </Button>

                      <div className="middle-content my-2">
                        <p className="mb-2">
                          <Link to="/login" className="text-primary ms-2">
                            เข้าสู่ระบบ
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
