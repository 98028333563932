import { useMutation, useQuery } from "react-query";
import createRequest from "../utils/request";
import { getToken } from "utils";

const promotionService = {
  // :: PROMOTION QUERY :: --------------------------------------------------
  useQueryGetAvailablePromotion({ onSuccess, onError }) {
    return useQuery(
      ["getAvailablePromotion"],
      async () => {
        const path = `/promotion/getAvailablePromotion`;
        const res = await createRequest.get(path);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!getToken(),
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  //   ------- PROMOTION MUTATION ----------------
  useMutationCollectPromotion(onSuccess, onError) {
    return useMutation(
      async (promotionId) => {
        const path = `/promotion/collectPromotion`;
        const query = { promotionId: promotionId };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};
export default promotionService;
