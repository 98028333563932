import { Button, Tag, message } from "antd";
import { NO_IMAGE } from "assets/images";
import { errorToMessage } from "hooks/functions/errorToMessage";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { HiLocationMarker } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom/dist";
import courseService from "services/course.services";
import { getRoleUser } from "utils";
import RatingStar from "./RatingStar";
import { AlertError } from "./alert/Alert";
import { useEffect, useState } from "react";
import TextWithPopover from "./textWithPopover";
import { TOGGLE_TO_LOAD } from "redux/reducers/cartSlice";
import { FaLink } from "react-icons/fa6";
import { IoMdHome } from "react-icons/io";
import masterService from "services/master.services";
import { ROLE_USER } from "constants/roleUserConstant";
const CourseCard = ({ data }) => {
  const courseId = data?.id;
  const roleUser = getRoleUser();
  const toMyCoursePath = `/${roleUser}/course/${courseId}`;
  const locationType = {
    online: "เรียนผ่านเว็บไซต์",
    at_home: "เรียนที่บ้าน",
    zoom: "เรียนผ่าน zoom",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const myCart = useSelector((state) => state.cart);
  const [messageApi, contextHolder] = message.useMessage();
  const [isPurcharse, setIsPurcharse] = useState(false);
  const warning = () => {
    messageApi.open({
      type: "warning",
      content: "มีรายการนี้แล้ว !",
    });
  };

  const success = () => {
    messageApi.open({
      type: "success",
      content: "เพิ่มรายการลงตะกร้าเรียบร้อย !",
    });
  };

  const { data: getAllMyCourseId, isLoading: isLoadingMyCourse } =
    masterService.useQueryGetAllMyPurchase({});
  const { mutate: addToCart } = courseService.useMutationAddItemToCart(
    (res) => {
      success();
      dispatch(TOGGLE_TO_LOAD());
    },
    (err) => {
      const statusCode = err?.response?.status;
      const isForbidden = statusCode == 403;
      if (isForbidden) {
        navigate("/login");
      } else if (
        err?.response?.data?.status?.description !== "ข้อมูลซ้ำกับในระบบ"
      ) {
        AlertError({ text: errorToMessage(err) });
      }
    }
  );

  const handleAddToCart = () => {
    let isDuplicate = _.find(myCart?.items, (n) => n.courseId === data?.id);
    if (isDuplicate) {
      warning();
    } else {
      addToCart({ courseId: data?.id });
    }
  };

  const handleGoToCourse = () => {
    navigate(toMyCoursePath);
  };

  useEffect(() => {
    if (getAllMyCourseId?.data) {
      setIsPurcharse(
        getAllMyCourseId.data.some((e) => e.courseId === courseId)
      );
    } else {
      setIsPurcharse(false);
    }
  }, [getAllMyCourseId]);

  return (
    <div className="sa-course">
      {contextHolder}
      <span className="badges">
        {data?.delPrice > 0 && <del>{`THB ${data?.delPrice || 0}`}</del>}{" "}
        {`THB ${data?.price || 0}`}
      </span>
      <div className="course-thumb">
        <div style={{ height: "260px" }} className="center">
          <img
            style={{ objectFit: "cover", height: "100%", width: "100%" }}
            src={data?.coverImgUrl || NO_IMAGE}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = NO_IMAGE;
            }}
            alt="cover-course"
            className="img-fluid"
          />
        </div>

        <div className="overlay">
          <div className="icons">
            {
              !roleUser || roleUser == ROLE_USER.student ?
                <ul className="global-list">
                  {!isPurcharse ? (
                    <>
                      <li>
                        <Link to={`/courses/${data?.id}`}>
                          <i className="fas fa-link"></i>
                        </Link>
                      </li>
                      {
                        !data?.isGroup ?
                          <li>
                            <a
                              href="#"
                              style={{ cursor: "pointer" }}
                              onClick={handleAddToCart}
                            >
                              <i className="fas fa-cart-plus"></i>
                            </a>
                          </li>
                          :
                          <></>
                      }

                    </>
                  ) : null}
                </ul>
                : null
            }
          </div>
        </div>

        <div className="rating-date align-self-end">
          <ul className="global-list d-flex star-rating align-self-center">
            <RatingStar rating={data?.rating || 0} />
          </ul>
          <div className="date">
            <span>
              <i className="far fa-clock"></i>
              {moment(data?.date).format("DD MMM YYYY")}
            </span>
          </div>
        </div>
      </div>

      <div className="course-info">
        <div className="info">
          <h2 style={{ minHeight: "40px" }} className="title">
            <Link to={isPurcharse ? toMyCoursePath : `/courses/${data?.id}`}>
              {data?.title || "Title name"}
            </Link>
          </h2>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <p className="m-0">{data?.category || "category name"}</p>
            <Tag color="orange">Course</Tag>
          </div>

          {data?.type && data?.type === "studio" ? (
            <div className="mb-3">
              <a
                href={`https://www.google.com/maps?q=${data?.position?.lat},${data?.position?.lon}`}
                target="_blank"
              >
                <div className="d-flex align-items-center">
                  <HiLocationMarker className="me-2" />
                  {
                    <TextWithPopover
                      text={data?.locationName || "Location name"}
                      maxLength={20}
                    />
                  }
                </div>
              </a>
            </div>
          ) : (
            <div className="mb-3">
              <div className="d-flex align-items-center">
                {data?.type === "at_home" ? (
                  <IoMdHome className="me-2" />
                ) : (
                  <FaLink className="me-2" />
                )}
                {
                  <TextWithPopover
                    text={locationType[data?.type] || "-"}
                    maxLength={20}
                  />
                }
              </div>
            </div>
          )}
        </div>
        <div>
          <ul
            className="global-list w-100"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <li>
              <a
                href="#"
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    marginRight: "8px",
                    width: "32px",
                    height: "32px",
                    borderRadius: "100px",
                    backgroundColor: "grey",
                    overflow: "hidden",
                  }}
                  className="center"
                >
                  <img
                    src={data?.teacherImg || NO_IMAGE}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = NO_IMAGE;
                    }}
                    alt="profile"
                    style={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </div>
                {data?.teacherName || "Teacher Name"}
              </a>
            </li>
          </ul>
        </div>
        <div className="sa-meta">
          <ul
            className="global-list"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <li>
              <a className="center">
                <p style={{ margin: "0px" }} className="text-primary">
                  {numeral(data?.price).format("0,0")} บาท
                </p>
                {data?.delPrice ? (
                  <del
                    style={{
                      marginLeft: "8px",
                      fontWeight: "lighter",
                      fontSize: "14px",
                    }}
                  >
                    {numeral(data?.delPrice).format("0,0")}.-
                  </del>
                ) : null}
              </a>
            </li>
            {isPurcharse ? (
              <li>
                <Button type="primary" onClick={handleGoToCourse}>
                  ไปยังคอร์ส
                </Button>
              </li>
            ) : data.isGroup ? (
              <li>
                <Link to={`/courses/${data?.id}`}>
                  ดูรอบกิจกรรม
                </Link>
              </li>
            ) :
              !roleUser || roleUser == ROLE_USER.student ? (
                <li>
                  <a
                    href="#"
                    style={{ cursor: "pointer" }}
                    onClick={handleAddToCart}
                  >
                    <i className="fa fa-shopping-cart" />
                  </a>
                </li>
              ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
