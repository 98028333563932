import React, { useEffect, useState } from "react";

const Tabs = ({ defaultActiveKey = 1, items = [], props }) => {
  const defaultItems = [
    {
      id: 1,
      key: "description",
      label: "Description",
      children: <h4>Course Details</h4>,
    },
    {
      id: 2,
      key: "course-info",
      label: "Course info",
      children: <h4>Course Information</h4>,
    },
    {
      id: 3,
      key: "reviews",
      label: "Reviews",
      children: <h4>Student Feedback</h4>,
    },
  ];

  const [active, setActive] = useState(defaultActiveKey);
  const [isItems, setIsItems] = useState(defaultItems);

  useEffect(() => {
    if (items.length > 0) {
      setIsItems(items);
    }
  }, [items]);

  return (
    <>
      <div className="description-tab">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          {isItems.map((tab, index) => {
            return (
              <li key={index} className="nav-item" role="presentation">
                <button
                  className={`nav-link ${tab?.id === active && "active"}`}
                  id={`${tab?.key}-tab`}
                  data-bs-toggle="tab"
                  data-bs-target={`#${tab?.key}`}
                  type="button"
                  role="tab"
                  aria-controls={tab?.key}
                  aria-selected="true"
                  onClick={() => {
                    setActive(tab?.id);
                  }}
                >
                  {tab?.label}
                </button>
              </li>
            );
          })}
        </ul>

        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id={`${isItems[active - 1]?.key}`}
            role="tabpanel"
            aria-labelledby={`${isItems[active - 1]?.key}-tab`}
          >
            {isItems[active - 1]?.id === active &&
              isItems[active - 1]?.children}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tabs;
