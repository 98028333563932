import { useIsVisible } from 'hooks/useIsVisible';
import React, { useRef } from 'react'

export default function FadingSection({ className = "", children }) {
    const ref = useRef();
    const isVisible1 = useIsVisible(ref);
    return (
        <section ref={ref} className={`fade ${isVisible1 ? "show" : ""} ${className}`}>
            {children}
        </section>
    )
}
