import { Col, Input, Modal, Row } from "antd";
import useModalHook from "hooks/useModalHook";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import _ from "lodash"
import { AimOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";

const SuggestText = styled.div`
  &{
    cursor: pointer;
    padding: 2px;
    :hover{
      background-color: #E2E8F0;
    }
  }
`;

const SearchBar = styled.div`
  &{
    position: absolute;
    top: 10px;
    left: 85px;
  }
`;

const Map = ({ id, value, onChange, readOnly, ...other }) => {

  const [map, setMap] = useState()
  const [modal, setModal] = useState()
  const [innerValue, setInnerValue] = useState()
  const [search, setSearch] = useState("")
  const [area, setArea] = useState("")
  const [tag, setTag] = useState("")
  const [limit, setLimit] = useState(10)
  const [span, setSpan] = useState("")
  const [longdo, setLongdo] = useState()
  const [suggestions, setSuggestions] = useState([]);
  const mapElement = useRef();
  const resultElement = useRef();
  const suggestElement = useRef();

  const {
    open: openMap,
    handleOpen: _handleMapOpen,
    handleClose: _handleMapClose,
  } = useModalHook();

  const doSuggest = (value) => {
    setSearch(value)
    doSearch(value);
  }

  const mapCallback = () => {
    const longdo = window.longdo
    if (longdo) {
      const map = new longdo.Map({
        placeholder: document.getElementById(id),
        language: 'th',
        zoomRange: {
          min: 6,
          max: 20
        }
      })
      map.Layers.setBase(longdo.Layers.GRAY)
      map.Ui.Fullscreen.visible(false);
      map.Ui.Toolbar.visible(false);
      // map.Ui.Mouse.enableWheel(false);

      if (value?.lat && value?.lon) {
        map.Overlays.clear()
        map.Overlays.add(new longdo.Marker({ ...value }))
      }

      map.Event.bind('click', function () {
        if (!readOnly) {
          const position = map.location(longdo.LocationMode.Pointer)
          setInnerValue(_.cloneDeep(position))
          if (resultElement.current) {
            resultElement.current.innerHTML = ''
          }
        }
      })

      map.Search.placeholder(
        document.getElementById('result'),
        {
          icon: false
        }
      );

      setMap(map)
      setLongdo(longdo)

      const suggest = document.getElementById('suggest');
      if (suggest) {
        map.Event.bind('suggest', function (result) {
          // if (result.meta.keyword != search) return;
          const newSuggestions = result?.data?.map((item) => ({
            label: item.d,
            value: item.w,
          }));
          setSuggestions(newSuggestions);
          suggest.style.display = 'block';
        });
      }
    }
  }

  const onKeyUp = (event) => {
    if ((event || window.event).keyCode != 13)
      return;
    doSearch();
  }

  const onSearchChange = (event) => {
    const value = event.target.value
    setSearch(value)

    if (value.length < 3) {
      suggestElement.current.style.display = 'none';
      return;
    }

    map?.Search.suggest(value, {
      area
    });
  }

  const doSearch = (value) => {
    map?.Search.search(value || search, {
      area,
      tag,
      span,
      limit
    });
    suggestElement.current.style.display = 'none';
  }

  const onOk = () => {
    onChange && onChange(_.cloneDeep(innerValue));
    _handleMapClose()
  }

  const onCancel = () => {
    _handleMapClose()
  }

  useEffect(() => {

    if (openMap) {
      suggestElement.current.style.display = 'none';
      const modal = document.querySelector('.ant-modal-wrap');
      if (modal) {
        setModal(modal)
      }

      const existingScript = document.getElementById('longdoMapScript')

      if (!existingScript) {
        const script = document.createElement('script')
        script.src = `https://api.longdo.com/map/?key=${process.env.REACT_APP_LONGDO_KEY}`
        script.id = 'longdoMapScript'
        document.body.appendChild(script)

        script.onload = () => {
          setTimeout(() => {
            mapCallback()
          }, 500)
        }
      } else {
        setTimeout(() => {
          mapCallback()
        }, 500)
      }
    } else {
      if (mapElement.current) {
        mapElement.current.innerHTML = ''
      }
      if (resultElement.current) {
        resultElement.current.innerHTML = ''
      }
      modal?.removeEventListener('scroll', handleScroll)
      setSearch("")
      setSuggestions([])
    }

  }, [openMap])

  useEffect(() => {
    if (innerValue?.lat && innerValue?.lon && map && longdo) {
      map.Overlays.clear()
      map.Overlays.add(new longdo.Marker(
        { ...innerValue },
        {
          draggable: true,
          clickable: true,
          weight: longdo.OverlayWeight.Top
        }))
    }
  }, [innerValue])

  useEffect(() => {
    if (openMap && !_.isEqual(innerValue, value)) {
      setInnerValue(_.cloneDeep(value))
    }
  }, [value])

  const handleScroll = _.debounce(function () {
    map.resize()
  }, 250);

  useEffect(() => {
    if (map && modal) {
      modal.addEventListener('scroll', handleScroll);
    }
  }, [map, modal])

  return (
    <>
      <Search value={value?.lat && value?.lon ? `${value.lat}, ${value.lon}` : ""} {...other} readOnly enterButton={<div className="d-flex justify-content-center h-100"><AimOutlined /></div>} onSearch={_handleMapOpen} />
      <Modal
        title={"แผนที่"}
        open={openMap}
        onOk={onOk}
        onCancel={onCancel}
        okText="ตกลง"
        cancelText="ยกเลิก"
        width={1000}
        footer={!readOnly ? undefined : ""}
      >
        <Row gutter={24}>
          <Col span={24} className="relative">
            <div ref={mapElement} id={id} className="w-100" style={{ height: "50vh", minHeight: "350px" }}></div>
            <SearchBar>
              <div id={id} className="w-100"></div>
              <div>
                <Input id="search" placeholder="ค้นหาสถานที่" className='w-100' value={search} onChange={onSearchChange} onKeyUp={onKeyUp} />
              </div>
              <div ref={suggestElement} id="suggest" className="position-absolute bg-white p-2 top-14 shadow z-50 w-100">
                {suggestions.map((item, index) => (
                  <SuggestText
                    key={index}
                    onClick={() => doSuggest(item.value)}
                    dangerouslySetInnerHTML={{ __html: item.label }}
                  >
                  </SuggestText>
                ))}
              </div>
              <div style={{ "overflow": "auto", maxHeight: "calc(50vh - 30px)", minHeight: "350px" }}>
                <div ref={resultElement} id="result"></div>
              </div>
            </SearchBar>
          </Col>
        </Row>
      </Modal >
    </>
  );
};

export default Map;
