import { Button, Col, Divider, Input, Row, Select, Spin } from "antd";
import { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import { useNavigate, useParams } from "react-router-dom";
import teacherBackOfficeService from "services/backoffice/teacherBackoffice.services";
const { TextArea } = Input;

export default function TeacherQuizDetail() {
    const { courseId, unitId, quizId } = useParams();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({});

    const { data: quizDetail, isLoading } =
        teacherBackOfficeService.useQueryGetQuizDetailByQuizId(quizId);
    
    useEffect(() => {
        setFormData(quizDetail)
    }, [quizDetail])

    const onSubmit = async () => {
        let data = formData;
        if (formData?.quizType === "writing") {
            const { choiceA, choiceB, choiceC, choiceD, answer, ...rest } = formData;
            data = rest;
        }
        const response = await teacherBackOfficeService.updateQuiz(data);
        if (response.status === 200) {
            navigate(`/teacher/course/${courseId}/quiz/${unitId}`);
        }
    };

    const handleFormInputChange = (e) => {
        const { id, value } = e.target || e;

        setFormData({
            ...formData,
            [id]: value,
        });
    };

    const choice = [
        {
            label: "A",
            value: "A",
        },
        {
            label: "B",
            value: "B",
        },
        {
            label: "C",
            value: "C",
        },
        {
            label: "D",
            value: "D",
        },
    ];

    return (
        <>
            <Spin spinning={isLoading}>
                <Row className="mb-4">
                    <Button type="link" className="center" onClick={() => {
                        navigate(`/teacher/course/${courseId}/quiz/${unitId}`);
                    }}>
                        <IoIosArrowBack size={22} className="me-2" />
                        <p>ย้อนกลับ</p>
                    </Button>
                </Row>
                <Divider />
                <h4 className="mb-4">แก้ไขแบบทดสอบ</h4>
                <Row className="mb-4">
                    <p className="mb-2">คำถาม</p>
                    <TextArea
                        id="question"
                        size="large"
                        rows={4}
                        value={formData?.question}
                        onChange={handleFormInputChange}
                    />
                </Row>
                {formData?.quizType === "choosing" ? <>
                    <Row gutter={[16, 16]} className="mb-4">
                        <Col span={12}>
                            <p className="mb-2">คำตอบ A : </p>
                            <Input
                                id="choiceA"
                                size="large"
                                className="w-100"
                                value={formData?.choiceA}
                                onChange={handleFormInputChange}
                            />
                        </Col>
                        <Col span={12}>
                            <p className="mb-2">คำตอบ B : </p>
                            <Input
                                id="choiceB"
                                size="large"
                                className="w-100"
                                value={formData?.choiceB}
                                onChange={handleFormInputChange}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="mb-4">
                        <Col span={12}>
                            <p className="mb-2">คำตอบ C : </p>
                            <Input
                                id="choiceC"
                                size="large"
                                className="w-100"
                                value={formData?.choiceC}
                                onChange={handleFormInputChange}
                            />
                        </Col>
                        <Col span={12}>
                            <p className="mb-2">คำตอบ D : </p>
                            <Input
                                id="choiceD"
                                size="large"
                                className="w-100"
                                value={formData?.choiceD}
                                onChange={handleFormInputChange}
                            />
                        </Col>
                    </Row>
                    <Divider />
                    <Row gutter={[16, 16]} className="mb-4">
                        <Col span={12}>
                            <p className="mb-2">เฉลยคำตอบ : </p>
                            <Select
                                id="answer"
                                size="large"
                                className="w-100"
                                value={formData?.answer}
                                options={choice}
                                onChange={(e) => handleFormInputChange({ value: e, id: "answer" })}
                            />
                        </Col>
                    </Row>
                </> : null}
                <Divider />
                <Button size="large" type="primary" onClick={onSubmit}>
                    บันทึกแบบทดสอบ
                </Button>
            </Spin>
        </>
    );
}
