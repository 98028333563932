import React, { useRef, useEffect, useState } from 'react';
import SignaturePad from 'signature_pad';
import { Button, Modal } from 'antd';
import { AlertConfirm } from 'components/alert/Alert';

export default function Signature({ id, value, onChange, readOnly, ...other }) {
	const canvasRef = useRef(null);
	const canvasContainerRef = useRef(null);
	const signaturePadRef = useRef(null);
	const [canvasWidth, setCanvasWidth] = useState(400);
	const [editing, setEditing] = useState(false);
	const [signatureImageUrl, setSignatureImageUrl] = useState(null);

	useEffect(() => {
		if (canvasContainerRef.current) {
			setCanvasWidth(canvasContainerRef.current.offsetWidth); // Set initial canvas width based on container
		}

		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		if (canvasRef.current && !readOnly) {
			const canvas = canvasRef.current;
			signaturePadRef.current = new SignaturePad(canvas, {
				penColor: '#000000',
			});
		}
	}, [canvasWidth, editing, readOnly]);

	const handleResize = () => {
		if (canvasContainerRef.current) {
			setCanvasWidth(canvasContainerRef.current.offsetWidth); // Update canvas width on resize
		}
	};

	const handleEditToggle = () => {
		setEditing(!editing);
		if (!editing) {
			clearSignature();
		}
	};

	const handleConfirmToggle = () => {
		AlertConfirm({
			title: "ท่านต้องการเปลี่ยนลายเซ็นหรือไม่?",
			text: '',
			onOk: handleConfirm,
			onCancel: handleConfirmToggle
		});
	};

	const handleConfirm = () => {
		const signatureDataUrl = signaturePadRef.current.toDataURL();
		fetch(signatureDataUrl)
			.then(res => res.blob())
			.then(blob => {
				// Create a file from the Blob
				const signatureFile = new File([blob], `signature-${new Date().getTime()}.png`, { type: "image/png" });
				onChange(signatureFile);
			})
			.catch(error => {
				console.error('Error converting data URL to file:', error);
			});
		setEditing(false)
	};

	const handleClear = () => {
		if (canvasRef.current && !readOnly) {
			const canvas = canvasRef.current;
			signaturePadRef.current = new SignaturePad(canvas, {
				penColor: '#000000',
			});
		}
	};

	const clearSignature = () => {
		if (signaturePadRef.current) {
			signaturePadRef.current.clear();
		}
	};

	useEffect(() => {
		if (value instanceof File) {
			const imageUrl = URL.createObjectURL(value);
			setSignatureImageUrl(imageUrl);
		} else {
			setSignatureImageUrl(value);
		}
	}, [value]);

	return (
		<div className='d-flex gap-2' style={{ width: 'calc(100% - 20px)' }} >

			<div ref={canvasContainerRef} className='w-100 tw-rounded-[4px] tw-border-1 tw-border-black tw-border-solid' style={{ background: "none" }}>
				{!editing ? signatureImageUrl ? <img src={signatureImageUrl} alt="Signature" style={{ width: '100%' }} /> : <p className='text-center'>No signature</p> : (
					<canvas ref={canvasRef} width={canvasWidth}></canvas>
				)}
			</div>
			{!readOnly && (
				<div className='d-flex flex-column gap-2'>
					{editing && (
						<Button type="primary" onClick={handleConfirmToggle}>
							ตกลง
						</Button>
					)}
					{editing && (
						<Button onClick={handleClear}>
							ล้าง
						</Button>
					)}
					<Button onClick={handleEditToggle}>{editing ? 'ยกเลิก' : 'แก้ไข'}</Button>
				</div>
			)}
		</div>
	);
}
