import { Button, Row, Spin, Tag } from "antd";
import { AlertError, AlertSuccess } from "components/alert/Alert";
import Slick from "components/slick";
import { errorToMessage } from "hooks/functions/errorToMessage";
import moment from "moment";
import numeral from "numeral";
import promotionService from "services/promotion.services";

const PromotionList = () => {
  const {
    data: availablePromotion,
    isLoading: isLoadingPromotion,
    refetch: refetchPromotion,
  } = promotionService.useQueryGetAvailablePromotion({});

  const { mutate: collectPromotion } =
    promotionService.useMutationCollectPromotion(
      () => {
        AlertSuccess({ title: "เก็บคูปองสำเร็จ!" });
        refetchPromotion();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  return (
    <>
      {availablePromotion?.data && (
        <div className="mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="order-summary">
                  <div
                    style={{
                      borderBottom: "1px solid #e1e1e1",
                      borderTop: "none",
                      marginTop: "0px",
                      paddingTop: "0px",
                      marginBottom: "25px",
                      paddingBottom: "20px",
                    }}
                    className="order-total"
                  >
                    <p style={{ color: "#5a5a5a", fontSize: "24px" }}>
                      {`คูปองส่วนลด ( ${availablePromotion?.total || 0} )`}
                    </p>
                  </div>
                  <div className="sa-course-content">
                    <Slick
                      className="sa-course-slider"
                      config={{
                        infinite: false,
                        dots: false,
                        arrows: true,
                        slidesToShow: 3,
                        autoplay: true,
                        autoplaySpeed: 2000,
                        speed: 2000,
                        pauseOnHover: true,
                        slidesToScroll: 1,
                        responsive: [
                          {
                            breakpoint: 1200,
                            settings: {
                              slidesToShow: 3,
                            },
                          },
                          {
                            breakpoint: 992,
                            settings: {
                              slidesToShow: 2,
                            },
                          },
                          {
                            breakpoint: 768,
                            settings: {
                              slidesToShow: 1,
                            },
                          },
                          {
                            breakpoint: 481,
                            settings: {
                              slidesToShow: 1,
                            },
                          },
                        ],
                      }}
                    >
                      {isLoadingPromotion ? (
                        <Row className="d-flex justify-content-center align-items-center">
                          <Spin />
                        </Row>
                      ) : (
                        [...(availablePromotion?.data || [])].map(
                          (item, index) => (
                            <Tag
                              key={`promotion-${index}`}
                              className="p-3"
                              color="gold"
                            >
                              <Row
                                style={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr 90px",
                                  alignItems: "start",
                                  gap: "16px",
                                }}
                              >
                                <div>
                                  <p
                                    className="mb-1"
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item?.code}
                                  </p>
                                  {item?.deductionPercentage ? <p>{`ส่วนลด ${item?.deductionPercentage} %`}</p> : null}
                                  {item?.limitAmount ? <p>{`ส่วนลด${item?.deductionPercentage ? "สูงสุด" : ""} ${numeral(
                                    item?.limitAmount
                                  ).format("0,0")} บาท`}</p>: null}
                                  <p>{`หมดอายุ ${moment(
                                    item?.expiryDate
                                  ).format("DD/MM/YYYY")}`}</p>
                                </div>
                                <div>
                                  <Button
                                    block
                                    onClick={() => {
                                      collectPromotion(item?.id);
                                    }}
                                  >
                                    เก็บคูปอง
                                  </Button>
                                </div>
                              </Row>
                            </Tag>
                          )
                        )
                      )}
                    </Slick>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PromotionList;
