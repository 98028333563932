import adminMasterService from "services/adminMaster.services";
import { Button, Row, Table, Tag, Tabs } from "antd";
import { localeCustomTH } from "constants/thaiLocale/localeCustomTH";
import dayjs from "dayjs";
import useModalHook from "hooks/useModalHook";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { MdAdd } from "react-icons/md";
import PromotionFormModal from "./PromotionFormModal";
import { IoSettingsOutline } from "react-icons/io5";

const AdminManagePromotion = () => {
  const [editPromotion, setEditPromotion] = useState(null);
  const [tab, setTab] = useState(1);

  const {
    open: openCreatePromotion,
    handleOpen: _handleOpenCreatePromotion,
    handleClose: _handleCloseCreatePromotion,
  } = useModalHook();

  const handleRowClick = (record) => {
    setEditPromotion({
      ...record,
      startDate: record?.startDate ? dayjs(record?.startDate) : null,
      expiryDate: record?.expiryDate ? dayjs(record?.expiryDate) : null,
    });
    _handleOpenCreatePromotion();
  };

  const {
    data: dataPromotion,
    isFetching: isLoadingPromotion,
    refetch: refetchPromotion,
  } = adminMasterService.useQueryGetAllPromotion({ isExpired: tab === 2 });

  const dataSourcePromotion = dataPromotion
    ? dataPromotion?.map((data) => ({
      id: data.id,
      code: data.code,
      startDate: data.startDate,
      expiryDate: data.expiryDate,
      deductionPercentage: data.deductionPercentage,
      limitAmount: data.limitAmount,
      redeemPoint: data.redeemPoint,
      date: data.date,
      active: data.active,
    }))
    : [];

  const promotionColumns = [
    {
      title: "โค้ด",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "เปอร์เซ็นต์ส่วนลด (%)",
      dataIndex: "deductionPercentage",
      key: "deductionPercentage",
      render: (_, { deductionPercentage }) => (
        <>{deductionPercentage ? `${numeral(deductionPercentage).format("0")} %` : ""}</>
      ),
    },
    {
      title: "ส่วนลดสูงสุด (บาท)",
      dataIndex: "limitAmount",
      key: "limitAmount",
      render: (_, { limitAmount }) => (
        <>{`${numeral(limitAmount).format("0,0")} บาท`}</>
      ),
    },
    {
      title: "คะแนนที่ใช้แลก",
      dataIndex: "redeemPoint",
      key: "redeemPoint",
    },
    {
      title: "วันที่เริ่มใช้",
      dataIndex: "startDate",
      key: "startDate",
      render: (_, { startDate }) => <>{moment(startDate).format("LL")}</>,
    },
    {
      title: "วันที่หมดอายุ",
      dataIndex: "expiryDate",
      key: "expiryDate",
      render: (_, { expiryDate }) => <>{moment(expiryDate).format("LL")}</>,
    },
    {
      title: "จัดการ",
      fixed: "right",
      render: (_, record) => {
        return (
          <Button
            className="center tw-gap-1"
            onClick={() => {
              handleRowClick(record);
            }}
          >
            <IoSettingsOutline />
            <span>แก้ไข</span>
          </Button>
        );
      },
    },
  ]

  useEffect(() => {
    window.scrollTo(0, 440);
  }, []);

  useEffect(() => {
    refetchPromotion()
  }, [tab]);

  const renderContent = () => {
    return <>
      <Row className="my-4 justify-content-between align-items-center">
        <h4 className="m-0 p-0">{`รายการโปรโมชั่น ( ${_.size(
          dataPromotion
        )} )`}</h4>
        <Button
          type="primary"
          className="center"
          onClick={() => {
            setEditPromotion(null);
            _handleOpenCreatePromotion();
          }}
        >
          <MdAdd className="me-2" />
          เพิ่มโปรโมชั่น
        </Button>
      </Row>
      <Row>
        <Table
          scroll={{ x: true }}
          className="w-100 text-nowrap"
          bordered
          locale={localeCustomTH.Table}
          loading={isLoadingPromotion}
          dataSource={dataSourcePromotion}
          columns={promotionColumns}
        />
      </Row>
    </>
  }

  const tabCourse = [
    {
      key: 1,
      label: `คูปองที่ใช้ได้`,
      children: renderContent(),
    },
    {
      key: 2,
      label: `คูปองที่หมดอายุ`,
      children: renderContent(),
    },
  ];

  const handleTabChange = (key) => {
    setTab(key);
  }

  return (
    <>
      <Tabs
        size="large"
        defaultActiveKey={1}
        items={tabCourse}
        onChange={handleTabChange}
      />
      <PromotionFormModal
        data={editPromotion}
        open={openCreatePromotion}
        onCancel={_handleCloseCreatePromotion}
        refetch={refetchPromotion}
      />
    </>
  );
};

export default AdminManagePromotion;
