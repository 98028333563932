import { v4 as uuidv4 } from 'uuid';

const ADMIN_PATH = "admin";
export const adminTabMenu = [
  {
    id: uuidv4(),
    key: "profile",
    name: "โปรไฟล์",
    desc: "จัดการข้อมูลโปรไฟล์ของคุณ",
    path: `/${ADMIN_PATH}/profile`,
    disabled: false,
  },
  {
    id: uuidv4(),
    key: "manageUser",
    name: "ข้อมูลนักเรียน",
    desc: "จัดการข้อมูลนักเรียน",
    path: `/${ADMIN_PATH}/manageUser`,
    disabled: false,
  },
  {
    id: uuidv4(),
    key: "manage-teacher",
    name: "ข้อมูลครูผู้สอน",
    desc: "จัดการข้อมูลครูผู้สอน",
    path: `/${ADMIN_PATH}/manage-teacher`,
    disabled: false,
  },
  {
    id: uuidv4(),
    key: "manageCourse",
    name: "คอร์สเรียน",
    desc: "จัดการข้อมูลคอร์สเรียน",
    path: `/${ADMIN_PATH}/manageCourse`,
    disabled: false,
  },
  {
    id: uuidv4(),
    key: "manageWorkshop",
    name: "เวิร์กชอป",
    desc: "จัดการข้อมูลเวิร์กชอปของคุณ",
    path: `/${ADMIN_PATH}/manageWorkshop`,
    disabled: false,
  },
  {
    id: uuidv4(),
    key: "managePackages",
    name: "แพ็คเกจ",
    desc: "จัดการข้อมูลแพ็กเกจ",
    path: `/${ADMIN_PATH}/managePackages`,
    disabled: true,
  },
  {
    id: uuidv4(),
    key: "managePromotion",
    name: "โปรโมชั่น",
    desc: "จัดการข้อมูลโปรโมชั่น",
    path: `/${ADMIN_PATH}/managePromotion`,
    disabled: false,
  },
  {
    id: uuidv4(),
    key: "order",
    name: "จัดการคำสั่งซื้อ",
    desc: "จัดการคำสั่งซื้อ",
    path: `/${ADMIN_PATH}/order`,
    disabled: false,
  },
  {
    id: uuidv4(),
    key: "redeemCash",
    name: "รายการแลกคะแนน",
    desc: "จัดการข้อมูลรายการแลกคะแนน",
    path: `/${ADMIN_PATH}/redeemCash`,
    disabled: true,
  },
  {
    id: uuidv4(),
    key: "report",
    name: "รายงาน",
    desc: "จัดการข้อมูลรายงาน",
    path: `/${ADMIN_PATH}/report`,
    disabled: true,
  },
  
];
