import { Tag } from "antd";
import numeral from "numeral";
import { Link } from "react-router-dom";
import { NO_IMAGE } from "../../../../../assets/images";
import { courseTypeToText } from "hooks/functions/courseTypeToText";

const ProductCardHistory = ({ tabs = 1, data = {}, method = "online" }) => {
  const onNextPath = () => {
    if (data?.courseId) {
      return `/course/${data?.courseId}`;
    } else if (data?.packageId) {
      return `/package/${data?.packageId}`;
    } else if (data?.sessionGroupId) {
      return `/session/${data?.sessionGroupId}`;
    } else if (data?.subscriptionId) {
      return `/subscription/${data?.subscriptionId}`;
    } else {
      return "#";
    }
  };

  return (
    <div className="tw-flex tw-justify-between tw-mb-3">
      <div className="tw-flex tw-gap-4">
        <div className="tw-w-[80px] tw-h-[80px] tw-rounded-md tw-overflow-hidden">
          <img
            className="tw-w-full tw-h-full tw-object-cover tw-object-center"
            src={data?.coverImgUrl || NO_IMAGE}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = NO_IMAGE;
            }}
            alt="cover"
          />
        </div>
        <div>
          {tabs === 2 && method !== "online" && (
            <Tag className="tw-mb-1" color={"gold"}>
              รอเปิดใช้งาน
            </Tag>
          )}
          <Link to={onNextPath()}>
            <p className="tw-font-medium tw-text-[18px]">
              {data?.courseName || "-"}
            </p>
          </Link>
          <p>
            ประเภทคอร์สเรียน :{" "}
            {data?.type ? courseTypeToText(data?.type) : data?.type || "-"}
          </p>
          <p>ครูผู้สอน : {data?.teacherName || "-"}</p>
        </div>
      </div>
      <div className="tw-text-right">
        <p>x 1</p>
        <p>ราคา {numeral(data?.amount).format("0,0")} บาท</p>
      </div>
    </div>
  );
};

export default ProductCardHistory;
