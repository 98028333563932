import { Modal, Input } from "antd";
import { AlertError, AlertSuccess } from "components/alert/Alert";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { useEffect, useState } from "react";
import paymentService from "services/payment.services";

const TrackingModal = ({ paymentId, open, onCancel, refetch }) => {
  const [trackingNo, setTrackingNo] = useState("");

  const { mutate: orderShipping } = paymentService.useMutationPendingPayment(
    () => {
      AlertSuccess({});
      refetch();
      onCancel();
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
      onCancel();
    }
  );

  useEffect(()=>{
    setTrackingNo("")
  }, [open])

  return (
    <Modal
      title={"Tracking Number"}
      open={open}
      onCancel={onCancel}
      onOk={() => {
        orderShipping({ paymentId, trackingNo });
      }}
      okText="ยืนยัน"
      cancelText="ยกเลิก"
    >
      <Input
        placeholder="Tracking Number"
        size="large"
        onChange={(e) => {
          const text = e.target.value || "";
          setTrackingNo(text?.trim());
        }}
      />
    </Modal>
  );
};

export default TrackingModal;
