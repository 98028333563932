import { Link, useLocation } from "react-router-dom";

export default function Breadcrumb() {
  const location = useLocation();
  const pathname = location.pathname;
  const pathSegments = pathname.split("/").filter(Boolean);

  const pageName = {
    about: "เกี่ยวกับเรา",
    tutor: "แนะนำผู้สอน",
  };

  return (
    <nav style={{ "--bs-breadcrumb-divider": ">" }} aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">หน้าหลัก</Link>
        </li>

        {pathSegments.map((segment, index) => {
          const routeTo = `/${pathSegments.slice(0, index + 1).join("/")}`;
          const isLast = index === pathSegments.length - 1;

          return (
            <li
              className={`breadcrumb-item ${isLast ? "active" : ""}`}
              key={segment}
            >
              {isLast ? (
                pageName[segment]
              ) : (
                <Link to={routeTo}>{pageName[segment]}</Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
