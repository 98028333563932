import { Input, Modal } from "antd";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { useEffect, useState } from "react";
import paymentService from "../services/payment.services";
import { AlertError, AlertSuccess } from "./alert/Alert";
import { useDispatch } from "react-redux";
import { SET_LOADING } from "redux/reducers/loadingSlice";

const ActivateModal = ({ open, onCancel }) => {
  const dispatch = useDispatch()
  const [code, setCode] = useState("");
  const { mutate: activeCODCode, isLoading } = paymentService.useMutationActiveCODCode(
    () => {
      AlertSuccess({});
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );

  useEffect(() => {
    setCode("")
  }, [open])

  useEffect(() => {
    dispatch(SET_LOADING(isLoading))
  }, [isLoading])

  return (
    <Modal
      title={"ระบุรหัสสำหรับเข้าใช้งาน"}
      open={open}
      onCancel={onCancel}
      onOk={() => {
        activeCODCode({ code: code });
      }}
      okText="ยืนยัน"
      cancelText="ยกเลิก"
    >
      <Input
        placeholder="กรุณาใส่ระบุรหัสเปิดใช้งาน"
        size="large"
        onChange={(e) => {
          const text = e.target.value || "";
          setCode(text?.trim());
        }}
      />
    </Modal>
  );
};

export default ActivateModal;
