import { NO_IMAGE } from "assets/images";
import ReactPlayer from "react-player";

export default function Video({
  url = null,
  coverImgUrl,
  handleVideoEnd,
  ...props
}) {

  const playerConfig = {
    file: {
      forceNative: true,
      attributes: {
        controlsList: 'nodownload',
        disablePictureInPicture: true
      }
    }
  };

  return url ? (
    <ReactPlayer
      url={url}
      width={"100%"}
      height={"500px"}
      onEnded={handleVideoEnd}
      config={playerConfig}
      controls
      {...props}
    />
  ) : (
    <img
      width={"100%"}
      height={"500px"}
      src={coverImgUrl || NO_IMAGE}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = NO_IMAGE;
      }}
      alt="NOT FOUND"
    />
  );
}
