import { Modal } from "antd";
import React from "react";

const PreviewImageModal = ({ open, onCancel, previewImage }) => {
  return (
    <Modal open={open} footer={null} onCancel={onCancel}>
      <img
        alt="profile-img"
        style={{
          width: "100%",
        }}
        src={previewImage}
      />
    </Modal>
  );
};

export default PreviewImageModal;
