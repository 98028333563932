import { useQuery } from "react-query";
import { PUBLIC_API } from "utils";
import createRequest from "../utils/request";

const packageService = {
  // :: PACKAGES :: --------------------------------------------------
  useQueryGetAllPackageActive({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllPackageActive", queryObj],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `${PUBLIC_API}/package/getAllPackageActive`;
        const query = { params: { ...queryObj, paging: pagingStr } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetAllUnitByPackageId({ packageId, onSuccess, onError }) {
    return useQuery(
      ["getAllUnitByPackageId", packageId],
      async () => {
        const path = `/package/getAllUnitByPackageId`;
        const query = { params: { packageId: packageId } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: !!packageId,
      }
    );
  },
  useQueryGetPackageById({ id, onSuccess, onError }) {
    return useQuery(
      ["getPackageById", id],
      async () => {
        const path = `/package/getPackageById`;
        const query = { params: { id: id } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: !!id,
      }
    );
  },
  useQueryGetPublicPackageById({ id, onSuccess, onError }) {
    return useQuery(
      ["getPackageById", id],
      async () => {
        const path = `${PUBLIC_API}/package/getPackageById`;
        const query = { params: { id: id } };
        const res = await createRequest.get(path, query);
        return res.data.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: !!id,
      }
    );
  },
};
export default packageService;
