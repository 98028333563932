import { useMutation, useQuery } from "react-query";
import createRequest from "utils/request";

const adminMasterService = {
  // :: ADMIN_MASTER :: --------------------------------------------------
  //   useQueryGetAllCourse({ queryObj, onSuccess, onError }) {
  //     return useQuery(
  //       ["getAllCourse"],
  //       async () => {
  //         const { id, type } = queryObj;
  //         const path = `/course/getAllCourseActive`;
  //         const query = { params: { id, type } };
  //         try {
  //           const res = await createRequest.get(path, query);
  //           return res.data.data;
  //         } catch (error) {
  //           throw error;
  //         }
  //       },
  //       {
  //         onSuccess,
  //         onError,
  //         refetchOnWindowFocus: false,
  //         refetchOnReconnect: false,
  //       }
  //     );
  //   },
  //   ---------------------- :: USE_MUTATION :: --------------------
  useMutationDeleteUser(onSuccess, onError) {
    return useMutation(
      async ({ id }) => {
        const path = `/user/deleteUser`;
        const query = { teacherId: id };
        try {
          const res = await createRequest.delete(path, { params: query });
          return res.data.data;
        } catch (error) {
          throw error;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  // for generate only
  useMutationInsertCourse(onSuccess, onError) {
    return useMutation(
      async ({ data }) => {
        const path = `/course/createCourse`;
        try {
          const res = await createRequest.post(path, { ...data });
          return res.data.data;
        } catch (error) {
          throw error;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useQueryGetAllRedeemCash(type) {
    return useQuery(
      ["getAllRedeemCash"],
      async () => {
        const path = `/point/getAllRedeemCash`;
        const query = { params: { type } };
        try {
          const res = await createRequest.get(path, query);
          return res.data.data;
        } catch (error) {
          throw error;
        }
      },
      {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  async approveRedeemCash(id) {
    const path = "/point/approveRedeemCash";
    const query = { id };
    try {
      return await createRequest.post(path, query);
    } catch (error) {
      console.log(error);
    }
  },
  async deniedRedeemCash(id) {
    const path = "/point/deniedRedeemCash";
    const query = { id };
    try {
      return await createRequest.post(path, query);
    } catch (error) {
      console.log(error);
    }
  },
  async paidRedeemCash(id) {
    const path = "/point/paidRedeemCash";
    const query = { id };
    try {
      return await createRequest.post(path, query);
    } catch (error) {
      console.log(error);
    }
  },
  useQueryGetAllPromotion({ isExpired, onSuccess, onError }) {
    return useQuery(
      ["getAllPromotion", isExpired],
      async () => {
        const path = `/master/getAllPromotion`;
        const query = { params: { isExpired } }
        try {
          const res = await createRequest.get(path, query);
          return res.data.data;
        } catch (error) {
          throw error;
        }
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useMutationCreatePromotion(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/master/createPromotion`;
        const query = { ...values };
        try {
          const res = await createRequest.post(path, query);
          return res.data;
        } catch (error) {
          throw error;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationUpdatePromotion(onSuccess, onError) {
    return useMutation(
      async ({ values }) => {
        const path = `/master/updatePromotion`;
        const query = { ...values };
        try {
          const res = await createRequest.post(path, query);
          return res.data;
        } catch (error) {
          throw error;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
  useMutationDeletePromotion(onSuccess, onError) {
    return useMutation(
      async ({ promotionId }) => {
        const path = `/master/deletePromotion`;
        const query = { params: { promotionId: promotionId } };
        try {
          const res = await createRequest.delete(path, query);
          return res.data;
        } catch (error) {
          throw error;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};

export default adminMasterService;
