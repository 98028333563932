import _ from "lodash";
import { useEffect, useState } from "react";
import masterService from "services/master.services";
import { AlertError } from "../components/alert/Alert";
import { errorToMessage } from "./functions/errorToMessage";

const useProvinceHook = () => {
  const [province, setProvince] = useState(null);
  const [district, setDistrict] = useState(null);
  const [subDistrict, setSubDistrict] = useState(null);
  const [zipCode, setZipCode] = useState(null);

  const [provinceOption, setProvinceOption] = useState([]);
  const [districtOption, setDistrictOption] = useState([]);
  const [subDistrictOption, setSubDistrictOption] = useState([]);
  const [zipCodeOption, setZipCodeOption] = useState([]);
  const [position, setPosition] = useState({ lat: null, lon: null });

  const { data: dataSource, isFetching: isLoadingProvince } =
    masterService.useQueryGetAllProvince({
      onSuccess: (data) => {
        let _province = _.uniqBy(data, "province");
        let _provineOption = _province?.map((n) => {
          return { value: n.province, label: n.province };
        });
        setProvinceOption(_provineOption);
      },
      onError: (err) => {
        AlertError({ text: errorToMessage(err) });
      },
    });

  const handleSetDefaultAddress = (loadData) => {
    if (loadData) {
      const { province, district, subDistrict, zipcode } = loadData;
      setProvince(province);
      setDistrict(district);
      setSubDistrict(subDistrict);
      setZipCode(zipcode);
    }
  };

  useEffect(() => {
    if (province) {
      let _districtOption = _.uniqBy(
        _.map(
          _.filter(dataSource, (n) => n.province === province),
          (x) => {
            return { value: x.amphoe, label: x.amphoe };
          }
        ),
        "label"
      );
      setDistrictOption(_districtOption);
    }
    if (district) {
      let _subDistrictOption = _.uniqBy(
        _.map(
          _.filter(dataSource, (n) => n.amphoe === district),
          (x) => {
            return { value: x.district, label: x.district };
          }
        ),
        "label"
      );
      setSubDistrictOption(_subDistrictOption);
    }
    if (subDistrict) {
      let _zipCodeOption = _.uniqBy(
        _.map(
          _.filter(dataSource, (n) => n.district === subDistrict),
          (x) => {
            return { value: x.zipcode, label: x.zipcode };
          }
        ),
        "label"
      );
      let _zipcode = _zipCodeOption[0]?.value;
      setZipCode(_zipcode);
      setZipCodeOption(_zipCodeOption);
    }
  }, [province, district, subDistrict, zipCode]);

  return {
    isLoadingProvince,
    province,
    district,
    subDistrict,
    zipCode,
    provinceOption,
    districtOption,
    subDistrictOption,
    zipCodeOption,
    position,
    setProvince,
    setDistrict,
    setSubDistrict,
    setZipCode,
    handleSetDefaultAddress,
    setPosition,
  };
};
export default useProvinceHook;
