import { DatePicker, Form, Input, InputNumber, Modal } from "antd";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import Map from "components/map";
import { thaiLocale } from "constants/thaiLocale/thaiLocale";
import dayjs from "dayjs";
import { errorToMessage } from "hooks/functions/errorToMessage";
import _ from "lodash";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import workshopBackofficeServices from "services/backoffice/workshopBackoffice.services";

const SessionFormModal = ({ isEditable, data, refetch, open, onCancel }) => {
  const [form] = Form.useForm();
  const { workshopId } = useParams();

  const { mutate: createSessionGroup } =
    workshopBackofficeServices.useMutationCreateSessionGroup(
      () => {
        refetch();
        onCancel();
        AlertSuccess({});
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );
  const { mutate: updateSessionGroup } =
    workshopBackofficeServices.useMutationUpdateSessionGroup(
      () => {
        refetch();
        onCancel();
        AlertSuccess({});
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const onFinish = async (values) => {
    let payload = {
      ...data,
      ...values,
      startTime: dayjs(values?.startTime).format("YYYY-MM-DD HH:mm"),
      finishTime: dayjs(values?.finishTime).format("YYYY-MM-DD HH:mm"),
      courseId: workshopId,
    };
    if (data?.id) {
      updateSessionGroup({ payload });
    } else {
      createSessionGroup({ payload });
    }
  };

  useEffect(() => {
    if (open) {
      if (!data) {
        if (form.__INTERNAL__.name) {
          form.resetFields();
        }
      } else {
        const { finishTime, startTime, ...other } = data;
        let _field = {
          ...other,
          startTime: dayjs(startTime),
          finishTime: dayjs(finishTime),
        };
        form.setFieldsValue(_field);
      }
    }
  }, [open]);

  return (
    <>
      <Modal
        title={
          isEditable
            ? data
              ? "แก้ไขรอบเวิร์กชอป"
              : "เพิ่มรอบเวิร์กชอป"
            : "รายละเอียดรอบเวิร์กชอป"
        }
        open={open}
        okButtonProps={{ disabled: !isEditable }}
        onOk={() => {
          AlertConfirm({
            onOk: () => {
              form.submit();
            },
          });
        }}
        onCancel={() => {
          if (!data) {
            form.resetFields();
          }
          onCancel();
        }}
        okText="บันทึก"
        cancelText="ยกเลิก"
      >
        <Form
          form={form}
          name="awards"
          className="mt-4"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="วันที่เริ่ม"
            name="startTime"
            rules={[
              {
                required: true,
                message: "กรุณาระบุวันที่เริ่ม",
              },
            ]}
          >
            <DatePicker
              locale={thaiLocale}
              format={"YYYY-MM-DD HH:mm"}
              placeholder="กรุณาระบุวันที่เริ่ม"
              size="large"
              className="w-100"
              showTime={true}
              showSecond={false}
              disabledDate={(currentDate) => {
                const startTimeFieldValue = form.getFieldValue("startTime");
                const finishTimeFieldValue = form.getFieldValue("finishTime");
                return (
                  (finishTimeFieldValue &&
                    currentDate?.isAfter(finishTimeFieldValue)) ||
                  (startTimeFieldValue &&
                    currentDate?.isBefore(startTimeFieldValue)) ||
                  currentDate?.isBefore(dayjs(new Date()).add(5, "day"))
                );
              }}
              disabled={!isEditable}
            />
          </Form.Item>
          <Form.Item
            label="วันที่สิ้นสุด"
            name="finishTime"
            rules={[
              {
                required: true,
                message: "กรุณาระบุวันที่สิ้นสุด",
              },
            ]}
          >
            <DatePicker
              locale={thaiLocale}
              format={"YYYY-MM-DD HH:mm"}
              placeholder="กรุณาระบุวันที่สิ้นสุด"
              size="large"
              className="w-100"
              showTime={true}
              showSecond={false}
              disabledDate={(currentDate) => {
                const startTimeFieldValue = form.getFieldValue("startTime");
                return (
                  currentDate?.isBefore(startTimeFieldValue) ||
                  currentDate?.isBefore(dayjs(new Date()).add(5, "day"))
                );
              }}
              disabled={!isEditable}
            />
          </Form.Item>
          <Form.Item
            label="จำนวนนักเรียนในกลุ่ม"
            name="limitPerGroup"
            rules={[
              {
                required: true,
                message: "กรุณาระบุ จำนวนนักเรียนในกลุ่ม !",
              },
            ]}
          >
            <InputNumber
              size="large"
              className="w-100"
              placeholder="จำนวนนักเรียนในกลุ่ม"
              readOnly={!isEditable}
            />
          </Form.Item>
          <Form.Item
            label="ชื่อสถานที่"
            name="locationName"
            rules={[
              {
                required: true,
                message: "กรุณาระบุชื่อสถานที่!",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="ชื่อสถานที่"
              readOnly={!isEditable}
            />
          </Form.Item>
          <Form.Item
            label="พิกัดสถานที่"
            name="position"
            rules={[
              {
                required: true,
                message: "กรุณาระบุ พิกัดสถานที่ !",
              },
            ]}
          >
            <Map size="large" placeholder="พิกัด" readOnly={!isEditable} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default SessionFormModal;
