import React from "react";

const RatingStar = ({ rating = 0 }) => {
  let active = rating < 0 ? 0 : rating > 5 ? 5 : rating; // max 5
  let inactive = 5 - active;
  return (
    <>
      {active > 0 &&
        [...Array(active)].map((n, i) => (
          <li key={i} className="checked">
            <i className="fas fa-star"></i>
          </li>
        ))}
      {inactive > 0 &&
        [...Array(inactive)].map((n, i) => (
          <li key={i}>
            <i className="fas fa-star"></i>
          </li>
        ))}
    </>
  );
};

export default RatingStar;
