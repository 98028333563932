import { Button, Card, Row, Spin, Tag } from "antd";
import { IoIosArrowBack } from "react-icons/io";
import teacherBackOfficeService from "services/backoffice/teacherBackoffice.services";

const QuizDetailSection = ({ unitItem, setUnitItem }) => {
  const { data: quizDetail, isLoading: isLoadingQuiz } =
    teacherBackOfficeService.useQueryGetQuizByUnitId(unitItem?.id);

  return (
    <div className="w-100">
      <Button type="link" onClick={() => setUnitItem(null)}>
        <div className="center tw-gap-2">
          <IoIosArrowBack /> <span>ย้อนกลับ</span>
        </div>
      </Button>
      <div>
        <h4 className="tw-text-[16px] tw-my-3">
          แบบทดสอบบทเรียน : {unitItem?.unitName}
        </h4>
        <Row>
          {isLoadingQuiz ? (
            <Spin />
          ) : (
            quizDetail?.map((n, i) => {
              const isChoiceType = n?.quizType === "choosing";
              return (
                <Card className="mb-2 w-100" key={`quiz-detail-${i}`}>
                  <div>
                    <p>
                      ข้อที่ {n?.seq} : {n?.question}
                    </p>
                    {isChoiceType && (
                      <div>
                        <p>{`A : ${n?.choiceA}`}</p>
                        <p>{`B : ${n?.choiceB}`}</p>
                        <p>{`C : ${n?.choiceC}`}</p>
                        <p>{`D : ${n?.choiceD}`}</p>
                        <Tag className="tw-mt-2" color="green">
                          เฉลย : {n?.answer}
                        </Tag>
                      </div>
                    )}
                  </div>
                </Card>
              );
            })
          )}
        </Row>
      </div>
    </div>
  );
};

export default QuizDetailSection;
