import {
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Pagination,
  Row,
  Spin,
  Switch,
  Tabs,
} from "antd";
import { NO_IMAGE } from "assets/images";
import { COLOR } from "constants/color";
import dayjs from "dayjs";
import useModalHook from "hooks/useModalHook";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { IoBookOutline } from "react-icons/io5";
import { LiaEditSolid } from "react-icons/lia";
import { MdAdd } from "react-icons/md";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import WorkshopFormModal from "../workshop-modal/WorkshopFormModal";
import { EMPTY_DESCRIPTION } from "constants/emptyDescription";
import { TABS_VALUE, SET_TAB } from "redux/reducers/courseSlice";
import { AlertConfirm, AlertSuccess, AlertError } from "components/alert/Alert";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoMdInformationCircleOutline } from "react-icons/io";
import workshopBackofficeServices from "services/backoffice/workshopBackoffice.services";

export default function TeacherWorkshop() {
  const tab = useSelector((state) => state.course.tab);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const limitPage = 5;
  const [page, setPage] = useState(1);
  const [editWorkshop, setEditWorkshop] = useState(null);

  const {
    open: openCreateWorkshop,
    handleOpen: _handleOpenCreateWorkshop,
    handleClose: _handleCloseCreateWorkshop,
  } = useModalHook();

  const {
    data: getWorkshopTeacher,
    isFetching: isLoadingWorkshop,
    refetch: refetchWorkshop,
  } = workshopBackofficeServices.useQueryGetAllMyTeacherWorkshop({
    queryObj: {
      paging: {
        limit: limitPage,
        start: page * limitPage - limitPage,
      },
      isApprove:
        (tab === TABS_VALUE.ONLINE || tab === TABS_VALUE.FINISH),
      isReject:
        tab === TABS_VALUE.ONLINE || tab === TABS_VALUE.FINISH
          ? false
          : tab === TABS_VALUE.DRAFT,
      isFinished: tab === TABS_VALUE.FINISH,
    },
  });

  const { mutate: sendToApproveWorkshop } =
    courseBackOfficeServices.useMutationSendToApproveCourse(
      () => {
        AlertSuccess({
          text: `
						กรุณารอตรวจสอบเนื้อหาและรอการอนุมัติ
						หากเวิร์กชอปของท่านได้รับการอนุมัติแล้ว ท่านจะได้รับอีเมลแจ้งเตือน
					`,
        });
        refetchWorkshop();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const { mutate: sendToPublishWorkshop } =
    courseBackOfficeServices.useMutationPublishCourse(
      () => {
        AlertSuccess({});
        refetchWorkshop();
      },
      (err) => {
        AlertError({ text: errorToMessage(err) });
      }
    );

  const sendToApprove = (e, n) => {
    e.stopPropagation();
    AlertConfirm({
      text: "กรุณาตรวจสอบเนื้อหาความถูกต้องของเวิร์กชอป หากคุณกดส่งเพื่ออนุมัติแล้ว คุณจะไม่สามารถแก้ไขเนื้อหาใด ๆ ได้",
      onOk: () => {
        sendToApproveWorkshop({ id: n.id });
      },
    });
  };

  const publishWorkshop = (v, e, n) => {
    e.stopPropagation();
    AlertConfirm({
      text: v
        ? "ต้องการเผยแพร่เวิร์กชอปหรือไม่ ?"
        : "ต้องการยกเลิกการเผยแพร่เวิร์กชอปหรือไม่ ?",
      onOk: () => {
        sendToPublishWorkshop({ id: n.id, publishStatus: v });
      },
    });
  };

  const renderContent = () => {
    return (
      <>
        {isLoadingWorkshop ? (
          <Spin className="p-4" />
        ) : (
          <>
            <Row className="my-4 justify-content-between">
              <p style={{ fontSize: "18px", opacity: "0.8" }}>
                เวิร์กชอปทั้งหมด {getWorkshopTeacher?.total || 0} เวิร์กชอป
              </p>
              {tab === TABS_VALUE.ONLINE && (
                <Button
                  type="primary"
                  className="center"
                  onClick={() => {
                    setEditWorkshop(null);
                    _handleOpenCreateWorkshop();
                  }}
                >
                  <MdAdd className="me-2" />
                  สร้างเวิร์กชอป
                </Button>
              )}
            </Row>

            <Row>
              {getWorkshopTeacher?.data ? (
                <Col span={24}>
                  {[...(getWorkshopTeacher?.data || [])].map((n, i) => {
                    return (
                      <Card
                        key={i}
                        title={n?.title}
                        extra={
                          <a
                            className="text-primary center"
                            onClick={() => {
                              setEditWorkshop({
                                ...n,
                                date: dayjs(n?.date),
                              });
                              _handleOpenCreateWorkshop();
                            }}
                          >
                            {tab === TABS_VALUE.ONLINE ||
                            tab === TABS_VALUE.WAITING ||
                            tab === TABS_VALUE.FINISH ? (
                              <div className="d-flex align-items-center gap-1">
                                <IoMdInformationCircleOutline size={18} />
                                ดูรายละเอียด
                              </div>
                            ) : (
                              <div className="d-flex align-items-center gap-1">
                                <LiaEditSolid size={18} />
                                แก้ไข
                              </div>
                            )}
                          </a>
                        }
                        className="mb-3 course-list"
                        style={{
                          width: "100%",
                        }}
                      >
                        <Row
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(`/teacher/workshop/${n?.id}`);
                          }}
                        >
                          <div className="tw-w-full tw-grid tw-grid-cols-1 lg:tw-grid-cols-[150px_1fr_140px] tw-gap-4">
                            <div className="tw-rounded-[8px] tw-overflow-hidden">
                              <img
                                className="tw-object-center tw-object-cover tw-w-full tw-h-full"
                                src={n?.coverImgUrl || NO_IMAGE}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = NO_IMAGE;
                                }}
                                alt="course-cover"
                              />
                            </div>
                            <div>
                              <p className="limited-text">
                                รายละเอียดเวิร์กชอป : {n?.description}
                              </p>

                              <div className="d-flex align-items-center mt-1">
                                <div className="d-flex align-items-center">
                                  <IoBookOutline
                                    color={COLOR.primary}
                                    className="me-2"
                                  />
                                  <p className="text-primary me-4">{`หมวดหมู่ : ${n?.category}`}</p>
                                </div>
                              </div>
                            </div>
                            <Divider className="my-1 lg:tw-hidden" />
                            <div className="tw-flex tw-justify-between tw-items-center tw-flex-row-reverse lg:tw-flex-col lg:tw-items-end">
                              {tab === TABS_VALUE.DRAFT ? (
                                <Button
                                  type="primary"
                                  onClick={(e) => sendToApprove(e, n)}
                                >
                                  ส่งอนุมัติ
                                </Button>
                              ) : tab === TABS_VALUE.ONLINE ? (
                                <div>
                                  เผยแพร่ <br></br>{" "}
                                  <Switch
                                    checked={n?.isPublish}
                                    onClick={(v, e) => publishWorkshop(v, e, n)}
                                  />
                                </div>
                              ) : (
                                <div></div>
                              )}
                              <b>
                                <p
                                  className="text-primary"
                                  style={{ fontSize: "16px" }}
                                >{`ราคา ${numeral(n?.price).format(
                                  "0,0"
                                )} บาท`}</p>
                              </b>
                            </div>
                          </div>
                        </Row>
                      </Card>
                    );
                  })}
                </Col>
              ) : (
                <div className="w-100 center p-4">
                  <Empty description={EMPTY_DESCRIPTION} />
                </div>
              )}
            </Row>
            <Row>
              {getWorkshopTeacher?.total > 0 && (
                <Pagination
                  defaultCurrent={1}
                  current={page}
                  pageSize={limitPage}
                  total={getWorkshopTeacher?.total || 1}
                  onChange={(page) => {
                    setPage(page);
                  }}
                />
              )}
            </Row>
          </>
        )}
      </>
    );
  };

  const tabWorkshop = [
    {
      key: TABS_VALUE.ONLINE,
      label: `ออนไลน์`,
      children: renderContent(),
    },
    {
      key: TABS_VALUE.DRAFT,
      label: `ฉบับร่าง`,
      children: renderContent(),
    },
    {
      key: TABS_VALUE.WAITING,
      label: `รออนุมัติ`,
      children: renderContent(),
    },
    {
      key: TABS_VALUE.FINISH,
      label: `เสร็จสิ้น`,
      children: renderContent(),
    },
  ];

  useEffect(() => {
    refetchWorkshop();
    window.scrollTo(0, 440);
  }, [tab]);

  useEffect(() => {
    setPage(1);
    refetchWorkshop();
  }, [page]);

  return (
    <>
      <Tabs
        size="large"
        activeKey={tab}
        items={tabWorkshop}
        onChange={(key) => dispatch(SET_TAB(key))}
      />
      <WorkshopFormModal
        isEditable={!editWorkshop || editWorkshop.isReject}
        data={editWorkshop}
        refetch={refetchWorkshop}
        open={openCreateWorkshop}
        onOk={_handleCloseCreateWorkshop}
        onCancel={_handleCloseCreateWorkshop}
      />
    </>
  );
}
