import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import { AlertConfirm, AlertError, AlertSuccess } from "components/alert/Alert";
import SelectUniversity from "components/elements/SelectUniversity";
import Map from "components/map";
import Signature from "components/signature";
import { thaiLocale } from "constants/thaiLocale/thaiLocale";
import dayjs from "dayjs";
import { dummyRequest } from "hooks/dummyRequest";
import { onUploadFile } from "hooks/onUploadFile";
import { toDateFormat } from "hooks/toDateFormat";
import useProvinceHook from "hooks/useProvinceHook";
import useUploadFileHook from "hooks/useUploadFileHook";
import _ from "lodash";
import moment from "moment";
import { titleNameOption } from "pages/back-office/constants/select/titleNameOption";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PROFILE_LOADING } from "redux/reducers/profileSlice";
import userBackOfficeServices from "services/backoffice/userBackoffice.services";
const { TextArea } = Input;

const TeacherProfile = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [tempProfile, setTempProfile] = useState([]);
  const [universitySelect, setUniversitySelect] = useState({});

  const {
    isLoadingProvince,
    province,
    district,
    subDistrict,
    zipCode,
    position,
    provinceOption,
    districtOption,
    subDistrictOption,
    zipCodeOption,
    setProvince,
    setDistrict,
    setSubDistrict,
    setZipCode,
    setPosition,
    handleSetDefaultAddress,
  } = useProvinceHook();

  const {
    fileList,
    previewImage,
    previewOpen,
    handlePreview: _handlePreviewUpload,
    handleChange: _handleChangeUpload,
    handleCancel: _handleCancelUpload,
  } = useUploadFileHook();

  const { data: userProfile, isLoading } =
    userBackOfficeServices.useQueryGetUserProfile({
      onSuccess: (res) => {
        let data = {
          ...res,
          title: res?.title ? res?.title.toUpperCase() : null,
          birthDate: res?.birthDate ? dayjs(res?.birthDate) : moment(),
        };
        let profile_img = {
          uid: "-1",
          name: "profile_img.png",
          status: "done",
          url: res?.thumbUrl,
        };
        setTempProfile(res?.thumbUrl ? [profile_img] : []);
        handleSetDefaultAddress(res);
        form.setFieldsValue(data);
      },
    });

  const { mutate: updateUserProfile } =
    userBackOfficeServices.useMutationUpdateUserProfile(
      () => {
        dispatch(PROFILE_LOADING(true));
        AlertSuccess({});
      },
      (err) => {
        AlertError({ text: `Update profile : ${err?.message}` });
      }
    );

  const onFinish = (values) => {
    const prefix = "userProfile";
    AlertConfirm({
      onOk: async () => {
        let fileName =
          _.size(fileList) > 0
            ? await onUploadFile({ prefix, fileList })
            : userProfile?.thumbUrl;
        let signatureFileName = userProfile?.signatureFileName;
        let signatureFile = form.getFieldValue("signature");

        if (signatureFile instanceof File) {
          signatureFileName = await onUploadFile({
            prefix,
            fileList: [signatureFile],
          });
        }
        let isUpdate = {
          ...userProfile,
          ...values,
          birthDate: toDateFormat(values?.birthDate),
          pictureUrl: fileName,
          prefixPath: _.size(fileList) > 0 ? prefix : null,
          signature: signatureFileName,
        };
        updateUserProfile(isUpdate);
      },
    });
  };

  useEffect(() => {
    if (universitySelect?.key === undefined) {
      window.scrollTo(0, 440);
    }
  }, [universitySelect]);

  return (
    <>
      <Spin spinning={isLoading}>
        <Form
          form={form}
          name="user-profile"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <h4 className="mb-4">ข้อมูลส่วนตัว</h4>

          <Row gutter={[16, 16]} className="mb-4">
            <Col>
              <p className="mb-4">รูปโปรไฟล์</p>
              <Upload
                fileList={
                  _.size(fileList) > 0
                    ? fileList
                    : _.size(tempProfile) > 0
                    ? tempProfile
                    : []
                }
                customRequest={dummyRequest}
                accept="image/png, image/jpeg"
                listType="picture-card"
                maxCount={1}
                onPreview={_handlePreviewUpload}
                onChange={_handleChangeUpload}
              >
                <Col>
                  <UploadOutlined />
                  <p> อัปโหลดรูป</p>
                </Col>
              </Upload>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={4}>
              <Form.Item
                label="คำนำหน้า"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุคำนำหน้า!",
                  },
                ]}
              >
                <Select
                  size="large"
                  placeholder="คำนำหน้า"
                  options={titleNameOption}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={10}>
              <Form.Item
                label="ชื่อจริง"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุชื่อจริง!",
                  },
                ]}
              >
                <Input size="large" placeholder="ชื่อจริง" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={10}>
              <Form.Item
                label="นามสกุล"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "กรุณาระบุนามสกุล!",
                  },
                ]}
              >
                <Input size="large" placeholder="นามสกุล" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={8}>
              <Form.Item label="ชื่อเล่น" name="displayName">
                <Input size="large" placeholder="ชื่อเล่น" />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item label="วัน/เดือน/ปีเกิด" name="birthDate">
                <DatePicker
                  format="DD/MM/YYYY"
                  size="large"
                  placeholder="วัน/เดือน/ปีเกิด"
                  className="w-100"
                  locale={thaiLocale}
                />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item
                label="เบอร์โทรศัพท์"
                name="tel"
                rules={[
                  {
                    required: true,
                    message: "กรุณากรอกหมายเลขโทรศัพท์!",
                  },
                ]}
              >
                <Input
                  type="phone"
                  maxLength={10}
                  size="large"
                  placeholder="เบอร์โทรศัพท์"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={8}>
              <Form.Item label="ราคาการเป็นสมาชิก" name="memberPrice">
                <InputNumber
                  className="w-100"
                  size="large"
                  placeholder="ราคาการเป็นสมาชิก"
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={16}>
              <Form.Item
                label="ลายเซ็น (สำหรับออกใบประกาศนียบัตร)"
                name="signature"
              >
                <Signature />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Form.Item
              label="ประวัติครูผู้สอน"
              name="personalRecord"
              className="w-100"
            >
              <TextArea rows={4} size="large" placeholder="ประวัติครูผู้สอน" />
            </Form.Item>
          </Row>

          <h4 className="my-4">การศึกษา</h4>
          <Form.List name="educations">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <React.Fragment key={key}>
                      <Row gutter={[16, 16]}>
                        <Col xs={24} lg={8}>
                          <Form.Item
                            {...restField}
                            name={[name, "universityName"]}
                            label="สถาบันการศึกษา"
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุสถาบันการศึกษา!",
                              },
                            ]}
                          >
                            <SelectUniversity
                              onChange={(value) => {
                                setUniversitySelect({ key, value });
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={16}>
                          {form.getFieldValue(["educations"])[key]
                            ?.universityName === "other" && (
                            <Form.Item
                              {...restField}
                              name={[name, "universityNameOther"]}
                              label="ระบุชื่อสถาบัน"
                            >
                              <Input
                                size="large"
                                placeholder="ระบุชื่อสถาบัน"
                              />
                            </Form.Item>
                          )}
                        </Col>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <Col xs={24} lg={8}>
                          <Form.Item
                            {...restField}
                            name={[name, "degrees"]}
                            label="ระดับการศึกษา"
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระดับการศึกษา!",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="ระดับการศึกษา" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={12}>
                          <Form.Item
                            {...restField}
                            name={[name, "major"]}
                            label="คณะ / สาขา"
                            rules={[
                              {
                                required: true,
                                message: "กรุณาระบุคณะ / สาขา!",
                              },
                            ]}
                          >
                            <Input size="large" placeholder="คณะ / สาขา" />
                          </Form.Item>
                        </Col>
                        <Col flex={1}>
                          <div
                            style={{ height: "71px" }}
                            className="w-100 d-flex align-items-end"
                          >
                            <Button
                              className="w-100"
                              size="large"
                              danger
                              onClick={() => {
                                remove(name);
                              }}
                            >
                              ลบ
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      <Divider />
                    </React.Fragment>
                  );
                })}
                <Form.Item>
                  <Button
                    size="large"
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    เพิ่มสถาบันการศึกษา
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <h4 className="my-4">การสอนนอกสถานที่</h4>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={12}>
              <Form.Item label="Studio" name="locationName">
                <Input size="large" placeholder="Studio" />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item
                label="พิกัด studio"
                name="position"
                rules={[
                  {
                    required: zipCode,
                    message: "กรุณาระบุพิกัด!",
                  },
                ]}
              >
                <Map
                  size="large"
                  placeholder="พิกัด"
                  value={position}
                  onChange={(e) => {
                    setPosition(e);
                    form.setFieldsValue({ position: e });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} lg={12}>
              <Form.Item label="ระยะทางที่ไปสอนได้ (Km) " name="distance">
                <InputNumber
                  className="w-100"
                  size="large"
                  placeholder="สำหรับสอนนอกสถานที่ "
                />
              </Form.Item>
            </Col>
          </Row>

          <h4 className="my-4">สถานที่อยู่อาศัย</h4>
          <Form.Item label="ที่อยู่" name="address">
            <Input size="large" placeholder="ที่อยู่" />
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col xs={24} lg={8}>
              <Form.Item label="จังหวัด" name="province">
                <Select
                  size="large"
                  placeholder="จังหวัด"
                  disabled={isLoadingProvince}
                  value={province}
                  options={provinceOption}
                  onChange={(e) => {
                    setProvince(e);
                    setDistrict(null);
                    setSubDistrict(null);
                    setZipCode(null);
                    form.setFieldsValue({
                      district: null,
                      subDistrict: null,
                      zipcode: null,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item label="อำเภอ" name="district">
                <Select
                  size="large"
                  placeholder="อำเภอ"
                  disabled={!province}
                  value={district}
                  options={districtOption}
                  onChange={(e) => {
                    setDistrict(e);
                    setSubDistrict(null);
                    setZipCode(null);
                    form.setFieldsValue({
                      subDistrict: null,
                      zipcode: null,
                    });
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12} lg={8}>
              <Form.Item label="ตำบล" name="subDistrict">
                <Select
                  size="large"
                  placeholder="ตำบล"
                  disabled={!district && !subDistrict}
                  value={subDistrict}
                  options={subDistrictOption}
                  onChange={(e) => {
                    setSubDistrict(e);
                    setZipCode(zipCode);
                    form.setFieldsValue({ zipcode: zipCode });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col xs={12} lg={8}>
              <Form.Item label="รหัสไปรษณีย์" name="zipcode">
                <Select
                  size="large"
                  placeholder="รหัสไปรษณีย์"
                  disabled={!subDistrict}
                  value={zipCode}
                  options={zipCodeOption}
                  onChange={(e) => {
                    setZipCode(e);
                    form.setFieldsValue({ zipcode: e });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <h4 className="my-4">ช่องทางการติดต่ออื่นๆ</h4>
          <Form.Item label="Line ID" name="lineIdCode">
            <Input size="large" placeholder="Line ID" />
          </Form.Item>
          <Form.Item label="Facebook" name="facebookLink">
            <Input size="large" placeholder="Facebook" />
          </Form.Item>
          <Form.Item label="Website" name="websiteLink">
            <Input size="large" placeholder="Website" />
          </Form.Item>
          <Divider className="my-4" />

          <Form.Item>
            <Button
              style={{ height: "48px", width: "160px" }}
              size="large"
              type="primary"
              htmlType="submit"
            >
              บันทึก
            </Button>
          </Form.Item>
        </Form>
      </Spin>
      <Modal open={previewOpen} footer={null} onCancel={_handleCancelUpload}>
        <img
          alt="profile-img"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};

export default TeacherProfile;
