import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  toggleToLoad: false,
  items: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    LOAD_ITEM_TO_CART: (state, action) => {
      state.items = action.payload;
    },
    TOGGLE_TO_LOAD: (state) => {
      state.toggleToLoad = !state.toggleToLoad;
    },
    // ---- manage this state from api ----
    // add_item_to_cart: (state, action) => {
    //   const newItem = action.payload;
    //   const existingItem = state.items.find((item) => item.id === newItem.id);

    //   if (!existingItem) {
    //     state.items.push(newItem);
    //   }
    // },
    // remove_item_in_cart: (state, action) => {
    //   const itemId = action.payload;
    //   state.items = state.items.filter((item) => item.id !== itemId);
    // },
    // clear_cart: (state) => {
    //   state.items = [];
    // },
  },
});

export const {
  LOAD_ITEM_TO_CART,
  TOGGLE_TO_LOAD
  // add_item_to_cart,
  // remove_item_in_cart,
  // clear_cart,
} = cartSlice.actions;
export default cartSlice.reducer;
