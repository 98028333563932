export const courseCategoryOption = [
  {
    value: "ทั้งหมด",
    label: "ทั้งหมด",
  },
  {
    value: "การร้องเพลง",
    label: "การร้องเพลง",
  },
  {
    value: "ทฤษฎีดนตรีสากล",
    label: "ทฤษฎีดนตรีสากล",
  },
  {
    value: "เปียโน",
    label: "เปียโน",
  },
  {
    value: "กีต้าร์",
    label: "กีต้าร์",
  },
  {
    value: "อูคูเลเล่",
    label: "อูคูเลเล่",
  },
  {
    value: "เบส",
    label: "เบส",
  },
  {
    value: "กลอง",
    label: "กลอง",
  },
  {
    value: "แซกโซโฟน",
    label: "แซกโซโฟน",
  },
  {
    value: "ไวโอลิน",
    label: "ไวโอลิน",
  },
  {
    value: "ขลุ่ย",
    label: "ขลุ่ย",
  },
  {
    value: "ระนาด",
    label: "ระนาด",
  },
  {
    value: "other",
    label: "อื่นๆ",
  },
];
