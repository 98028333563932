// LineCallbackPage.js
import axios from "axios";
import { AlertError } from "components/alert/Alert";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { LINE_CHANNEL_ID, LINE_CHANNEL_SECRET, PUBLIC_API, REDIRECT_URI, URL_API } from "utils";
import { setToken, setUser } from "../../utils/index";

const LineCallbackPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // ดึงรหัสจาก query parameters
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (code) {
      // แลกเปลี่ยนรหัสเพื่อรับ Access Token
      exchangeCodeForAccessToken(code);
    } else {
      console.error("Missing authorization code");
    }
  }, [location]);

  const exchangeCodeForAccessToken = async (code) => {
    const tokenResponse = await axios.post(
      "https://api.line.me/oauth2/v2.1/token",
      `grant_type=authorization_code&code=${code}&redirect_uri=${REDIRECT_URI}&client_id=${LINE_CHANNEL_ID}&client_secret=${LINE_CHANNEL_SECRET}&scope=openid%20email`
    );

    const tokenData = tokenResponse.data;

    const verifyParams = new URLSearchParams();
    verifyParams.append("client_id", LINE_CHANNEL_ID);
    verifyParams.append("id_token", tokenData.id_token);
    const requestVerify = await axios.post(
      "https://api.line.me/oauth2/v2.1/verify",
      verifyParams,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      }
    );

    const data = {
      lineToken: tokenData,
      profile: requestVerify.data,
    };

    try {
      const response = await axios.post(
        URL_API + PUBLIC_API + "/loginLine",
        data.profile
      );
      if (response?.data?.data?.loginStatus) {
        const token = response.data.data.token || null;
        const userInfo = response?.data?.data?.userInfo?.userData || null;
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 2);
        setToken(token);
        setUser(userInfo);
        navigate("/");
      } else if (!response?.data?.data?.loginStatus && response?.data?.data?.loginMsg === "duplicate email") {
        navigate("/verifyEmail/" + response?.data?.data?.token);
      } else {
        let msg = response?.data?.data?.loginMsg;
        AlertError({ text: `LOGIN : ${msg}` });
        navigate("/");
      }
    } catch (error) {
      console.error(
        "เกิดข้อผิดพลาดในการส่งข้อมูลไปยังเซิร์ฟเวอร์หลังบ้าน:",
        error
      );
    }
  };
};

export default LineCallbackPage;
