import { useMutation, useQuery } from "react-query";
import createRequest from "../utils/request";
import { getUserId } from "../utils";

const paymentService = {
  // :: WORKSHOP :: --------------------------------------------------
  useQueryGetAllMyPayment({ queryObj, onSuccess, onError }) {
    const userId = getUserId();
    return useQuery(
      ["getAllMyPayment", queryObj],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `/payment/getAllMyPayment`;
        const query = { params: { ...queryObj, paging: pagingStr } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: !!userId,
      }
    );
  },

  useQueryGetAllPayment({ queryObj, onSuccess, onError }) {
    const userId = getUserId();
    return useQuery(
      ["getAllPayment", queryObj],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `/payment/getAllPayment`;
        const query = { params: { ...queryObj, paging: pagingStr } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        enabled: !!userId,
      }
    );
  },

  useMutationCancelPayment(onSuccess, onError) {
    return useMutation(
      async ({ paymentId }) => {
        const path = `/payment/cancelPayment`;
        const query = { paymentId };
        try {
          const res = await createRequest.post(path, query);
          return res.data;
        } catch (error) {
          throw error;
        }
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  useMutationActiveCODCode(onSuccess, onError) {
    return useMutation(
      async ({ code }) => {
        const path = `/payment/activeCODCode`;
        const query = { code };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },

  useMutationPendingPayment(onSuccess, onError) {
    return useMutation(
      async ({ paymentId, trackingNo }) => {
        const path = `/payment/pendingPayment`;
        const query = { paymentId, trackingNo };
        const res = await createRequest.post(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
      }
    );
  },
};
export default paymentService;
