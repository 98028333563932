import SkeletonCard from "components/skeleton-card/SkeletonCard";
import Slick from "components/slick/index";
import { COURSE_SLIDE_CONFIG } from "pages/back-office/constants/course";
import React from "react";

const SkeletonCardLoader = () => {
  return (
    <Slick className="sa-course-slider" config={COURSE_SLIDE_CONFIG(4)}>
      <SkeletonCard />
      {[...Array(3)].map((_, index) => (
        <SkeletonCard key={index} />
      ))}
    </Slick>
  );
};

export default SkeletonCardLoader;
