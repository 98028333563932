import { Button, Input, Spin } from "antd";
import { bg_tonemusic } from "assets";
import { AlertError, AlertSuccess } from "components/alert/Alert";
import { COLOR } from "constants/color";
import { errorToMessage } from "hooks/functions/errorToMessage";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { CLOSE_LOADING, OPEN_LOADING } from "redux/reducers/loadingSlice";
import masterService from "services/master.services";

const backgroundStyle = {
  margin: 0,
  padding: 0,
  backgroundImage: `url(${bg_tonemusic})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  height: "100vh",
};

const text = {
  color: "#424242",
  opacity: "0.6",
  fontSize: "20px",
};

const header = {
  color: COLOR.primary,
  fontWeight: "bold",
  fontSize: "40px",
  lineHeight: "34px",
};

const VerifyEmail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();
  const [otp, setOtp] = useState(null);
  const [countdown, setCountdown] = useState(0);
  const [isCounting, setIsCounting] = useState(false);
  const [initialText, setInitialText] = useState(true);

  const { data, isFetching } =
    masterService.useQueryGetUserDataFromToken({
      token: id,
      onSuccess: () => { },
      onError: () => {
        AlertError({ text: "ไม่พบข้อมูล", onOk: ()=>{
          navigate("/")
        } });
      },
    });

  const { mutate: sendOTP, isLoading } = masterService.useMutationSendOTP(
    () => {
      setCountdown(60);
      setIsCounting(true);
      setInitialText(false);
      AlertSuccess({
        text: "ส่ง OTP แล้ว กรุณาตรวจสอบอีเมลของคุณ",
      });
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );

  const { mutate: confirmOTP, isLoading: isLoadingOTP } = masterService.useMutationConfirmOTP(
    () => {
      AlertSuccess({
        text: "ผูกบัญชีสำเร็จ กรุณาเข้าสู่ระบบอีกครั้ง",
        onOk: () => {
          navigate("/")
        }
      });
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );

  useEffect(() => {
    let timer;
    if (isCounting && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsCounting(false);
    }
    return () => clearInterval(timer);
  }, [countdown, isCounting]);

  useEffect(() => {
    dispatch(isFetching || isLoading || isLoadingOTP ? OPEN_LOADING() : CLOSE_LOADING())
  }, [isFetching, isLoading, isLoadingOTP])

  const _handleSendOtp = () => {
    sendOTP({ token: id })
  };

  const _handleConfirmOtp = () => {
    confirmOTP({ token: id, otp })
  };

  return (
    <>
      {
        loading ?
          <div className="tw-flex tw-justify-center tw-items-center tw-w-screen tw-h-full tw-overflow-hidden tw-absolute tw-bg-black tw-bg-opacity-20 tw-z-50">
            <Spin size="large" /></div> :
          null
      }
      <div style={backgroundStyle}>
        <div className="sg-section tw-h-screen tw-flex tw-justify-center tw-items-center">
          <div className="section-content tw-mt-[-250px]">
            <div className="container">
              <div className="ragister-account text-center">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="account-content">
                      <div
                        style={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        className="d-flex w-100"
                      >
                        <h1 className="w-100">ยืนยันอีเมล</h1>
                        <Link to="/" style={{ width: "200px" }}>
                          <u className="me-2">กลับสู่หน้าหลัก</u>
                        </Link>
                      </div>
                      <div className="tw-p-4">
                        <p className="tw-m-8">
                          อีเมล {data?.data} ถูกลงทะเบียนแล้วในระบบ กรุณายืนยันรหัส 6 หลักเพื่อผูกบัญชีอีเมลของท่าน โดยรหัสยืนยันจะถูกส่งไปที่อีเมลของท่าน
                        </p>

                        {
                          !initialText && <div className="tw-mb-4">
                            <Input.OTP
                              length={6}
                              formatter={(str) => str.toUpperCase()}
                              onChange={(e) => {
                                setOtp(e);
                              }}
                            />
                          </div>
                        }

                        <div className="middle-content my-2">
                          {
                            !initialText && <Button
                              type="text"
                              onClick={_handleConfirmOtp}
                            >
                              ยืนยันรหัส
                            </Button>
                          }
                          <Button
                            className="mb-2"
                            type="text"
                            disabled={isCounting}
                            onClick={!isCounting ? _handleSendOtp : null}
                          >

                            {isCounting ? `กรุณารอ ${countdown} วินาที` : (initialText ? 'ส่งรหัสยืนยัน' : 'ส่งรหัสยืนยันอีกครั้ง')}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;
