export const courseTypeOption = [
  {
    value: "ทั้งหมด",
    label: "ทั้งหมด",
  },
  {
    value: "online",
    label: "เรียนออนไลน์ผ่านระบบ",
  },
  {
    value: "zoom",
    label: "เรียนตัวต่อตัวผ่าน Zoom",
  },
  {
    value: "studio",
    label: "เรียนตัวต่อตัวที่ Studio",
  },
  {
    value: "at_home",
    label: "เรียนตัวต่อตัวที่บ้านนักเรียน",
  },
];
