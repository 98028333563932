import { Button, Checkbox, Form, Input, InputNumber, Modal, Radio, Typography } from "antd";
import { AlertError, AlertSuccess } from "components/alert/Alert";
import { errorToMessage } from "hooks/functions/errorToMessage";
import Calendar from "pages/back-office/components/Calendar";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import courseBackOfficeServices from "services/backoffice/courseBackoffice.services";
import CalendarModal from "./CalendarModal";
import useModalHook from "hooks/useModalHook";
import moment from "moment";
import Title from "antd/es/skeleton/Title";
import Paragraph from "antd/es/typography/Paragraph";
import TextWithPopover from "components/textWithPopover";
import { MdDelete } from "react-icons/md";
import { AlertConfirm } from "components/alert/Alert";
const { TextArea } = Input;

const GroupModal = ({ data, index, total, open, onCancel, onOk, isEditable, refetch }) => {
  const { courseId } = useParams();
  const [form] = Form.useForm();
  const [fieldsChange, setFieldsChange] = useState({});
  const [currentDate, setCurrentDate] = useState();

  const {
    open: openCalendar,
    handleOpen: _handleOpenCalendar,
    handleClose: _handleCloseCalendar,
  } = useModalHook();

  const { mutate: createZoomGroup } = courseBackOfficeServices.useMutationCreateZoomGroup(
    () => {
      onCancel();
      AlertSuccess({ text: "สร้างกลุ่มเรียนสำเร็จ!" });
      form.resetFields();
      refetch();
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );
  const { mutate: updateZoomGroup } = courseBackOfficeServices.useMutationUpdateZoomGroup(
    () => {
      onCancel();
      AlertSuccess({ text: "อัปเดตกลุ่มเรียนสำเร็จ!" });
      form.resetFields();
      refetch();
    },
    (err) => {
      AlertError({ text: errorToMessage(err) });
    }
  );

  const onFinish = async (values) => {
    if (data?.id) {
      let _update = {
        ...data,
        ...values,
      };
      updateZoomGroup({ values: _update });
    } else {
      let _create = {
        courseId,
        ...values,
      };
      createZoomGroup({ values: _create });
    }
  };

  const removeEvent = (id) => {
    AlertConfirm({
      text: "ต้องการลบตารางเวลาหรือไม่",
      onOk: () => {
        const schedule = { ...fieldsChange?.schedule }

        for (let key in schedule) {
          let filteredArray = schedule[key].filter(item => item.id === id || item.refId === id);
          if (filteredArray.length === 1) {
            delete schedule[key];
          }
        }

        setFieldsChange({
          ...fieldsChange,
          schedule
        });

        form.setFieldsValue({
          schedule,
        });
      }
    })
  }

  const onClickDate = (value) => {
    const nextWeek = new Date();
    nextWeek.setDate(nextWeek.getDate() + 6);

    if (new Date(value.getFullYear(), value.getMonth(), value.getDate()) >= nextWeek) {
      setCurrentDate(value)
      _handleOpenCalendar()
    } else {
      AlertError({ text: "กรุณาเลือกล่วงหน้าอย่างน้อย 1 สัปดาห์" })
    }
  }

  const handleCalendarChange = (value) => {
    setFieldsChange({
      ...fieldsChange,
      schedule: { ...fieldsChange?.schedule, ...value },
    });
    form.setFieldsValue({
      schedule: { ...fieldsChange?.schedule, ...value },
    });
  }

  const eventTitleContent = (item) => {
    return (
      <TextWithPopover
        className="tw-ml-2"
        text={`${moment(item.startTime, 'HH:mm').locale('th').format('HH:mm')} ${item.note || ""}`}
        maxLength={40}
      />
    )
  }

  const calendarPopover = (item) => {
    const startTimeFormatted = moment(item.startDate + item.startTime, 'YYYY-MM-DDHH:mm').locale('th').format('dddd, MMMM D ⋅ HH:mm');
    const finishTimeFormatted = moment(item.finishTime, 'HH:mm').locale('th').format('HH:mm');
    return (
      <div onClick={e => e.stopPropagation()}>
        <Typography>
          <Paragraph className='!tw-font-kanit !tw-mb-0'>
            {startTimeFormatted} – {finishTimeFormatted}
            {
              isEditable ? <Button type="text" onClick={() => removeEvent(item.refId || item.id)}>
                <MdDelete />
              </Button> : null
            }
          </Paragraph>
          {
            item.note ?
              <Paragraph className='!tw-font-kanit'>
                หมายเหตุ: {item.note}
              </Paragraph>
              : null
          }
        </Typography>
      </div>
    )
  }

  useEffect(() => {
    if (data?.id) {
      let fields = {
        ...data,
      };
      form.setFieldsValue(fields);
      setFieldsChange(fields);
    } else {
      if (form.__INTERNAL__.name) {
        form.resetFields();
      }
      setFieldsChange(null);
    }
  }, [open, data, form]);

  return (
    <Modal
      title={"รายละเอียดกลุ่มเรียน"}
      open={open}
      onOk={() => {
        form.submit();
      }}
      okButtonProps={{ disabled: !isEditable }}
      onCancel={onCancel}
      okText="บันทึก"
      cancelText="ยกเลิก"
      width={800}
    >
      <Form
        form={form}
        name="group-form"
        className="mt-4"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >

        {data?.id ? (
          <h5>{`กลุ่มที่ ${index}`}</h5>
        ) : (
          <h5>{`กลุ่มที่ ${total + 1}`}</h5>
        )}

        <Form.Item
          label="ลิงค์ zoom"
          name="linkZoom"
          rules={[
            {
              required: true,
              message: "กรุณาระบุลิงค์ zoom!",
            },
          ]}
        >
          <Input
            size="large"
            placeholder="ลิงค์ zoom"
            readOnly={!isEditable}
          />
        </Form.Item>

        <Form.Item
          label="จำนวนนักเรียนในกลุ่ม"
          name="limitPerGroup"
          rules={[
            {
              required: true,
              message: "กรุณาระบุ จำนวนนักเรียนในกลุ่ม !",
            },
          ]}
        >
          <InputNumber
            size="large"
            className="w-100"
            placeholder="จำนวนนักเรียนในกลุ่ม"
            readOnly={!isEditable}
          />
        </Form.Item>

        <Form.Item
          label="ลิงค์กลุ่มเรียน"
          name="linkGroup"
        >
          <Input
            size="large"
            placeholder="ลิงค์กลุ่มเรียน"
            readOnly={!isEditable}
          />
        </Form.Item>

        <Form.Item
          label="ตารางเรียน"
          name="schedule"
        >
          <Calendar
            placeholder="ตารางเรียน"
            onClickDate={onClickDate}
            listData={fieldsChange?.schedule}
            eventTitleContent={eventTitleContent}
            innerPopover={calendarPopover}
            readOnly={!isEditable}
          />
        </Form.Item>

      </Form>
      <CalendarModal
        currentDate={currentDate}
        open={openCalendar}
        onOk={_handleCloseCalendar}
        onCancel={_handleCloseCalendar}
        onChange={handleCalendarChange}
      />
    </Modal>
  );
};

export default GroupModal;
