import { ROLE_USER } from "constants/roleUserConstant";
import { v4 as uuidv4 } from 'uuid';

const ROLE_PATH = ROLE_USER.teacher;
export const teacherTabMenu = [
  {
    id: uuidv4(),
    key: "profile",
    name: "โปรไฟล์",
    desc: "จัดการข้อมูลโปรไฟล์ของคุณ",
    path: `/${ROLE_PATH}/profile`,
    disabled: false,
    isKruNut: false,
  },
  {
    id: uuidv4(),
    key: "account",
    name: "บัญชีของฉัน",
    desc: "จัดการข้อมูลบัญชีของคุณ",
    path: `/${ROLE_PATH}/account`,
    disabled: false,
    isKruNut: false,
  },
  {
    id: uuidv4(),
    key: "award",
    name: "รางวัลและผลงาน",
    desc: "จัดการข้อมูลรางวัลและผลงานของคุณ",
    path: `/${ROLE_PATH}/award`,
    disabled: false,
    isKruNut: true,
  },
  {
    id: uuidv4(),
    key: "availableTime",
    name: "เวลาที่เปิดสอน",
    desc: "จัดการข้อมูลวันเวลาที่สอนของคุณ",
    path: `/${ROLE_PATH}/availableTime`,
    disabled: false,
    isKruNut: true,
  },
  {
    id: uuidv4(),
    key: "course",
    name: "คอร์สเรียน",
    desc: "จัดการข้อมูลคอร์สเรียนของคุณ",
    path: `/${ROLE_PATH}/course`,
    disabled: false,
    isKruNut: true,
  },
  {
    id: uuidv4(),
    key: "packages",
    name: "แพ็กเกจ",
    desc: "จัดการข้อมูลแพ็กเกจของคุณ",
    path: `/${ROLE_PATH}/packages`,
    disabled: true,
    isKruNut: true,
  },
  {
    id: uuidv4(),
    key: "workshop",
    name: "เวิร์กชอป",
    desc: "จัดการข้อมูลเวิร์กชอปของคุณ",
    path: `/${ROLE_PATH}/workshop`,
    disabled: false,
    isKruNut: true,
  },
  {
    id: uuidv4(),
    key: "homework",
    name: "การบ้านนักเรียน",
    desc: "จัดการข้อมูลการบ้านนักเรียนของคุณ",
    path: `/${ROLE_PATH}/homework`,
    disabled: false,
    isKruNut: true,
  },
  {
    id: uuidv4(),
    key: "quiz",
    name: "แบบทดสอบ",
    desc: "จัดการแบบทดสอบของคุณ",
    path: `/${ROLE_PATH}/quiz`,
    disabled: false,
    isKruNut: true,
  },
  {
    id: uuidv4(),
    key: "report",
    name: "รายงาน",
    desc: "จัดการข้อมูลรายงานของคุณ",
    path: `/${ROLE_PATH}/report`,
    disabled: true,
    isKruNut: false,
  },
  // {
  //   id: uuidv4(),
  //   key: "quiz",
  //   name: "แบบทดสอบ",
  //   desc: "จัดการข้อมูลแบบทดสอบของคุณ",
  //   path: `/${ROLE_PATH}/quiz`,
  //   disabled: false,
  //   isKruNut: true,
  // },
];
