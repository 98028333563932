import { useMutation, useQuery } from "react-query";
import { PUBLIC_API } from "utils";
import createRequest from "../utils/request";

const workshopService = {
  // :: WORKSHOP :: --------------------------------------------------
  useQueryGetAllWorkshop({ queryObj, onSuccess, onError }) {
    return useQuery(
      ["getAllWorkshop", queryObj],
      async () => {
        let pagingStr = JSON.stringify(queryObj?.paging);
        const path = `${PUBLIC_API}/workshop/getAllWorkshopActive`;
        const query = { params: { ...queryObj, paging: pagingStr } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
  useQueryGetAllSessionGroupByWorkshopId({ workshopId, onSuccess, onError }) {
    return useQuery(
      ["getAllSessionGroupByWorkshopId", workshopId],
      async () => {
        const path = `${PUBLIC_API}/workshop/getAllSessionGroupByWorkshopId`;
        const query = { params: { workshopId } };
        const res = await createRequest.get(path, query);
        return res.data;
      },
      {
        onSuccess,
        onError,
        enabled: !!workshopId,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      }
    );
  },
};
export default workshopService;
